import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {get, isEmpty} from 'lodash';
import { AuthContext } from '@basecomponents/Auth';
import EARContext from "@petcomponents/EditAccessRights/Context";
import mgaSpecificPermissionId from "@src/utilities/mga-specific-permission-id";
import remoteActionQuery from "@queries/remote-action.gql";

/*
---------------------------------------------------------------
|   VALUE   |   DESCRIPTION     |   VISIBLE   |   READ-ONLY   |
---------------------------------------------------------------
|   D       |   DISABLED        |   TRUE      |   TRUE        |
|   E       |   EDITABLE        |   TRUE      |   FALSE       |
|   H       |   HIDDEN          |   FALSE     |   NA          |
---------------------------------------------------------------
*/

const EARProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { data, loading } = useQuery(remoteActionQuery, {
    skip: isEmpty(user) || get(user, 'customRole',null) === null,
    variables: {
      name: 'get-user-permissions',
      params: JSON.stringify({
        role: get(user, 'customRole'),
      }),
    },
  });
  const mga = get(user, 'customMga', 'default');
  const permissions = JSON.parse(get(data, 'remoteAction.data', '{}'));

  return loading || !user ? null : (
    <EARContext.Provider
      value={{
        isEditable: (id) => {
          if (Object.keys(permissions).length === 0) {
            return false;
          }
          if (id) {
            if (id.includes('::mga')) {
              return ['E'].includes(
                get(permissions, mgaSpecificPermissionId(id, mga), 'E')
              );
            }
          }
          return ['E'].includes(get(permissions, id, 'E'));
        },
        isVisible: (id) => {
          if (Object.keys(permissions).length === 0) {
            return false;
          }
          if (id) {
            if (id.includes('::mga')) {
              return ['E', 'D'].includes(
                get(permissions, mgaSpecificPermissionId(id, mga), 'E')
              );
            }
          }
          return ['E', 'D'].includes(get(permissions, id, 'E'));
        },
      }}
    >
      {children}
    </EARContext.Provider>
  );
};

EARProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EARProvider;
