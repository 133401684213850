import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
import arrayMutators from 'final-form-arrays';
import { navigate, useParams } from '@reach/router';
import { get, isPlainObject } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { ApolloConsumer } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { required } from "@src/utilities/validators";
import InputText from "@basecomponents/Input";
import dashboardConstants from "@src/utilities/dashboard-constants";
import { labelHandler, labelUtils } from "@src/utilities/label-utils";
import listingConfig from "@src/utilities/listing-config";
import setDashboardURL from "@src/utilities/set-dashboard-url";
import ButtonGroups from "@basecomponents/ButtonGroups";
import DashboardFilterForm from "@petcomponents/DashboardFilterForm";
import DashboardPaginator from "@petcomponents/DashboardPaginator";
import Icon from "@src/components/Icon";
import Table from "@basecomponents/Table";
import TableWrapper from "@basecomponents/TableWrapper";
import ClassDriversSection from "@petcomponents/ProductAdminPage/ClassConfiguration/ClassDriversSection/index";
import remoteActionQuery from "@queries/remote-action.gql";
import getFormattedValue from "@src/utilities/get-formatted-value";
import getPrettyString from "@src/utilities/get-pretty-string";
import Spinner from "@basecomponents/Spinner";
import convertCurrencyToInt from "@src/utilities/convert-currency-to-int";
import useSnackbar from "@src/utilities/use-snackbar";
import GetData from "@src/utilities/get-data";
import ToolbarButton from "@basecomponents/ToolbarButton";
import config from "@src/config.json";
import generateRedirectPath from "@src/utilities/generate-redirect-path";
import dateUtils from "@src/utilities/date-utils";

/**
 * @category ProductAdmin
 * @param {Object} location
 * @param {string} tableType
 * @param {string} name
 * @param {*} rest
 * @returns {React.FC}
 */
const ClassConfiguration = ({
  location,
  tableType,
  name,
  isRenewal,
  ...rest
}) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const planId = get(queryParams, 'planId', '');
  const showFilters = false;
  const [nextLoading, setNextLoading] = useState(false);

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  const { filters, pageCriteria } = dashboardConstants.getCriterias({
    search: location.search,
    type: tableType,
  });

  const query = dashboardConstants.getQuery({
    filters,
    page: pageCriteria,
  });

  const {
    apiData = {},
    loading: eligibilityClassesLoading,
    refetch,
  } = GetData(
    'get-eligibility-class',
    JSON.stringify([...query, { planId }]),
    !planId
  );

  const { content = [], totalElements = 0, numberOfElements = 0 } = apiData;

  const { apiData: { classDrivers = [] } = {}, loading: classDriversLoading } =
    GetData('get-class-drivers', JSON.stringify({}));

  const list = listingConfig(tableType);

  const getRowData = ({ data, key }) => {
    const { gridHeaders, actions } = list;
    const row = gridHeaders.map((headerItem) => {
      const rowItem = get(data, `${headerItem.name}`, '');
      if (typeof rowItem === 'boolean') {
        return (
          <Box
            as="input"
            checked={rowItem}
            disabled
            id={`checkbox-${key}`}
            type="checkbox"
          />
        );
      }
      if (isPlainObject(rowItem)) {
        return (
          <>
            {Object.keys(rowItem).map((driverKey) => (
              <Box key={driverKey}>
                {getPrettyString(driverKey)} -{' '}
                {getFormattedValue(
                  getPrettyString(driverKey),
                  rowItem[driverKey]
                )}
                <br />
              </Box>
            ))}
          </>
        );
      }
      return rowItem;
    });
    if (actions && actions.length > 0) {
      const actionOptions = actions.map((actionItem) => {
        const { action } = actionItem;
        switch (action) {
          case 'edit':
            return (
              <Button key={`${key}-edit`} link="/" simple>
                <Icon color="primary" height="1rem" svg="edit" width="1rem" />
              </Button>
            );
          case 'activate':
            return (
              <Button key={`${key}-manage`} link="/" simple>
                <Icon
                  color="accentDark"
                  height="1rem"
                  svg="manage"
                  width="1rem"
                />
              </Button>
            );
          default:
        }
        return null;
      });
      row.push(<ButtonGroups leftAlign>{actionOptions}</ButtonGroups>);
    }
    return row;
  };

  const getHeaders = ({ headers }) => {
    const { actions, gridHeaders } = headers;
    const result = gridHeaders.map(({ name, sortable }) => {
      const label = labelUtils({ key: name, type: tableType });
      if (sortable) {
        return {
          label,
          value: Array.isArray(name) ? name.join('.') : name,
        };
      }
      return label;
    });
    if (actions && actions.length > 0) {
      result.push('Actions');
    }
    return result;
  };

  const renderClassesTable = () => {
    if (eligibilityClassesLoading) {
      return <Spinner />;
    }
    return (
      <Box sx={{ bg: 'white', borderRadius: 4, width: '600px' }}>
        <DashboardFilterForm
          filters={filters}
          page={pageCriteria}
          showFilters={showFilters}
        />
        <TableWrapper>
          <Table
            header={getHeaders({
              headers: list,
            })}
            onSortUpdate={() => {
              return navigate(
                setDashboardURL({
                  filters,
                  filterValues: filters.values,
                  page: pageCriteria,
                  pathname: location.pathname,
                }),
                { replace: true }
              );
            }}
            rows={
              content &&
              content.map((rowItem, index) =>
                getRowData({
                  data: rowItem,
                  key: index,
                })
              )
            }
            tableSx={{ fontSize: 2 }}
          />
          <DashboardPaginator
            filters={{
              page: pageCriteria.offset,
            }}
            goToNextPage={() =>
              navigate(
                setDashboardURL({
                  filters,
                  filterValues: filters.values,
                  page: {
                    offset: pageCriteria.offset + pageCriteria.pageSize,
                    pageSize: pageCriteria.pageSize,
                  },
                  pathname: location.pathname,
                }),
                { replace: true }
              )
            }
            goToPreviousPage={() =>
              navigate(
                setDashboardURL({
                  filters,
                  filterValues: filters.values,
                  page: {
                    offset: pageCriteria.offset - pageCriteria.pageSize,
                    pageSize: pageCriteria.pageSize,
                  },
                  pathname: location.pathname,
                }),
                { replace: true }
              )
            }
            isClientSidePagination
            pageTotal={numberOfElements}
            sx={{
              fontSize: 2,
              pt: 5,
            }}
            totalRecordCount={totalElements}
          />
        </TableWrapper>
      </Box>
    );
  };

  if (classDriversLoading) {
    return <Spinner />;
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const onCreateClass = (values, form) => {
          const driverValues = values.driverValue.map((item) => {
            const { dataType, type, keyValue } = classDrivers.find(
              (x) => x.keyValue === item.name
            );
            let values;
            if (dataType === 'Integer') {
              if (type === 'RANGE') {
                values = [
                  convertCurrencyToInt(`${item[`${keyValue}_${type}_min`]}`),
                  convertCurrencyToInt(`${item[`${keyValue}_${type}_max`]}`),
                ];
              } else if (type === 'HOUR_RANGE' || type === 'PERCENT_RANGE') {
                const key =
                  type === 'HOUR_RANGE'
                    ? ['minhour', 'maxhour']
                    : ['minrate', 'maxrate'];
                const minValue = `${item[`${keyValue}_${type}_${key[0]}`]}`;
                const maxValue = `${item[`${keyValue}_${type}_${key[1]}`]}`;
                values = [
                  Number(minValue.split(/[ %]+/)[0]),
                  Number(maxValue.split(/[ %]+/)[0]),
                ];
              } else {
                const value = `${item[`${keyValue}_${type}`]}`;
                values = value
                  .replace(/\s*,\s*/g, ',')
                  .split(',')
                  .map((x) => Number(x));
              }
              return {
                [keyValue]: values,
              };
            }
            if (dataType === 'String') {
              const value = `${item[`${keyValue}_${type}`]}`;
              return {
                [keyValue]: value.replace(/\s*,\s*/g, ',').split(','),
              };
            }
            if (dataType === 'Timestamp') {
              const values = [
                dateUtils.setAPIDateOnly(
                  `${item[`${keyValue}_${type}_start`]}`
                ),
                dateUtils.setAPIDateOnly(`${item[`${keyValue}_${type}_end`]}`),
              ];
              return {
                [keyValue]: values,
              };
            }
            return {
              [keyValue]: [`${item[`${keyValue}_${type}`]}`],
            };
          });
          const payload = {
            active: true,
            driverValue: {
              ...Object.assign(...driverValues),
            },
            eligibilityClassName: get(values, 'eligibilityClassName', ''),
            planId,
          };
          return client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'create-eligibility-class',
                params: JSON.stringify(payload),
              },
            })
            .then(() => {
              form.restart();
              refetch(
                'get-eligibility-class',
                JSON.stringify([...query, { planId }])
              );
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };

        const onNextClick = () => {
          setNextLoading(true);
          return client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'get-eligibility-class',
                params: JSON.stringify([...query, { planId }]),
              },
            })
            .then((response) => {
              const content = get(
                JSON.parse(get(response, 'data.remoteAction.data', '')),
                'content',
                []
              );

              if (content.length > 0) {
                setNextLoading(false);
                return navigate(
                  generateRedirectPath({
                    ...location,
                    queryParams: { [name]: isRenewal ? 7 : 1 },
                  })
                );
              }
              setNextLoading(false);
              return setErrorSnack(
                'Create at least one class to move forward.',
                config.notificationDuration
              );
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };

        return (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={{
                driverValue: [{}],
              }}
              mutators={arrayMutators}
              onSubmit={onCreateClass}
              render={({ handleSubmit, submitting }) => {
                return (
                  <Box sx={{ mx: 'auto', width: '100%' }}>
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          alignItems: 'center',
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          margin: '0 auto 30px',
                          padding: 3,
                          width: '25rem',
                        }}
                      >
                        <Box
                          as="h3"
                          color="black"
                          display="flex"
                          justifyContent="flex-start"
                          sx={{
                            margin: '20px 0px 20px 0px',
                            padding: 3,
                            width: '25rem',
                          }}
                        >
                          Class Configuration
                        </Box>
                        <Field
                          aria-label="Class Name"
                          component={InputText}
                          label={labelHandler('Class Name', true)}
                          name="eligibilityClassName"
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Box m={3}>
                          <ClassDriversSection
                            classDrivers={classDrivers}
                            {...rest}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6,
                          }}
                        >
                          <ToolbarButton
                            bg="accentSecondary"
                            isDisabled={submitting}
                            label="Create Eligibility Class"
                            m={0}
                            submitting={submitting}
                            type="submit"
                            width="20rem"
                          />
                        </Box>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: isRenewal
                              ? 'space-between'
                              : 'flex-end',
                            maxWidth: isRenewal && '25rem',
                            my: 6,
                            p: isRenewal && 3,
                            width: '100%',
                          }}
                        >
                          {isRenewal && (
                            <ToolbarButton
                              bg="primaryLight"
                              label={t('common.previous')}
                              ml={0}
                              onClick={() => {
                                return navigate(
                                  generateRedirectPath({
                                    ...location,
                                    queryParams: {
                                      [name]: 5,
                                    },
                                  })
                                );
                              }}
                              width="10rem"
                            />
                          )}
                          <ToolbarButton
                            bg="primaryDark"
                            label={t('common.next')}
                            onClick={onNextClick}
                            submitting={nextLoading}
                            width="150px"
                          />
                        </Box>
                      </Box>
                    </form>
                  </Box>
                );
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              {renderClassesTable()}
            </Box>
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

ClassConfiguration.propTypes = {
  isRenewal: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
  tableType: PropTypes.string.isRequired,
};

ClassConfiguration.defaultProps = {
  isRenewal: false,
  name: 'step',
};

export default ClassConfiguration;
