import { useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { composeValidations, required } from '@utils/validations';
import { get } from 'lodash';
import React from 'react';
import arrayMutators from 'final-form-arrays';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import remoteActionQuery from "@queries/remote-action.gql";
import {
  notificationTypes,
  settingLevel,
} from "@src/utilities/constants";
import GetData from "@src/utilities/get-data";
import useSnackbar from "@src/utilities/use-snackbar";
import InputMasked from "@basecomponents/InputMasked";
import Spinner from "@basecomponents/Spinner";
import ToolbarButton from "@basecomponents/ToolbarButton";
import Route from "@petcomponents/Route";
import config from "@src/config.json";

const isGreaterThanZero = (value) => {
  const trimmedValue = value?.match?.(/\d/g)?.join('');

  if (trimmedValue <= 0) {
    return `Must be greater than Zero`;
  }
};

/**
 * Group Reports page
 *
 * @category Group
 * @param {*} rest
 * @returns {React.FC}
 */
const GroupReports = ({ ...rest }) => {
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  const queryParams = useParams();
  const groupId = get(queryParams, 'groupId', '');

  const { apiData: constantsData = {}, loading: enumLoading } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: ['SettingType', 'SettingLevel'] }])
  );

  const {
    apiData: benAdminChangeFileData = {},
    loading: benAdminChangeFileLoading,
  } = GetData(
    'get-all-system-settings',
    JSON.stringify({
      identifier: groupId,
      settingLevel: settingLevel.group,
      settingType: notificationTypes.benAdminChangeFileSchedule,
    })
  );

  const getSettingLevel = (settingLevel) => {
    const { value } = constantsData?.SettingLevel?.find(
      ({ value }) => value === settingLevel
    );
    return value;
  };

  const getSettingType = (settingType) => {
    const { value } = constantsData?.SettingType?.find(
      ({ value }) => value === settingType
    );
    return value;
  };

  const getBenAdminFileDays =
    benAdminChangeFileData?.content?.[0]?.settingValue;

  const initialValues = {
    benAdminChangeFileSchedule: getBenAdminFileDays
      ? `${getBenAdminFileDays} Days`
      : 0,
  };

  return (
    <Route
      header={{
        title: 'Group Notifications',
        type: 'billing',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Reports
      </Box>
      {enumLoading || benAdminChangeFileLoading ? (
        <Spinner />
      ) : (
        <ApolloConsumer>
          {(client) => {
            const onChangeSettings = async (values) => {
              const benAdminChangeFileSchedule =
                values?.benAdminChangeFileSchedule?.match?.(/\d/g)?.join('');

              const benAdminChangeFileData = {
                identifier: groupId,
                settingLevel: getSettingLevel(settingLevel.group),
                settingType: getSettingType(
                  notificationTypes.benAdminChangeFileSchedule
                ),
                settingValue: benAdminChangeFileSchedule,
              };

              await client
                .query({
                  fetchPolicy: 'no-cache',
                  query: remoteActionQuery,
                  variables: {
                    name: 'create-system-settings',
                    params: JSON.stringify({ ...benAdminChangeFileData }),
                  },
                })
                .then(() => {
                  setSuccessSnack(
                    'Change file report settings updated Successfully!',
                    config.notificationDuration
                  );
                })
                .catch((e) => {
                  setErrorSnack(
                    `There was an error : ${e.message}`,
                    config.notificationDuration
                  );
                });
            };

            return (
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <FinalForm
                  initialValues={{ ...initialValues }}
                  mutators={{
                    ...arrayMutators,
                  }}
                  onSubmit={onChangeSettings}
                  render={({ handleSubmit, submitting }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Box
                          maxWidth="30rem"
                          sx={{
                            bg: 'white',
                            borderRadius: 4,
                            boxShadow: 1,
                            padding: 3,
                          }}
                        >
                          <Box sx={{ mt: 6 }}>
                            <Box as="h4">
                              Set the number of days at which the change file
                              report is generated
                            </Box>
                            <Box
                              sx={{
                                alignItems: 'center',
                                color: 'primary',
                                display: 'flex',
                                marginTop: '20px',
                              }}
                            >
                              <Field
                                aria-label="Input"
                                component={InputMasked}
                                mask={createNumberMask({
                                  allowDecimal: false,
                                  integerLimit: 3,
                                  prefix: '',
                                  suffix: ' Days',
                                })}
                                name="benAdminChangeFileSchedule"
                                sx={{ height: '35px' }}
                                validate={composeValidations(
                                  required,
                                  isGreaterThanZero
                                )}
                                wrapperSx={{ ...commonFieldSxHalf }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <ToolbarButton
                              bg="accentDark"
                              isDisabled={submitting}
                              label="Save"
                              mr={0}
                              my={5}
                              submitting={submitting}
                              type="submit"
                              width="150px"
                            />
                          </Box>
                        </Box>
                      </form>
                    );
                  }}
                />
              </Box>
            );
          }}
        </ApolloConsumer>
      )}
    </Route>
  );
};

export default GroupReports;
