import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import { ThemeContext } from 'styled-components';
import Variant from "@petcomponents/Variant";
import EditAccessRightsContext from "@petcomponents/EditAccessRights/Context";

/**
 * @category BaseComponents
 * @param {number} columnCount
 * @param {Object} data
 * @param {Array<Object>} fields
 * @param {Object} meta
 * @returns {React.FC}
 */
const RowKeyValuePairs = ({
  columnCount,
  data,
  fields,
  meta,
  innerSxProps,
}) => {
  const ear = useContext(EditAccessRightsContext);
  const { defaultStyle = {} } = useContext(ThemeContext);
  const {
    containerBox = {},
    innerContainerBox = {},
    keyLabelBox = {},
    valueLabelBox = {},
    containerBoxNoData = {},
    innerContainerNoData = {},
  } = defaultStyle.defaultRowKeyValuePair(columnCount);

  return (
    <Box sx={containerBox}>
      {fields.length > 0 ? (
        fields.map(({ dynamicLabel, label, name, permission }, index) => {
          return ear.isVisible(permission) ? (
            <Box
              key={`kvp-${name}-${index}`}
              sx={{ ...innerContainerBox, ...innerSxProps }}
            >
              <Box sx={keyLabelBox}>
                {dynamicLabel || label || (name ? get(meta, `${name}.label`, `#${name}`) : '')}
              </Box>
              <Box sx={valueLabelBox}>
                {name ? <Variant data={data} meta={meta} name={name} /> : ''}
              </Box>
            </Box>
          ) : null;
        })
      ) : (
        <Box sx={containerBoxNoData}>
          <Box sx={innerContainerNoData}>No Fields Defined</Box>
        </Box>
      )}
    </Box>
  );
};
RowKeyValuePairs.defaultProps = {
  columnCount: 4,
  fields: [],
  innerSxProps: {},
};

RowKeyValuePairs.propTypes = {
  columnCount: PropTypes.number,
  data: PropTypes.shape({}).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  innerSxProps: PropTypes.shape({}),
  meta: PropTypes.shape({}).isRequired,
};

const KeyValuePairs = ({ columnCount, data, fields, meta, innerSxProps }) => {
  return (
    <RowKeyValuePairs
      columnCount={columnCount}
      data={data}
      fields={fields}
      innerSxProps={innerSxProps}
      meta={meta}
    />
  );
};

KeyValuePairs.defaultProps = {
  columnCount: 4,
  fields: [],
  innerSxProps: {},
};

KeyValuePairs.propTypes = {
  columnCount: PropTypes.number,
  data: PropTypes.shape({}).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  innerSxProps: PropTypes.shape({}),
  meta: PropTypes.shape({}).isRequired,
};

export default KeyValuePairs;
