import React, { useContext } from 'react';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import { AuthContext } from '@basecomponents/Auth';
import { useTranslation } from 'react-i18next';
import Route from "@petcomponents/Route";
import Icon from "@src/components/Icon";
import config from "@src/config.json";

const imgSx = {
  alignItems: 'center',
  display: 'flex',
  height: '35px',
  justifyContent: 'center',
  mr: 2,
  width: '35px',
};

const containerSx = {
  alignItems: 'center',
  borderRadius: 5,
  boxShadow: 5,
  display: 'flex',
  justifyContent: 'center',
  m: 5,
  overflowWrap: 'anywhere',
  p: 2,
};

const containerCanadaSx = {
  alignItems: 'center',
  bg: '#95E6E2',
  borderRadius: 24,
  boxShadow: 5,
  display: 'flex',
  justifyContent: 'center',
  m: 5,
  overflowWrap: 'anywhere',
  p: 2,
};

/* eslint react/prop-types: 0 */

const PhoneButton = ({ number }) => (
  config.canadaEnv ?
    <Box as="a" href={`tel:${number}`} sx={{ ...containerCanadaSx, color: 'primary' }}>
      <Icon color="lightYellow" svg="phone" sx={imgSx} />
      <Box>{number}</Box>
    </Box> :
    <Box as="a" href={`tel:${number}`} sx={containerSx}>
      <Icon color="accentSecondaryDark" svg="phone" sx={imgSx} />
      <Box>{number}</Box>
    </Box>
);

const EmailButton = ({ email }) => (
  config.canadaEnv ?
    <Box as="a" href={`mailto:${email}`} sx={{ ...containerCanadaSx, color: 'primary' }}>
      <Icon color="lightYellow" svg="chatbubble" sx={imgSx} />
      <Box>{email}</Box>
    </Box> :
    <Box as="a" href={`mailto:${email}`} sx={containerSx}>
      <Icon color="accentSecondaryDark" svg="chatbubble" sx={imgSx} />
      <Box>{email}</Box>
    </Box>
);

const Row = ({ children }) => (
  <Box
    flexDirection={{ _: 'column', md: 'row' }}
    sx={{ display: 'flex', justifyContent: 'center' }}
  >
    {children}
  </Box>
);

const Paper = ({ children, sx }) => (
  <Box
    sx={{
      ...sx,
      bg: 'white',
      borderRadius: 2,
      boxShadow: 1,
      p: 5,
    }}
  >
    {children}
  </Box>
);

const PrimaryText = ({ children }) => (
  config.canadaEnv ?
    <Box
      sx={{
        color: 'primary',
        fontSize: 4,
        fontWeight: 'bold',
      }}
    >
      {children}
    </Box> :
    <Box
      sx={{
        color: 'primary',
        fontSize: 4,
        fontWeight: 'bold',
      }}
    >
      {children}
    </Box>
);

export default function ContactUs({ ...rest }) {
  const { user } = useContext(AuthContext);
  const groupMgaName = get(user, 'customMga', 'ppi').toLowerCase();
  const { t } = useTranslation()

  // PPI
  if (groupMgaName === 'ppi') {
    return (
      <Route
        header={{
          icon: 'settings',
          title: t('contactUsScreen.title'),
          type: 'Contact Us',
        }}
        isPrivate
        permission="provider.contact.page.dashboard.::mga"
        {...rest}
      >
            <>
              <Box aria-label="Contact Us" as="h2" sx={{ mb: 2, py: 3 }}>
                {t('contactUsScreen.title')}
              </Box>
              <Paper sx={{ mb: 2 }}>
                <PrimaryText>
                  {t('contactUsScreen.questionsSection.petPartners.policyDescription')}
                </PrimaryText>
                <Row>
                  <PhoneButton number="800-956-2495" />
                  <EmailButton email="mypolicy@petpartners.com" />
                </Row>
              </Paper>

              <Paper sx={{ mb: 2 }}>
                <PrimaryText>
                  {t('contactUsScreen.questionsSection.petPartners.claimDescription')}
                </PrimaryText>
                <Row>
                  <PhoneButton number="800-956-2495" />
                  <EmailButton email="myclaims@petpartners.com" />
                </Row>
              </Paper>

              <Paper sx={{ mb: 2 }}>
                <PrimaryText>
                  {t('contactUsScreen.questionsSection.petPartners.adminDescription')}
                </PrimaryText>
                <Row>
                  <PhoneButton number="844-738-4242" />
                  <EmailButton email="IAIC.pets@independenceamerican.com" />
                </Row>
              </Paper>
        </>
      </Route>
    );
  }

    if (groupMgaName === 'ptz_sodaco') {
        return (
            <Route
                header={{
                    icon: 'settings',
                    title: t('contactUsScreen.title'),
                    type: 'Contact Us',
                }}
                isPrivate
                permission="provider.contact.page.dashboard.::mga"
                {...rest}
            >
               <>
                   <Box aria-label="Contact Us" as="h2" sx={{ color: '#01313D', mb: 2, py: 3 }}>
                       {t('contactUsScreen.title')}
                   </Box>
                   <Paper sx={{ mb: 2 }}>
                       <PrimaryText>
                       {t('contactUsScreen.questionsSection.ptzSodaco.policyDescription')}
                       </PrimaryText>
                       <Row>
                           <PhoneButton number="833-310-7225" />
                           <EmailButton email="mypolicy@onepackplan.ca" />
                       </Row>
                   </Paper>

                   <Paper sx={{ mb: 2 }}>
                       <PrimaryText>
                       {t('contactUsScreen.questionsSection.ptzSodaco.claimDescription')}
                       </PrimaryText>
                       <Row>
                           <PhoneButton number="833-310-7225" />
                           <EmailButton email="myclaims@onepackplan.ca" />
                       </Row>
                   </Paper>

                </>
            </Route>
        );
    }
  return (
    <Route
      header={{
        icon: 'settings',
        title: t('contactUsScreen.title'),
        type: 'Contact Us',
      }}
      isPrivate
      permission="provider.contact.page.dashboard.::mga"
      {...rest}
    >
      <Box aria-label="Contact Us" as="h2" sx={{ mb: 2, py: 3 }}>
        {t('contactUsScreen.title')}
      </Box>
      <Paper sx={{ mb: 2 }}>
        <PrimaryText>
          {t('contactUsScreen.questionsSection.figo.policyDescription')}
        </PrimaryText>
        <Row>
          <PhoneButton number="844-738-3446" />
          <EmailButton email="claims@figopetinsurance.com" />
          <EmailButton email="support@figopetinsurance.com" />
        </Row>
      </Paper>
      <Paper sx={{ mb: 2 }}>
        <PrimaryText>
          {t('contactUsScreen.questionsSection.petPartners.adminDescription')}
        </PrimaryText>
        <Row>
          <PhoneButton number="844-738-4242" />
          <EmailButton email="IAIC.pets@independenceamerican.com" />
        </Row>
      </Paper>
    </Route>
  );
}
