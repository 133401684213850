import PropTypes from 'prop-types';
import React from 'react';
import MasterPolicyVariation from "@petcomponents/MasterPolicyGenerator/Variations";
import { variation } from "@src/config.json";
import DemoMP from "@petcomponents/MasterPolicyGenerator/Variations/demo";
import CnfMP from "@petcomponents/MasterPolicyGenerator/Variations/cnf";

/**
 * @category TBD
 * @param {string} type
 * @param {*} rest
 * @returns {React.FC}
 */
const MasterPolicyGenerator = ({ type, ...rest }) => {
  if (variation === 'cnf') {
    return <CnfMP {...rest} />;
  }
  if (variation === 'demo') {
    return <DemoMP {...rest} />;
  }
  return <MasterPolicyVariation type={type} {...rest} />;
};

MasterPolicyGenerator.propTypes = {
  type: PropTypes.string.isRequired,
};

export default MasterPolicyGenerator;
