import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import arrayMutators from 'final-form-arrays';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import dashboardConstants from '@src/utilities/dashboard-constants';
import { useTranslation } from 'react-i18next';
import { composeValidations, required } from "@src/utilities/validators";
import remoteActionQuery from "@queries/remote-action.gql";
import { labelHandler } from "@src/utilities/label-utils";
import useSnackbar from "@src/utilities/use-snackbar";
import {
  commissionMatch,
  totalCommission,
} from "@src/utilities/validations";
import InputMasked from "@basecomponents/InputMasked";
import InputMonth from "@basecomponents/InputMonth";
import Spinner from "@basecomponents/Spinner";
import ToolbarButton from "@basecomponents/ToolbarButton";
import CommissionFieldsArray from "@petcomponents/ProductAdminPage/Commissions/CommissionFieldsArray";
import CommissionsHistoryTable from "@petcomponents/ProductAdminPage/Commissions/CommissionsHistoryTable";
import Modal from "@basecomponents/Modal";
import GetData from "@src/utilities/get-data";
import generateRedirectPath from "@src/utilities/generate-redirect-path";
import config from "@src/config.json";
import dateUtils from "@src/utilities/date-utils";

/**
 * @category ProductAdmin
 * @param {Object} location
 * @param {string} name
 * @param {*} rest
 * @returns {React.FC}
 */
const Commissions = ({ location, name, isRenewal, ...rest }) => {
  const { t, i18n } = useTranslation();
  const parameters = useParams();
  const groupId = get(parameters, 'groupId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const [commissionData, setCommissionData] = useState({});
  const [deletedCommissionId, setDeletedCommissionData] = useState(null);
  const today = new Date();
  const resetDay = new Date(today.setDate(1));
  const initialMonth = new Date(resetDay.setMonth(resetDay.getMonth()));
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const commissionsHistoryTableRef = useRef();
  const { apiData: group = {}, loading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );
  let expirationDate;
  if (isRenewal) {
    expirationDate = new Date(
      get(group, 'plan.planChoices[0].coverageToDate', '')
    );
  }
  const state = get(group, 'locationDetails.address.state');

  const { filters, sortOptions, pageCriteria } =
    dashboardConstants.getCriterias({
      search: get(location, 'search', ''),
      type: 'commissionsDashboard',
    });

  const query = dashboardConstants.getQuery({
    filters,
    page: pageCriteria,
    sort: sortOptions,
  });

  query.push({ groupId });

  const {
    apiData: { content = [] },
  } = GetData('get-all-commission-history', JSON.stringify(query));

  if (loading) {
    return <Spinner />;
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const onClickCreateCommission = async (values, form) => {
          const effectiveDate = new Date(get(values, 'effectiveDate', ''));
          const totalCommissionPercentage = parseFloat(
            get(values, 'totalCommissionPercentage', '0')
          );

          const commissionConfigs = get(values, 'commissions', []).map(
            (item) => {
              const temp = {};
              temp.commissionPercentage = parseFloat(
                get(item, 'commissionPercentage', '0')
              );
              temp.subProducerId = get(item, 'subProducer.subProducerId', '');
              return temp;
            }
          );

          const paramData = {
            commissionConfigs,
            effectiveDate: dateUtils.setAPIDateOnly(effectiveDate),
            groupId,
            totalCommissionPercentage,
          };
          if (isRenewal) {
            paramData.id = get(commissionData, 'groupCommissionHistoryId');
            if (!isEmpty(commissionData)) {
              paramData.expirationDate = expirationDate;
            }
          }
          return client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: !isEmpty(commissionData)
                  ? 'update-commission-history'
                  : 'create-commission',
                params: JSON.stringify(paramData),
              },
            })
            .then(() => {
              commissionsHistoryTableRef.current.refetchCommissionsTableData();
              if (isRenewal) {
                setCommissionData({});
                return setSucessSnack(
                  `Commission group ${!isEmpty(commissionData) ? 'updated' : 'created'
                  } successfully.`,
                  config.notificationDuration
                );
              }
              return setSucessSnack('Commission group created successfully.');
            })
            .catch((e) => {
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              );
            })
            .finally(() => form.restart());
        };

        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              maxWidth: '98vw',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={
                isRenewal
                  ? {
                    commissions: get(commissionData, 'commissionDTOList', [
                      {
                        producerDetail: {},
                        subProducer: {},
                      },
                    ]),
                    ...commissionData,
                    effectiveDate: dateUtils.setOffset2(
                      get(commissionData, 'effectiveDate')
                    ),
                  }
                  : {
                    commissions: [
                      {
                        producerDetail: {},
                        subProducer: {},
                      },
                    ],
                  }
              }
              mutators={arrayMutators}
              onSubmit={(values, form) => onClickCreateCommission(values, form)}
              render={(formContext) => {
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 3,
                      }}
                    >
                      <Box
                        as="h3"
                        color="black"
                        display="flex"
                        justifyContent="flex-start"
                        sx={{
                          color: 'primary',
                          margin: '20px 0px 20px 0px;',
                          padding: 3,
                          width: '25rem',
                        }}
                      >
                        Commissions
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          mb: 6,
                        }}
                      >
                        <Field
                          aria-label="Effective Date"
                          component={InputMonth}
                          dateFormat={config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                          label={labelHandler('Effective Date', true)}
                          minDate={initialMonth}
                          name="effectiveDate"
                          validate={required}
                          view="month"
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Box
                          sx={{
                            color: 'primary',
                            display: 'block',
                            fontSize: 1,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                            maxWidth: '25rem',
                            mb: 1,
                            textTransform: 'uppercase',
                          }}
                        >
                          Effective Date will be the 1st of the month selected.
                        </Box>
                        <Box
                          sx={{
                            color: 'primary',
                            display: 'block',
                            fontSize: 1,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                            lineHeight: '12px',
                            maxWidth: '25rem',
                            mb: 1,
                            textAlign: 'center',
                            textTransform: 'uppercase',
                          }}
                        >
                          Expiration Date will be the Coverage End Date unless a
                          new commission group is created in the interm, in
                          which case it would the last day of the month prior to
                          the selected effective month of the new Effective
                          Date.
                        </Box>
                        <Field
                          aria-label="Total Commission in %"
                          component={InputMasked}
                          label={labelHandler('Total Commission in %', true)}
                          mask={createNumberMask({
                            allowDecimal: true,
                            includeThousandsSeparator: false,
                            integerLimit: 2,
                            prefix: '',
                          })}
                          name="totalCommissionPercentage"
                          validate={composeValidations(
                            required,
                            totalCommission
                          )}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                      </Box>
                      <CommissionFieldsArray
                        client={client}
                        commissionsData={get(formContext.values, 'commissions')}
                        name="commissions"
                        state={state}
                        validation={commissionMatch}
                        {...rest}
                      />
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          my: 6,
                        }}
                      >
                        <ToolbarButton
                          bg="primaryDark"
                          isDisabled={formContext.submitting}
                          label={`${!isEmpty(commissionData) ? 'Update' : 'Create'
                            } Commission Group`}
                          mr={0}
                          submitting={formContext.submitting}
                          type="submit"
                          width="25rem"
                        />
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          maxWidth: '25rem',
                          my: 6,
                          p: 1,
                          width: '100%',
                        }}
                      >
                        <ToolbarButton
                          bg="primaryLight"
                          label={t('common.previous')}
                          ml={0}
                          onClick={() =>
                            navigate(
                              generateRedirectPath({
                                ...location,
                                queryParams: { [name]: isRenewal ? 8 : 2 },
                              })
                            )
                          }
                          width="10rem"
                        />
                        <ToolbarButton
                          bg="primaryDark"
                          label={t('common.next')}
                          mr={0}
                          onClick={() => {
                            if (
                              formContext?.values?.commissions?.some(
                                (item) =>
                                  isEmpty(item.producerDetail) ||
                                  isEmpty(item.subProducer) ||
                                  isEmpty(item.commissionPercentage)
                              ) &&
                              !content?.length
                            ) {
                              return setErrorSnack(
                                'There was an error: Please fill the commissions details first',
                                config.notificationDuration
                              );
                            }
                            navigate(
                              generateRedirectPath({
                                ...location,
                                queryParams: { [name]: isRenewal ? 10 : 4 },
                              })
                            );
                          }}
                          width="10rem"
                        />
                      </Box>
                    </Box>
                    {isRenewal && deletedCommissionId && (
                      <Modal
                        closeText={t('common.no')}
                        isOpen={deletedCommissionId !== null}
                        onClose={() => {
                          setDeletedCommissionData(null);
                        }}
                        onSubmit={() => {
                          client
                            .query({
                              fetchPolicy: 'no-cache',
                              query: remoteActionQuery,
                              variables: {
                                name: 'delete-commission-history',
                                params: JSON.stringify({
                                  id: deletedCommissionId,
                                }),
                              },
                            })
                            .then(async () => {
                              setDeletedCommissionData(null);
                              commissionsHistoryTableRef.current.refetchCommissionsTableData();
                            })
                            .catch((e) => {
                              setErrorSnack(`There was an error: ${e.message}`);
                              setDeletedCommissionData(null);
                            });
                        }}
                        submitText={t('common.yes')}
                        title="Remove Commission Group"
                      >
                        <Box>
                          By clicking yes, you are confirming that the
                          Commission will be removed from your group plan.
                          <Box
                            sx={{
                              display: 'flex',
                              fontWeight: 'bold',
                              justifyContent: 'center',
                              my: 5,
                            }}
                          >
                            THIS ACTION CAN NOT BE UNDONE.
                          </Box>
                          Do you wish to continue?
                        </Box>
                      </Modal>
                    )}
                  </form>
                );
              }}
            />
            {isRenewal ? (
              <CommissionsHistoryTable
                ref={commissionsHistoryTableRef}
                currentDate={today}
                editable
                isRenewal={isRenewal}
                location={location}
                setCommissionData={setCommissionData}
                setDeletedCommissionData={setDeletedCommissionData}
                tableType="commissionsDashboard"
                {...rest}
              />
            ) : (
              <CommissionsHistoryTable
                ref={commissionsHistoryTableRef}
                location={location}
                tableType="commissionsDashboard"
                {...rest}
              />
            )}
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

Commissions.propTypes = {
  isRenewal: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
};

Commissions.defaultProps = {
  isRenewal: false,
  name: 'step',
};

export default Commissions;
