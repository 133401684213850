import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import PetAppLayout from "@petcomponents/PetAppLayout";
import config from "@src/config.json";

const { variation } = config;

const Layout = ({ children }) => {
  useEffect(() => {
    const onetrustScript = document.createElement('script');
    if(config?.canadaEnv && config?.cookieLawKey){
      onetrustScript.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
      onetrustScript.type = "text/javascript";
      onetrustScript.charset = "UTF-8";
      onetrustScript.dataset.domainScript = config.cookieLawKey;
      onetrustScript.async = true;
      document.body.appendChild(onetrustScript);
      // eslint-disable-next-line no-unused-vars
      onetrustScript.onload =  () => {
        // eslint-disable-next-line no-unused-vars
        function OptanonWrapper() { }
        
        // window?.OptanonWrapper();
        // eslint-disable-next-line no-unused-expressions
        typeof window.OneTrust !== 'undefined' && window.OneTrust.initializeCookiePolicyHtml(true);
      }

    }
    const script = document.createElement('script');
    if (config?.canadaEnv && config?.developerMode) {
      script.src = 'https://cdn.weglot.com/weglot.min.js';
      document.body.appendChild(script);

      script.onload = () => {
        window.Weglot.initialize({
          api_key: 'wg_64a1a115a4ae2183338c0790f6389b9b6',
          whitelist: [
            { value: "#___gatsby" },
            { value: "#gatsby-focus-wrapper" },
            { value: "body" },
          ]
        });
        window.Weglot.on("initialized", ()=>{
          if(['fr-CA'].includes(navigator.language)){
            window.Weglot.switchTo('fr')
          }
        })
      }
    }

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(onetrustScript);
    };
  }, []);

  if (variation === 'ihc') {
    return <PetAppLayout> {children}</PetAppLayout>;
  }
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default Layout;
