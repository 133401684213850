import { settingLevel } from "@src/utilities/constants";

export const getRenewalDays = (arrToFind, findBy) => {
  return arrToFind?.find((item) => item?.identifier === findBy)?.settingValue;
};

export const getNotificationDays = ({ apiData, meta }) => {
  const groupRenewalNotificationDays = getRenewalDays(
    apiData?.groupSettings?.content,
    meta?.groupId
  );

  const stateRenewalNotificationDays = getRenewalDays(
    apiData?.stateSettings?.content,
    meta?.groupState
  );

  const globalRenewalNotificationDays = getRenewalDays(
    apiData?.globalSettings?.content,
    settingLevel.global
  );

  let initialValues = {};

  if (groupRenewalNotificationDays) {
    initialValues = {
      renewalNotificationDays: groupRenewalNotificationDays,
    };
    return initialValues;
  }

  if (stateRenewalNotificationDays) {
    initialValues = {
      renewalNotificationDays: stateRenewalNotificationDays,
    };
    return initialValues;
  }

  initialValues = {
    renewalNotificationDays: globalRenewalNotificationDays,
  };

  return initialValues;
};
