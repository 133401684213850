import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import { navigate } from '@reach/router';
import GetData from "@src/utilities/get-data";
import Route from "@petcomponents/Route";
import KeyValuePairs from "@basecomponents/KeyValuePairs";
import Card from "@basecomponents/Card";
import getMetaData from "@src/utilities/get-meta-data";
import ErrorSection from "@petcomponents/FileDetailPage/ErrorSection";
import downloadFile from "@src/utilities/download-file";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import HeaderRightContainer from "@basecomponents/HeaderRightContainer";
import DataModifiedButton from "@petcomponents/FileDetailPage/DataModified";
import config from "@src/config.json";

const downloadErrors = (client, datasetId, setSuccessSnack, setErrorSnack) => {
  client
    .query({
      fetchPolicy: 'no-cache',
      query: remoteActionQuery,
      variables: {
        name: 'barrel-error-file',
        params: JSON.stringify({
          datasetId,
        }),
      },
    })
    .then((response) => {
      const results = JSON.parse(get(response, 'data.remoteAction.data', '{}'));
      downloadFile({
        s3BucketName: get(results, 's3BucketName', ''),
        s3KeyName: get(results, 's3KeyName', ''),
      })
        .then(() => {
          setSuccessSnack('Error file downloaded', config.notificationDuration);
        })
        .catch(() => {
          setErrorSnack(`No error file found`, config.notificationDuration);
        });
    })
    .catch((e) =>
      setErrorSnack(
        `There was an error: ${e.message}`,
        config.notificationDuration
      )
    );
};

/**
 * @category File
 * @param {string} datasetId Indicates the unique data set ID
 * @returns {React.FC}
 */
const FileDetailPage = ({ datasetId }) => {
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const { apiData, loading, refetch } = GetData(
    'barrel-file-detail',
    JSON.stringify({ datasetId })
  );
  const meta = getMetaData({ moduleName: 'barrelFiles' });
  const files = get(apiData, 'filePaths.actual', []);

  return (
    <ApolloConsumer>
      {(client) => {
        return (
          <Route
            header={{
              rightContainer: (
                <HeaderRightContainer
                  headerActions={[
                    {
                      action: async () => {
                        try {
                          await Promise.all(
                            files.map(({ s3BucketName, s3KeyName }) =>
                              downloadFile({
                                s3BucketName,
                                s3KeyName,
                              })
                            )
                          );
                          setSuccessSnack(
                            'Files downloaded successfully',
                            config.notificationDuration
                          );
                        } catch (e) {
                          setErrorSnack(e.message, config.notificationDuration);
                        }
                      },
                      icon: 'download',
                      isHidden: files.length === 0,
                      label: 'Download File',
                    },
                    {
                      action: () =>
                        downloadErrors(
                          client,
                          datasetId,
                          setSuccessSnack,
                          setErrorSnack
                        ),
                      icon: 'download',
                      label: 'Download Errors',
                    },
                    {
                      action: () => navigate(`/files/columns/${datasetId}`),
                      icon: 'edit',
                      isHidden:
                        get(apiData, 'status') !== 'column-check-required',
                      label: 'Match Columns',
                    },
                  ]}
                />
              ),
              title: 'File Dashboard',
              type: 'groups',
            }}
            isPrivate
            isResponsive
            permission=""
          >
            {!loading && (
              <DataModifiedButton
                datasetId={datasetId}
                errorCount={get(apiData, 'errorCount', 0)}
                onClick={async () => refetch()}
                timestamp={get(apiData, 'updatedDate')}
              />
            )}
            <Card loading={loading} title="Details">
              <KeyValuePairs
                columnCount={2}
                data={apiData}
                fields={[
                  { name: 'datasetName' },
                  { name: 'status' },
                  { name: 'createdDate' },
                  { name: 'updatedDate' },
                  { name: 'errorCount' },
                  { name: 'rowCount' },
                ]}
                meta={meta}
              />
            </Card>

            <Card cardSx={{ bg: 'inherit', padding: 0 }} title="Errors">
              <ErrorSection datasetId={datasetId} />
            </Card>
          </Route>
        );
      }}
    </ApolloConsumer>
  );
};

FileDetailPage.propTypes = {
  datasetId: PropTypes.string.isRequired,
};

export default FileDetailPage;
