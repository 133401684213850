import Box from '@basecomponents/Box';
import { print } from 'graphql';
import React, { useContext, useEffect } from 'react';
import { Field, Form as FinalForm, Form } from 'react-final-form';
import { ThemeContext } from 'styled-components';
import { get } from 'lodash';
import { navigate } from '@reach/router';
import { lighten } from 'polished';
import EmailSentModal from '@basecomponents/EmailSentModal';
import useWindowSize from '@utils/hooks/windowSize';
import { useTranslation } from 'react-i18next';
import { OnChange } from 'react-final-form-listeners';
import Dropdown from '@basecomponents/Dropdown';
import i18n from '@src/utilities/i18n';
import {
  composeValidations,
  email,
  required,
} from "@src/utilities/validators";
import Input from "@basecomponents/Input";
import Logo from "@petcomponents/Logo";
import ToolbarButton from "@basecomponents/ToolbarButton";
import config, { promoImageUrl } from "@src/config.json";
import pixelToNumber from "@src/utilities/pixel-to-number";
import useSnackbar from "@src/utilities/use-snackbar";
import mutationRemoteAction from "@mutations/mutation-remote-action.gql";

const EmailVerificationPage = () => {
  const { colors, breakpoints, shadows } = useContext(ThemeContext);
  const { height, width } = useWindowSize();
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [modal, setModal] = React.useState(false);
  const { t } = useTranslation();
  const selectedLang = localStorage.getItem('lang');
  useEffect(() => {
    navigate('/login/');
  }, []);

  const commonSimpleButtonSx = {
    '&:hover': {
      color: `${lighten(0.1, `${colors.accent}`)}`,
      transition: 'color ease-in-out 0.25s',
    },
    color: 'white',
    display: 'flex',
    fontSize: 2,
    fontWeight: 'bold',
    mb: 5,
    mt: 5,
    transition: 'color ease-in-out 0.25s',
    width: '100%',
  };

  const backgroundSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        backgroundImage: `url(${promoImageUrl})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '95%',
      };
    }
    return {};
  };

  const boxShadowSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        boxShadow: shadows[4],
      };
    }
    return {};
  };

  return (
    <Box
      bg={{ _: 'primary', lg: 'white' }}
      display="flex"
      flexDirection={{ _: 'column', lg: 'row' }}
      height={height}
      width={width}
    >
      <Box
        bg={{ _: 'primary', lg: 'white' }}
        display="flex"
        height={{ lg: '100%' }}
        justifyContent={{ _: 'center', lg: 'flex-start' }}
        sx={{ ...backgroundSx() }}
        width={{ lg: '50%' }}
      >
        {width >= pixelToNumber(breakpoints[3]) ? (
          <Logo
            marginLeft={{ lg: 5 }}
            mono={width < pixelToNumber(breakpoints[3])}
            sx={{
              mt: '30px',
              top: '0',
            }}
          />
        ) : (
          <Logo
            mono
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              mt: [5, null, null, 7],
              p: 1,
              svg: {
                height: 'auto',
              },
              width: '20rem',
            }}
          />
        )}

        {width >= pixelToNumber(breakpoints[3]) && (
          <Box
            as="h1"
            color="primary"
            fontSize={7}
            sx={{
              ml: 5,
              mt: 5,
              position: [null, null, null, null, 'absolute'],
              top: '13rem',
              width: '30%',
            }}
          >
            <Box>{t('auth.subtitle1')}</Box>
            <Box>{t('auth.subtitle2')}</Box>
          </Box>
        )}
      </Box>
      <Box
        alignItems={['flex-start', null, 'center']}
        bg="primary"
        display="flex"
        height="auto"
        justifyContent="center"
        p={5}
        sx={{
          ...boxShadowSx(),
        }}
        width={{ lg: '50%' }}
      >
        <FinalForm
          onSubmit={async (values) => {
            try {
              const resolverName = 'alternate-email';
              const params = JSON.stringify({
                emailId: values.emailId.toLowerCase().trim(),
              });
              const emailVerification = await (
                await fetch(config.apollo.url, {
                  body: JSON.stringify({
                    query: print(mutationRemoteAction),
                    variables: {
                      name: resolverName,
                      params,
                    },
                  }),
                  headers: {
                    'content-type': 'application/json',
                    'x-api-key': config.apollo.apiKey,
                  },
                  method: 'POST',
                })
              ).json();
              if (emailVerification) {
                const data = get(emailVerification, 'data', null);
                if (!data) {
                  setErrorSnack(
                    t('snack.error.emailNotFound'),
                    config.notificationDuration
                  );
                } else {
                  setModal(t('emailVerification.tempPasswordSent'));
                  navigate('/login/?redirect=/secondary-auth/');
                }
              }
            } catch (e) {
              setErrorSnack(
                t('snack.error.tryAgainWithMessage', { message: e.message }),
                config.notificationDuration
              );
            }
          }}
          render={(formContext) => (
            <Box maxWidth="maxWidths.smallForm" mx="auto" width="100%">
              {config.canadaEnv && (
                <Box
                  sx={{
                    position: 'fixed',
                    right: '1rem',
                    top: '1rem',
                    width: 300,
                  }}
                >
                  <Form
                    initialValues={{
                      language: selectedLang || i18n.language,
                    }}
                    onSubmit={() => {}}
                    render={() => (
                      <>
                        <Field
                          component={Dropdown}
                          isSearchable={false}
                          name="language"
                          options={
                            config.canadaEnv
                              ? [
                                  {
                                    canEnglishValue: null,
                                    frenchValue: 'Français Canadien',
                                    label: 'Français Canadien',
                                    value: 'fr',
                                  },
                                  {
                                    canEnglishValue: null,
                                    frenchValue: null,
                                    label: 'Canadian English',
                                    value: 'caEn',
                                  },
                                ]
                              : [
                                  {
                                    canEnglishValue: null,
                                    frenchValue: 'Français Canadien',
                                    label: 'Français Canadien',
                                    value: 'fr',
                                  },
                                  {
                                    canEnglishValue: null,
                                    frenchValue: null,
                                    label: 'Canadian English',
                                    value: 'caEn',
                                  },
                                  {
                                    canEnglishValue: null,
                                    frenchValue: null,
                                    label: 'American English',
                                    value: 'en',
                                  },
                                ]
                          }
                        />
                        <OnChange name="language">
                          {(lang) => {
                            i18n.changeLanguage(lang);
                            localStorage.setItem('lang', lang);
                          }}
                        </OnChange>
                      </>
                    )}
                  />
                </Box>
              )}
              <Box
                color="white"
                fontSize={7}
                mb={[4, null, 6]}
                textAlign={{ _: 'center', lg: 'left' }}
              >
                {t('emailVerification.verifyEmail')}
              </Box>
              <Box sx={{ color: 'white', mb: 6 }}>
                {t('emailVerification.enterEmail')}
              </Box>
              <Box
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                  mb: 6,
                  textAlign: 'justify',
                }}
              >
                {t('emailVerification.useTempPassword')}
              </Box>
              <EmailSentModal modal={modal} setModal={setModal} />
              <form autoComplete="off" onSubmit={formContext.handleSubmit}>
                <Field
                  autocomplete="xyz123"
                  component={Input}
                  data-cy="email Id"
                  label={t('emailVerification.email')}
                  labelSx={{
                    color: 'white',
                  }}
                  name="emailId"
                  sx={{
                    transition: 'all 80ms',
                  }}
                  validate={composeValidations(required, email)}
                />
                <ToolbarButton
                  bg="accentSecondaryDark"
                  data-cy="submit"
                  label={t('common.submit')}
                  m={0}
                  mt={6}
                  submitting={formContext.submitting}
                  type="submit"
                  width="100%"
                />
                <ToolbarButton
                  label={t('emailVerification.gotoLogin')}
                  link="/login/?redirect=/secondary-auth/"
                  marginLeft={0}
                  simple
                  sx={{
                    ...commonSimpleButtonSx,
                    justifyContent: 'center',
                    textTransform: 'uppercase',
                  }}
                />
              </form>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default EmailVerificationPage;
