import get from 'lodash/get';
import { FILE_FLOWS } from "@src/utilities/constants";

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const FileFlows = ({ value }) => {
  const fileFlows = FILE_FLOWS.find((s) => s.value === value);
  return fileFlows ? get(fileFlows, 'label') : `#${value}`;
};

export default FileFlows;
