import { ApolloConsumer } from '@apollo/client';
import { get } from 'lodash';
import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import React from 'react';
import { diff } from 'deep-object-diff';
import InputCheckbox from '@basecomponents/InputCheckbox';
import { phoneNumber } from '@src/utilities/validations';
import { useTranslation } from 'react-i18next';
import useSsm from '@src/utilities/hooks/useSsm';
import {
  required,
  composeValidations,
} from "@src/utilities/validators";
import InputText from "@basecomponents/Input";
import InputDropdown from "@basecomponents/Dropdown";
import { labelHandler } from "@src/utilities/label-utils";
import { CONTACT_TYPE } from "@src/utilities/constants";
import ToolbarButton from "@basecomponents/ToolbarButton";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import GetData from "@src/utilities/get-data";
import Spinner from "@basecomponents/Spinner";
import AddressFormSection from "@petcomponents/AddressFormSection";
import InputMasked from "@basecomponents/InputMasked";
import { phoneNumberMask } from "@src/utilities/masks";
import config from "@src/config.json";

/**
 * @category BenAdmin
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const InitialSetup = ({ ...rest }) => {
  const { t } = useTranslation()
  const queryParams = useParams();
  const benAdminId = get(queryParams, 'benAdminId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const { apiData, loading } = GetData(
    'get-ben-admin-by-id',
    JSON.stringify({ id: benAdminId }),
    !benAdminId
  );
  const createBenInfo = {
    address: get(apiData, 'address', {}),
    contact: get(apiData, 'contact', {}),
    name: get(apiData, 'name', ''),
    postEnrollment: get(apiData, 'postEnrollment', ''),
  };
  const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment')
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  if (loading) {
    return <Spinner />;
  }
  return (
    <ApolloConsumer>
      {(client) => {
        const onClickCreateBenAdminAction = async (values) => {
          const apiCall = (pathName, params) =>
            client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: pathName,
                  params: JSON.stringify(params),
                },
              })
              .then((response) => {
                const apiBenAdminId = get(
                  JSON.parse(get(response, 'data.remoteAction.data', '')),
                  'benAdminId',
                  ''
                );
                if (apiBenAdminId) {
                  navigate(`/ben-admin/dashboard`);
                } else {
                  setErrorSnack(
                    'Something went wrong. Please try again.',
                    config.notificationDuration
                  );
                }
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );
          if (benAdminId) {
            const { address, contact, ...rest } = createBenInfo;
            const {
              address: newAddress,
              contact: newContact,
              ...restValues
            } = values;

            const diffPayload = { ...diff(rest, restValues) };
            if (diff(contact, newContact)) {
              diffPayload.contact = newContact;
            }
            if (diff(address, newAddress)) {
              diffPayload.address = newAddress;
            }
            if (Object.keys(diffPayload).length) {
              return apiCall('update-ben-admin', {
                ...diffPayload,
                id: benAdminId,
              });
            }
            return navigate(`/ben-admin/dashboard`);
          }
          return apiCall('create-ben-admin', {
            active: true,
            ...values,
            deleted: false,
          });
        };

        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={benAdminId ? { ...createBenInfo } : {}}
              onSubmit={onClickCreateBenAdminAction}
              render={(formContext) => (
                <form onSubmit={formContext.handleSubmit}>
                  <Box
                    sx={{
                      bg: 'white',
                      borderRadius: 4,
                      boxShadow: 1,
                      padding: 3,
                    }}
                  >
                    <Field
                      component={InputText}
                      label={labelHandler('Ben Admin Name', true)}
                      name="name"
                      validate={required}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Field
                      component={InputDropdown}
                      label={labelHandler(t('groupEmployees.enrollmentPage.phoneNumberType'), true)}
                      name="contact.contactType"
                      options={CONTACT_TYPE}
                      searchable={false}
                      validate={required}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Field
                      component={InputMasked}
                      label={labelHandler(t('groupEmployees.enrollmentPage.phoneNumber'), true)}
                      mask={phoneNumberMask}
                      name="contact.phoneNumber"
                      validate={composeValidations(required, phoneNumber)}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    {postEnrollmentConfigEnabled && (
                      <Field
                        aria-label="Pet Demographics Post Enrollment"
                        component={InputCheckbox}
                        inputWrapperSx={{
                          display: 'inline-flex',
                          float: 'left',
                          marginTop: '7px',
                        }}
                        label="Pet Demographics Post Enrollment"
                        labelSx={{
                          display: 'inline-flex',
                        }}
                        name="postEnrollment"
                        type="checkbox"
                        wrapperSx={{ ...commonFieldSxHalf, display: 'flex' }}
                      />
                    )}
                    <AddressFormSection
                      label="address"
                      wrapperSx={commonFieldSxHalf}
                      {...rest}
                    />
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        mt: 6,
                      }}
                    >
                      <ToolbarButton
                        bg="accentDark"
                        label={t('common.submit')}
                        mr={0}
                        submitting={formContext.submitting}
                        type="submit"
                        width="150px"
                      />
                    </Box>
                  </Box>
                </form>
              )}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

export default InitialSetup;
