import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import InputMasked from "@basecomponents/InputMasked";
import InputCheckbox from "@basecomponents/InputCheckbox";

/**
 * @category Settings
 * @param {boolean} isNotificationsDisabled
 * @param {string} name
 * @param {string} title
 * @param {function} validators
 * @param {*} rest
 * @returns {React.FC}
 */
const NotificationSettingItemWithCheckbox = ({
  isNotificationsDisabled,
  name,
  title,
  validators,
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Field
        aria-label="Do not send email"
        component={InputCheckbox}
        label="Do not send email."
        name={`${name}-checkbox`}
        type="checkbox"
        wrapperSx={{
          display: 'flex',
        }}
      />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          my: 3,
        }}
      >
        <Box
          as="h4"
          sx={{
            color: 'accent',
          }}
        >
          {title}
        </Box>
        <Field
          aria-label="Input"
          component={InputMasked}
          mask={createNumberMask({
            allowDecimal: false,
            integerLimit: 3,
            prefix: '',
            suffix: ' Days',
          })}
          name={name}
          placeholder="Renewal Number in Days"
          validate={validators}
          wrapperSx={{ maxWidth: '50%' }}
          {...rest}
        />
      </Box>
    </Box>
  );
};

NotificationSettingItemWithCheckbox.propTypes = {
  isInputDisabled: PropTypes.bool,
  isNotificationsDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  validators: PropTypes.func,
};

NotificationSettingItemWithCheckbox.defaultProps = {
  isInputDisabled: false,
  isNotificationsDisabled: false,
  validators: () => {},
};

export default NotificationSettingItemWithCheckbox;
