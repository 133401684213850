import get from 'lodash/get';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import {useTranslation} from "react-i18next";
import GetData from "@src/utilities/get-data";

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const NotesCategory = ({ value }) => {
  const { colors } = useContext(ThemeContext);
  const { i18n } = useTranslation();
  const data = [
    GetData(
      'get-enum-options',
      JSON.stringify([{ params: 'BillingNotesCategory' }])
    ),
  ];
  if (get(data, '0.loading', false)) {
    return <PulseLoader color={`${colors.accentSecondary}`} size={7} />;
  }
  const type = get(data, '0.apiData.BillingNotesCategory', []);
  const billingNotesCategory = type.find((item) => item.value === value);
  // eslint-disable-next-line no-nested-ternary
  return billingNotesCategory ?  (i18n.language === 'fr' && get(billingNotesCategory, 'frenchValue', '') !== '') ? get(billingNotesCategory, 'frenchValue') : get(billingNotesCategory, 'label') : value;
};

NotesCategory.propTypes = {
  value: PropTypes.string.isRequired,
};

export default NotesCategory;
