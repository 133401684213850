import config from '@src/config.json';
import { print } from 'graphql/index';
import mutationRemoteAction from '@mutations/mutation-remote-action.gql';
import { get } from 'lodash';
import { useEffect, useState } from 'react';

const useSsmData = (paramName) => {
  const [userId, setUserId] = useState({ data: null, errors: null });

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await fetch(config.apollo.url, {
          body: JSON.stringify({
            query: print(mutationRemoteAction),
            variables: {
              name: 'get-params-value',
              params: JSON.stringify({
                paramName,
              }),
            },
          }),
          headers: {
            'content-type': 'application/json',
            'x-api-key': config.apollo.apiKey,
          },
          method: 'POST',
        });

        const data = await response.json();

        if (isMounted) {
          const responseData = data?.data?.mutationRemoteAction?.data;
          // const {
          //   mutationRemoteAction: { data: responseData },
          // } = data;
          const dataInfo = JSON.parse(responseData);
          const dataValue = JSON.parse(dataInfo.value);
          setUserId({
            data: get(dataValue, `${config.environment}`, null),
            errors: null,
          });
        }
      } catch (error) {
        console.error('Error fetching SSM data:', error);
        if (isMounted) {
          setUserId((prevState) => ({ ...prevState, errors: error }));
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [paramName]);
  return userId;
};

export default useSsmData;
