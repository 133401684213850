import { Router } from '@reach/router';
import React from 'react';
import DashboardPage from '@petcomponents/Claims/ClaimsDashboardPage';
import UpsertClaim from '@petcomponents/Claims/UpsertClaimPage';
import ClaimsDetailsPage from '@petcomponents/Claims/ClaimDetailsPage';
import NotFoundPage from "@src/routes/ihc/404";

const Claims = (props) => (
  <Router>
    <DashboardPage
      {...props}
      path="/claims/dashboard"
      type="claimsDashboardPage"
    />
    <UpsertClaim {...props} path="/claims/create" type="upsertClaimPage" />
    <UpsertClaim
      {...props}
      path="/claims/create/:groupId/:employeeId"
      type="upsertClaimPage"
    />
    <ClaimsDetailsPage
      {...props}
      path="/claims/view/:claimId"
      type="viewClaimPage"
    />
    <NotFoundPage path="/404" />
  </Router>
);

export default Claims;
