import { get } from 'lodash';
import { required } from "@src/utilities/validators";
import { COMPANY_CODES, GROUP_MGA } from "@src/utilities/constants";
import {
  legalIdentificationNumberMask,
  producerCodeMask,
  SICCodeMask,
} from "@src/utilities/masks";

const basicInformation = {
  fields: [
    {
      isDisabled: ({ stateValues }) => get(stateValues, 'groupId'),
      label: 'Group MGA',
      name: 'mga',
      options: GROUP_MGA,
      type: 'Dropdown',
      validate: required,
    },
    {
      label: 'Federal Number',
      mask: legalIdentificationNumberMask,
      name: 'fin',
      type: 'InputMasked',
      validate: required,
    },
    {
      label: 'Company Code',
      name: 'companyCode',
      options: COMPANY_CODES,
      type: 'Dropdown',
      validate: required,
    },
    {
      label: 'Agency Code',
      mask: producerCodeMask,
      name: 'agencyCode',
      type: 'InputMasked',
      validate: required,
    },
    {
      label: 'SIC Code',
      mask: SICCodeMask,
      name: 'sicCode',
      type: 'InputMasked',
      validate: required,
    },
    {
      isDisabled: () => true,
      label: 'MGA Group Number',
      name: 'groupNumber',
      type: 'Input',
    },
    {
      label: 'Group Name',
      name: 'groupName',
      type: 'Input',
      validate: required,
    },
    {
      label: 'Alternate Registration',
      name: 'alternateRegistration',
      type: 'Checkbox',
    },
  ],
  name: 'basic-information',
  sectionOperations: {
    canSkip: ({ stateValues }) => get(stateValues, 'groupId'),
  },
  title: 'Basic Information',
  type: 'Form',
};

export default basicInformation;
