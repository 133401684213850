import { Font, StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import Arial from "@src/assets/fonts/Arial.woff";
import ArialBold from "@src/assets/fonts/ArialBold.woff";

Font.register({ family: 'Arial', src: Arial });
Font.register({ family: 'ArialBold', src: ArialBold });

/**
 * @category TBD
 * @param {boolean} secondary
 * @param {React.ReactNode} children
 * @returns {React.FC}
 */
const UnorderedListItem = ({ secondary, children }) => {
  const styles = StyleSheet.create({
    bulletItem: {
      flexDirection: 'row',
      margin: '0 0 0 17px',
    },
    bulletItemSecondary: {
      flexDirection: 'row',
      margin: '0 0 0 51px',
    },
    bulletPoint: {
      margin: '0 10px 0 0',
      width: 10,
    },
  });

  return (
    <View style={secondary ? styles.bulletItemSecondary : styles.bulletItem}>
      {secondary ? (
        <Text style={[styles.bulletPoint]}>&#9702;</Text>
      ) : (
        <Text style={[styles.bulletPoint]}>&#8226;</Text>
      )}
      <Text>{children}</Text>
    </View>
  );
};

export default UnorderedListItem;

UnorderedListItem.propTypes = {
  children: PropTypes.node.isRequired,
  secondary: PropTypes.bool,
};

UnorderedListItem.defaultProps = {
  secondary: false,
};
