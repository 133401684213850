import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import GetData from "@src/utilities/get-data";
import ModuleContainer from "@petcomponents/Variant/ModuleInfo/Container";

/**
 * Employee Dependent Breed variant
 *
 * @category Components
 * @param {string} status
 * @returns {React.FC}
 */
const Breed = ({ value }) => {
    const { i18n } = useTranslation();
    const [breedNameData, setBreedNameData] = useState({});
    const { apiData: breeds, loading } = GetData(
        'get-all-breeds',
        JSON.stringify([])
    );
        useEffect(()=>{
        if(breeds.length > 0 ){
            const breedData = breeds?.find(
                (v) => v.breedName === value
            );
            setBreedNameData(breedData);
        }
    },[loading])


    return (
        <ModuleContainer displaySx={{whiteSpace: 'normal'}}
            displayValue={i18n.language === 'fr' ? breedNameData?.frenchValue : breedNameData?.breedName}
            loading={loading}
        />
    );
};
Breed.propTypes = {
    value: PropTypes.string.isRequired,
};

export default Breed;
