import React from 'react';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import InputMasked from "@basecomponents/InputMasked";

/**
 * @category BaseComponents
 * @param {string} defaultValue
 * @param {*} rest
 * @returns {React.FC}
 */
const InputHours = ({ defaultValue, ...rest }) => (
  <InputMasked
    autoComplete="new-password"
    defaultValue={defaultValue || ''}
    mask={createNumberMask({
      allowDecimal: false,
      integerLimit: 3,
      prefix: '',
      suffix: ' hr(s)',
    })}
    {...rest}
  />
);

InputHours.propTypes = {
  defaultValue: PropTypes.string,
};

InputHours.defaultProps = {
  defaultValue: '',
};

export default InputHours;
