import { get } from 'lodash';
import config from "@src/config.json";
import {
  composeValidations,
  required,
} from "@src/utilities/validators";
import {CA_PROVINCES, US_STATES} from "@src/utilities/constants";
import {
  billingAccountNumberMask,
  billingRoutingNumberMask,
  phoneNumberMask,
} from "@src/utilities/masks";
import { zipcode } from "@src/utilities/validations";
import BillingInfoSitusAddress from "@petcomponents/Groups/BillingInfoSitusAddress";


const billingInfo = {
  fields: [
    {
      label: 'Contact Number',
      mask: phoneNumberMask,
      name: 'contact.phoneNumber',
      type: 'InputMasked',
    },
    {
      label: 'Billing Cycle',
      name: 'billingCycle',
      options: [
        { label: '12', value: '12' },
        { label: '24', value: '24' },
        { label: '26', value: '26' },
        { label: '52', value: '52' },
      ],
      type: 'Dropdown',
    },
    {
      label: 'Payroll Frequency',
      name: 'payrollFrequency',
      options: [
        { label: '', value: '' },
        { label: '12', value: '12' },
        { label: '24', value: '24' },
        { label: '26', value: '26' },
        { label: '52', value: '52' },
      ],
      type: 'Dropdown',
    },
    {
      label: 'Billing Account Number',
      mask: billingAccountNumberMask,
      name: 'billingAccountNumber',
      type: 'InputMasked',
    },
    {
      label: 'Billing Routing Number',
      mask: billingRoutingNumberMask,
      name: 'billingRoutingNumber',
      type: 'InputMasked',
    },
    {
      label: 'Use Company Address',
      name: 'useSitusAddress',
      type: 'Checkbox',
    },
    {
      label: 'Situs Address',
      name: 'situsAddressDisplay',
      render: BillingInfoSitusAddress,
      type: 'Custom',
    },
    {
      isVisible: ({ currentValues }) => !get(currentValues, 'useSitusAddress'),
      label: 'Address Line 1',
      name: 'billingAddress.address.addressLine1',
      type: 'Input',
      validate: required,
    },
    {
      isVisible: ({ currentValues }) => !get(currentValues, 'useSitusAddress'),
      label: 'Address Line 2',
      name: 'billingAddress.address.addressLine2',
      type: 'Input',
    },
    {
      isVisible: ({ currentValues }) => !get(currentValues, 'useSitusAddress'),
      label: 'City',
      name: 'billingAddress.address.city',
      type: 'Input',
      validate: required,
    },
    {
      isVisible: ({ currentValues }) => !get(currentValues, 'useSitusAddress'),
      label: config.canadaEnv ? "Provinces" : "State",
      name: 'billingAddress.address.state',
      options: config.canadaEnv ? CA_PROVINCES  :US_STATES,
      type: 'Dropdown',
      validate: required,
    },
    {
      isVisible: ({ currentValues }) => !get(currentValues, 'useSitusAddress'),
      label: 'Zip Code',
      maxLength: 5,
      name: 'billingAddress.address.zipcode',
      type: 'Input',
      validate: composeValidations(required, zipcode),
    },
  ],
  name: 'billing-info',
  title: 'Billing Information',
  type: 'Form',
};

export default billingInfo;
