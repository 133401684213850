import { Router } from '@reach/router';
import React from 'react';
import LifeEventsDashboardPage from '@petcomponents/LifeEvents/DashboardPage';
import LifeEventsDetailPage from '@petcomponents/LifeEvents/DetailPage';
import AddQLEDocumentsPage from '@petcomponents/LifeEvents/AddQLEDocuments';
import NotFoundPage from "@src/routes/ihc/404";

const LifeEvents = (props) => (
  <Router>
    <LifeEventsDashboardPage {...props} path="/life-events/dashboard" />
    <LifeEventsDetailPage {...props} path="/life-events/:qleEventId/details" />
    <AddQLEDocumentsPage
      {...props}
      path="/life-events/:qleEventId/add-documents"
    />
    <NotFoundPage default path="/404" />
  </Router>
);

export default LifeEvents;
