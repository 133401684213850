import React, { useContext } from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import ButtonGroups from "@basecomponents/ButtonGroups";
import { TOOLTIP } from "@basecomponents/ListGrid/utils/constants";
import ToolbarButton from "@basecomponents/ToolbarButton";
import ActionDropdownMenu from "@basecomponents/ActionDropdown";
import EditAccessRightsContext from "@petcomponents/EditAccessRights/Context";
import TooltipBox from "@basecomponents/TooltipBox";

/**
 * @category BaseComponents
 * @param {Array<Object>} headerActions
 * @param {number} numberOfVisibleButtons
 * @param {number} dropDownZIndex
 * @param {string} permission
 * @param {boolean} isHidden
 * @param {*} rest
 * @returns {React.FC}
 */
const HeaderRightContainer = ({
  headerActions,
  numberOfVisibleButtons,
  dropDownZIndex,
  permission,
  isHidden,
  ...rest
}) => {
  const ear = useContext(EditAccessRightsContext);
  const updatedActions = headerActions.filter(
    (headerAction) =>
      !headerAction.isHidden && ear.isVisible(headerAction.permission)
  );
  const { defaultStyle = {} } = useContext(ThemeContext);
  const {
    outerBox = {},
    innerBox = {},
    displayBox = {},
  } = defaultStyle.defaultHeaderRightContainer;
  return (
    !isHidden &&
    ear.isVisible(permission) && (
      <Box sx={{ ...outerBox }}>
        <Box sx={{ ...innerBox }}>
          <ButtonGroups>
            {updatedActions
              .slice(0, numberOfVisibleButtons)
              .map((headerAction, index) => {
                return (
                  <>
                    <Box
                      key={`headerRightContainer-${index}`}
                      data-for={`${index}-${TOOLTIP}`}
                      data-tip={headerAction.label}
                      mx={2}
                    >
                      <ToolbarButton
                        aria-label={headerAction.label}
                        bg={index % 2 === 0 ? 'primaryDark' : 'primaryLight'}
                        icon={headerAction.icon}
                        isDisabled={headerAction.isDisabled ?? false}
                        link={
                          typeof headerAction.action === 'string'
                            ? headerAction.action
                            : null
                        }
                        onClick={
                          typeof headerAction.action !== 'string'
                            ? headerAction.action
                            : null
                        }
                        permission={headerAction.permission ?? ''}
                      />
                    </Box>

                    <TooltipBox
                      key={`${index}-"ts"`}
                      id={`${index}-${TOOLTIP}`}
                      tooltipProps={{ effect: 'solid' }}
                      tooltipSx={{ bg: 'accent' }}
                    />
                  </>
                );
              })}
          </ButtonGroups>
          {updatedActions.length > numberOfVisibleButtons && (
            <ActionDropdownMenu
              items={updatedActions.slice(numberOfVisibleButtons)}
              menuZIndex={dropDownZIndex}
              permission={permission}
              {...rest}
            />
          )}
        </Box>
        <Box sx={{ ...displayBox }}>
          <ActionDropdownMenu
            items={updatedActions}
            menuZIndex={dropDownZIndex}
            permission={permission}
            {...rest}
          />
        </Box>
      </Box>
    )
  );
};

HeaderRightContainer.propTypes = {
  dropDownZIndex: PropTypes.number,
  headerActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isHidden: PropTypes.bool,
  numberOfVisibleButtons: PropTypes.number,
  permission: PropTypes.string,
};

HeaderRightContainer.defaultProps = {
  dropDownZIndex: 3,
  isHidden: false,
  numberOfVisibleButtons: 3,
  permission: '',
};
export default HeaderRightContainer;
