import { AuthContext } from '@basecomponents/Auth';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { ThemeContext } from 'styled-components';
import { TOOLTIP } from '@basecomponents/ListGrid/utils/constants';
import { useTranslation } from 'react-i18next';
import remoteActionQuery from "@queries/remote-action.gql";
import dateUtils from "@src/utilities/date-utils";
import GetData from "@src/utilities/get-data";
import useSnackbar from "@src/utilities/use-snackbar";
import Input from "@basecomponents/Input";
import ToolbarButton from "@basecomponents/ToolbarButton";
import User from "@petcomponents/Variant/ModuleInfo/User";
import config from "@src/config.json";

/**
 * @category QLE
 * @param {string} qleEventId
 * @param {*} rest
 * @returns {React.FC}
 */
const QleNotesSection = ({ qleEventId, ...rest }) => {
  const { apiData = {}, refetch } = GetData(
    'get-all-qle-comments',
    JSON.stringify({ qleEventId, sort: 'created,desc' })
  );
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const data = get(apiData, 'content', []);
  const theme = useContext(ThemeContext);
  const { t } = useTranslation()

  return (
    <ApolloConsumer>
      {(client) => {
        return (
          <>
            <FinalForm
              onSubmit={async (values, form) => {
                client
                  .query({
                    fetchPolicy: 'no-cache',
                    query: remoteActionQuery,
                    variables: {
                      name: 'create-qle-comment',
                      params: JSON.stringify({
                        comment: values.comment,
                        commentedBy: userId,
                        qleEventId,
                      }),
                    },
                  })
                  .then(() => {
                    setSucessSnack(
                      t('snack.success.addedComment'),
                      config.notificationDuration
                    );
                    form.reset();
                    refetch();
                  })
                  .catch((e) =>
                    setErrorSnack(
                      t('snack.error.errorWithMessage', { message: e.message }),
                      config.notificationDuration
                    )
                  );
              }}
            >
              {(formContext) => (
                <form onSubmit={formContext.handleSubmit}>
                  <Box
                    display={{ _: 'block', md: 'flex' }}
                    sx={{
                      mb: 4,
                    }}
                  >
                    <Field
                      aria-label="Comment"
                      component={Input}
                      mb={{ _: 5, md: 0 }}
                      name="comment"
                      placeholder="Type to comment"
                      wrapperSx={{ mr: 2 }}
                      {...rest}
                    />
                    <ToolbarButton
                      isDisabled={
                        formContext.submitting || !formContext.values.comment
                      }
                      label="Comment"
                      margin="auto"
                      submitting={formContext.submitting}
                      type="submit"
                      width="150px"
                    />
                  </Box>
                </form>
              )}
            </FinalForm>
            <Box
              sx={{
                color: 'accent',
                fontSize: 3,
                mb: 3,
                mt: 6,
              }}
            >
              {data.length > 0 &&
                data.map((data, index) => (
                  <Box
                    key={`comment-${index}`}
                    sx={{
                      border: `1px solid ${theme.colors.accent}`,
                      borderRadius: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      my: 3,
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        mb: 1,
                        mt: 3,
                      }}
                    >
                      <User
                        displaySx={{
                          fontWeight: 'bold',
                          mr: 2,
                        }}
                        id={get(data, 'commentedBy', null)}
                      />
                      <Box
                        data-for={TOOLTIP}
                        data-tip={dateUtils.getFormattedDateTime(
                          get(data, 'created', '')
                        )}
                        sx={{
                          color: 'accent',
                          flexDirection: 'flex-end',
                          fontSize: 2,
                        }}
                      >
                        {dateUtils.fromNow(get(data, 'created', ''))}
                      </Box>
                    </Box>
                    {data.comment}
                  </Box>
                ))}
            </Box>
          </>
        );
      }}
    </ApolloConsumer>
  );
};

QleNotesSection.propTypes = {
  qleEventId: PropTypes.string.isRequired,
};

export default QleNotesSection;
