import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useParams } from '@reach/router';
import { get } from 'lodash';
import { AuthContext } from '@basecomponents/Auth';
import { ThemeContext } from 'styled-components';
import { EXISTING_CONDITION } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import GetData from "@src/utilities/get-data";
import numberWithCommas from "@src/utilities/number-with-commas";
import Icon from "@src/components/Icon";
import CoverageField from "@petcomponents/GroupEmployees/Upsert/CoverageTab/CoverageField";
import SelectablePetPremium from "@petcomponents/GroupEmployees/Upsert/CoverageTab/SelectablePetPremium";
import config from "@src/config.json";

const isValidSelection = (element, amount, isSelected) => {
  if (element === 'borderColor') {
    if (amount === 0) {
      if (isSelected) {
        return 'primary';
      }
      return 'accent';
    }
    return 'accent';
  }
  if (element === 'svg') {
    if (amount === 0) {
      if (isSelected) {
        return 'check';
      }
      return 'add';
    }
    return 'close';
  }
};

/**
 * @category Employee
 * @param {string} contributionMessage
 * @param {Object} coverageResponse
 * @param {Array<Object>} dependents
 * @param {Array<string>} disclaimers
 * @param {Array<string>} maxAgeError
 * @param {boolean} isResponsive
 * @param {function} onSelect
 * @param {string} planChoiceId
 * @param {Object} premium
 * @param {string} product
 * @param {function} refetch
 * @param {string} selectedPlanChoiceId
 * @param {string} title
 * @returns {React.FC}
 */
const CoverageItem = ({
  contributionMessage,
  coverageResponse,
  dependents,
  disclaimers,
  maxAgeError,
  disabledClick,
  isResponsive,
  onSelect,
  payrollFrequency,
  planChoiceId,
  premium,
  product,
  refetch,
  selectedPlanChoiceId,
  title,
}) => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const queryParams = useParams();
  const employeeId = get(queryParams, 'employeeId', '');
  const theme = useContext(ThemeContext);

  const { apiData: groups = {} } = GetData(
    'get-employee-by-id',
    JSON.stringify({ id: employeeId })
  );

  const disabledDependents = dependents.filter(
    (dependent) => dependent.premium === 0 && dependent.status === 'PENDING'
  ).length || maxAgeError.length;

  const [additionalBenefitsExpanded, setAdditionalBenefitsExpanded] =
    useState(false);
  const [premiumBreakupExpanded, setPremiumBreakupExpanded] = useState(false);
  const [Expanded, setExpanded] = useState(false);

  return (
    <Box
      sx={{
        borderRadius: 4,
        boxShadow: selectedPlanChoiceId === planChoiceId ? 6 : 7,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        m: 2,
        transition: 'all, 0.25s ease-in-out',
        width: '27rem',
      }}
    >
      <Box
        bg={isValidSelection(
          'bg',
          disabledDependents,
          selectedPlanChoiceId === planChoiceId
        )}
        data-testid="selectionPlan"
        onClick={onSelect}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 3,
          transition: 'all 0.25s ease-in-out',
        }}
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          sx={{ margin: '0px 0px 0px 5px' }}
        >
          <Box
            as="h3"
            color="primary"
            display="flex"
            flexDirection="row"
            fontSize="25px"
            padding="20 0 0 5px"
          >
            {title}
          </Box>
          <Icon
            svg={
              selectedPlanChoiceId === planChoiceId || disabledClick
                ? 'check'
                : 'add'
            }
            sx={{
              bg: `${
                selectedPlanChoiceId === planChoiceId
                  ? 'primary'
                  : 'transparent'
              }`,
              borderRadius: '30px',
              color: `${
                selectedPlanChoiceId === planChoiceId ? 'white' : 'primary'
              }`,
              height: '30px',
              mr: 4,
              p: '1px',
              transition: 'all 1s ease-in-out',
              width: '30px',
            }}
          />
        </Box>
        <Box
          color="primary"
          display="flex"
          fontSize="20px"
          fontWeight="bold"
          justifyContent="flex-start"
          sx={{ margin: '25px 5px 20px 5px;' }}
        >
          {product} Insurance
        </Box>
        {isResponsive === true ? (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(!Expanded);
            }}
            sx={{
              bg: isValidSelection(
                'bg',
                disabledDependents,
                selectedPlanChoiceId === planChoiceId
              ),
              borderRadius: 4,
              boxShadow: 7,
              margin: '20px 0px 0px 5px',
              p: 4,
              width: '25rem',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box as="h4" sx={{ color: 'primary' }}>
                {Expanded ? 'See less' : 'See more'}
              </Box>
              <Icon
                svg={premiumBreakupExpanded ? 'expand-less' : 'expand-more'}
                sx={{
                  color: 'primary',
                  height: '30px',
                  width: '30px',
                }}
              />
            </Box>
            <Box
              sx={{
                display: Expanded ? 'border-box' : 'none',
                mt: '20px',
              }}
            >
              <Box sx={{ margin: '10px 0px 0px 5px;' }}>
                {disclaimers.map((disclaimer) => {
                  return (
                    <Box as="h4" sx={{ color: 'error', mb: '20px' }}>
                      {disclaimer}
                    </Box>
                  );
                })}
                {maxAgeError.map((maxAgeError) => {
                  return (
                    <Box as="h4" sx={{ color: 'error', mb: '20px' }}>
                      {maxAgeError}
                    </Box>
                  );
                })}

                {role === 'employee' ? (
                  <Box
                    as="h4"
                    onClick={() =>
                      window.open(
                        `/group-employees/defaultEnrollee?showCoverage=true`,
                        '_blank'
                      )
                    }
                    sx={{ cursor: 'pointer', mb: '20px' }}
                  >
                    {t('groupEmployees.enrollmentPage.selectAdditionalCoverage')}
                  </Box>
                ) : null}
                {role === 'employer' ? (
                  <Box
                    as="h4"
                    onClick={() =>
                      window.open(`/groups/defaultGroup`, '_blank')
                    }
                    sx={{ cursor: 'pointer', mb: '20px' }}
                  >
                    {t('groupEmployees.enrollmentPage.selectAdditionalCoverage')}
                  </Box>
                ) : null}
                {role === 'admin' ? (
                  <Box
                    as="h4"
                    onClick={() =>
                      window.open(
                        `/groups/${get(groups, 'groupId')}/details`,
                        '_blank'
                      )
                    }
                    sx={{ cursor: 'pointer', mb: '20px' }}
                  >
                    {t('groupEmployees.enrollmentPage.selectAdditionalCoverage')}
                  </Box>
                ) : null}

                <Box as="h4" sx={{ color: 'primary', mb: '20px' }}>
                {t('groupEmployees.enrollmentPage.basePolicy')}
                </Box>
                <CoverageField
                  label={t('metadata.annualPolicyLimit')}
                  value={
                    coverageResponse?.annualPolicyLimit > 0
                      ? `$${numberWithCommas(
                          coverageResponse.annualPolicyLimit
                        )}`
                      : t('metadata.unlimited')
                  }
                />
                <CoverageField
                  label={t('metadata.annaulDeductible')}
                  value={`$${numberWithCommas(
                    coverageResponse.annualDeductible
                  )}`}
                />
                <CoverageField
                  label={t('metadata.coinsurance')}
                  value={`${coverageResponse.coInsurance}%`}
                />
              </Box>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  setAdditionalBenefitsExpanded(!additionalBenefitsExpanded);
                }}
                sx={{
                  bg: isValidSelection(
                    'bg',
                    disabledDependents,
                    selectedPlanChoiceId === planChoiceId
                  ),
                  borderRadius: 4,
                  boxShadow: 7,
                  margin: '20px 0px 0px 5px',
                  p: 4,
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box as="h4" sx={{ color: 'primary' }}>
                  {t('groupEmployees.enrollmentPage.additionalBenefits')}
                  </Box>
                  <Icon
                    svg={
                      additionalBenefitsExpanded ? 'expand-less' : 'expand-more'
                    }
                    sx={{
                      color: 'primary',
                      height: '30px',
                      width: '30px',
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: additionalBenefitsExpanded ? 'border-box' : 'none',
                    mt: '20px',
                  }}
                >
                  {product === 'Accident & Illness' ? (
                    <CoverageField
                      label={t('metadata.nonriderWellness')}
                      value={
                        coverageResponse.wellness === 'Not Included'
                          ? t('metadata.notIncluded')
                          : `${coverageResponse.wellness}`
                      }
                    />
                  ) : null}

                  <CoverageField
                    label={t('metadata.nonriderRPT')}
                    value={coverageResponse.riderRPT}
                  />

                  {product === 'Accident & Illness' ? (
                    <CoverageField
                      label={
                        config.canadaEnv
                          ? t('metadata.inheritedConditionRider')
                          : 'Inherited and Congenital Care'
                      }
                      value={coverageResponse.riderICC}
                    />
                  ) : null}

                  <CoverageField
                    label={t('metadata.nonriderOETC')}
                    value={coverageResponse.riderOETC}
                  />

                  {product === 'Accident & Illness' ? (
                    <CoverageField
                      label={t('metadata.alternateBehavioralCare')}
                      value={coverageResponse.riderABC}
                    />
                  ) : null}

                  {product === 'Accident Only' ? (
                    <CoverageField
                      label={t('metadata.alternateBehavioralCare')}
                      value={coverageResponse.riderAC}
                    />
                  ) : null}

                  <CoverageField
                    label={t('metadata.finalRespects')}
                    value={
                      coverageResponse?.riderFR > 0
                        ? `$${numberWithCommas(coverageResponse.riderFR)}`
                        : t('metadata.notIncluded')
                    }
                  />

                  <CoverageField
                    label={t('metadata.routineDental')}
                    value={
                      coverageResponse.riderRD === 0
                        ? t('metadata.notIncluded')
                        : `$${coverageResponse.riderRD}`
                    }
                  />

                  {product === 'Accident & Illness' ? (
                    <CoverageField
                      label={
                        config.canadaEnv
                          ? t('metadata.inheritedConditionRider')
                          : 'Inherited and Congenital Care'
                      }
                      value={coverageResponse.riderICC}
                    />
                  ) : null}

                  <CoverageField
                    label={t('metadata.poaAR')}
                    value={coverageResponse.poaAR}
                  />

                  <CoverageField
                    label={t('metadata.bwpInjuries')}
                    value={
                      coverageResponse.bwpInjuries
                        ? `${coverageResponse.bwpInjuries} ${t('common.days')}`
                        : t('metadata.waived')
                    }
                  />

                  {product === 'Accident & Illness' ? (
                    <CoverageField
                      label={t('metadata.bwpIllness')}
                      value={
                        coverageResponse.bwpIllness
                          ? `${coverageResponse.bwpIllness} ${t('common.days')}`
                          : t('metadata.waived')
                      }
                    />
                  ) : null}

                  <CoverageField
                    label={t('metadata.bwpCLI')}
                    value={t('metadata.sixMonths')}
                  />

                  {product === 'Accident & Illness' ? (
                    <CoverageField
                      label={t('metadata.maxAge')}
                      value={
                        coverageResponse.maxAge === 0
                          ? t('metadata.none')
                          : coverageResponse.maxAge
                      }
                    />
                  ) : null}

                  <CoverageField
                    label={t('metadata.preExistingConditions')}
                    value={
                      EXISTING_CONDITION.find(
                        (s) =>
                          s.value ===
                          (parseInt(coverageResponse.existingCondition, 10)
                            ? parseInt(coverageResponse.existingCondition, 10)
                            : 0)
                      )[i18n.language === 'fr' ? 'frenchValue' : 'label']
                    }
                  />

                  {product === 'Accident & Illness' ? (
                    <CoverageField
                      label={t('metadata.coverageCredit')}
                      value={coverageResponse.coverageCredit}
                    />
                  ) : null}

                  {product === 'Accident & Illness' ? (
                    <CoverageField
                      label={t('metadata.diminishingDeductible')}
                      value={`$${coverageResponse.diminishingDeductible}`}
                    />
                  ) : null}

                  {product === 'Accident & Illness' ? (
                    <CoverageField
                      label={t('metadata.copay')}
                      value={
                        coverageResponse.copay === 0
                          ? t('metadata.notIncluded')
                          : `$${coverageResponse.copay}`
                      }
                    />
                  ) : null}
                </Box>
              </Box>
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                  setPremiumBreakupExpanded(!premiumBreakupExpanded);
                }}
                sx={{
                  bg: isValidSelection(
                    'bg',
                    disabledDependents,
                    selectedPlanChoiceId === planChoiceId
                  ),
                  borderRadius: 4,
                  boxShadow: 7,
                  margin: '20px 0px 0px 5px',
                  p: 4,
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box as="h4" sx={{ color: 'primary' }}>
                  {t('groupEmployees.enrollmentPage.premiumSummary')}
                  </Box>
                  <Icon
                    svg={premiumBreakupExpanded ? 'expand-less' : 'expand-more'}
                    sx={{
                      color: 'primary',
                      height: '30px',
                      width: '30px',
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    display: premiumBreakupExpanded ? 'border-box' : 'none',
                    mt: '20px',
                  }}
                >
                  <Box
                    sx={{
                      mb: 3,
                      mt: 3,
                    }}
                  >
                    {t('groupEmployees.enrollmentPage.petExcludeCoverage')}
                  </Box>
                  {dependents.map((dependent) => (
                    <SelectablePetPremium
                      dependent={dependent}
                      disabledClick={disabledClick}
                      petBackgroundColor={isValidSelection(
                        'bg',
                        disabledDependents,
                        selectedPlanChoiceId === planChoiceId
                      )}
                      refetch={refetch}
                      showEmployerContribution={
                        get(premium, 'employerContribution', 0) > 0
                      }
                    />
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  mb: 4,
                  mt: 6,
                }}
              >
                {get(premium, 'payPeriodPremiumEmployer', 0) > 0 && (
                  <CoverageField
                    label={t(
                      'groupEmployees.enrollmentPage.addPetSection.empContributionPayCycle'
                    )}
                    value={`$${numberWithCommas(
                      get(premium, 'payPeriodPremiumEmployer', 0).toFixed(2)
                    )} `}
                  />
                )}

                {get(premium, 'payPeriodPremiumEmployee', 0) > 0 && (
                  <CoverageField
                    label={t('metadata.totalEmployeePremiumPerPayCycle')}
                    value={`$${numberWithCommas(
                      get(premium, 'payPeriodPremiumEmployee', 0).toFixed(2)
                    )} `}
                  />
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={{ margin: '10px 0px 0px 5px;' }}>
              {disclaimers.map((disclaimer) => {
                return (
                  <Box as="h4" sx={{ color: 'error', mb: '20px' }}>
                    {disclaimer}
                  </Box>
                );
              })}
              {maxAgeError.map((maxAgeError) => {
                return (
                  <Box as="h4" sx={{ color: 'error', mb: '20px' }}>
                    {maxAgeError}
                  </Box>
                );
              })}

              {role === 'employee' ? (
                <Box
                  as="h4"
                  onClick={() =>
                    window.open(
                      `/group-employees/defaultEnrollee?showCoverage=true`,
                      '_blank'
                    )
                  }
                  sx={{ cursor: 'pointer', mb: '20px' }}
                >
                  {t('groupEmployees.enrollmentPage.selectAdditionalCoverage')}
                </Box>
              ) : null}
              {role === 'employer' ? (
                <Box
                  as="h4"
                  onClick={() => window.open(`/groups/defaultGroup`, '_blank')}
                  sx={{ cursor: 'pointer', mb: '20px' }}
                >
                  {t('groupEmployees.enrollmentPage.selectAdditionalCoverage')}
                </Box>
              ) : null}
              {role === 'admin' ? (
                <Box
                  as="h4"
                  onClick={() =>
                    window.open(
                      `/groups/${get(groups, 'groupId')}/details`,
                      '_blank'
                    )
                  }
                  sx={{ cursor: 'pointer', mb: '20px' }}
                >
                  {t('groupEmployees.enrollmentPage.selectAdditionalCoverage')}
                </Box>
              ) : null}

              <Box as="h4" sx={{ color: 'primary', mb: '20px' }}>
              {t('groupEmployees.enrollmentPage.basePolicy')}
              </Box>
              <CoverageField
                label={t('metadata.annualPolicyLimit')}
                value={
                  coverageResponse?.annualPolicyLimit > 0
                    ? `$${numberWithCommas(coverageResponse.annualPolicyLimit)}`
                    : t('metadata.unlimited')
                }
              />
              <CoverageField
                label={t('metadata.annaulDeductible')}
                value={`$${numberWithCommas(
                  coverageResponse.annualDeductible
                )}`}
              />
              <CoverageField
                label={t('metadata.coinsurance')}
                value={`${coverageResponse.coInsurance}%`}
              />
            </Box>
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setAdditionalBenefitsExpanded(!additionalBenefitsExpanded);
              }}
              sx={{
                bg: isValidSelection(
                  'bg',
                  disabledDependents,
                  selectedPlanChoiceId === planChoiceId
                ),
                borderRadius: 4,
                boxShadow: 7,
                margin: '20px 0px 0px 5px',
                p: 4,
                width: '25rem',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box as="h4" sx={{ color: 'primary' }}>
                {t('groupEmployees.enrollmentPage.additionalBenefits')}
                </Box>
                <Icon
                  svg={
                    additionalBenefitsExpanded ? 'expand-less' : 'expand-more'
                  }
                  sx={{
                    color: 'primary',
                    height: '30px',
                    width: '30px',
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: additionalBenefitsExpanded ? 'border-box' : 'none',
                  mt: '20px',
                }}
              >
                {product === 'Accident & Illness' ? (
                  <CoverageField
                    label={t('metadata.nonriderWellness')}
                    value={
                      coverageResponse.wellness === 'Not Included'
                        ? t('metadata.notIncluded')
                        : `${coverageResponse.wellness}`
                    }
                  />
                ) : null}

                <CoverageField
                  label={t('metadata.nonriderRPT')}
                  value={coverageResponse.riderRPT}
                />

                {product === 'Accident & Illness' ? (
                  <CoverageField
                    label={
                      config.canadaEnv
                        ? t('metadata.inheritedConditionRider')
                        : 'Inherited and Congenital Care'
                    }
                    value={coverageResponse.riderICC}
                  />
                ) : null}

                <CoverageField
                  label={t('metadata.nonriderOETC')}
                  value={coverageResponse.riderOETC}
                />

                {product === 'Accident & Illness' ? (
                  <CoverageField
                    label={t('metadata.alternateBehavioralCare')}
                    value={coverageResponse.riderABC}
                  />
                ) : null}

                {product === 'Accident Only' ? (
                  <CoverageField
                    label={t('metadata.alternateCare')}
                    value={coverageResponse.riderAC}
                  />
                ) : null}

                <CoverageField
                  label={t('metadata.finalRespects')}
                  value={
                    coverageResponse?.riderFR > 0
                      ? `$${numberWithCommas(coverageResponse.riderFR)}`
                      : t('metadata.notIncluded')
                  }
                />

                <CoverageField
                  label={t('metadata.routineDental')}
                  value={
                    coverageResponse.riderRD === 0
                      ? t('metadata.notIncluded')
                      : `$${coverageResponse.riderRD}`
                  }
                />

                {product === 'Accident & Illness' ? (
                  <CoverageField
                    label={
                      config.canadaEnv
                        ? t('metadata.inheritedConditionRider')
                        : 'Inherited and Congenital Care'
                    }
                    value={coverageResponse.riderICC}
                  />
                ) : null}

                <CoverageField
                  label={t('metadata.poaAR')}
                  value={coverageResponse.poaAR}
                />

                <CoverageField
                  label={t('metadata.bwpInjuries')}
                  value={
                    coverageResponse.bwpInjuries
                      ? `${coverageResponse.bwpInjuries} ${t('common.days')}`
                      : t('metadata.waived')
                  }
                />

                {product === 'Accident & Illness' ? (
                  <CoverageField
                    label={t('metadata.bwpIllness')}
                    value={
                      coverageResponse.bwpIllness
                        ? `${coverageResponse.bwpIllness} ${t('common.days')}`
                        : t('metadata.waived')
                    }
                  />
                ) : null}

                <CoverageField
                  label={t('metadata.bwpCLI')}
                  value={t('metadata.sixMonths')}
                />

                {product === 'Accident & Illness' ? (
                  <CoverageField
                    label={t('metadata.maxAge')}
                    value={
                      coverageResponse.maxAge === 0
                        ? t('metadata.none')
                        : coverageResponse.maxAge
                    }
                  />
                ) : null}

                <CoverageField
                  label={t('metadata.preExistingConditions')}
                  value={
                    EXISTING_CONDITION.find(
                      (s) =>
                        s.value ===
                        (parseInt(coverageResponse.existingCondition, 10)
                          ? parseInt(coverageResponse.existingCondition, 10)
                          : 0)
                    )[i18n.language === 'fr' ? 'frenchValue' : 'label']
                  }
                />

                {product === 'Accident & Illness' ? (
                  <CoverageField
                    label={t('metadata.coverageCredit')}
                    value={coverageResponse.coverageCredit}
                  />
                ) : null}

                {product === 'Accident & Illness' ? (
                  <CoverageField
                    label={t('metadata.diminishingDeductible')}
                    value={`$${coverageResponse.diminishingDeductible}`}
                  />
                ) : null}

                {product === 'Accident & Illness' ? (
                  <CoverageField
                    label={t('metadata.copay')}
                    value={
                      coverageResponse.copay === 0
                        ? t('metadata.notIncluded')
                        : `$${coverageResponse.copay}`
                    }
                  />
                ) : null}
              </Box>
            </Box>
            <Box
              onClick={(e) => {
                e.stopPropagation();
                setPremiumBreakupExpanded(!premiumBreakupExpanded);
              }}
              sx={{
                bg: isValidSelection(
                  'bg',
                  disabledDependents,
                  selectedPlanChoiceId === planChoiceId
                ),
                borderRadius: 4,
                boxShadow: 7,
                margin: '20px 5px 0px 5px',
                p: 4,
                width: '25rem',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box as="h4" sx={{ color: 'primary' }}>
                {t('groupEmployees.enrollmentPage.premiumSummary')}
                </Box>
                <Icon
                  svg={premiumBreakupExpanded ? 'expand-less' : 'expand-more'}
                  sx={{
                    color: 'primary',
                    height: '30px',
                    width: '30px',
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: premiumBreakupExpanded ? 'border-box' : 'none',
                  mt: '20px',
                }}
              >
                <Box
                  sx={{
                    mb: 3,
                    mt: 3,
                  }}
                >
                  {t('groupEmployees.enrollmentPage.petExcludeCoverage')}
                </Box>
                <Box
                  sx={{
                    mb: 3,
                  }}
                >
                  {t('groupEmployees.enrollmentPage.premiumCollectedPayCycle')}
                </Box>
                {dependents.map((dependent) => (
                  <SelectablePetPremium
                    dependent={dependent}
                    disabledClick={disabledClick}
                    petBackgroundColor={isValidSelection(
                      'bg',
                      disabledDependents,
                      selectedPlanChoiceId === planChoiceId
                    )}
                    refetch={refetch}
                    showEmployerContribution={
                      get(premium, 'employerContribution', 0) > 0
                    }
                  />
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                mb: 0,
                mt: 6,
              }}
            >
              {get(premium, 'payPeriodPremiumEmployer', 0) > 0 && (
                <CoverageField
                  label={
                    payrollFrequency === '12'
                      ? t(
                          'groupEmployees.enrollmentPage.addPetSection.employerContributionMonthly'
                        )
                      : t(
                          'groupEmployees.enrollmentPage.addPetSection.empContributionPayCycle'
                        )
                  }
                  value={`$${numberWithCommas(
                    get(premium, 'payPeriodPremiumEmployer', 0).toFixed(2)
                  )}`}
                />
              )}

              {get(premium, 'payPeriodPremiumEmployee', 0) > 0 && (
                <CoverageField
                  label={
                    payrollFrequency === '12'
                      ? t(
                          'groupEmployees.enrollmentPage.addPetSection.monthlyPremiumEmployee'
                        )
                      : t(
                          'groupEmployees.enrollmentPage.addPetSection.empPremiumPayCycle'
                        )
                  }
                  value={`$${numberWithCommas(
                    get(premium, 'payPeriodPremiumEmployee', 0).toFixed(2)
                  )}`}
                />
              )}
            </Box>
          </>
        )}
      </Box>
      {contributionMessage && (
        <Box
          sx={{
            margin: '20px 15px 20px 15px',
            textAlign: 'justify',
          }}
        >
          {contributionMessage}
        </Box>
      )}
      <Box
        sx={{
          alignItems: 'center',
          bg: `${
            selectedPlanChoiceId === planChoiceId
              ? `${theme.colors.primary}`
              : `${theme.colors.transparent}`
          }`,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          color: `${
            selectedPlanChoiceId === planChoiceId ? 'white' : 'primary'
          }`,
          fontSize: 4,
          fontWeight: 'bold',
          p: 3,
          transition: 'all 0.25s ease-in-out',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <Box>
            <Box>
              Total Premium{' '}
              {payrollFrequency === '12' ? 'Monthly' : 'Per Pay Cycle'}{' '}
            </Box>
          </Box>{' '}
          {numberWithCommas(coverageResponse.displayAmount)}
        </Box>
        <Box
          sx={{
            fontSize: 1,
            textAlign: 'center',
          }}
        >
          {t('groupEmployees.enrollmentPage.premiumPayrollFrequency', { payrollFrequency })}
        </Box>
      </Box>
    </Box>
  );
};

CoverageItem.propTypes = {
  contributionMessage: PropTypes.string,
  coverageResponse: PropTypes.shape({
    amount: PropTypes.number,
    annualDeductible: PropTypes.number,
    annualPolicyLimit: PropTypes.number,
    bwpIllness: PropTypes.string,
    bwpInjuries: PropTypes.string,
    coInsurance: PropTypes.number,
    copay: PropTypes.string,
    coverageCredit: PropTypes.string,
    diminishingDeductible: PropTypes.string,
    displayAmount: PropTypes.number,
    existingCondition: PropTypes.number,
    maxAge: PropTypes.string,
    poaAR: PropTypes.string,
    poaBF: PropTypes.string,
    poaLP: PropTypes.string,
    poaTTPDL: PropTypes.string,
    poaVC: PropTypes.string,
    riderABC: PropTypes.string,
    riderAC: PropTypes.string,
    riderFR: PropTypes.number,
    riderICC: PropTypes.string,
    riderOETC: PropTypes.string,
    riderRD: PropTypes.number,
    riderRPT: PropTypes.string,
    wellness: PropTypes.string,
  }).isRequired,
  dependents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabledClick: PropTypes.bool,
  disclaimers: PropTypes.arrayOf().isRequired,
  maxAgeError: PropTypes.arrayOf().isRequired,
  isResponsive: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  payrollFrequency: PropTypes.string,
  planChoiceId: PropTypes.string.isRequired,
  premium: PropTypes.shape({}).isRequired,
  product: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  selectedPlanChoiceId: PropTypes.string,
  title: PropTypes.string.isRequired,
};

CoverageItem.defaultProps = {
    contributionMessage: null,
    disabledClick: false,
    payrollFrequency: null,
    selectedPlanChoiceId: '',
};

export default CoverageItem;
