import get from 'lodash/get';
import {useTranslation} from "react-i18next";
import GetData from "@src/utilities/get-data";

const PayrollFrequency = ({ value }) => {
  const { i18n } = useTranslation();
  const data = [
    GetData(
      'get-enum-options',
      JSON.stringify([{ params: 'PayrollCycleFrequency' }])
    ),
  ];
  const type = get(data, '0.apiData.PayrollCycleFrequency', []);
  const billingCycle = type.find((item) => item.value === value);
  // eslint-disable-next-line no-nested-ternary
  return billingCycle ? (i18n.language === 'fr' && get(billingCycle, 'frenchValue', '') !== '') ? get(billingCycle, 'frenchValue') : get(billingCycle, 'label') : value;
};

export default PayrollFrequency;
