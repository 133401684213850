import get from 'lodash/get';
import { FILE_STATUS } from "@src/utilities/constants";

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const FileStatus = ({ value }) => {
  const fileStatus = FILE_STATUS.find((s) => s.value === value);
  return fileStatus ? get(fileStatus, 'label') : `#${value}`;
};

export default FileStatus;
