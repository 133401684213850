import React, { useContext } from 'react';
import { useParams } from '@reach/router';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import Box from '@basecomponents/Box';
import config from "@src/config.json";
import { useTranslation } from 'react-i18next';
import Route from "@petcomponents/Route";
import Tabs from "@basecomponents/Tabs";
import KeyValuePairs from "@basecomponents/KeyValuePairs";
import GetData from "@src/utilities/get-data";
import getMetaData from "@src/utilities/get-meta-data";
import Card from "@basecomponents/Card";
import GroupsDashboardPage from "@petcomponents/GroupsDashboardPage";

/**
 * @category Subproducer
 * @param {*} rest
 * @returns {React.FC}
 */
const SubProducerDetails = ({ ...rest }) => {
  const { t } = useTranslation()
  const theme = useContext(ThemeContext);
  const queryParams = useParams();
  const subProducerId = get(queryParams, 'subProducerId', '');
  const { apiData = {}, loading } = GetData(
    'get-subproducer-by-id',
    JSON.stringify({ id: subProducerId })
  );
  const { apiData: { content: employeeData = [] } = {} } = GetData(
    'get-all-employee',
    JSON.stringify({
      email: get(apiData, 'email', ''),
      sort: 'created,desc',
    }),
    !get(apiData, 'isBroker')
  );
  const meta = getMetaData({ moduleName: 'sub-producers' });
  return (
    <Route
      header={{
        title: get(apiData, 'name', subProducerId),
        type: 'sub-producers',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {loading ? (
          <PulseLoader color={get(theme, 'colors.accent')} size={5} />
        ) : (
          `Details of ${get(apiData, 'name', subProducerId)}`
        )}
      </Box>
      <Card loading={loading}>
        <KeyValuePairs
          data={apiData}
          fields={[
            { name: 'name' },
            {
              label: config.canadaEnv ? t('groupEmployees.detailsPage.authorizedProvinces') : 'Authorized States',
              name: 'authorizedStates',
            },
            { name: 'status' },
            { name: 'isBroker' },
            { name: 'address' },
            { name: 'contact' },
          ]}
          meta={meta}
        />
      </Card>
      {get(apiData, 'isBroker') && (
        <Tabs
          loading={loading}
          tabs={[
            {
              label: 'Associated Groups',
              render: () => (
                <GroupsDashboardPage
                  brokerGroupId={get(employeeData, '0.groupId') || null}
                  extBrokerId={subProducerId}
                />
              ),
            },
          ]}
        />
      )}
    </Route>
  );
};

SubProducerDetails.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default SubProducerDetails;
