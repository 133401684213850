import React from 'react';
import { Field } from 'react-final-form';
import { get, uniqBy } from 'lodash';
import { useQuery } from '@apollo/client';
import Input from "@basecomponents/Input";
import DatePicker from "@basecomponents/DatePicker";
import InputDropdown from "@basecomponents/Dropdown";
import SingleDatePicker from "@basecomponents/SingleDatePicker";

/**
 * @category Components
 * @param {object} filter
 * @param {string} filterKey
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const DashboardFilterFormSection = ({ filter, filterKey, ...rest }) => {
  let result;
  const { label, options, query, type } = filter;
  const placeholder = 'Search...';

  switch (type) {
    case 'Input': {
      result = (
        <Field
          aria-label={label}
          component={Input}
          label={label}
          labelSx={{
            color: 'white',
            fontSize: 2,
          }}
          name={filterKey}
          placeholder={placeholder}
          {...rest}
        />
      );
      break;
    }
    case 'DatePicker': {
      result = (
        <Field
          aria-label={label}
          component={DatePicker}
          label={label}
          labelSx={{
            color: 'white',
            fontSize: 2,
          }}
          name={filterKey}
          {...rest}
        />
      );
      break;
    }
    case 'SingleDatePicker': {
      result = (
        <Field
          aria-label={label}
          component={SingleDatePicker}
          label={label}
          labelSx={{
            color: 'white',
            fontSize: 1,
          }}
          name={filterKey}
          {...rest}
        />
      );
      break;
    }
    case 'Dropdown': {
      result = (
        <Field
          aria-label={label}
          component={InputDropdown}
          label={label}
          labelSx={{
            color: 'white',
            fontSize: 2,
          }}
          name={filterKey}
          options={options}
          placeholder={placeholder}
          {...rest}
        />
      );
      break;
    }
    case 'DropdownQuery': {
      const { generateOptions, graphQLQuery, name, variables } = query;
      const { data, loading } = variables
        // eslint-disable-next-line react-hooks/rules-of-hooks
        ? useQuery(graphQLQuery, { fetchPolicy: 'network-only', variables })
        // eslint-disable-next-line react-hooks/rules-of-hooks
        : useQuery(graphQLQuery, { fetchPolicy: 'network-only' });

      result = (
        <Field
          aria-label={label}
          component={InputDropdown}
          isLoading={loading}
          label={label}
          labelSx={{
            color: 'white',
            fontSize: 2,
          }}
          name={filterKey}
          options={uniqBy(
            get(data, name, []).map((row) => generateOptions(row)),
            'value'
          )}
          placeholder={placeholder}
          {...rest}
        />
      );
      break;
    }
    default: {
      result = null;
    }
  }
  return result;
};

export default DashboardFilterFormSection;
