import React from 'react';
import Box from '@basecomponents/Box';
import get from 'lodash/get';
import { Field, Form } from 'react-final-form';
import { navigate, useParams } from '@reach/router';
import { capitalCase } from 'change-case';
import { ApolloConsumer, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  composeValidations,
  email,
  required,
} from "@src/utilities/validators";
import Input from "@basecomponents/Input";
import Dropdown from "@basecomponents/Dropdown";
import getMetaData from "@src/utilities/get-meta-data";
import { ADMIN_ROLE } from "@src/utilities/constants";
import Route from "@petcomponents/Route";
import ToolbarButton from "@basecomponents/ToolbarButton";
import mutationRemoteAction from "@mutations/mutation-remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import InputMasked from "@basecomponents/InputMasked";
import { phoneNumberMask } from "@src/utilities/masks";
import { phoneNumber } from "@src/utilities/validations";
import GetData from "@src/utilities/get-data";
import remoteActionQuery from "@queries/remote-action.gql";
import config from "@src/config.json";

/**
 * @category User
 * @param {*} rest
 * @returns {React.FC}
 */
const UpsertUserPage = ({ ...rest }) => {
  const { t } = useTranslation()
  const queryParams = useParams();
  const benAdminId = get(queryParams, 'benAdminId', null);
  const userId = get(queryParams, 'userId', '');
  const meta = getMetaData({ moduleName: 'users' });

  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const [createMutation] = useMutation(mutationRemoteAction);
  const {
    apiData: { ContactType = [] },
  } = GetData('get-enum-options', JSON.stringify([{ params: 'ContactType' }]));
  const { apiData: benAdminData = {} } = GetData(
    'get-ben-admin-by-id',
    JSON.stringify({ id: benAdminId }),
    !benAdminId
  );
  const userInfo = benAdminId
    ? {
        data: {
          companyName: get(benAdminData, 'name', ''),
        },
        role: 'BEN_ADMIN',
      }
    : {};

  const formFields = [
    {
      disabled: userId,
      labelVal: t('filters.firstName'),
      mandatory: true,
      name: 'firstName',
      type: 'Input',
      validate: required,
    },
    {
      disabled: userId,
      labelVal: t('filters.lastName'),
      mandatory: true,
      name: 'lastName',
      type: 'Input',
      validate: required,
    },
    {
      disabled: userId,
      mandatory: true,
      name: 'email',
      type: 'Input',
      validate: composeValidations(required, email),
    },
    {
      disabled: benAdminId,
      labelVal: t('groupEmployees.dashboardPage.role'),
      mandatory: true,
      name: 'role',
      options: Object.keys(ADMIN_ROLE).map((r) => ({
        canEnglishValue: capitalCase(r),
        frenchValue: capitalCase(r),
        label: capitalCase(r),
        value: r,
      })),
      type: 'Dropdown',
      validate: required,
    },
    {
      disabled: benAdminId,
      disabledConditionKey: 'role',
      disabledConditionValue: 'BEN_ADMIN',
      mandatory: true,
      name: 'data.companyName',
      type: 'Input',
      validate: required,
    },
    {
      disabled: userId,
      disabledConditionKey: 'role',
      disabledConditionValue: 'BEN_ADMIN',
      mandatory: false,
      name: 'data.jobTitle',
      type: 'Input',
    },
    {
      disabled: userId,
      disabledConditionKey: 'role',
      disabledConditionValue: 'BEN_ADMIN',
      mandatory: true,
      name: 'contacts.0.contactType',
      options: ContactType,
      type: 'Dropdown',
      validate: required,
    },
    {
      disabled: userId,
      disabledConditionKey: 'role',
      disabledConditionValue: 'BEN_ADMIN',
      mandatory: true,
      name: 'contacts.0.phoneNumber',
      type: 'InputMasked',
      validate: composeValidations(required, phoneNumber),
    },
    {
      disabled: userId,
      disabledConditionKey: 'role',
      disabledConditionValue: 'BEN_ADMIN',
      labelVal: t('groups.altContactType'),
      mandatory: false,
      name: 'contacts.1.contactType',
      options: ContactType,
      type: 'Dropdown',
      validate: (value, allValues) => {
        if (!value && allValues.contacts?.[1]?.phoneNumber) {
          return 'Required';
        }
      },
    },
    {
      disabled: userId,
      disabledConditionKey: 'role',
      disabledConditionValue: 'BEN_ADMIN',
      labelVal: 'Alt Contact Number',
      mandatory: false,
      name: 'contacts.1.phoneNumber',
      type: 'InputMasked',
      validate: (value, allValues) => {
        if (!value && allValues.contacts?.[1]?.contactType) {
          return 'Required';
        }
        return phoneNumber(value);
      },
    },
  ];
  return (
    <Route
      header={{
        title: `Create User`,
        type: benAdminId ? 'Ben Admin' : 'users',
      }}
      isPrivate
      permission={benAdminId ? 'benAdmin.page.addUsers' : 'user.page.upsert'}
      {...rest}
    >
      <ApolloConsumer>
        {(client) => {
          const onClickAction = async (values) => {
            try {
              const result = await createMutation({
                variables: {
                  name: 'create-user',
                  params: JSON.stringify(values),
                },
              });
              const mutationRes = JSON.parse(
                get(result, 'data.mutationRemoteAction.data', '{}')
              );
              const userId = get(mutationRes, 'userId');
              const email = get(mutationRes, 'email');
              if (benAdminId) {
                await client
                  .query({
                    fetchPolicy: 'no-cache',
                    query: remoteActionQuery,
                    variables: {
                      name: 'add-user-to-ben-admin',
                      params: JSON.stringify([
                        {
                          benAdminId,
                          email,
                          userId,
                        },
                      ]),
                    },
                  })
                  .then(() => {
                    setSucessSnack(
                      'User Added successfully!',
                      config.notificationDuration
                    );
                    navigate(`/ben-admin/${benAdminId}/details`);
                  })
                  .catch((e) =>
                    setErrorSnack(
                      `There was an error creating the user: ${e.message}`,
                      config.notificationDuration
                    )
                  );
              }
              navigate(`/users/${userId}/details`);
            } catch (e) {
              return setErrorSnack(e.message, config.notificationDuration);
            }
          };
          return (
            <>
              <Box as="h2" sx={{ py: 3 }}>
                Create User
              </Box>
              <Form
                initialValues={{ ...userInfo }}
                onSubmit={async (formValues) => {
                  await onClickAction(formValues);
                }}
                render={({ handleSubmit, submitting, values }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 2,
                          mx: 'auto',
                          p: 5,
                          width: ['25rem', null, null, null, '50rem'],
                        }}
                      >
                        <Box
                          sx={{
                            alignItems: 'flex-start',
                            display: 'flex',
                            flexDirection: ['column', null, null, null, 'row'],
                            flexWrap: 'wrap',
                          }}
                        >
                          {formFields.map(
                            ({
                              disabled,
                              disabledConditionKey,
                              disabledConditionValue,
                              labelVal,
                              mandatory,
                              name,
                              options,
                              type,
                              validate,
                            }) => {
                              const metaLabel = get(
                                meta,
                                `${name}.label`,
                                `#${name}`
                              );
                              const label = mandatory ? (
                                <Box alignItems="center" display="flex">
                                  <Box>{labelVal || metaLabel}</Box>
                                  <Box color="error">*</Box>
                                </Box>
                              ) : (
                                metaLabel
                              );
                              let result = null;
                              switch (type) {
                                case 'Input': {
                                  if (disabledConditionKey) {
                                    if (
                                      get(values, `${disabledConditionKey}`) ===
                                      disabledConditionValue
                                    ) {
                                      result = (
                                        <Field
                                          component={Input}
                                          disabled={submitting || disabled}
                                          label={label}
                                          name={name}
                                          validate={validate}
                                          {...rest}
                                        />
                                      );
                                    }
                                  } else {
                                    result = (
                                      <Field
                                        component={Input}
                                        disabled={submitting || disabled}
                                        label={label}
                                        name={name}
                                        validate={validate}
                                        {...rest}
                                      />
                                    );
                                  }
                                  break;
                                }

                                case 'InputMasked': {
                                  if (disabledConditionKey) {
                                    if (
                                      get(values, `${disabledConditionKey}`) ===
                                      disabledConditionValue
                                    ) {
                                      result = (
                                        <Field
                                          component={InputMasked}
                                          disabled={submitting}
                                          label={label}
                                          mask={phoneNumberMask}
                                          name={name}
                                          validate={validate}
                                          {...rest}
                                        />
                                      );
                                    }
                                  } else {
                                    result = (
                                      <Field
                                        component={InputMasked}
                                        disabled={submitting}
                                        label={label}
                                        mask={phoneNumberMask}
                                        name={name}
                                        validate={validate}
                                        {...rest}
                                      />
                                    );
                                  }
                                  break;
                                }
                                case 'Dropdown': {
                                  if (disabledConditionKey) {
                                    if (
                                      get(values, `${disabledConditionKey}`) ===
                                      disabledConditionValue
                                    ) {
                                      result = (
                                        <Field
                                          aria-label={label}
                                          component={Dropdown}
                                          disabled={submitting || disabled}
                                          label={label}
                                          name={name}
                                          options={options}
                                          validate={validate}
                                          {...rest}
                                        />
                                      );
                                    }
                                  } else {
                                    result = (
                                      <Field
                                        component={Dropdown}
                                        disabled={submitting || disabled}
                                        label={label}
                                        name={name}
                                        options={options}
                                        validate={validate}
                                        {...rest}
                                      />
                                    );
                                  }
                                  break;
                                }

                                default: {
                                  result = null;
                                }
                              }

                              return (
                                result && (
                                  <Box
                                    key={name}
                                    sx={{
                                      p: 5,
                                      width: ['100%', null, null, null, '50%'],
                                    }}
                                  >
                                    {result}
                                  </Box>
                                )
                              );
                            }
                          )}
                        </Box>

                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="space-between"
                          p={4}
                        >
                          <ToolbarButton
                            label={t('common.back')}
                            link={
                              benAdminId
                                ? '/ben-admin/dashboard'
                                : '/users/dashboard'
                            }
                          />
                          <ToolbarButton
                            bg="primaryDark"
                            isDisabled={submitting}
                            label="Create"
                            submitting={submitting}
                            type="submit"
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

export default UpsertUserPage;
