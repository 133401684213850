import { Storage } from 'aws-amplify';
import config from "@src/config.json";

const downloadFile = ({
  fileName,
  s3KeyName,
  signedUrl,
  s3BucketName,
  setView,
}) => {
  Storage.configure({
    AWSS3: { bucket: s3BucketName || config.amplify.Storage.AWSS3.bucket },
  });

  return new Promise((resolve, reject) => {
    if (signedUrl && setView) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.target = '_blank';
      a.href = signedUrl;
      a.click();
    } else {
      Storage.get(decodeURIComponent(s3KeyName), { expires: 360 })
        .then((result) => {
          fetch(result).then((response) => {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              a.target = '_blank';
              a.download = fileName || s3KeyName.split('/').pop();
              a.click();
              resolve();
            });
          });
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export default downloadFile;
