import React from 'react';
import { Field } from 'react-final-form';
import Box from '@basecomponents/Box';
import { composeValidations } from "@src/utilities/validators";
import Input from "@basecomponents/Input";
import Dropdown from "@basecomponents/Dropdown";
import InputMasked from "@basecomponents/InputMasked";
import InputNumber from "@basecomponents/InputNumber";
import { positive } from "@src/utilities/validations";
import { formLabelSx } from "@petcomponents/DataFormPage/utils/constants";
import Icon from "@src/components/Icon";

/**
 * @category Components
 * @param {boolean} disabled Indicates whether the field is disabled
 * @param {string} label Indicates the label for the form fields
 * @param {Function} mask Function used for masking of the Input field
 * @param {number} maxLength Indicates the maximum length of the function
 * @param {string} name Indicates the name of the form fields
 * @param {string} type Indicates the type of the form
 * @param {Array<Object>} options Indicates the dropdown options
 * @param {Function} validate Indicates the method to validate form fields
 * @param {boolean} visible Indicates whehter the form should be visible
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const FormField = ({
  disabled,
  label,
  mask = () => null,
  maxLength,
  name,
  type,
  options,
  validate = () => false,
  visible,
  ...rest
}) => {
  let result = null;
  if (visible) {
    switch (type) {
      case 'Checkbox': {
        result = (
          <Field key={name} name={name} {...rest}>
            {({ input: { onChange, value } }) => {
              return (
                <Box>
                  <Box sx={formLabelSx}>{label}</Box>
                  <Box alignItems="center" color="accent" display="flex" py={4}>
                    <Box
                      alignItems="center"
                      display="flex"
                      onClick={() => onChange(true)}
                    >
                      <Box>
                        <Icon
                          height="1.5rem"
                          svg={
                            value
                              ? 'radio-button-checked'
                              : 'radio-button-unchecked'
                          }
                          width="1.5rem"
                        />
                      </Box>
                      <Box mx={2}>Yes</Box>
                    </Box>
                    <Box
                      alignItems="center"
                      display="flex"
                      mx={5}
                      onClick={() => onChange(false)}
                    >
                      <Box>
                        <Icon
                          height="1.5rem"
                          svg={
                            !value
                              ? 'radio-button-checked'
                              : 'radio-button-unchecked'
                          }
                          width="1.5rem"
                        />
                      </Box>
                      <Box mx={2}>No</Box>
                    </Box>
                  </Box>
                </Box>
              );
            }}
          </Field>
        );
        break;
      }

      case 'Dropdown': {
        result = (
          <Field
            key={name}
            component={Dropdown}
            disabled={disabled}
            label={label}
            labelSx={formLabelSx}
            name={name}
            options={options}
            placeholder={label}
            validate={disabled ? null : validate}
            {...rest}
          />
        );
        break;
      }

      case 'Input': {
        result = (
          <Field
            key={name}
            component={Input}
            disabled={disabled}
            label={label}
            labelSx={formLabelSx}
            maxLength={maxLength}
            name={name}
            placeholder={label}
            validate={disabled ? null : validate}
            {...rest}
          />
        );
        break;
      }

      case 'InputMasked': {
        result = (
          <Field
            key={name}
            component={InputMasked}
            disabled={disabled}
            label={label}
            labelSx={formLabelSx}
            mask={mask}
            name={name}
            placeholder={label}
            validate={disabled ? null : validate}
            {...rest}
          />
        );
        break;
      }

      case 'InputNumber': {
        result = (
          <Field
            key={name}
            component={InputNumber}
            disabled={disabled}
            label={label}
            labelSx={formLabelSx}
            name={name}
            placeholder={label}
            validate={disabled ? null : composeValidations(validate, positive)}
            {...rest}
          />
        );
        break;
      }

      default: {
        // no op
      }
    }
  }

  return result;
};

export default FormField;
