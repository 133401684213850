import { AuthContext } from '@basecomponents/Auth';
import { get } from 'lodash';
import React, { useContext } from 'react';
import Box from '@basecomponents/Box';
import GetData from "@src/utilities/get-data";
import Spinner from "@basecomponents/Spinner";
import GroupDetails from "@petcomponents/GroupDetails";
import Route from "@petcomponents/Route";

/**
 * @category TBD
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const DefaultGroup = ({ ...rest }) => {
  const { user } = useContext(AuthContext);
  const { apiData: { content: groupData = [] } = {}, loading } = GetData(
    'get-all-administrator-by-group-id',
    JSON.stringify({ userId: get(user, 'customSystemUserId', '') })
  );
  const groupId =
    groupData.find((item) => item.groupActive === true)?.groupId ||
    get(groupData, '0.groupId', '');

  if (loading) {
    return (
      <Route
        header={{
          type: 'groups',
        }}
        isPrivate
        {...rest}
      >
        <Spinner />
      </Route>
    );
  }
  if (groupId) {
    return <GroupDetails applyPermission={false} groupId={groupId} {...rest} />;
  }
  return (
    <Route
      header={{
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h1">No Default Group Present</Box>
    </Route>
  );
};

export default DefaultGroup;
