import Box from '@basecomponents/Box';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import { ThemeContext } from 'styled-components';
import { get } from 'lodash';
import { AuthContext } from '@basecomponents/Auth';
import { Field, Form } from 'react-final-form';
import Dropdown from '@basecomponents/Dropdown';
import { OnChange } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import config from '@src/config.json'
import localStorage from '@src/utilities/local-storage';
import Icon from "@src/components/Icon";
import Logo from "@petcomponents/Logo";
import GetData from "@src/utilities/get-data";
import getS3Url from "@src/utilities/get-s3urls";
import { USER_ROLE } from "@src/utilities/constants";
import TooltipBox from "@basecomponents/TooltipBox";


// const { moduleMapping, environment } = config;
const TOOLTIP = 'header-tooltip';

/**
 * @category Components
 * @param {Object} header
 * @param {boolean} setIsSidebarOpen
 * @param {boolean} isSidebarOpen
 * @param {*} rest
 * @returns {React.FC}
 */
const Header = ({ header, setIsSidebarOpen, isSidebarOpen, ...rest }) => {
    const selectedLang = localStorage.getItem('lang');
  const { t, i18n } = useTranslation();
  const {
    rightContainer,
    subHeader,
  } = header;
  useEffect(() => {
    if (selectedLang !== i18n.language) {
      i18n.changeLanguage(selectedLang);
    }
  }, [selectedLang])
  const { isHighContrastOn, isResponsive, setShowContrastResetModal } = rest;
  const { user } = useContext(AuthContext);
  const [logoURL, setLogoURL] = useState(null);
  const userId = get(user, 'customSystemUserId', '');
  const userRole = get(user, 'customRole', '');
  const { apiData: { content = [] } = {} } = GetData(
    'get-all-group-employees',
    JSON.stringify({ userId }),
    ![USER_ROLE.EMPLOYEE].includes(userRole),
    'cache-first'
  );
  const { apiData: { content: adminData = [] } = {} } = GetData(
    'get-all-administrator-by-group-id',
    JSON.stringify({ userId }),
    ![USER_ROLE.EMPLOYER].includes(userRole),
    'cache-first'
  );

  const s3KeyName =
    adminData.length > 0
      ? get(adminData, '0.groupLogoPath', null)
      : get(content, '0.groupLogoPath', null);
  const sessionLogo = sessionStorage.getItem('logoUrl');
  if (s3KeyName && !sessionLogo) {
    getS3Url({ s3KeyName }).then((data) => {
      setLogoURL(data);
      sessionStorage.setItem('logoUrl', data);
    });
  }
  const theme = useContext(ThemeContext);
  const mga = theme.MGA;

  const languageOptions = [
    { canEnglishValue: null, frenchValue: 'Français Canadien', label: "Français Canadien", value: "fr" },
    { canEnglishValue: null, frenchValue: null, label: "Canadian English", value: "caEn" },
    { canEnglishValue: null, frenchValue: null, label: "American English", value: "en" },
  ];

  const filteredOptions = [USER_ROLE.EMPLOYEE].includes(userRole) || [USER_ROLE.EMPLOYER].includes(userRole) || [USER_ROLE.TERMINATED_EMPLOYEE].includes(userRole)
    ? languageOptions.filter(option => option.value !== "en")
    : languageOptions;

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          boxShadow: 1,
          height: theme.sizes.heights.header,
          left: 0,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: theme.zIndices[1],
        }}
      >
        <Box sx={{ height: '100%' }}>
          <Box
            sx={{
              alignItems: 'center',
              bg: isHighContrastOn ? 'primary' : 'white',
              color: theme.colors.textSubtle,
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
              margin: '0 auto 0 auto',
              padding: `0 10px 0 10px`,
              transition: 'all 0.5s ease-in-out',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                width: '100%',
              }}
            >
              {isResponsive && (
                <Box
                  onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                  sx={{ zIndex: 1 }}
                >
                  <Icon
                    svg="menubar"
                    sx={{
                      color: `${isHighContrastOn ? 'white' : 'primary'}`,
                      minHeight: '25px',
                      minWidth: '25px',
                    }}
                  />
                </Box>
              )}
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: config.canadaEnv ? ['left', null, null, null, 'flex-start'] :['center', null, null, null, 'flex-start'],
                  marginLeft: config.canadaEnv ? ['30px', '30px', 0, 0, 0] : 0,
                  position: ['absolute', null, null, null, 'relative'],
                  width: '100%',
                  zIndex: 0,
                }}
              >
                <Link aria-label="Home Page" to="/home/">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {logoURL || sessionLogo ? (
                    <Box
                      as="img"
                      src={sessionLogo || logoURL}
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '60px',
                        justifyContent: 'center',
                        p: 1,
                        width: '180px',
                      }}
                    />
                  ) : mga === 'default' ? (
                    <Logo
                      mono={isHighContrastOn}
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        svg: {
                          height: 'auto',
                          width: 'auto',
                        },
                        width: '9rem',
                      }}
                    />
                  ) : (
                    <Logo
                      mono={isHighContrastOn}
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        svg: {
                          height: 'auto',
                        },
                        width: '9rem',
                      }}
                    />
                  )}
                </Link>
              </Box>
            </Box>
            {
              config.canadaEnv &&
              <Box
                sx={{
                  marginRight: '20px',
                  width: 300,
                }}>
                <Form
                  initialValues={{
                    language: selectedLang || i18n.language
                  }}
                  onSubmit={() => { }}
                  render={() => (
                    <>
                      <Field
                        component={Dropdown}
                        isSearchable={false}
                        name="language"
                        options={filteredOptions}
                      />
                      <OnChange name='language'>
                        {(lang) => {
                            i18n.changeLanguage(lang);
                            localStorage.setItem('lang',lang);
                        }}
                      </OnChange>
                    </>
                  )}
                />
              </Box>
            }
          {!config.canadaEnv && (
            <Box
              data-for={TOOLTIP}
              data-tip={
                isHighContrastOn ? t('component.highContrastOn') : t('component.highContrastOff')
              }
              onClick={() => setShowContrastResetModal(true)}
              sx={{
                alignItems: 'center',
                bg: isHighContrastOn ? '#8bc34a' : '#f44336',
                borderRadius: '50%',
                boxShadow: `0px 0px 5px 3px ${
                  isHighContrastOn ? '#5a9216' : '#ba000d'
                  }`,
                cursor: 'pointer',
                display: 'flex',
                fontSize: 2,
                fontWeight: 'bold',
                height: '30px',
                justifyContent: 'center',
                m: 2,
                minHeight: '30px',
                minWidth: '30px',
                width: '30px',
                zIndex: 1,
              }}
            >
              <Icon
                svg="contrast"
                sx={{ color: 'white', height: '20px', width: '20px' }}
              />
            </Box>
          )}
            {rightContainer && !isResponsive && (
              <Box
                sx={{
                  color: `${isHighContrastOn ? 'white' : 'primary'}`,
                  mx: 4,
                  transition: 'all 0.5s ease-in-out',
                }}
              >
                |
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mr: config.canadaEnv ? 5 :2,
              }}
            >
              {rightContainer}
            </Box>
          </Box>
        </Box>
        {subHeader && (
          <Box
            sx={{
              ml: theme.sizes.widths.sidebarMin,
              my: 2,
            }}
          >
            {subHeader}
          </Box>
        )}
      </Box>
      <TooltipBox
        key="header"
        id={TOOLTIP}
        tooltipProps={{ effect: 'solid' }}
      />
    </>
  );
};

Header.propTypes = {
  header: PropTypes.shape({
    icon: PropTypes.string,
    isHighContrastOn: PropTypes.bool,
    isOld: PropTypes.bool,
    leftContainer: PropTypes.node,
    rightContainer: PropTypes.node,
    setIsHighContrastOn: PropTypes.func,
    setIsOld: PropTypes.func,
    subHeader: PropTypes.node,
    subTitle: PropTypes.node,
    title: PropTypes.string,
    type: PropTypes.string,
    typeLabel: PropTypes.string,
  }).isRequired,
  isResponsive: PropTypes.bool.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
  setIsSidebarOpen: PropTypes.func.isRequired,
};

export default Header;
