/* eslint-disable react/prop-types */
import Box from '@basecomponents/Box';
import Icon from '@src/components/Icon';
import Redirect from '@basecomponents/Redirect';
import {composeValidations, minLength, required} from '@utils/validators';
import React, {useContext, useState} from 'react';
import {Field, Form as FinalForm, Form} from 'react-final-form';
import {ThemeContext} from 'styled-components';
import {get, isEmpty, isEqual} from 'lodash';
import {print} from 'graphql';
// import updateLastLogin from '@src/utilities/ihc/updateLastLogin.js';
import {OnChange} from 'react-final-form-listeners';
import localStorage from '@src/utilities/local-storage';
import useWindowSize from '@utils/hooks/windowSize';
import {useTranslation} from 'react-i18next';
import Dropdown from '@basecomponents/Dropdown';
import i18n from '@src/utilities/i18n';
import Logo from "@petcomponents/Logo";
import ToolbarButton from "@basecomponents/ToolbarButton";
import config from "@src/config.json";
import AuthContainer from "@src/containers/AuthContainer";
import pixelToNumber from "@src/utilities/pixel-to-number";
import useSnackbar from "@src/utilities/use-snackbar";
import mutationRemoteAction from "@mutations/mutation-remote-action.gql";
import InputPassword from "@basecomponents/InputPassword";

const CompleteSignupPage = () => {
    const {breakpoints, shadows} = useContext(ThemeContext);
    const {width} = useWindowSize();
    const [setSucessSnack] = useSnackbar({color: 'accent'});
    const [setErrorSnack] = useSnackbar({color: 'error'});
    const [passwordState, setPassword] = useState('');
    const [verifiedPasswordState, setVerifiedPassword] = useState('');
    const {defaultStyle = {}} = useContext(ThemeContext);
    const {errorContainerBelow = {}} = defaultStyle.defaultInputWrapper();
    const {t} = useTranslation()
    const selectedLang = localStorage.getItem('lang');

    const backgroundSx = () => {
        if (width >= pixelToNumber(breakpoints[3])) {
            return {
                backgroundImage: `url(${config.promoImageUrl})`,
                backgroundPosition: 'center bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '95%',
            };
        }
        return {};
    };

    const boxShadowSx = () => {
        if (width >= pixelToNumber(breakpoints[3])) {
            return {
                boxShadow: shadows[4],
            };
        }
        return {};
    };

    return (<AuthContainer title={t('signup.complete')}>
            {({completeNewPasswordChallenge, newPasswordRequired}) => (<Box
                    bg={{_: 'primary', lg: 'white'}}
                    display="flex"
                    flexDirection={{_: 'column', lg: 'row'}}
                    height="100vh"
                    width="100%"
                >
                    <Redirect
                        to="/login/?redirect=/secondary-auth/"
                        when={!newPasswordRequired}
                    />
                    <Box
                        bg={{_: 'primary', lg: 'white'}}
                        display="flex"
                        height={{lg: '100%'}}
                        justifyContent={{_: 'center', lg: 'flex-start'}}
                        sx={{...backgroundSx()}}
                        width={{lg: '50%'}}
                    >
                        {width >= pixelToNumber(breakpoints[3]) ? (<Logo
                                marginLeft={{lg: 5}}
                                mono={width < pixelToNumber(breakpoints[3])}
                                sx={{
                                    mt: '30px', top: '0',
                                }}
                            />) : (<Logo
                                mono
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: '50px',
                                    p: 1,
                                    svg: {
                                        height: 'auto',
                                    },
                                    width: '20rem',
                                }}
                            />)}
                        {width >= pixelToNumber(breakpoints[3]) && (<Box
                                as="h1"
                                color="primary"
                                fontSize={7}
                                sx={{
                                    ml: 5,
                                    mt: 5,
                                    position: [null, null, null, null, 'absolute'],
                                    top: '13rem',
                                    width: '30%',
                                }}
                            >
                                <Box>{t('auth.subtitle1')}</Box>
                                <Box>{t('auth.subtitle2')}</Box>
                            </Box>)}
                    </Box>
                    <Box
                        alignItems={['flex-start', null, 'center']}
                        bg="primary"
                        display="flex"
                        height="auto"
                        justifyContent="center"
                        p={5}
                        sx={{
                            ...boxShadowSx(),
                        }}
                        width={{lg: '50%'}}
                    >
                        <FinalForm
                            onSubmit={async (values) => {
                                try {
                                    await completeNewPasswordChallenge({
                                        password: values.password,
                                    }).then(async (response) => {
                                        const userId = get(response, 'authUser.customSystemUserId', null);
                                        // const email = get(response, 'authUser.email', null);
                                        const params = JSON.stringify({
                                            isVerified: true, userId,
                                        });

                                        try {
                                            const resolverName = 'update-user';
                                            const resendVerification = await (await fetch(config.apollo.url, {
                                                    body: JSON.stringify({
                                                        query: print(mutationRemoteAction), variables: {
                                                            name: resolverName, params,
                                                        },
                                                    }), headers: {
                                                        'content-type': 'application/json', // using this api key is potentially risky if the UI
                                                        // isn't behind a firewall... maybe there's a cleaner
                                                        // way to handle this?
                                                        'x-api-key': config.apollo.apiKey,
                                                    }, method: 'POST',
                                                })).json();
                                            if (resendVerification) {
                                                setSucessSnack(t('snack.success.signedUp'), config.notificationDuration);
                                                localStorage.setItem('secondaryAuthSuccessful', true);
                                            }
                                        } catch (e) {
                                            setErrorSnack(t('snack.error.errorTryAgainMessage', {message: e.message}), config.notificationDuration);
                                        }
                                    });
                                } catch (e) {
                                    return setErrorSnack(t('snack.error.errorWithMessage', {message: e.message}), config.notificationDuration);
                                }
                            }}
                            render={(formContext) => (<Box maxWidth="maxWidths.smallForm" mx="auto" width="100%">
                                    {config.canadaEnv && <Box
                                        sx={{
                                            position: 'fixed', right: '1rem', top: '1rem', width: 300,
                                        }}
                                    >
                                        <Form
                                            initialValues={{
                                                language: selectedLang || i18n.language
                                            }}
                                            onSubmit={() => {
                                            }}
                                            render={() => (<>
                                                    <Field
                                                        component={Dropdown}
                                                        isSearchable={false}
                                                        name="language"
                                                        options={config.canadaEnv ? [{
                                                            canEnglishValue: null,
                                                            frenchValue: 'Français Canadien',
                                                            label: "Français Canadien",
                                                            value: "fr"
                                                        }, {
                                                            canEnglishValue: null,
                                                            frenchValue: null,
                                                            label: "Canadian English",
                                                            value: "caEn"
                                                        },] : [{
                                                            canEnglishValue: null,
                                                            frenchValue: 'Français Canadien',
                                                            label: "Français Canadien",
                                                            value: "fr"
                                                        }, {
                                                            canEnglishValue: null,
                                                            frenchValue: null,
                                                            label: "Canadian English",
                                                            value: "caEn"
                                                        }, {
                                                            canEnglishValue: null,
                                                            frenchValue: null,
                                                            label: "American English",
                                                            value: "en"
                                                        },]}
                                                    />
                                                    <OnChange name='language'>
                                                        {(lang) => {
                                                            i18n.changeLanguage(lang);
                                                            localStorage.setItem('lang', lang);
                                                        }}
                                                    </OnChange>
                                                </>)}
                                        />
                                    </Box>}
                                    <Box
                                        color="white"
                                        fontSize={{_: 4, md: 7}}
                                        mb={[4, null, 6]}
                                        mt={[4, null, 2]}
                                        textAlign={{_: 'center', lg: 'left'}}
                                    >
                                        {t('signup.enterPassword')}
                                    </Box>
                                    <form onSubmit={formContext.handleSubmit}>
                                        <Field
                                            component={InputPassword}
                                            label={t('signup.newPassword')}
                                            labelSx={{
                                                color: 'white',
                                            }}
                                            name="password"
                                            validate={composeValidations(minLength(config.minPasswordLength), required)}
                                        />
                                        <Field
                                            component={InputPassword}
                                            label={t('signup.verifyPassword')}
                                            labelSx={{
                                                color: 'white',
                                            }}
                                            name="verifiedPassword"
                                        />
                                        <OnChange name="password">
                                            {(value) => setPassword(value)}
                                        </OnChange>
                                        <OnChange name="verifiedPassword">
                                            {(value) => setVerifiedPassword(value)}
                                        </OnChange>
                                        {!isEmpty(verifiedPasswordState) && !isEqual(passwordState, verifiedPasswordState) && (
                                            <Box sx={errorContainerBelow}>
                                                <Icon height="20px" svg="error" width="20px"/>
                                                <Box color="basic.red" m={1}>
                                                    {t('signup.error.passwordsShouldMatch')}
                                                </Box>
                                            </Box>)}
                                        <Box
                                            color="white"
                                            fontSize={{_: 2, md: 3}}
                                            mt={[4, 20, 20]}
                                            textAlign={{_: 'left'}}
                                        >
                                            {t('signup.password8characters')}
                                        </Box>
                                        <Box
                                            as="ul"
                                            color="white"
                                            ml={[2, 30, 6]}
                                            style={{listStyleType: 'disc'}}
                                            textAlign={{_: 'center'}}
                                        >
                                            <Box as="li" color="white">
                                                <Box
                                                    color="white"
                                                    fontSize={{_: 2, md: 3}}
                                                    mb={[2, null, 4]}
                                                    mt={[3, null, 5]}
                                                    textAlign={{_: 'left'}}
                                                >
                                                    {t('signup.number')}
                                                </Box>
                                            </Box>
                                            <Box as="li" color="white">
                                                <Box
                                                    color="white"
                                                    fontSize={{_: 2, md: 3}}
                                                    mb={[2, null, 4]}
                                                    textAlign={{_: 'left'}}
                                                >
                                                    {t('signup.specialCharacter')}
                                                </Box>
                                            </Box>
                                            <Box as="li" color="white">
                                                <Box
                                                    color="white"
                                                    fontSize={{_: 2, md: 3}}
                                                    mb={[2, null, 4]}
                                                    textAlign={{_: 'left'}}
                                                >
                                                    {t('signup.uppercaseLetter')}
                                                </Box>
                                            </Box>
                                            <Box as="li" color="white">
                                                <Box
                                                    color="white"
                                                    fontSize={{_: 2, md: 3}}
                                                    mb={[2, null, 4]}
                                                    textAlign={{_: 'left'}}
                                                >
                                                    {t('signup.lowercaseLetter')}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box
                                            color="white"
                                            fontSize={{_: 2, md: 3}}
                                            mt={[4, 20, 20]}
                                            textAlign={{_: 'left'}}
                                        >
                                            {t('signup.tempPasswordExpire')}
                                        </Box>
                                        <ToolbarButton
                                            bg="accentSecondaryDark"
                                            data-cy="submit"
                                            isDisabled={passwordState !== verifiedPasswordState}
                                            label="Complete Sign-In"
                                            mt={6}
                                            mx={0}
                                            submitting={formContext.submitting}
                                            type="submit"
                                            width="100%"
                                        />
                                    </form>
                                </Box>)}
                        />
                    </Box>
                </Box>)}
        </AuthContainer>);
};

export default CompleteSignupPage;
