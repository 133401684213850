import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Box from '@basecomponents/Box';
import useSnackbar from '@src/utilities/use-snackbar';
import MasterPolicyPdfViewer from '@petcomponents/MasterPolicyPdfViewer';
import Route from "@petcomponents/Route";
import ClassConfiguration from "@petcomponents/ProductAdminPage/ClassConfiguration";
import Commissions from "@petcomponents/ProductAdminPage/Commissions";
import EmployeeCensus from "@petcomponents/EmployeeCensus";
import PlanConfiguration from "@petcomponents/ProductAdminPage/PlanConfiguration";
import PlanPricing from "@petcomponents/ProductAdminPage/PlanPricing";
import RatingTable from "@petcomponents/ProductAdminPage/RatingTable";
import SummaryPage from "@petcomponents/ProductAdminPage/SummaryPage";
import ProgressBar from "@basecomponents/ProgressBar";
import config from "@src/config.json";

/**
 * @category ProductAdmin
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const ProductAdmin = ({ location, ...rest }) => {
  const name = 'step';
  const [products, setProducts] = useState(
    ['ACCIDENT', 'ACCIDENT AND ILLNESS'].map((x) => {
      return { planName: x };
    })
  );
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const isEditing = get(location, 'pathname', '').includes('/upsert-plan');
  const isRegenerateMasterPolicy = get(location, 'pathname', '').includes(
    '/regenerate-master-policy'
  );

  useEffect(() => {
    if (isRegenerateMasterPolicy) {
      setSuccessSnack(
        'Please wait until the master policy gets generated',
        config.notificationDuration
      );
    }
  }, [isRegenerateMasterPolicy]);

  const handleRenderer = () => {
    if (isEditing) {
      return <PlanPricing location={location} products={products} {...rest} />;
    }
    if (isRegenerateMasterPolicy) {
      return <MasterPolicyPdfViewer location={location} {...rest} />;
    }
    return (
      <ProgressBar
        defaultTab="0"
        name={name}
        slides={[
          'Class Configuration',
          'Plan Setup',
          'Plan Configuration',
          'Commissions',
          'Upload Rating Table',
          'Employee Census',
          'Summary',
          'Master Policy',
        ]}
        tabs={[
          {
            render: () => (
              <ClassConfiguration
                location={location}
                tableType="configureClassesSection"
                {...rest}
              />
            ),
            value: '0',
          },
          {
            render: () => (
              <PlanConfiguration
                location={location}
                setProducts={setProducts}
                {...rest}
              />
            ),
            value: '1',
          },
          {
            render: () => (
              <PlanPricing location={location} products={products} {...rest} />
            ),
            value: '2',
          },
          {
            render: () => <Commissions location={location} {...rest} />,
            value: '3',
          },
          {
            render: () => <RatingTable location={location} {...rest} />,
            value: '4',
          },
          {
            render: () => (
              <EmployeeCensus location={location} showFooterButtons {...rest} />
            ),
            value: '5',
          },
          {
            render: () => <SummaryPage location={location} {...rest} />,
            value: '6',
          },
          {
            render: () => (
              <MasterPolicyPdfViewer location={location} {...rest} />
            ),
            value: '7',
          },
        ]}
      />
    );
  };

  return (
    <Route
      header={{
        // eslint-disable-next-line no-nested-ternary
        title: isEditing
          ? 'Update Open Enrollment Period'
          : isRegenerateMasterPolicy
          ? 'Regenerate Master Policy'
          : 'Product Administration',
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isEditing
          ? 'Update Open Enrollment Period'
          : isRegenerateMasterPolicy
          ? 'Regenerate Master Policy'
          : 'Product Administration'}
      </Box>
      {handleRenderer()}
    </Route>
  );
};

ProductAdmin.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default ProductAdmin;
