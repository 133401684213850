import React from 'react';
import Box from '@basecomponents/Box';
import get from 'lodash/get';
import { PRODUCER_SUB_PRODUCER_STATUS } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import Route from "@petcomponents/Route";
import ListGrid from "@basecomponents/ListGrid";
import HeaderRightContainer from "@basecomponents/HeaderRightContainer";
import config from "@src/config.json";

/**
 * @category Producer
 * @param {*} rest
 * @returns {any}
 */
const ProducerDashboardPage = ({ ...rest }) => {
  const { t } = useTranslation()
  const filters = [
    {
      label: 'Producer Name',
      name: 'name',
      type: 'Input',
    },
    {
      label: t('metadata.status'),
      name: 'status',
      options: PRODUCER_SUB_PRODUCER_STATUS,
      type: 'Dropdown',
    },
  ];
  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'details',
            link: `/producers/${get(data, 'producerId')}/details`,
          };
        },
        label: 'View Details',
        name: 'details',
      },
      {
        getProps: (data) => {
          return {
            icon: 'add-to-list',
            link: `/sub-producers/upsert?producerId=${get(data, 'producerId')}`,
          };
        },
        label: 'Create Sub Producer',
        name: 'create-sub-producer',
      },
      {
        getProps: (data) => {
          return {
            icon: 'edit',
            link: `/producers/edit/${get(data, 'producerId')}`,
          };
        },
        label: 'Edit',
        name: 'upsert-producer',
      },
    ],
    fields: [
      {
        name: 'name',
      },
      {
        name: 'status',
      },
    ],
  };

  if (config.canadaEnv) {
    grid.fields.splice(1, 0, { label: t('component.canada.fsraAgentNumber'), name: 'producerCode' });
  } else {
    grid.fields.splice(1, 0, { label: 'Producer Code/NPN', name: 'producerCode' });
  }
  return (
    <Route
      header={{
        rightContainer: (
          <HeaderRightContainer
            headerActions={[
              {
                action: '/producers/create',
                icon: 'add',
                label: 'Create Producer',
              },
            ]}
            {...rest}
          />
        ),
        title: 'Dashboard',
        type: 'producers',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Producer Dashboard
      </Box>
      <ListGrid
        filters={filters}
        grid={grid}
        gridQueryName="get-all-producer"
        moduleName="producers"
      />
    </Route>
  );
};

export default ProducerDashboardPage;
