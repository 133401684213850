import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import { ApolloConsumer } from '@apollo/client';
import { get } from 'lodash';
import ReactPlayer from 'react-player';
import { AuthContext } from '@basecomponents/Auth';
import convertCamelCaseToTitleCase from '@utils/convert-camel-case-to-title-case';
import Modal from "@basecomponents/Modal";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import Icon from "@src/components/Icon";
import dateUtils from "@src/utilities/date-utils";
import downloadData from "@src/utilities/download-file";
import config from "@src/config.json";
import NoRecordFound from "@petcomponents/UploadedDocumentsSection/NoRecordFound";

/**
 * @category Components
 * @param {string} attachmentId
 * @param {string} attachmentName
 * @param {string} created
 * @param {function} deleteFile
 * @param {function} downloadFile
 * @param {number} index
 * @param {boolean} isEmbedLink
 * @param {boolean} isLogo
 * @param {string} s3Key
 * @param {function} setShowModal
 * @param {function} setVideo
 * @param {string} signedUrl
 * @param {function} setDownloading
 * @param {boolean} isDownloading
 * @param {boolean} setSignUrl
 * @param {boolean} setView
 * @param {function} setLoader
 * @param {(function|boolean)} deleted
 * @returns {React.FC}
 */
const ExpandableFileBlock = ({
  attachmentId,
  attachmentName,
  created,
  deleteFile,
  downloadFile,
  index,
  isEmbedLink,
  isLogo,
  s3Key,
  setShowModal,
  setVideo,
  signedUrl,
  setDownloading,
  isDownloading,
  setSignUrl,
  setView,
  setLoader,
  deleted,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box
      key={index}
      onClick={() => setIsExpanded(!isExpanded)}
      sx={{
        borderRadius: 5,
        boxShadow: 6,
        display: 'flex',
        flexDirection: 'row',
        m: 5,
      }}
    >
      <Box sx={{ cursor: 'pointer', pr: 3 }}>
        <Box
          sx={{
            color: 'accent',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Icon
            svg={isEmbedLink ? 'movie' : 'file'}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mr: 1,
              py: 3,
              width: '37.5px',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ pr: 1 }}>
              {attachmentName
                ? convertCamelCaseToTitleCase(attachmentName.replace(/-/g, ' '))
                : null}
            </Box>
            {created && (
              <Box
                sx={{
                  color: 'grey',
                  fontSize: 1,
                  fontWeight: 'bold',
                }}
              >
                {dateUtils.getFormattedDate(created)}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {isExpanded && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            onClick={async () => {
              if (isEmbedLink) {
                setVideo(s3Key);
                setShowModal(true);
              } else {
                setDownloading([...isDownloading, index]);
                if (setSignUrl) {
                  await downloadData({
                    s3BucketName: config.amplify.Storage.AWSS3.bucket,
                    s3KeyName: s3Key,
                    setView,
                    signedUrl,
                  });
                  setTimeout(() => {
                    setLoader(index);
                  }, 1000);
                } else if (attachmentId && !isDownloading.includes(index)) {
                  await downloadFile(attachmentId, attachmentName, index);
                }
              }
            }}
            sx={{
              alignItem: 'center',
              bg: 'accent',
              borderBottomRightRadius: deleted && deleted(s3Key) ? null : 5,
              borderTopRightRadius: deleted && deleted(s3Key) ? null : 5,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Icon
              svg="download"
              sx={{
                color: 'white',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '30px',
              }}
            />
          </Box>
          {deleted && deleted(s3Key) && (
            <Box
              onClick={async () => {
                await deleteFile(s3Key, attachmentName, isLogo);
              }}
              sx={{
                bg: 'error',
                borderBottomRightRadius: 5,
                borderTopRightRadius: 5,
                display: 'flex',
              }}
            >
              <Icon
                svg="bin"
                sx={{
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '30px',
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

ExpandableFileBlock.defaultProps = {
  deleted: false,
  fileSx: {},
  setSignUrl: false,
  setView: false,
};

ExpandableFileBlock.propTypes = {
  attachmentId: PropTypes.string.isRequired,
  attachmentName: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  deleted: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  fileSx: PropTypes.shape({}),
  index: PropTypes.number.isRequired,
  isDownloading: PropTypes.bool.isRequired,
  isEmbedLink: PropTypes.bool.isRequired,
  isLogo: PropTypes.bool.isRequired,
  s3Key: PropTypes.string.isRequired,
  setDownloading: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,

  setShowModal: PropTypes.func.isRequired,
  setSignUrl: PropTypes.bool,
  setVideo: PropTypes.func.isRequired,
  setView: PropTypes.bool,
  signedUrl: PropTypes.string.isRequired,
};

/**
 * @category Components
 * @param {string} apiPath
 * @param {(boolean|function)} deleted
 * @param {Array<Object>} documentsList
 * @param {Object} fileSx
 * @param {string} groupId
 * @param {boolean} setFigoMsg
 * @param {boolean} setSignUrl
 * @param {boolean} setView
 * @param {function} setData
 * @returns {React.FC}
 */
const UploadedDocumentsSection = ({
  apiPath,
  deleted,
  documentsList,
  fileSx,
  groupId,
  setFigoMsg,
  setSignUrl,
  setView,
  setData,
}) => {
  const [video, setVideo] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const [removedDocs, setRemovedDocs] = useState([]);
  const [isDownloading, setDownloading] = useState([]);
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const setLoader = (index) => {
    const updatedRowIndex = [...isDownloading];
    const rowIndex = updatedRowIndex.findIndex((v) => v === index);
    updatedRowIndex.splice(rowIndex, 1);
    setDownloading(updatedRowIndex);
  };

  return (
    <ApolloConsumer>
      {(client) => {
        const deleteFile = async (s3Key, name, isLogo) => {
          await client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'set-file',
                params: JSON.stringify({ delete: true, name, s3Key }),
              },
            })
            .then(async () => {
              if (isLogo) {
                await client.query({
                  fetchPolicy: 'no-cache',
                  query: remoteActionQuery,
                  variables: {
                    name: 'update-group',
                    params: JSON.stringify({
                      groupId,
                      logoPath: null,
                      logoUrl: null,
                      userId,
                    }),
                  },
                });
              }
              setData(name, s3Key);
              setRemovedDocs([...removedDocs, name]);
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };
        const downloadFile = async (attachmentId, attachmentName, index) => {
          await client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: apiPath,
                params: JSON.stringify({ id: attachmentId }),
              },
            })
            .then(async (response) => {
              const responseData = await JSON.parse(
                get(response, 'data.remoteAction.data', {})
              );

              const dataLink = await get(
                responseData,
                'tempAttachmentPath',
                null
              );
              if (dataLink) {
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = `data:application/octet-stream;base64,${dataLink}`;
                a.download = `${attachmentName}.pdf`;
                a.target = '_blank';
                a.click();
              } else {
                await downloadData({
                  fileName: `${attachmentName}.pdf`,
                  s3BucketName: config.amplify.Storage.AWSS3.bucket,
                  s3KeyName: get(responseData, 'filePath'),
                  setView: false,
                });
              }
              setTimeout(() => {
                setLoader(index);
              }, 1000);
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };
        return (
          <Box
            sx={{
              alignItems: 'baseline',
              display: 'flex',
              flexDirection: 'row',
              flexFlow: 'wrap',
              justifyContent: 'flex-start',
              ...fileSx,
            }}
          >
            {Array.isArray(documentsList) &&
              documentsList
                .filter(
                  (document) => !removedDocs.includes(document.attachmentName)
                )
                .map(
                  (
                    {
                      attachmentName,
                      attachmentTypeDisplay,
                      attachmentId,
                      created,
                      isEmbedLink,
                      isLogo,
                      s3Key,
                      signedUrl,
                    },
                    index
                  ) => (
                    <ExpandableFileBlock
                      attachmentId={attachmentId}
                      attachmentName={attachmentName ?? attachmentTypeDisplay}
                      created={created}
                      deleted={deleted}
                      deleteFile={deleteFile}
                      downloadFile={downloadFile}
                      index={index}
                      isDownloading={isDownloading}
                      isEmbedLink={isEmbedLink}
                      isLogo={isLogo}
                      s3Key={s3Key}
                      setDownloading={setDownloading}
                      setLoader={setLoader}
                      setShowModal={setShowModal}
                      setSignUrl={setSignUrl}
                      setVideo={setVideo}
                      setView={setView}
                      signedUrl={signedUrl}
                    />
                  )
                )}
            {(Object.keys(documentsList).length === 0 ||
              (Array.isArray(documentsList) && documentsList.length === 0)) && (
              <>
                <NoRecordFound setFigoMsg={setFigoMsg} />
              </>
            )}
            {showModal === true && (
              <Modal
                isOpen
                onClose={() => {
                  setShowModal(false);
                }}
                simple
              >
                <ReactPlayer controls playsinline url={video} />
              </Modal>
            )}
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};
UploadedDocumentsSection.defaultProps = {
  apiPath: null,
  deleted: false,
  documentsList: [],
  fileSx: {},
  groupId: null,
  setData: () => {},
  setFigoMsg: false,
  setSignUrl: false,
  setView: false,
};

UploadedDocumentsSection.propTypes = {
  apiPath: PropTypes.string,
  deleted: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  documentsList: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      filePath: PropTypes.string,
    })
  ),
  fileSx: PropTypes.shape({}),
  groupId: PropTypes.string,
  setData: PropTypes.func,
  setFigoMsg: PropTypes.bool,
  setSignUrl: PropTypes.bool,
  setView: PropTypes.bool,
};

export default UploadedDocumentsSection;
