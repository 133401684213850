import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  composeValidations,
  email,
  required,
} from "@src/utilities/validators";
import Input from "@basecomponents/Input";
import Dropdown from "@basecomponents/Dropdown";
import { labelHandler } from "@src/utilities/label-utils";
import InputMasked from "@basecomponents/InputMasked";
import { phoneNumberMask } from "@src/utilities/masks";
import { phoneNumber } from "@src/utilities/validations";

const wrapperSx = {
  m: 0,
  p: 3,
  width: '25rem',
};

/**
 * @category TBD
 * @param {Object} editContactInfo
 * @param {Array<Object>} contactType
 * @param {boolean} setBroker Sets the contact as broker
 * @param {*} rest
 * @returns {React.FC}
 */
const AddContact = ({ editContactInfo, contactType, setBroker, ...rest }) => {
  const { t } = useTranslation()
  return (
    <>
      <Field
        aria-label={t('filters.firstName')}
        component={Input}
        data-cy="firstName"
        disabled={setBroker}
        label={labelHandler(t('filters.firstName'), true)}
        name="firstName"
        validate={required}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label={t('groupEmployees.enrollmentPage.lastName')}
        component={Input}
        data-cy="lastName"
        disabled={setBroker}
        label={labelHandler(t('groupEmployees.enrollmentPage.lastName'), true)}
        name="lastName"
        validate={required}
        wrapperSx={wrapperSx}
        {...rest}
      />
      <Field
        aria-label="Email"
        component={Input}
        data-cy="email"
        disabled={setBroker}
        label={labelHandler('Email', true)}
        name="email"
        validate={composeValidations(required, email)}
        wrapperSx={wrapperSx}
        {...rest}
      />
      {!setBroker && (
        <>
          <Field
            aria-label={t('groupEmployees.enrollmentPage.phoneNumberType')}
            component={Dropdown}
            label={labelHandler(t('groupEmployees.enrollmentPage.phoneNumberType'), true)}
            name="contactType"
            options={contactType}
            searchable={false}
            validate={required}
            wrapperSx={wrapperSx}
            {...rest}
          />
          <Field
            aria-label="Primary Phone Number"
            component={InputMasked}
            label={labelHandler('Primary Phone Number', true)}
            mask={phoneNumberMask}
            name="phoneNumber"
            validate={composeValidations(required, phoneNumber)}
            wrapperSx={wrapperSx}
            {...rest}
          />
          <Field
            aria-label={t('groupEmployees.enrollmentPage.phoneNumberType')}
            component={Dropdown}
            label={labelHandler(t('groupEmployees.enrollmentPage.phoneNumberType'), false)}
            name="altContactType"
            options={contactType}
            searchable={false}
            wrapperSx={wrapperSx}
            {...rest}
          />
          <Field
            aria-label={t('groups.alternatePhoneNumber')}
            component={InputMasked}
            label={t('groups.alternatePhoneNumber')}
            mask={phoneNumberMask}
            name="altPhoneNumber"
            wrapperSx={wrapperSx}
            {...rest}
          />
        </>
      )}
    </>
  );
};

AddContact.propTypes = {
  contactType: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  editContactInfo: PropTypes.shape({}),
  setBroker: PropTypes.bool,
};

AddContact.defaultProps = {
  editContactInfo: {},
  setBroker: false,
};

export default AddContact;
