import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import { useParams, navigate } from '@reach/router';
import { ApolloConsumer } from '@apollo/client';
import { get, omit, isEmpty } from 'lodash';
import { diff } from 'deep-object-diff';
import { AuthContext } from '@basecomponents/Auth';
import { composeValidations, email, required } from '@utils/validators';
import { labelHandler } from '@utils/label-utils';
import { phoneNumberMask } from '@utils/masks';
import { phoneNumber } from '@utils/validations';
import { CONTACT_TYPE, USER_ROLE } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import InputText from "@basecomponents/Input";
import InputDropdown from "@basecomponents/Dropdown";
import InputMasked from "@basecomponents/InputMasked";
import AddressFormSection from "@petcomponents/AddressFormSection";
import remoteActionQuery from "@queries/remote-action.gql";
import Spinner from "@basecomponents/Spinner";
import useSnackbar from "@src/utilities/use-snackbar";
import GetData from "@src/utilities/get-data";
import ToolbarButton from "@basecomponents/ToolbarButton";
import EditAccessRightsContext from "@petcomponents/EditAccessRights/Context";
import gaEvent from "@src/utilities/gaEvent";
import config from "@src/config.json";
import generateRedirectPath from "@src/utilities/generate-redirect-path";

/**
 * @category Employee
 * @param {Object} location
 * @param {string} name
 * @param {*} rest
 * @returns {React.FC}
 */
const PolicyHolderInfo = ({ location, name, ...rest }) => {
  const start = Date.now();
  const { t } = useTranslation()

  const queryParams = useParams();
  const ear = useContext(EditAccessRightsContext);
  const disabled = !ear.isEditable(
    'groupEmployee.form.enroll.policyHolderInfo'
  );
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const employeeId = get(queryParams, 'employeeId', '');
  const { apiData = {}, loading } = GetData(
    'get-employee-by-id',
    JSON.stringify({ id: employeeId })
  );

  const userData = get(apiData, 'user', {});
  const contact = get(userData, 'contacts', []) || [];
  const isEditing = get(location, 'pathname', '').includes(
    '/group-employees/edit/'
  );
  if (loading) {
    return <Spinner />;
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const apiCall = (pathName, params) => {
          return client.query({
            fetchPolicy: 'no-cache',
            query: remoteActionQuery,
            variables: {
              name: pathName,
              params: JSON.stringify(params),
            },
          });
        };
        const onUpdatePolicyHolder = (values) => {
          gaEvent('Enrollment', 'Employee Information Set', role, start);
          let params = {};
          const userDiff = diff(userData, {
            ...values.user,
          });
          if (Object.keys(userDiff).length) {
            params = {
              ...userDiff,
              email: get(userData, 'email'),
            };
          }
          const contactValues = get(values, 'contact', {});
          const updatedContactValues = contactValues.phoneNumber
            ? [
              {
                contactId: contactValues.contactId,
                contactType: contactValues.contactType || 'CELL',
                // deleted: get(contactValues, 'deleted', false),
                phoneNumber: contactValues.phoneNumber,
              },
            ]
            : [];
          const contactData = contact.map((data) =>
            omit(data, ['created', 'lastModified'])
          );
          const contactDiff = diff(contactData, updatedContactValues);
          if (!isEmpty(contactDiff)) {
            params.contacts = contactValues.phoneNumber
              ? updatedContactValues
              : [];
          }
          const updatedAddressValues = {
            addressId: get(contactValues, 'addressId', ''),
            addressLine1: get(contactValues, 'addressLine1', ''),
            addressLine2: get(contactValues, 'addressLine2', ''),
            city: get(contactValues, 'city', ''),
            country: get(contactValues, 'country', ''),
            county: get(contactValues, 'county', ''),
            deleted: get(contactValues, 'deleted', ''),
            state: get(contactValues, 'state', ''),
            zipcode: get(contactValues, 'zipcode', ''),
          };
          let addressData = get(userData, 'address', {});
          addressData = omit(addressData, ['created', 'lastModified']);
          const addressDiff = diff(addressData, updatedAddressValues);
          if (Object.keys(addressDiff).length) {
            params.address = updatedAddressValues;
          }
          if (Object.keys(params).length) {
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'update-core-user',
                  params: JSON.stringify({
                    userId: userData.userId,
                    ...params,
                  }),
                },
              })
              .then(() => {
                if (
                  isEditing &&
                  ['ENROLLED'].includes(get(apiData, 'status'))
                ) {
                  const paramsData = {
                    employeeId,
                    helper: false,
                    sendEnrollmentEmail: false,
                    sendNotification: true,
                  };
                  return apiCall('generate-enrollment-by-employee', paramsData)
                    .then(() => {
                      return [
                        USER_ROLE.EMPLOYEE,
                        USER_ROLE.TERMINATED_EMPLOYEE,
                        USER_ROLE.RENEWAL_POLICY_DELETED,
                      ].includes(role)
                        ? navigate('/group-employees/defaultEnrollee')
                        : navigate(
                          generateRedirectPath({
                            ...location,
                            queryParams: { [name]: 1 },
                          })
                        );
                    })
                    .catch((e) =>
                      setErrorSnack(
                        t('snack.error.errorWithMessage', { message: e.message }),
                        config.notificationDuration
                      )
                    );
                }
                return navigate(
                  generateRedirectPath({
                    ...location,
                    queryParams: { [name]: 1 },
                  })
                );
              })
              .catch((e) =>
                setErrorSnack(
                  t('snack.error.errorWithMessage', {message: e.message}),
                  config.notificationDuration
                )
              );
          }
          return [
            USER_ROLE.EMPLOYEE,
            USER_ROLE.TERMINATED_EMPLOYEE,
            USER_ROLE.RENEWAL_POLICY_DELETED,
          ].includes(role) && isEditing
            ? navigate('/group-employees/defaultEnrollee')
            : navigate(
              generateRedirectPath({
                ...location,
                queryParams: { [name]: 1 },
              })
            );
        };
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={{
                contact: {
                  ...get(contact, '[0]', {}),
                  ...get(userData, 'address', {}),
                },
                user: userData,
              }}
              onSubmit={(values) => onUpdatePolicyHolder(values)}
              render={(formContext) => {
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        padding: 3,
                      }}
                    >
                      <Box
                        as="h3"
                        color="accent"
                        display="flex"
                        justifyContent="flex-start"
                        sx={{
                          margin: '20px 0px 20px 0px;',
                          padding: 3,
                          width: '25rem',
                        }}
                      >
                        {t('groupEmployees.enrollmentPage.employeeInfo')}
                      </Box>
                      <Box>
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.firstName')}
                          component={InputText}
                          disabled={disabled}
                          label={labelHandler(t('groupEmployees.enrollmentPage.firstName'), true)}
                          name="user.firstName"
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.middleName')}
                          component={InputText}
                          disabled={disabled}
                          label={labelHandler(t('groupEmployees.enrollmentPage.middleName'), false)}
                          name="user.middleName"
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.lastName')}
                          component={InputText}
                          disabled={disabled}
                          label={labelHandler(t('groupEmployees.enrollmentPage.lastName'), true)}
                          name="user.lastName"
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.phoneNumberType')}
                          component={InputDropdown}
                          label={labelHandler(t('groupEmployees.enrollmentPage.phoneNumberType'))}
                          name="contact.contactType"
                          options={CONTACT_TYPE}
                          searchable={false}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.phoneNumber')}
                          component={InputMasked}
                          label={labelHandler(t('groupEmployees.enrollmentPage.phoneNumber'))}
                          mask={phoneNumberMask}
                          name="contact.phoneNumber"
                          validate={phoneNumber}
                          wrapperSx={{
                            ...commonFieldSxHalf,
                          }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.email')}
                          component={InputText}
                          disabled
                          label={labelHandler(t('groupEmployees.enrollmentPage.email'), true)}
                          name="user.email"
                          sx={{
                            textTransform: 'lowercase',
                          }}
                          validate={
                            get(userData, 'email')
                              ? composeValidations(required, email)
                              : null
                          }
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />

                        <AddressFormSection
                          disabled={disabled}
                          label="contact"
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                      </Box>

                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          my: 6,
                          p: 3,
                        }}
                      >
                        <ToolbarButton
                          bg="accentSecondary"
                          isDisabled={formContext.submitting}
                          label={
                            [
                              USER_ROLE.EMPLOYEE,
                              USER_ROLE.TERMINATED_EMPLOYEE,
                              USER_ROLE.RENEWAL_POLICY_DELETED,
                            ].includes(role) && isEditing
                              ? t('common.submit')
                              : t('common.next')
                          }
                          mr={0}
                          submitting={formContext.submitting}
                          type="submit"
                          width="10rem"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

PolicyHolderInfo.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
};

PolicyHolderInfo.defaultProps = {
  name: 'step',
};

export default PolicyHolderInfo;
