import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import get from "lodash/get";
import GetData from "@src/utilities/get-data";
import ModuleContainer from "@petcomponents/Variant/ModuleInfo/Container";

/**
 * Employee Dependent status variant
 *
 * @category Components
 * @param {string} status
 * @returns {React.FC}
 */
const EmployeeDependentStatus = ({ status }) => {
  const { i18n } = useTranslation();
  const {
    apiData: { EmployeeDependentStatus = [] },
    loading,
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: 'EmployeeDependentStatus' }]),
    false,
    'cache-first'
  );
  const employeeDependentStatus = EmployeeDependentStatus.find(
    (v) => v.value === status
  );

  return (
    <ModuleContainer
      displayValue={(i18n.language === 'fr' && get(employeeDependentStatus, 'frenchValue', '') !== null) ? get(employeeDependentStatus, 'frenchValue')  : employeeDependentStatus?.label}
      loading={loading}
    />
  );
};
EmployeeDependentStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default EmployeeDependentStatus;
