import { Router } from '@reach/router';
import React from 'react';
import SettingsDashboardPage from '@petcomponents/Settings/DashboardPage';
import AdminNotificationsSettings from '@petcomponents/Settings/Notifications/AdministratorNotificationSettings';
import NotificationsType from '@petcomponents/Settings/Notifications/NotificationsType';
import EmployeeNotificationSettings from '@petcomponents/Settings/Notifications/EmployeeNotificationSettings';
import NotFoundPage from "@src/routes/ihc/404";

const Settings = (props) => (
  <Router>
    <SettingsDashboardPage {...props} path="/settings" />
    <NotificationsType {...props} path="/settings/notifications/type" />
    <AdminNotificationsSettings
      {...props}
      path="/settings/notifications/type/admin"
    />
    <EmployeeNotificationSettings
      {...props}
      path="/settings/notifications/type/employee"
    />
    <NotFoundPage default path="/404" />
  </Router>
);

export default Settings;
