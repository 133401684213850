import { OnChange } from 'react-final-form-listeners';
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import { diff } from 'deep-object-diff';
import { required } from '@utils/validators';
import { E_CONSENT_URLS, PET_AGE, PET_SPECIES } from '@utils/constants';
import { labelHandler } from '@utils/label-utils';
import { composeValidations, maxLength } from '@utils/validations';
import InputCheckbox from '@basecomponents/InputCheckbox';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import Modal from '@basecomponents/Modal';
import UploadFile from '@petcomponents/UploadFile';
import UploadedDocumentsSection from '@petcomponents/UploadedDocumentsSection';
import GetData from '@utils/get-data';
import { useTranslation } from 'react-i18next';
import SingleDatePicker from '@basecomponents/SingleDatePicker';
import InputText from '@basecomponents/Input';
import InputDropdown from '@basecomponents/Dropdown';
import InputNumber from '@basecomponents/InputNumber';
import useSnackbar from '@src/utilities/use-snackbar';
import ToolbarButton from '@basecomponents/ToolbarButton';
import remoteActionQuery from '@queries/remote-action.gql';
import config from '@src/config.json';

const commonFieldSxHalf = {
  mx: 'auto',
  padding: 3,
  width: '25rem',
};

const lessThan65forCats = (name) => (value, values) => {
  if (get(values, `[${name}].species`, '') === 'Cat') {
    return (
      Number(value) > 65 &&
      'Weight must not be greater that 65 pounds for Cats.'
    );
  }
};

const lessThan0 = (name) => (value, values) => {
  // eslint-disable-next-line no-constant-condition
  if (get(values, `[${name}].species`, '') === 'Cat' || 'Dog') {
    return Number(value) === 0 && 'Weight must be greater that 0.';
  }
};

/**
 * @category Employee
 * @param {string} name
 * @param {boolean} isEditing
 * @param {boolean} isLateEnrollment
 * @param {Object} values
 * @param {Array<Object>} breeds
 * @param {string} groupId
 * @param {string} parentGroupId
 * @param {function} canSubmit
 * @param {string} parentPolicyYear
 * @param {string} parentProductPlanId
 * @param {string} productPlanId
 * @param {boolean} setInvalidSelection
 * @param {string} employeeId
 * @param {function} form
 * @param {Object} formValues
 * @param {string} url
 * @param {function} triggers
 * @param {*} rest
 * @returns {React.FC}
 */
const AddPet = ({
  breeds,
  canSubmit,
  groupId,
  isEditing,
  isLateEnrollment,
  name,
  parentGroupId,
  parentPolicyYear,
  parentProductPlanId,
  productPlanId,
  province,
  setInvalidSelection,
  employeeId,
  form,
  formValues,
  url,
  triggers,
  values,
  ...rest
}) => {
  const { colors } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(null);
  const [parentRates, setParentRates] = useState([]);
  const [parentRatesLoading, setParentRatesLoading] = useState(false);
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [loading, setLoading] = useState(false);
  const [premiumAmount, setPremiumAmount] = useState(0);
  const [rstAmount, setRstAmount] = useState(0);
  const [attributeLength, setAttributeLength] = useState(0);
  const [getSpecies, setSpecies] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const attributes = {};

  const previousValues = useRef({});
  useEffect(() => {
    const difference = diff(previousValues.current, values);
    if (Object.keys(difference).length > 0) {
      previousValues.current = values;
      setShouldRefresh(true);
      canSubmit(false);
    } else {
      setShouldRefresh(false);
      canSubmit(true);
    }
    triggers(values);
  }, [values, loading]);
  const filterBreedEnv = config.canadaEnv ? 'CA' : 'US';
  const {
    apiData: { ReasonType = [] },
  } = GetData('get-enum-options', JSON.stringify([{ params: 'ReasonType' }]));
  const petBreeds = breeds
    ?.filter?.(
      (item) =>
        item.species === values.species && item?.envFlag === filterBreedEnv
    )
    .map((item) => {
      return {
        canEnglishValue: item.canEnglishValue,
        frenchValue: item.frenchValue,
        label: item.breedName,
        value: item.breedName,
      };
    });

  function valuePresentInArray(value) {
    if (petBreeds?.map((x) => x.label).includes(value) === false) {
      return 'Pet breed must belong to species';
    }
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const onClickCalculatePremium = async (parentCal) => {
          if (parentCal) {
            setParentRatesLoading(true);
          } else {
            setLoading(true);
          }
          const { species, age, weight, breed } = values;
          if (species) {
            attributes.species = species;
            setSpecies(species);
          } else {
            setSpecies(null);
            delete attributes.species;
          }

          if (weight || weight === 0) {
            attributes.weight = weight;
          } else {
            delete attributes.weight;
          }

          if (age || age === 0) {
            attributes.age = age;
          } else {
            delete attributes.age;
          }
          if (species) {
            if (species !== 'Cat') {
              attributes.breed =
                breed === 'Mixed Breed' ? 'MIXED BREED' : 'PUREBRED';
            }
          } else {
            delete attributes.breed;
          }

          await client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'get-plan-rates',
                params: JSON.stringify({
                  groupId: parentCal ? parentGroupId : groupId,
                  productPlanId: parentCal
                    ? parentProductPlanId
                    : productPlanId,
                  ...attributes,
                }),
              },
            })
            .then((response) => {
              if (parentCal) {
                setParentRates(
                  JSON.parse(get(response, 'data.remoteAction.data', []))
                    .content
                );
              } else {
                const results = JSON.parse(
                  get(response, 'data.remoteAction.data', '')
                );
                const { content } = results;
                let contentData = content;
                if (config.canadaEnv) {
                  // eslint-disable-next-line no-empty
                  if (content.length > 0) {
                    contentData = content.filter(
                      (o) => o.province === province
                    );
                  }
                }
                if (
                  contentData &&
                  contentData.length === 1 &&
                  contentData[0].premiumAmount > 0
                ) {
                  setAttributeLength(Object.keys(attributes).length);
                  setInvalidSelection(false);
                  setPremiumAmount(contentData[0].payPeriodPremiumAmount);
                  if (config.canadaEnv) {
                    setRstAmount(contentData[0].rstAmount);
                  }
                } else {
                  setInvalidSelection(true);
                  setPremiumAmount(0);
                  setRstAmount(0);
                }
                // }
              }
            })
            .catch(() =>
              setErrorSnack(
                t('snack.error.calculatePremium'),
                config.notificationDuration
              )
            );
          if (parentCal) {
            setParentRatesLoading(false);
          } else {
            setLoading(false);
          }
        };
        return (
          <Box>
            <Field
              aria-label={t(
                'groupEmployees.enrollmentPage.addPetSection.petSpecies'
              )}
              component={InputDropdown}
              disabled={isEditing || loading}
              label={labelHandler(
                t('groupEmployees.enrollmentPage.addPetSection.petSpecies'),
                true
              )}
              name={`${name}.species`}
              options={PET_SPECIES}
              searchable={false}
              validate={required}
              wrapperSx={{ ...commonFieldSxHalf }}
              {...rest}
            />
            <Field
              aria-label={t(
                'groupEmployees.enrollmentPage.addPetSection.petName'
              )}
              component={InputText}
              label={labelHandler(
                t('groupEmployees.enrollmentPage.addPetSection.petName'),
                true
              )}
              maxLength={50}
              name={`${name}.petName`}
              validate={composeValidations(
                required,
                maxLength(50, 'Maximum 50 charecters allowed.')
              )}
              wrapperSx={{ ...commonFieldSxHalf }}
              {...rest}
            />
            <Field
              aria-label={t(
                'groupEmployees.enrollmentPage.addPetSection.petAgeEffective'
              )}
              component={InputDropdown}
              disabled={isEditing || loading}
              label={labelHandler(
                t(
                  'groupEmployees.enrollmentPage.addPetSection.petAgeEffective'
                ),
                true
              )}
              name={`${name}.age`}
              options={values.species ? PET_AGE[values.species] : []}
              searchable={false}
              validate={isEditing ? null : required}
              wrapperSx={{ ...commonFieldSxHalf }}
              {...rest}
            />
            <Field
              aria-label={t(
                'groupEmployees.enrollmentPage.addPetSection.petWeightAdulthood'
              )}
              component={InputNumber}
              disabled={isEditing || loading}
              label={labelHandler(
                t(
                  'groupEmployees.enrollmentPage.addPetSection.petWeightAdulthood'
                ),
                true
              )}
              name={`${name}.weight`}
              type="number"
              validate={composeValidations(
                required,
                lessThan65forCats(name),
                lessThan0(name)
              )}
              wrapperSx={{ ...commonFieldSxHalf }}
              {...rest}
            />
            <Field
              aria-label={t(
                'groupEmployees.enrollmentPage.addPetSection.petBreed'
              )}
              component={InputDropdown}
              disabled={isEditing || loading}
              label={labelHandler(
                t('groupEmployees.enrollmentPage.addPetSection.petBreed'),
                true
              )}
              name={`${name}.breed`}
              options={petBreeds}
              searchable={false}
              validate={composeValidations(required, valuePresentInArray)}
              wrapperSx={{ ...commonFieldSxHalf }}
              {...rest}
            />
            {isLateEnrollment && (
              <>
                <Box
                  sx={{
                    color: 'accentSecondary',
                    display: 'flex',
                    fontSize: 2,
                    fontWeight: 'bold',
                    justifyContent: 'flex-start',
                    mt: 4,
                    mx: 3,
                    ...commonFieldSxHalf,
                  }}
                >
                  {t(
                    'groupEmployees.enrollmentPage.addPetSection.petAddedRenewal'
                  )}
                </Box>
                <Field
                  aria-label={t(
                    'groupEmployees.enrollmentPage.addPetSection.addToCurrentCoverage'
                  )}
                  component={InputCheckbox}
                  disabled={
                    !get(values, `species`) ||
                    !get(values, `age`) ||
                    !get(values, `weight`) ||
                    !get(values, `breed`)
                  }
                  label={labelHandler(
                    t(
                      'groupEmployees.enrollmentPage.addPetSection.addToCurrentCoverage'
                    ),
                    false
                  )}
                  name={`${name}.addLateEnrollment`}
                  type="checkbox"
                  wrapperSx={{ display: 'flex', ...commonFieldSxHalf }}
                />
                {get(values, `addLateEnrollment`) && (
                  <>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <ToolbarButton
                        bg="accent"
                        isLoading={loading}
                        label={t(
                          'groupEmployees.enrollmentPage.addPetSection.calculatePolicyPremium'
                        )}
                        onClick={() => onClickCalculatePremium(true)}
                      />
                    </Box>
                    {parentRatesLoading && (
                      <Box
                        sx={{
                          ...commonFieldSxHalf,
                        }}
                      >
                        <PulseLoader
                          color={`${colors.accentSecondary}`}
                          size={5}
                        />
                      </Box>
                    )}
                    {!parentRatesLoading &&
                      get(parentRates, '0.premiumAmount', '') && (
                        <Box
                          sx={{
                            ...commonFieldSxHalf,
                            fontSize: '12px',
                            fontWeight: 600,
                            margin: '0 0 3px',
                            ml: 0,
                            textTransform: 'capitalize',
                          }}
                        >
                          {`CURRENT POLICY PREMIUM HAS INCREASED BY $${get(
                            parentRates,
                            '0.payPeriodPremiumAmount'
                          ).toFixed(2)} ${t(
                            'groupEmployees.enrollmentPage.addPetSection.perPayCycle'
                          )}`}
                        </Box>
                      )}
                    {!parentRatesLoading &&
                      !get(parentRates, '0.premiumAmount', null) && (
                        <Box
                          sx={{
                            ...commonFieldSxHalf,
                            color: 'error',
                            fontSize: '12px',
                            fontWeight: 600,
                            margin: '0 0 3px',
                            ml: 0,
                            textTransform: 'capitalize',
                          }}
                        >
                          {t(
                            'groupEmployees.enrollmentPage.addPetSection.currentPolicyNoPremium'
                          )}
                        </Box>
                      )}
                    <Field
                      aria-label={t(
                        'groupEmployees.enrollmentPage.petAcquisitionDate'
                      )}
                      component={SingleDatePicker}
                      label={labelHandler(
                        t('groupEmployees.enrollmentPage.petAcquisitionDate'),
                        true
                      )}
                      name={`${name}.acquisitionDate`}
                      validate={required}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Field
                      aria-label={t(
                        'groupEmployees.enrollmentPage.addPetSection.reasonForAddingPet'
                      )}
                      component={InputDropdown}
                      label={labelHandler(
                        t(
                          'groupEmployees.enrollmentPage.addPetSection.reasonForAddingPet'
                        ),
                        true
                      )}
                      name={`${name}.reasonType`}
                      options={ReasonType}
                      searchable={false}
                      validate={required}
                      wrapperSx={{
                        mb: 6,
                        mx: 'auto',
                        padding: 3,
                        width: '25rem',
                      }}
                      {...rest}
                    />
                    <Field
                      aria-label={t(
                        'groupEmployees.enrollmentPage.addPetSection.supportingDocumentation'
                      )}
                      component={InputText}
                      label={labelHandler(
                        t(
                          'groupEmployees.enrollmentPage.addPetSection.supportingDocumentation'
                        )
                      )}
                      name={`${name}.fileName`}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    {get(formValues, `${name}.fileName`) && (
                      <>
                        <UploadFile
                          allowMultiple
                          employeeId={employeeId}
                          fieldName={`${name}.fileUpload`}
                          files={get(formValues, `${name}.fileUpload`, [])}
                          flow="lateEnrollment"
                          form={form}
                          groupId={groupId}
                          name={get(formValues, `${name}.fileName`)}
                        />
                        {get(formValues, `${name}.fileUpload`, []).length >
                          0 && (
                          <UploadedDocumentsSection
                            deleted={(s3Key) => s3Key !== null}
                            documentsList={
                              Array.isArray(
                                get(formValues, `${name}.fileUpload`, [])
                              ) &&
                              get(formValues, `${name}.fileUpload`, []).map(
                                (file) => {
                                  return {
                                    attachmentName: get(file, 'documentName'),
                                    created: get(file, 'created', null),
                                    s3Key: get(file, 's3KeyName', null),
                                  };
                                }
                              )
                            }
                            fileSx={{
                              flexDirection: 'column!important',
                            }}
                            setData={(fileName) => {
                              const updatedFiles = get(
                                formValues,
                                `${name}.fileUpload`,
                                []
                              ).filter(
                                (file) => get(file, 'documentName') !== fileName
                              );
                              form.change(`${name}.fileUpload`, updatedFiles);
                            }}
                            setSignUrl
                            {...rest}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {showModal && (
              <Modal
                closeText="Okay"
                isOpen
                onClose={() => setShowModal(null)}
                title={t(
                  'groupEmployees.enrollmentPage.addPetSection.termsConditions'
                )}
              >
                <Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.misstatementPetInfo'
                        )}
                      </Box>
                      {t(
                        'groupEmployees.enrollmentPage.addPetSection.agreeLegend'
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.fraudWarning'
                        )}
                      </Box>
                      {config.canadaEnv && (
                        <Box>
                          {t(
                            'groupEmployees.enrollmentPage.addPetSection.fraudWarningLegend'
                          )}
                        </Box>
                      )}
                      {!config.canadaEnv && (
                        <Box>
                          I acknowledge and understand that any person who
                          knowingly provides false, incomplete, or misleading
                          information to an insurance company for the purpose of
                          defrauding the company is guilty of a crime and may be
                          subject to fines, imprisonment, and denial of
                          insurance benefits. See state specific fraud notice:{' '}
                          <a
                            href="https://www.petpartners.com/insurance-fraud-warnings"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Insurance Fraud Warnings
                          </a>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreement'
                        )}
                      </Box>

                      {t(
                        'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreementLegend'
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      margin: '20px 10px 0',
                      padding: '0 20px',
                      textAlign: 'justify',
                    }}
                  >
                    <Box>
                      <Box fontWeight={600} mb="10px">
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.eConsent'
                        )}
                      </Box>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'groupEmployees.enrollmentPage.addPetSection.eConsentLegend',
                            { url: E_CONSENT_URLS[url] || '#' }
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  {config.canadaEnv && (
                    <Box
                      sx={{
                        margin: '20px 10px 0',
                        padding: '0 20px',
                        textAlign: 'justify',
                      }}
                    >
                      <Box>
                        <Box fontWeight={600} mb="10px">
                          {t('component.caslDisclosure')}
                        </Box>
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: t(
                              'groupEmployees.enrollmentPage.addPetSection.eConsentLegendCanada',
                              {
                                url:
                                  i18n.language === 'fr'
                                    ? 'https://www.onepackplan.ca/fr/politique-protection-renseignements'
                                    : 'https://www.onepackplan.ca/privacy-policy',
                              }
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Modal>
            )}
            {productPlanId && (
              <>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 6,
                  }}
                >
                  <ToolbarButton
                    bg="accent"
                    isLoading={loading}
                    label={t(
                      'groupEmployees.enrollmentPage.addPetSection.calculatePremium'
                    )}
                    onClick={() => onClickCalculatePremium(false)}
                  />
                </Box>
                <Box
                  sx={{
                    alignItems: 'flex-start',
                    bg: 'primary',
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 4,
                    fontWeight: 'bold',
                    justifyContent: 'space-around',
                    mt: 6,
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Box>
                      <Box>
                        {t(
                          'groupEmployees.enrollmentPage.addPetSection.calculatedPremium'
                        )}
                      </Box>
                    </Box>
                    &nbsp;
                    {premiumAmount &&
                    ((attributeLength === 3 && getSpecies === 'Cat') ||
                      attributeLength === 4) &&
                    !shouldRefresh
                      ? `$${premiumAmount.toFixed(2)}`
                      : '-'}
                    &nbsp;
                    <Box fontSize={1}>
                      {t(
                        'groupEmployees.enrollmentPage.addPetSection.perPayCycle'
                      )}
                    </Box>
                  </Box>
                  {config.canadaEnv && <Box>RST $ {rstAmount.toFixed(2)}</Box>}
                </Box>
              </>
            )}
            <OnChange name={`${name}.species`}>
              {() => {
                form.change(`${name}.breed`, null);
              }}
            </OnChange>
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};
AddPet.propTypes = {
  breeds: PropTypes.arrayOf(PropTypes.shape({})),
  canSubmit: PropTypes.func,
  employeeId: PropTypes.string,
  form: PropTypes.func,
  formValues: PropTypes.shape({}),
  groupId: PropTypes.string.isRequired,
  isEditing: PropTypes.bool,
  isLateEnrollment: PropTypes.bool,
  name: PropTypes.string.isRequired,
  parentGroupId: PropTypes.string,
  parentPolicyYear: PropTypes.string,
  parentProductPlanId: PropTypes.string,
  productPlanId: PropTypes.string.isRequired,
  province: PropTypes.string,
  setInvalidSelection: PropTypes.func,
  triggers: PropTypes.func,
  url: PropTypes.string,
  values: PropTypes.shape({
    age: PropTypes.number,
    breed: PropTypes.string,
    species: PropTypes.string,
    weight: PropTypes.number,
  }),
};

AddPet.defaultProps = {
  breeds: [],
  canSubmit: () => {},
  employeeId: null,
  form: () => {},
  formValues: {},
  isEditing: false,
  isLateEnrollment: false,
  parentGroupId: null,
  parentPolicyYear: null,
  parentProductPlanId: null,
  province: null,
  setInvalidSelection: () => {},
  triggers: () => {},
  url: '',
  values: {
    age: -1,
    breed: '',
    species: '',
    weight: -1,
  },
};

export default AddPet;
