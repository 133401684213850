import Box from '@basecomponents/Box';
import { Link } from 'gatsby';
import React from 'react';
import Icon from "@src/components/Icon";
import Route from "@petcomponents/Route";

const settingsMeta = [
  {
    icon: 'notifications',
    link: '/settings/notifications/type',
    name: 'Notifications',
  },
];

/**
 * @category Settings
 * @param {*} rest
 * @returns {React.FC}
 */
const SettingsDashboardPage = ({ ...rest }) => (
  <Route isPrivate permission="setting.page" {...rest}>
    <Box as="h2" sx={{ py: 3 }}>
      Settings Dashboard
    </Box>
    {settingsMeta.map(({ link, icon, name }) => (
      <Box
        key={`${icon}-${name}`}
        sx={{
          '&:hover': {
            boxShadow: 6,
          },
          bg: 'white',
          borderRadius: 5,
          m: 3,
          p: 3,
          transitionDuration: '0.25s',
        }}
      >
        <Link to={link}>
          <Box
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box as="div" display="flex" flexDirection="row">
              <Icon
                height={25}
                svg={icon}
                sx={{ color: 'primary' }}
                width={25}
              />
              <Box as="h3" marginLeft={2}>
                {name}
              </Box>
            </Box>
            <Icon
              height={25}
              svg="right-chevron"
              sx={{ color: 'primary' }}
              width={25}
            />
          </Box>
        </Link>
      </Box>
    ))}
  </Route>
);

export default SettingsDashboardPage;
