import { Router } from '@reach/router';
import React from 'react';
import DashboardPage from '@petcomponents/GroupsDashboardPage';
import UpsertGroupPage from '@petcomponents/UpsertGroupPage';
import ProductAdminFormPage from '@petcomponents/ProductAdminPage';
import GroupDetails from '@petcomponents/GroupDetails';
import FilePage from '@petcomponents/FileDashboardPage';
import ErrorPage from '@petcomponents/ErrorDashboardPage';
import GroupTemplate from '@petcomponents/GroupTemplate';
import GroupDefaultDetails from '@petcomponents/DefaultGroup';
import AddEmployee from '@petcomponents/AddEmployee';
import AddGroupDocuments from '@petcomponents/AddGroupDocuments';
import AddRemittance from '@petcomponents/AddRemittance';
import AddCredit from '@petcomponents/AddCredit';
import UpsertGroupAdministrators from '@petcomponents/GroupAdministrators/Upsert';
import UpsertGroupLocations from '@petcomponents/UpsertGroupLocations';
import InferLabels from '@petcomponents/InferLabels';
import UpsertCommissions from '@petcomponents/GroupDetails/UpsertCommission';
import ExportDetails from '@petcomponents/GroupDetails/ExportDetails';
import Renewal from '@petcomponents/Renewals';
import GroupSettingsDashboardPage from '@petcomponents/GroupDetails/GroupSettingsDashboard';
import Notifications from '@petcomponents/GroupDetails/GroupSettingsDashboard/Notifications';
import Reports from '@petcomponents/GroupDetails/GroupSettingsDashboard/GroupReports';
import UpdateAccountInfo from '@petcomponents/GroupDetails/UpdateAccountInfo';
import AddGroupNotes from '@petcomponents/GroupDetails/AddGroupNotes';
import BillingNotesDetails from '@petcomponents/BillingNotesDetails';
import UpsertGroupPageData from '@petcomponents/Groups/UpsertPage';
import UpsertNotes from "@petcomponents/GroupNotes/Upsert";
import ViewNote from '@petcomponents/ViewNote';
import NotFoundPage from "@src/routes/ihc/404";

const Groups = (props) => {
  return (
    <Router>
      <DashboardPage {...props} path="/groups/dashboard" />
      <UpsertGroupPage
        {...props}
        path="/groups/create"
        type="upsertGroupPage"
      />
      <UpsertGroupPage
        {...props}
        path="/groups/create/:groupId/:planId"
        type="upsertGroupPage"
      />
      <UpsertGroupPage
        {...props}
        path="/groups/edit/:groupId/:planId"
        type="upsertGroupPage"
      />
      <UpsertGroupPageData
        {...props}
        path="/groups/upsert/:groupId/:planId"
        type="upsertGroupPage"
      />
      <UpsertGroupPageData
        {...props}
        path="/groups/createNotes"
        type="upsertGroupPage"
      />
      <GroupDetails
        {...props}
        applyPermission
        path="/groups/:groupId/details"
        type="groupDetailsPage"
      />
      <ProductAdminFormPage
        {...props}
        path="/groups/activate/:groupId/:planId"
        type="productAdminFormPage"
      />
      <ProductAdminFormPage
        {...props}
        path="/groups/regenerate-master-policy/:groupId/:planId"
        type="productAdminFormPage"
      />
      <ProductAdminFormPage
        {...props}
        path="/groups/:groupId/:planId/upsert-plan"
        type="upsertPlanFormPage"
      />
      <UpsertCommissions
        {...props}
        path="/groups/:groupId/upsert-commission"
        type="upsertGroupCommissionPage"
      />
      <FilePage {...props} path="/groups/templateStatus" />
      <ErrorPage {...props} path="/groups/errors/:taskId" />
      <InferLabels {...props} path="/groups/columns/:taskId" />
      <GroupTemplate
        {...props}
        icon="groups"
        path="/groups/templateUpload"
        title="Upload Group Template"
        typeLabel="Groups"
      />
      <UpsertGroupAdministrators
        {...props}
        path="/groups/:groupId/upsert-administrators"
      />
      <UpsertGroupLocations
        {...props}
        path="/groups/:groupId/upsert-locations"
      />
      <AddEmployee {...props} path="/groups/:groupId/add-employee" />
      <AddGroupDocuments {...props} path="/groups/:groupId/add-document" />
      <AddGroupDocuments
        {...props}
        isEducational
        path="/groups/:groupId/add-educational-material"
      />
      <AddGroupDocuments
        {...props}
        isPromoFlag
        isVideo
        path="/groups/:groupId/groupsettings/promotional-content"
      />

      <AddGroupDocuments
        {...props}
        isLogoUpload
        path="/groups/:groupId/upload-logo"
      />
      <AddRemittance {...props} path="/groups/:groupId/upload-remittance" />
      <AddCredit {...props} path="/groups/:groupId/add-credit" />
      <GroupDefaultDetails {...props} path="/groups/defaultGroup" />
      <Renewal {...props} path="/groups/:groupId/renewal" type="renewalPage" />
      <Renewal
        {...props}
        path="/groups/:groupId/:planId/renewal"
        type="renewalPage"
      />

      <GroupSettingsDashboardPage
        {...props}
        path="/groups/:groupId/groupsettings"
      />
      <Notifications
        {...props}
        path="/groups/:groupId/groupsettings/notifications"
      />
      <Reports {...props} path="/groups/:groupId/groupsettings/reports" />
      <UpdateAccountInfo {...props} path="/groups/:groupId/upsert-account" />
      <AddGroupNotes {...props} path="/groups/:groupId/addNotes" />
      <BillingNotesDetails
        {...props}
        path="/groups/:billingNotesId/bill-notes"
      />
      <AddGroupNotes
        {...props}
        path="/groups/:billingNotesId/:groupId/addNotes/edit"
      />
      <ExportDetails {...props} path="/groups/exportFile" />
      <UpsertNotes
        {...props}
        path="/groups/notes/create"
        type="upsertGroupPage"
      />
      <UpsertNotes
        {...props}
        path="/groups/notes/upsert/:noteId"
        type="upsertGroupPage"
      />
      <ViewNote
        path="/groups/note/:noteId"
      />
      <NotFoundPage default path="/404" />
    </Router>
  );
};

export default Groups;
