import Box from '@basecomponents/Box';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { print } from 'graphql';
import { useTranslation } from 'react-i18next';
import Dropdown from '@basecomponents/Dropdown';
import { OnChange } from 'react-final-form-listeners';
import { Field, Form } from 'react-final-form';
import i18n from '@src/utilities/i18n';
import mutationRemoteAction from "@mutations/mutation-remote-action.gql";
import Spinner from "@basecomponents/Spinner";
import config from "@src/config.json";
import useSnackbar from "@src/utilities/use-snackbar";

const CLIENT_ID = config.amplify.Auth.userPoolWebClientId;
const USERPOOL_DOMAIN_NAME = config.amplify.Auth.userPoolDomainName;
const BASE_URL = config.baseURL;

const cleanEmail = (email) => email.trim().split(' ').join('+');

const BuildProfile = ({ location }) => {
  const { email, groupNumber } = qs.parse(location.search);
  const [checking, setChecking] = useState(false);
  const [setErrorSnack] = useSnackbar('error');
  const { t } = useTranslation();
  const selectedLang = localStorage.getItem('lang');

  useEffect(() => {
    const effect = async () => {
      if (!checking && email && groupNumber) {
        setChecking(true);
        const cognitoCheck = await (
          await fetch(config.apollo.url, {
            body: JSON.stringify({
              query: print(mutationRemoteAction),
              variables: {
                name: 'check-cognito',
                params: JSON.stringify({ email: cleanEmail(email) }),
              },
            }),
            headers: {
              'content-type': 'application/json',
              'x-api-key': config.apollo.apiKey,
            },
            method: 'POST',
          })
        ).json();
        if (cognitoCheck.data) {
          const { error, exists } = JSON.parse(
            cognitoCheck.data.mutationRemoteAction.data
          );
          if (error) {
            setErrorSnack(t('buildProfile.somethingWentWrong', { error }));
          }
          if (exists) {
            // navigate(`${USERPOOL_DOMAIN_NAME}/authorize?response_type=token&identity_provider=${groupNumber}&client_id=${CLIENT_ID}&redirect_uri=http://localhost:8000/sso`)
            await navigate(
              `${USERPOOL_DOMAIN_NAME}/authorize?response_type=token&identity_provider=${groupNumber}&client_id=${CLIENT_ID}&redirect_uri=${BASE_URL}sso`
            );
          } else {
            setTimeout(() => setChecking(false), 5000);
          }
        }
      }
    };
    effect();
  }, [checking]);

  return (
    <Box
      alignItems="center"
      bg="primary"
      color="white"
      display="flex"
      flexDirection="column"
      height="100vh"
      justifyContent="center"
      width="100%"
    >
      {config.canadaEnv && (
        <Box
          sx={{
            position: 'fixed',
            right: '1rem',
            top: '1rem',
            width: 300,
          }}
        >
          <Form
            initialValues={{
              language: selectedLang || i18n.language,
            }}
            onSubmit={() => {}}
            render={() => (
              <>
                <Field
                  component={Dropdown}
                  isSearchable={false}
                  name="language"
                  options={
                    config.canadaEnv
                      ? [
                          {
                            canEnglishValue: null,
                            frenchValue: 'Français Canadien',
                            label: 'Français Canadien',
                            value: 'fr',
                          },
                          {
                            canEnglishValue: null,
                            frenchValue: null,
                            label: 'Canadian English',
                            value: 'caEn',
                          },
                        ]
                      : [
                          {
                            canEnglishValue: null,
                            frenchValue: 'Français Canadien',
                            label: 'Français Canadien',
                            value: 'fr',
                          },
                          {
                            canEnglishValue: null,
                            frenchValue: null,
                            label: 'Canadian English',
                            value: 'caEn',
                          },
                          {
                            canEnglishValue: null,
                            frenchValue: null,
                            label: 'American English',
                            value: 'en',
                          },
                        ]
                  }
                />
                <OnChange name="language">
                  {(lang) => {
                    i18n.changeLanguage(lang);
                    localStorage.setItem('lang', lang);
                  }}
                </OnChange>
              </>
            )}
          />
        </Box>
      )}
      {email && groupNumber ? (
        <>
          <Box>{t('buildProfile.pleaseWait')}</Box>
          <Spinner />
        </>
      ) : (
        <Box>{t('buildProfile.invalidProfile')}</Box>
      )}
    </Box>
  );
};

BuildProfile.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default BuildProfile;
