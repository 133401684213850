import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import Spinner from '@basecomponents/Spinner';
import { Page } from '@basecomponents/Auth';
import get from 'lodash/get';
import Redirect from '@basecomponents/Redirect';
import { Auth } from 'aws-amplify';
import Modal from "@basecomponents/Modal";
import MainLayout from "@petcomponents/MainLayout";
import EditAccessRightsContext from "@petcomponents/EditAccessRights/Context";
import NoEntry from "@petcomponents/NoEntry";
import localStorage from "@src/utilities/local-storage";

/**
 * @category MISC
 * @param {React.ReactNode} children
 * @param {Object} header
 * @param {('main'|'focused'|'plain')} layout
 * @param {string} permission
 * @param {string} title
 * @param {boolean} isResponsive
 * @param {*} rest
 * @returns {React.FC}
 */
const Route = ({
  children,
  header,
  layout,
  permission,
  title,
  isResponsive,
  ...rest
}) => {
  const ear = useContext(EditAccessRightsContext);
  const isPrivate = get(rest, 'isPrivate', false);
  const [toMFA, setToMFA] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setSessionExpired(false);
      })
      .catch(() => {
        setSessionExpired(true);
      });
    const redirectMFA =
      localStorage.getItem('secondaryAuthSuccessful') === 'false';
    setToMFA(redirectMFA && isPrivate);
  }, []);

  let Layout;

  switch (layout) {
    case 'main': {
      Layout = MainLayout;
      break;
    }

    case 'plain': {
      Layout = null;
      break;
    }

    default: {
      // noop
    }
  }

  const expiryModal = (
    <Modal
      closeText="Sign In"
      isOpen
      onClose={() => {
        window.location.reload();
      }}
    >
      Your session has expired. You will need to log in again.
    </Modal>
  );

  const privateChildren = sessionExpired ? expiryModal : children;

  return (
    <Page redirect="/auth-checker/" renderLoading={<Spinner />} {...rest}>
      <Redirect to="/secondary-auth" when={toMFA} />

      {Layout ? (
        <Layout
          header={header}
          isResponsive={isResponsive}
          title={title}
          {...rest}
        >
          {isPrivate && ear.isVisible(permission) ? (
            privateChildren
          ) : (
            <NoEntry />
          )}
          {!isPrivate ? children : null}
        </Layout>
      ) : (
        children
      )}
    </Page>
  );
};

Route.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.shape({}),
  isResponsive: PropTypes.bool.isRequired,
  layout: PropTypes.oneOf(['main', 'focused', 'plain']),
  permission: PropTypes.string,
  title: PropTypes.string,
};

Route.defaultProps = {
  header: {
    icon: 'dashboard',
    leftContainer: null,
    rightContainer: null,
    title: '',
  },
  layout: 'main',
  permission: '',
  title: null,
};

export default Route;
