/* eslint-disable sort-keys */
/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash/get';
import { isEmpty } from 'lodash';
import Box from '@basecomponents/Box';
import { Form, Field } from 'react-final-form';
import InputDropdown from '@basecomponents/Dropdown';
import { labelHandler } from '@src/utilities/label-utils';
import { composeValidations, required, FSRAcode } from '@src/utilities/validations';
import { FSRAMask } from '@src/utilities/masks';
import ToolbarButton from '@basecomponents/ToolbarButton';
import {
  PRODUCER_SUB_PRODUCER_STATUS,
  CONTACT_TYPE,
} from '@src/utilities/constants';
import InputText from '@basecomponents/Input';
import GetData from '@utils/get-data';
import Spinner from '@basecomponents/Spinner';
import { useTranslation } from 'react-i18next';
import InputMasked from "@basecomponents/InputMasked";
import Modal from "@basecomponents/Modal";
import remoteActionQuery from "@queries/remote-action.gql";
import config from "@src/config.json";

export default function PromoteModal({
  showModal,
  setShowModal,
  client,
  setErrorSnack,
  setSuccessSnack,
  submitting,
}) {
  const { t } = useTranslation()
  const { apiData: groupData = {}, loading: getGroupByIdLoading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: get(showModal, 'groupId') }),
    !get(showModal, 'groupId')
  );
  const apiCall = (pathName, params) =>
    client.query({
      fetchPolicy: 'no-cache',
      query: remoteActionQuery,
      variables: {
        name: pathName,
        params: JSON.stringify(params),
      },
    });

  async function createSubproducer(employeeToSubproducer) {
    const { firstName, lastName } = employeeToSubproducer;
    let subProducerId = '';
    try {
      const createSubproducerResponse = await apiCall('create-subproducer', {
        ...employeeToSubproducer,
        isPromoteEmployee: true,
        subProducerData: {
          firstName,
          lastName,
        },
      });
      subProducerId = get(
        JSON.parse(
          get(createSubproducerResponse, 'data.remoteAction.data', '')
        ),
        'subProducerId',
        ''
      );

      if (!subProducerId) {
        throw new Error('Could not create subproducer');
      }
      const employeeId = get(showModal, 'employeeId', '');
      await apiCall('process-employee-to-ben-broker', {
        employeeId,
        subProducerId,
      });
      // refetch
      showModal.refetch();
    } catch (error) {
      setErrorSnack(
        t('snack.error.errorWithMessage', { message: error.message }),
        config.notificationDuration
      );
    } finally {
      setShowModal({});
      setSuccessSnack(
        t('snack.success.employeePromoted'),
        config.notificationDuration
      );
    }
  }

  async function setAsAdmin(user) {
    try {
      await apiCall('create-group-admin', user);
      showModal.refetch();
    } catch (error) {
      setErrorSnack(
        t('snack.error.errorWithMessage', { message: error.message }),
        config.notificationDuration
      );
    } finally {
      setShowModal({});
      setSuccessSnack(
        t('snack.success.employeePromoted'),
        config.notificationDuration
      );
    }
  }
  if (getGroupByIdLoading) {
    return <Spinner />;
  }
  return (
    <Modal
      isOpen={!isEmpty(showModal)}
      onClose={() => {
        setShowModal({});
      }}
      shouldCloseOnOverlayClick={false}
      submitting={submitting}
      title={t('modal.promoteEmployee')}
    >
      <Box>
        {t('modal.wishToAssign', { firstName: get(showModal, 'user.firstName'), lastName: get(showModal, 'user.lastName') })}
        <Form
          onSubmit={async (e) => {
            try {
              const { role, producer, subProducerCode } = e;
              if (role === 'admin') {
                await setAsAdmin(showModal);
                return;
              }
              const fullName = get(showModal, 'user.fullName', '');
              const firstName = get(showModal, 'user.firstName', '');
              const lastName = get(showModal, 'user.lastName', '');
              const email = get(showModal, 'user.email', '');
              const state = get(groupData, 'locationDetails.address.state', '');
              const contactType = get(
                showModal,
                'user.contacts.contactType',
                CONTACT_TYPE[0].value // HOME
              );
              const addressId = get(showModal, 'user.address.addressId', '');
              const contactId = get(showModal, 'user.contacts.contactId', '');
              const producerId = producer;
              const phoneNumber = get(
                showModal,
                'user.contacts.phoneNumber',
                ''
              );
              // map from empoyee to subproducer
              const employeeToSubproducer = {
                active: true,
                addressId,
                subProducerCode,
                firstName,
                lastName,
                email,
                name: fullName,
                authorizedStates: state ? [state] : [],
                paidToProducer: false,
                isBroker: true,
                status: PRODUCER_SUB_PRODUCER_STATUS[0].value, // ACTIVE
                contactType,
                phoneNumber,
                contactId,
                producerId,
              };
              if (role === 'benBroker') {
                await createSubproducer(employeeToSubproducer);
              }
            } catch (error) {
              setErrorSnack(
                t('snack.error.errorWithMessage', { message: e.message }),
                config.notificationDuration
              );
            }
          }}
          render={(formContext) => {
            return (
              <form>
                <Field
                  aria-label={t('modal.role')}
                  component={InputDropdown}
                  label={labelHandler('Role', true)}
                  name="role"
                  options={[
                    // admin or ben broker
                    { canEnglishValue: null, frenchValue: 'ADMINISTRATEUR', value: 'admin', label: t('groupEmployees.detailsPage.admin') },
                    { canEnglishValue: null, frenchValue: "COURTIER D'AVANTAGES", value: 'benBroker', label: t('groupEmployees.detailsPage.benBroker') },
                  ]}
                  validate={required}
                />
                {get(formContext, 'values.role') === 'benBroker' && (
                  <>
                    <Field
                      aria-label={t('modal.producer')}
                      component={InputDropdown}
                      isClearable
                      label={labelHandler(t('modal.producer'), true)}
                      loadOptions={async (data) => {
                        const options = await client
                          .query({
                            fetchPolicy: 'no-cache',
                            query: remoteActionQuery,
                            variables: {
                              name: 'get-all-producer',
                              params: JSON.stringify({
                                name: data,
                                size: 5,
                              }),
                            },
                          })
                          .then((response) => {
                            const { content } = JSON.parse(
                              get(response, 'data.remoteAction.data', {})
                            );
                            return content.map((item) => {
                              return {
                                label: item.name,
                                value: item.producerId,
                              };
                            });
                          });
                        return options;
                      }}
                      name="producer"
                      validate={required}
                    />
                    <Field
                      aria-label={config.canadaEnv ? t('component.canada.fsraAgentNumber') : "Sub Producer Code/NPN"}
                      component={config.canadaEnv ? InputMasked : InputText}
                      label={labelHandler(config.canadaEnv ? t('component.canada.fsraAgentNumber') : 'Sub Producer Code/NPN', true)}
                      mask={config.canadaEnv ? FSRAMask : ''}
                      name="subProducerCode"
                      validate={composeValidations(config.canadaEnv ? (required, FSRAcode) : required)}
                    />
                  </>
                )}
                <Box display="flex" justifyContent="center" m={2}>
                  <Box alignSelf="center" display="flex" flexDirection="row">
                    <ToolbarButton
                      bg="error"
                      label={t('common.cancel')}
                      onClick={() => setShowModal({})}
                    />
                    <ToolbarButton
                      disabled={formContext.submitting}
                      label={t('modal.promote')}
                      onClick={formContext.handleSubmit}
                      submitting={formContext.submitting}
                      type="submit"
                    />
                  </Box>
                </Box>
              </form>
            );
          }}
        />
        <Box
          sx={{
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'center',
            color: 'red',
            my: 5,
          }}
        >
          {t('common.cannotBeUndone')}
        </Box>
      </Box>
    </Modal>
  );
}
