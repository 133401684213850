import { Link } from '@reach/router';
import Box from '@basecomponents/Box';
import React from 'react';
import Route from "@petcomponents/Route";
import Icon from "@src/components/Icon";

/**
 * @category Settings
 * @param {*} rest
 * @returns {React.FC}
 */
const NotificationsType = ({ ...rest }) => {
  const settingTypes = [
    {
      icon: 'notifications',
      link: '/settings/notifications/type/admin',
      name: 'Administrator',
    },
    {
      icon: 'notifications',
      link: '/settings/notifications/type/employee',
      name: 'Employee',
    },
  ];

  return (
    <Route isPrivate {...rest}>
      <Box as="h2" sx={{ py: 3 }}>
        Notifications
      </Box>
      {settingTypes?.map(({ name, link, icon }) => (
        <Box
          key={`${icon}-${name}`}
          sx={{
            '&:hover': {
              boxShadow: 6,
            },
            bg: 'white',
            borderRadius: 5,
            m: 3,
            p: 3,
            transitionDuration: '0.25s',
          }}
        >
          <Link to={link}>
            <Box
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box as="div" display="flex" flexDirection="row">
                <Icon
                  height={25}
                  svg={icon}
                  sx={{ color: 'primary' }}
                  width={25}
                />
                <Box as="h3" marginLeft={3}>
                  {name}
                </Box>
              </Box>
              <Icon
                height={25}
                svg="right-chevron"
                sx={{ color: 'primary' }}
                width={25}
              />
            </Box>
          </Link>
        </Box>
      ))}
    </Route>
  );
};

export default NotificationsType;
