import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { navigate } from '@reach/router';
import mutationRemoteAction from '@mutations/mutation-remote-action.gql';
import { print } from 'graphql';
import { isEmpty, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import config from "@src/config.json";
import localStorage from "@src/utilities/local-storage";
import useSnackbar from "@src/utilities/use-snackbar";

const CLIENT_ID = config.amplify.Auth.userPoolWebClientId;
const USERPOOL_DOMAIN_NAME = config.amplify.Auth.userPoolDomainName;
const BASE_URL = config.baseURL;

const decodePayload = (jwtToken) => {
  const payload = jwtToken.split('.')[1];
  try {
    return JSON.parse(Buffer.from(payload, 'base64').toString('utf8'));
  } catch (err) {
    return {};
  }
};

const calculateClockDrift = (iatAccessToken, iatIdToken) => {
  const now = Math.floor(new Date() / 1000);
  const iat = Math.min(iatAccessToken, iatIdToken);
  return now - iat;
};

async function getWorkDay() {
  const paramName = `workday-group-number-${config.environment}`;
  return (
    await fetch(config.apollo.url, {
      body: JSON.stringify({
        query: print(mutationRemoteAction),
        variables: {
          name: 'get-params-value',
          params: JSON.stringify({
            paramName,
          }),
        },
      }),
      headers: {
        'content-type': 'application/json',
        'x-api-key': config.apollo.apiKey,
      },
      method: 'POST',
    })
  ).json();
}

async function getGroupId(groupNum) {
  return (
    await fetch(config.apollo.url, {
      body: JSON.stringify({
        query: print(mutationRemoteAction),
        variables: {
          name: 'get-groupInfo-by-groupNumber',
          params: JSON.stringify({
            groupNumber: groupNum,
          }),
        },
      }),
      headers: {
        'content-type': 'application/json',
        'x-api-key': config.apollo.apiKey,
      },
      method: 'POST',
    })
  ).json();
}
const SSO = () => {
  const fragment = window.location.hash;
  const [setErrorSnack] = useSnackbar('error');
  const [setSuccessSnack] = useSnackbar('accent');
  const { t } = useTranslation()

  useEffect(async () => {
    if (fragment.includes('error_description')) {
      const [message] = fragment.split('&');
      const userName = decodeURIComponent(
        message.split('PreSignUp+failed+with+error+').pop()
      ).slice(0, -2);

      const groupNumber = userName.substring(0, userName.indexOf('_'));
      const empId = userName.substring(userName.indexOf('_') + 1);

      if (groupNumber) {
        const getWorkDayGroup = await getWorkDay();
        const { data, errors } = getWorkDayGroup;
        const groupDataId = await getGroupId(groupNumber);
        const { data: groupData, errors: groupError } = groupDataId;
        if (!isEmpty(errors) || !isEmpty(groupError)) {
          return setErrorSnack(
            `${errors[0].message}`,
            config.notificationDuration
          );
        }
        const {
          mutationRemoteAction: { data: responseData },
        } = data;
        const { value } = JSON.parse(responseData);
        const {
          mutationRemoteAction: { data: responseGroupData },
        } = groupData;
        const groupId = get(JSON.parse(responseGroupData), '0.groupId');
        if (value === groupNumber) {
          window.location.href = `/sso-intake?empId=${empId}&groupNumber=${groupNumber}&groupId=${groupId}`;
        } else {
          window.location.href = `/add-email-id?empId=${empId}&groupNumber=${groupNumber}`;
        }
      } else {
        setTimeout(
          () =>
            navigate(
              `${USERPOOL_DOMAIN_NAME}/authorize?response_type=token&identity_provider=${empId}&client_id=${CLIENT_ID}&redirect_uri=${BASE_URL}sso`
            ),
          5000
        );
      }
      return null;
    }

    let [accessToken, idToken] = fragment.split('&');
    accessToken = accessToken.substring(accessToken.indexOf('=') + 1);
    const accessTokenData = decodePayload(accessToken);

    idToken = idToken.substring(idToken.indexOf('=') + 1);
    const idTokenData = decodePayload(idToken);

    if (idTokenData['cognito:username']) {
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${CLIENT_ID}.LastAuthUser`,
        idTokenData['cognito:username']
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${CLIENT_ID}.${idTokenData['cognito:username']}.idToken`,
        idToken
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${CLIENT_ID}.${idTokenData['cognito:username']}.accessToken`,
        accessToken
      );
      localStorage.setItem(
        `CognitoIdentityServiceProvider.${CLIENT_ID}.${idTokenData['cognito:username']}.clockDrift`,
        calculateClockDrift(accessTokenData.iat, idTokenData.iat)
      );
      localStorage.setItem('secondaryAuthSuccessful', true);
      Auth.currentAuthenticatedUser()
        .then(() => {
          setSuccessSnack('SSO Login Success!', config.notificationDuration);
          // window.location.href = '/secondary-auth';
          window.location.href = '/home';
        })
        .catch((error) =>
          setErrorSnack(
            t('snack.error.somethingWrongMessage', { error }),
            config.notificationDuration
          )
        );
    } else {
      setErrorSnack(
        t('snack.error.somethingWrongMessage', { error: `accessToken:${accessToken}; idToken: ${idToken}` }),
        config.notificationDuration
      );
    }
  }, []);

  return null;
};

export default SSO;
