import React from 'react';
import Box from '@basecomponents/Box';
import APIDocument from "@src/assets/documents/IAIC_FINEOS_API_DOC.pdf";
import PDFViewer from "@petcomponents/PDFViewer";
import Route from "@petcomponents/Route";

const Documentation = ({ ...rest }) => {
  return (
    <Route
      header={{
        icon: 'info',
        title: 'API Documentation',
        type: 'Documentation',
        typeLabel: 'Documentation',
      }}
      isPrivate
      {...rest}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <PDFViewer file={APIDocument} />
      </Box>
    </Route>
  );
};

export default Documentation;
