import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import React, { useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import remoteActionQuery from "@queries/remote-action.gql";
import GetData from "@src/utilities/get-data";
import useSnackbar from "@src/utilities/use-snackbar";
import InputText from "@basecomponents/Input";
import Modal from "@basecomponents/Modal";
import Route from "@petcomponents/Route";
import Spinner from "@basecomponents/Spinner";
import ToolbarButton from "@basecomponents/ToolbarButton";
import config from "@src/config.json";

/**
 * @category Components
 * @param {*} rest
 * @returns {React.FC}
 */
const UpdatePrimaryEmail = ({ ...rest }) => {
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const queryParams = useParams();
  const employeeId = get(queryParams, 'employeeId', '');
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState(null);
  const { t } = useTranslation()

  const { apiData = {}, loading } = GetData(
    'get-group-employee-by-id',
    JSON.stringify({ id: employeeId })
  );
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <Route
      header={{
        title: t('groupEmployees.updatePrimaryEmail.title'),
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {t('groupEmployees.updatePrimaryEmail.title')}
      </Box>
      <ApolloConsumer>
        {(client) => {
          const updatePrimaryEmail = async () => {
            setShowModal(false);
            client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'update-user-email',
                  params: JSON.stringify({
                    email,
                    userId: get(apiData, 'userId', ''),
                  }),
                },
              })
              .then(() => {
                navigate(`/group-employees/${employeeId}/details`);
              })
              .catch((e) =>
                setErrorSnack(
                  t('snack.error.errorWithMessage', { message: e.message }),
                  config.notificationDuration
                )
              );
          };
          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                initialValues={{
                  email: get(apiData, 'user.email', ''),
                }}
                onSubmit={async (values) => {
                  setEmail(get(values, 'email', '').toLowerCase());
                  setShowModal(true);
                }}
                render={({ submitting, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        padding: 3,
                      }}
                    >
                      <Field
                        aria-label={t('groupEmployees.detailsPage.primaryEmail')}
                        component={InputText}
                        isDisabled
                        label={t('groupEmployees.detailsPage.primaryEmail')}
                        name="email"
                        wrapperSx={{ ...commonFieldSxHalf }}
                        {...rest}
                      />
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          maxWidth: '25rem',
                          mt: 6,
                          width: '100%',
                        }}
                      >
                        <ToolbarButton
                          isDisabled={
                            !get(apiData, 'user.email', '') || submitting
                          }
                          label={t('common.cancel')}
                          mr={0}
                          onClick={() =>
                            navigate(`/group-employees/${employeeId}/details`)
                          }
                          simple
                          submitting={loading}
                          sx={{
                            bg: 'transparent',
                            color: `${!get(apiData, 'user.email', '') || submitting
                              ? '#E9675D'
                              : 'error'
                              }`,
                            fontSize: 2,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}
                          width="150px"
                        />
                        {showModal === true && (
                          <Modal
                            closeText={t('common.no')}
                            isOpen
                            onClose={() => setShowModal(false)}
                            onSubmit={updatePrimaryEmail}
                            submitText={t('common.yes')}
                            title={t('groupEmployees.updatePrimaryEmail.confirmSubmission')}
                          >
                            <Box>
                              {t('modal.updatePrimaryEmail')}
                            </Box>
                          </Modal>
                        )}

                        <ToolbarButton
                          bg="accentDark"
                          isDisabled={submitting}
                          label={t('common.submit')}
                          mr={0}
                          submitting={submitting}
                          type="submit"
                          width="150px"
                        />
                      </Box>
                    </Box>
                  </form>
                )}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

export default UpdatePrimaryEmail;
