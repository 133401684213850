import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Content from "@petcomponents/MainLayout/Content";
import Footer from "@petcomponents/MainLayout/Footer";
import AppHeader from "@petcomponents/MainLayout/Header";
import Sidebar from "@petcomponents/MainLayout/Sidebar";

/**
 * @category Components
 * @param {React.ReactNode} children
 * @param {Object} header
 * @param {boolean} isResponsive
 * @param {*} rest
 * @returns {React.FC}
 */
const MainLayout = ({ children, header, isResponsive, ...rest }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  if (window.onclick && isSidebarOpen) {
    setIsSidebarOpen(false);
  }

  return (
    <>
      <Sidebar isOpen={isSidebarOpen} isResponsive={isResponsive} />
      <AppHeader
        header={header}
        isResponsive={isResponsive}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        {...rest}
      />
      <Content isResponsive={isResponsive} isSidebarOpen={isSidebarOpen}>
        {children}
        {isResponsive && <Footer />}
      </Content>
      {!isResponsive && <Footer />}
    </>
  );
};

MainLayout.defaultProps = {
  header: {
    leftContainer: null,
    rightContainer: null,
    subHeader: null,
    title: '',
  },
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.shape({}),
  isResponsive: PropTypes.bool.isRequired,
};

export default MainLayout;
