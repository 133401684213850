import { Router } from '@reach/router';
import React from 'react';
import ProducerDashboardPage from '@petcomponents/Producers/ProducerDashboardPage';
import ProducerDetailPage from '@petcomponents/Producers/ProducerDetails';
import UpsertProducerPage from '@petcomponents/Producers/UpsertProducerPage';
import NotFoundPage from "@src/routes/ihc/404";

const Groups = (props) => (
  <Router>
    <ProducerDashboardPage {...props} path="/producers/dashboard" />
    <ProducerDetailPage {...props} path="/producers/:producerId/details" />
    <UpsertProducerPage
      {...props}
      path="/producers/upsert"
      type="upsertProducerPage"
    />
    <UpsertProducerPage
      {...props}
      path="/producers/create"
      type="upsertProducerPage"
    />
    <UpsertProducerPage
      {...props}
      path="/producers/create/:producerId"
      type="upsertProducerPage"
    />
    <UpsertProducerPage
      {...props}
      path="/producers/edit/:producerId"
      type="upsertProducerPage"
    />
    <NotFoundPage default path="/404" />
  </Router>
);

export default Groups;
