import Box from '@basecomponents/Box';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from "@basecomponents/Modal";

export default function EmailSentModal({ modal, setModal, title }) {
  const { t } = useTranslation()
  return (
    <Modal
      closeText={t('common.ok')}
      isOpen={modal}
      onClose={() => setModal(false)}
      shouldCloseOnOverlayClick={false}
      title={title}
    >
      <Box>{modal}</Box>
    </Modal>
  );
}

EmailSentModal.propTypes = {
  modal: PropTypes.string.isRequired,
  setModal: PropTypes.func.isRequired,
  title: PropTypes.string,
};

EmailSentModal.defaultProps = {
  title: 'Email Sent',
};
