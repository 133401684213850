import React from 'react';
import { Location } from '@reach/router';
import AuthContext from "@basecomponents/Auth/utilities/context";
import PageHandler from "@basecomponents/Auth/containers/Page/PageHandler";
import { pageTypes } from "@basecomponents/Auth/containers/Page/utilities/types";

const Page = (props) => (
  <Location>
    {(location) => (
      <AuthContext.Consumer>
        {(context) => <PageHandler {...location} {...context} {...props} />}
      </AuthContext.Consumer>
    )}
  </Location>
);

Page.propTypes = pageTypes;

export default Page;
