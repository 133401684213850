import get from 'lodash/get';
import {useTranslation} from "react-i18next";
import GetData from "@src/utilities/get-data";

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const BillingType = ({ value }) => {
  const { i18n } = useTranslation();
  const data = [
    GetData('get-enum-options', JSON.stringify([{ params: 'BillingType' }])),
  ];
  const type = get(data, '0.apiData.BillingType', []);
  const billingType = type.find((item) => item.value === value);
  // eslint-disable-next-line no-nested-ternary
  return billingType ? (i18n.language === 'fr' && get(billingType, 'frenchValue', '') !== '') ? get(billingType, 'frenchValue'): get(billingType, 'label') : value;
};

export default BillingType;
