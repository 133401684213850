import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import { createGlobalStyle, ThemeContext } from 'styled-components';
import Box from '@basecomponents/Box';
import ToolbarButton from "@basecomponents/ToolbarButton";

const StyledGlobal = createGlobalStyle`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: ${(p) => p.theme.zIndices[4]};
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .ReactModal__Content {
    transition: transform 200ms ease-in-out;
    transform: translate(-50%, -52%);
  }

  .ReactModal__Content--after-open{
    transform: translate(-50%, -50%);
  }

  .ReactModal__Content--before-close{
    transform: translate(-50%, -52%);
  }
`;

/**
 * @category BaseComponents
 * @param {React.ReactNode} children
 * @param {Object} childrenSx
 * @param {string} closeText
 * @param {function} onClose
 * @param {function} onSubmit
 * @param {boolean} simple
 * @param {Object} style
 * @param {string} submitText
 * @param {boolean} submitting
 * @param {string} title
 * @param {React.ReactNode} titleNode
 * @param {React.ReactNode} footerNode
 * @param {Object} footerSx
 * @param {boolean} shouldCloseOnOverlayClick
 * @param {*} rest
 * @returns {React.FC}
 */
const Modal = ({
  children,
  childrenSx,
  closeText,
  onClose,
  onSubmit,
  simple,
  style,
  submitText,
  submitting,
  title,
  titleNode,
  footerNode,
  footerSx,
  shouldCloseOnOverlayClick,
  ...rest
}) => {
  const { defaultStyle = {} } = useContext(ThemeContext);
  const {
    simpleModalStyle = {},
    reactModalStyle = {},
    titleBox = {},
    childrenBox = {},
    footerBox = {},
  } = defaultStyle.defaultModal(style, title, titleNode);

  const renderTitle = () => {
    return title || titleNode || null;
  };

  const renderFooter = () => {
    if (!footerNode) {
      return (
        <>
          {closeText && (
            <ToolbarButton
              bg="error"
              disabled={submitting}
              label={closeText}
              onClick={onClose}
            />
          )}
          {onSubmit && submitText && (
            <ToolbarButton
              label={submitText}
              ml={5}
              onClick={onSubmit}
              submitting={submitting}
            />
          )}
        </>
      );
    }
    return footerNode || null;
  };
  return simple ? (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={200}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{ ...simpleModalStyle }}
      {...rest}
    >
      <StyledGlobal />
      {children}
    </ReactModal>
  ) : (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={200}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{ ...reactModalStyle }}
      {...rest}
    >
      <StyledGlobal />
      <Box sx={{ ...titleBox }}>{renderTitle()}</Box>
      <Box sx={{ ...childrenBox, ...childrenSx }}>{children}</Box>
      {(footerNode || closeText || submitText) && (
        <Box sx={{ ...footerBox, ...footerSx }}>{renderFooter()}</Box>
      )}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  childrenSx: PropTypes.shape({}),
  closeText: PropTypes.string,
  footerNode: PropTypes.node,
  footerSx: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
  simple: PropTypes.bool,
  style: PropTypes.shape({
    content: PropTypes.shape({}),
    overlay: PropTypes.shape({}),
  }),
  submitText: PropTypes.string,
  submitting: PropTypes.bool,
  title: PropTypes.string,
  titleNode: PropTypes.node,
};

Modal.defaultProps = {
  childrenSx: {},
  closeText: null,
  footerNode: null,
  footerSx: {},
  onSubmit: null,
  shouldCloseOnOverlayClick: true,
  simple: false,
  style: {},
  submitText: null,
  submitting: false,
  title: null,
  titleNode: null,
};

export default Modal;
