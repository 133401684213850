import React, { useContext } from 'react';
import { useParams } from '@reach/router';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import { USER_ROLE } from '@utils/constants';
import Route from "@petcomponents/Route";
// import Tabs from '../../BaseComponents/Tabs';
import KeyValuePairs from "@basecomponents/KeyValuePairs";
import GetData from "@src/utilities/get-data";
import getMetaData from "@src/utilities/get-meta-data";
import Card from "@basecomponents/Card";

/**
 * @category User
 * @param {*} rest
 * @returns {React.FC}
 */
const UsersDetailPage = ({ ...rest }) => {
  const theme = useContext(ThemeContext);
  const queryParams = useParams();
  const userId = get(queryParams, 'userId', '');
  const { apiData = {}, loading } = GetData(
    'get-user-by-id',
    JSON.stringify({ userId })
  );
  const fields = [
    { name: 'firstName' },
    { name: 'lastName' },
    { name: 'email' },
    { name: 'role' },
    { name: 'active' },
    { name: 'deleted' },
  ];
  if (
    [
      USER_ROLE.EMPLOYEE,
      USER_ROLE.TERMINATED_EMPLOYEE,
      USER_ROLE.EMPLOYER,
      USER_ROLE.RENEWAL_POLICY_DELETED,
    ].includes(get(apiData, 'role', '').toLowerCase())
  ) {
    fields.push({ name: 'userId' });
  }
  const meta = getMetaData({ moduleName: 'users' });
  return (
    <Route
      header={{
        title: get(apiData, 'email', userId),
        type: 'users',
      }}
      isPrivate
      permission="user.page.detail"
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {loading ? (
          <PulseLoader color={get(theme, 'colors.accent')} size={5} />
        ) : (
          `Details of ${get(apiData, 'user.firstName', '')} ${
            get(apiData, 'user.lastName') ?? get(apiData, 'fullName')
          }`
        )}
      </Box>
      <Card loading={loading}>
        <KeyValuePairs data={apiData} fields={fields} meta={meta} />
      </Card>
      {/* <Tabs
        loading={loading}
        tabs={[
          {
            label: 'Address',
            render: () => (
              <KeyValuePairs
                columnCount={2}
                data={apiData}
                fields={[{ name: 'address' }]}
                meta={meta}
              />
            ),
          },
          {
            label: 'Contacts',
            render: () => (
              <TableSection
                data={apiData}
                dataField="contacts"
                grid={{ fields: [{ name: '_thisRow' }] }}
                meta={meta}
              />
            ),
          },
        ]}
      /> */}
    </Route>
  );
};

UsersDetailPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default UsersDetailPage;
