import React, { useState } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import { BARREL_STATUS, FILE_FLOWS, FILE_STATUS } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import Route from "@petcomponents/Route";
import ListGrid from "@basecomponents/ListGrid";
import downloadData from "@src/utilities/download-file";
import config from "@src/config.json";
import Modal from "@basecomponents/Modal";
import HeaderRightContainer from "@basecomponents/HeaderRightContainer";

/**
 * @category Files
 * @param {boolean} sftp
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const FilePage = ({ sftp, ...rest }) => {
  const { t } = useTranslation();
  const [fileError, setFileError] = useState(false);

  const filters = [
    {
      name: 'groupName',
      type: 'Input',
    },
    {
      name: t('metadata.fileStatus'),
      options: FILE_STATUS,
      type: 'Dropdown',
    },
    {
      name: 'flow',
      options: FILE_FLOWS,
      type: 'Dropdown',
    },
  ];
  
  const barrelFilters = [
    {
      label: 'Group Number',
      name: 'groupNumber',
      type: 'Input',
    },
    {
      label: t('metadata.fileStatus'),
      name: 'status',
      options: BARREL_STATUS,
      type: 'Dropdown',
    },
    {
      label: t('metadata.flow'),
      name: 'fileType',
      // options: BARREL_FLOWS,
      options: [
        {
          canEnglishValue: null,
          frenchValue: 'Recensement des employés',
          label: t('groupEmployees.enrollmentPage.employeeCensus'),
          value: 'KCCENSUS',
        },
        {
          canEnglishValue: 'Pet Level Post Enrolment',
          frenchValue: 'Niveau de l’animal de compagnie après l’inscription',
          label: 'Pet Level Post Enrollment',
          value: 'KCPOSTENROLL',
        },
        {
          canEnglishValue: 'Employee Level Post Enrolment',
          frenchValue: 'Niveau de l’employé après l’inscription',
          label: 'Employee Level Post Enrollment',
          value: 'KCEMPPOSTENROLL',
        },
        {
          canEnglishValue: 'Employee Enrolment',
          frenchValue: 'Inscription des employés',
          label: 'Employee Enrollment',
          value: 'KCENROLL',
        },
      ],
      type: 'Dropdown',
    },
  ];

  const grid = {
    actions: [
      {
        getProps: (data) => {
          return get(data, 'status') === 'errors-fail'
            ? {
                icon: 'details',
                link: `/groups/errors/${get(data, 'taskId')}`,
              }
            : {
                icon: 'details',
                isDisabled: get(data, 'status') !== 'file-error',
                onClick: () => {
                  setFileError(data.fileError);
                },
              };
        },
        label: 'View Errors',
        name: 'get-errors',
      },
      {
        getProps: (data) => {
          return {
            icon: 'edit',
            isDisabled: !(
              get(data, 'status') === 'columns-fail' && get(data, 'waiting')
            ),
            link: `/groups/columns/${get(data, 'taskId')}`,
          };
        },
        label: 'Match Columns',
        name: 'infer-labels',
      },
      {
        getProps: (data) => {
          return {
            icon: 'download',
            onClick: async () => {
              await downloadData({
                s3BucketName: config.amplify.Storage.AWSS3.bucket,
                s3KeyName: get(data, 's3Key', ''),
              });
            },
          };
        },
        label: 'Download',
        name: 'download',
      },
    ],
    fields: [
      {
        name: 'groupId',
      },
      {
        name: 'groupNumber',
        sortable: true,
      },
      {
        name: 'created',
        sortable: true,
        sortDefault: 'desc',
      },
      {
        name: 'status',
      },
      {
        name: 'flow',
      },
    ],
  };

  const barrelGrid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'details',
            link: `/files/detail/${get(data, 'datasetId')}`,
          };
        },
        label: 'View Details',
        name: 'view-details',
      },
      {
        getProps: (data) => {
          return {
            icon: 'edit',
            isDisabled: !(get(data, 'status') === 'column-check-required'),
            link: `/files/columns/${get(data, 'datasetId')}`,
          };
        },
        label: 'Match Columns',
        name: 'infer-labels',
      },
    ],
    fields: [
      { name: 'errorCount' },
      { name: 'rowCount' },
      { name: 'tags.groupNumber' },
      { name: 'datasetName' },
      { name: 'createdDate' },
      { name: 'status' },
      { name: 'tags.useCase' },
    ],
  };

  return (
    <Route
      header={{
        rightContainer: (
          <HeaderRightContainer
            headerActions={[
              {
                action: '/groups/dashboard',
                icon: 'groups',
                label: 'View Groups',
              },
            ]}
            {...rest}
          />
        ),
        title: 'File Dashboard',
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {sftp ? 'SFTP Files Dashboard' : 'Template Files Dashboard'}
      </Box>
      {fileError && (
        <Modal
          closeText="Close"
          isOpen
          onClose={() => setFileError(false)}
          title="Issue Uploading File"
        >
          <Box fontWeight="bold" padding="1rem 0">
            {fileError}
          </Box>
          <Box>If this problem persists please contact support.</Box>
        </Modal>
      )}

      <ListGrid
        filters={sftp ? barrelFilters : filters}
        grid={sftp ? barrelGrid : grid}
        gridQueryName={sftp ? 'barrel-files' : 'get-tasks'}
        moduleName={sftp ? 'barrelFiles' : 'files'}
      />
    </Route>
  );
};

FilePage.propTypes = {
  sftp: PropTypes.bool,
};

FilePage.defaultProps = {
  sftp: false,
};

export default FilePage;
