/* stylelint-disable */
// Disabling Stylelint for this file because it is a pain to modify these values and test them out globally.
// TODO: support more than just date ranges!

import DateRange from '@wojtekmaj/react-daterange-picker/dist/entry.nostyle';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import config from "@src/config.json";
import {useTranslation} from "react-i18next";
import InputWrapper from "@basecomponents/InputWrapper";
import themeVariantToValue from "@src/utilities/theme-variant-to-value";

const CustomDateRange = styled(DateRange)`
  .react-calendar {
    font-family: inherit;
    border-color: ${(p) =>
      themeVariantToValue(p.theme, 'colors', 'inputs.primary.borderColor')};
    border-radius: ${(p) =>
      themeVariantToValue(p.theme, 'radii', 'inputs.primary.borderRadius')};

    &__tile {
      &:disabled {
        background-color: ${(p) => p.theme.colors.accent};
      }

      &:enabled {
        &:focus,
        &:hover {
          background-color: ${(p) => p.theme.colors.accent};
        }
      }

      &--active {
        background: ${(p) => p.theme.colors.accent};

        &:enabled {
          &:focus,
          &:hover {
            background: ${(p) => p.theme.colors.primary};
          }
        }
      }
    }

    &--selectRange &__tile--hover {
      background-color: ${(p) => p.theme.colors.accent};
    }

    &__navigation {
      button:enabled {
        &:hover,
        &:focus {
          background-color: ${(p) => p.theme.colors.accent};
        }
      }

      button[disabled] {
        background-color: ${(p) => p.theme.colors.accent};
      }
    }

    &__month-view__days__day {
      &--neighboringMonth {
        color: ${(p) => p.theme.colors.accent};
      }
    }
  }

  &.react-daterange-picker {
    width: 100%;
    height: ${(p) =>
      `calc(${themeVariantToValue(
        p.theme,
        'space',
        'inputs.primary.py'
      )} * 2 + ${p.theme.lineHeights[1]})`};
    padding: 0
      ${(p) => themeVariantToValue(p.theme, 'space', 'inputs.primary.px')};
    border-radius: ${(p) =>
      themeVariantToValue(p.theme, 'radii', 'inputs.primary.borderRadius')};
    background-color: ${(p) =>
      themeVariantToValue(p.theme, 'colors', 'inputs.primary.bg')};
  }

  .react-daterange-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-daterange-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-daterange-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 2px;
  }
  .react-daterange-picker__button:enabled {
    cursor: pointer;
  }
  .react-daterange-picker__button:enabled:hover
    .react-daterange-picker__button__icon,
  .react-daterange-picker__button:enabled:focus
    .react-daterange-picker__button__icon {
    stroke: #0078d7;
  }
  .react-daterange-picker__button:disabled
    .react-daterange-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-daterange-picker__button svg {
    display: inherit;
  }

  .react-daterange-picker__calendar .react-calendar {
    border-width: thin;
  }

  .react-daterange-picker {
    &__wrapper {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      align-items: center;
      border: 0;
      width: 100%;
      justify-content: center;
      height: 100%;
    }

    &__inputGroup {
      height: 100%;
      flex-grow: 1;
      padding: 0 2px;
      box-sizing: content-box;
      flex-grow: unset;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;

      &__divider {
        padding: 1px 0;
        white-space: pre;
      }
    }

    &__clear-button {
      margin-left: auto;
    }

    &__button {
      border: 0;
      background: transparent;
      padding: 4px 2px;
      color: ${(p) => p.theme.colors.accent};

      &:enabled {
        cursor: pointer;
        &:hover,
        &:focus {
          .react-daterange-picker__button__icon {
            stroke: currentColor;
            color: hsl(0, 0%, 60%);
          }
        }
      }

      &:disabled {
        .react-daterange-picker__button__icon {
          stroke: rgb(109, 109, 109);
        }
      }

      svg {
        display: inherit;
        stroke: currentColor !important;
      }
    }

    &__calendar {
      width: 350px;
      max-width: 100vw;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      margin-top: 10px;

      &--closed {
        display: none;
      }
    }
  }

  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    box-shadow: ${(p) => p.theme.shadows[1]};

    &--doubleView {
      width: 700px;

      .react-calendar__viewContainer {
        display: flex;
        margin: -0.5em;

        > * {
          width: 50%;
          margin: 0.5em;
        }
      }
    }

    button {
      margin: 0;
      border: 0;
      outline: none;

      &:enabled {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &__navigation {
      height: 44px;
      margin-bottom: 1em;

      button {
        min-width: 44px;
        background: none;

        &:enabled {
          &:hover,
          &:focus {
            background-color: rgb(230, 230, 230);
          }
        }

        &[disabled] {
          background-color: rgb(240, 240, 240);
        }
      }
    }

    &__month-view {
      &__weekdays {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.75em;

        &__weekday {
          padding: 0.5em;
        }
      }

      &__weekNumbers {
        font-weight: bold;

        .react-calendar__tile {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.75em;
          padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
        }
      }

      &__days {
        &__day {
          &--weekend {
            color: rgb(209, 0, 0);
          }

          &--neighboringMonth {
            color: rgb(117, 117, 117);
          }
        }
      }
    }

    &__year-view,
    &__decade-view,
    &__century-view {
      .react-calendar__tile {
        padding: 2em 0.5em;
      }
    }

    &__tile {
      max-width: 100%;
      text-align: center;
      padding: 0.75em 0.5em;
      background: none;

      &:disabled {
        background-color: rgb(240, 240, 240);
      }

      &:enabled {
        &:hover,
        &:focus {
          background-color: rgb(230, 230, 230);
        }
      }

      &--now {
        @bgcolor: lighten(rgb(220, 220, 0), 30%);
        background: @bgcolor;

        &:enabled {
          &:hover,
          &:focus {
            background: lighten(@bgcolor, 10%);
          }
        }
      }

      &--hasActive {
        color: white;
        background: ${(p) => p.theme.colors.primary};

        &:enabled {
          &:hover,
          &:focus {
            background: lighten(${(p) => p.theme.colors.primary}, 10%);
          }
        }
      }

      &--active {
        background: ${(p) => p.theme.colors.primary};
        color: white;

        &:enabled {
          &:hover,
          &:focus {
            background: lighten(@bgcolor, 10%);
          }
        }
      }
    }

    &--selectRange {
      .react-calendar__tile {
        &--hover {
          background-color: rgb(230, 230, 230);
        }
      }
    }
  }
`;

const DatePickerWrapper = styled(InputWrapper)`
  border: ${(p) => p.theme.variants.inputs.primary.borderStyle}
    ${(p) =>
      themeVariantToValue(p.theme, 'sizes', 'inputs.primary.borderWidth')}
    ${(p) =>
      themeVariantToValue(p.theme, 'colors', 'inputs.primary.borderColor')};

  &:focus-within {
    border-color: ${(p) =>
      themeVariantToValue(
        p.theme,
        'colors',
        'inputs.primary.&:focus.borderColor'
      )};
  }
`;

/**
 * @category BaseComponents
 * @param {Object} input
 * @returns {React.FC}
 */
const DatePicker = ({ input, ...rest }) => {
    const { i18n } = useTranslation();
  const onChange = (date, meta) => {
    input.onChange(date, meta);
  };

  return (
    <DatePickerWrapper input={input} {...rest}>
      {(props) => (
        <CustomDateRange
          calendarAriaLabel="Toggle calendar"
          clearAriaLabel="Clear value"
          dayAriaLabel="Day"
          format={config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? "dd/MM/y" : "MM/dd/y"}
          locale={i18n.language === 'fr' ? "fr" : 'en'}
          monthAriaLabel="Month"
          nativeInputAriaLabel="Date"
          onChange={onChange}
          value={input.value}
          yearAriaLabel="Year"
          {...props}
        />
      )}
    </DatePickerWrapper>
  );
};

DatePicker.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  }).isRequired,
};

export default DatePicker;

/* stylelint-enable */
