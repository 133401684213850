import Box from '@basecomponents/Box';
import { isArray } from 'lodash';
import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { composeValidations, required } from '@utils/validators';
import { CONTACT_TYPE } from '@utils/constants';
import { labelHandler } from '@utils/label-utils';
import { phoneNumberMask } from '@utils/masks';
import { phoneNumber } from '@utils/validations';
import InputDropdown from "@basecomponents/Dropdown";
import InputText from "@basecomponents/Input";
import AddressFormSection from "@petcomponents/AddressFormSection";
import InputMasked from "@basecomponents/InputMasked";
import ToolbarButton from "@basecomponents/ToolbarButton";

/**
 * @category TBD
 * @param {boolean} isStateEditing
 * @param {*} rest
 * @returns {React.FC}
 */
const GroupLocationsFormSection = ({ isStateEditing, ...rest }) => {
  const { t } = useTranslation();
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  return (
    <FieldArray name="locations" {...rest}>
      {({ fields, meta }) => (
        <>
          <Box
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              maxWidth: '98vw',
            }}
          >
            {fields.map((name, index) => {
              return (
                <Box
                  key={index}
                  mt={6}
                  sx={{
                    borderRadius: 3,
                    boxShadow: 7,
                    m: 2,
                  }}
                >
                  <Box sx={{ my: 4 }}>
                    <Box
                      sx={{
                        ...commonFieldSxHalf,
                        fontSize: 3,
                        fontWeight: 'bold',
                        pb: 0,
                      }}
                    >
                      {index === 0 ? 'SITUS ADDRESS' : 'BRANCH ADDRESS'}
                    </Box>
                    <Field
                      aria-label={t('groups.locationName')}
                      component={InputText}
                      label={labelHandler(t('groups.locationName'), true)}
                      name={`${name}.name`}
                      validate={required}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <AddressFormSection
                      isStateEditing={isStateEditing}
                      label={`${name}.address`}
                      situs={index === 0}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Field
                      aria-label={t('groups.contactType')}
                      component={InputDropdown}
                      label={labelHandler(t('groups.contactType'), true)}
                      name={`${name}.contact.contactType`}
                      options={CONTACT_TYPE}
                      searchable={false}
                      validate={required}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Field
                      aria-label={t('groups.contactNumber')}
                      component={InputMasked}
                      label={labelHandler(t('groups.contactNumber'), true)}
                      mask={phoneNumberMask}
                      name={`${name}.contact.phoneNumber`}
                      validate={composeValidations(required, phoneNumber)}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Box
                      p={4}
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        width: '100%',
                      }}
                    >
                      {index > 0 && (
                        <ToolbarButton
                          aria-label="close"
                          bg="error"
                          buttonSx={{
                            borderRadius: '50%',
                            height: '30px',
                            width: '30px',
                          }}
                          icon="close"
                          iconSx={{
                            height: '20px',
                            width: '20px',
                          }}
                          onClick={() => fields.remove(index)}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
            {meta.error && meta.touched && !isArray(meta.error) && (
              <Box
                sx={{
                  color: 'error',
                  fontSize: 2,
                  mt: 1,
                }}
              >
                {meta.error}
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="center" mt={6}>
            <ToolbarButton
              bg="accentLight"
              icon="add"
              iconSx={{
                height: '20px',
                mr: [0, null, null, 1],
                width: '20px',
              }}
              label={t('groups.addAddress')}
              onClick={() => fields.push({})}
            />
          </Box>
        </>
      )}
    </FieldArray>
  );
};
GroupLocationsFormSection.defaultProps = {
  isStateEditing: false,
};

GroupLocationsFormSection.propTypes = {
  isStateEditing: PropTypes.bool,
};
export default GroupLocationsFormSection;
