import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from '@reach/router';
import { get } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { required } from "@src/utilities/validators";
import Route from "@petcomponents/Route";
import remoteActionQuery from "@queries/remote-action.gql";
import { labelHandler } from "@src/utilities/label-utils";
import ToolbarButton from "@basecomponents/ToolbarButton";
import useSnackbar from "@src/utilities/use-snackbar";
import InputDate from "@basecomponents/InputDate";
import downloadData from "@src/utilities/download-file";
import config from "@src/config.json";
import InputDropdown from "@basecomponents/Dropdown";
import dateUtils from "@src/utilities/date-utils";

/**
 * @category Billing
 * @param {string} type
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const AgingReport = ({ type, ...rest }) => {
  const { t } = useTranslation()
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });

  return (
    <Route
      header={{
        title:
          type === 'terminatePetReport'
            ? 'Download Terminate Pet Report'
            : 'Download Aging Report',
        type: 'billing',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {type === 'terminatePetReport'
          ? 'Terminate Pet Report'
          : 'Aging Report'}
      </Box>
      <ApolloConsumer>
        {(client) => {
          const getAgingReport = async (values) => {
            // let fixedDate = new Date(values.selectedDate.getTime());
            // fixedDate = dateUtils.setOffset(fixedDate, true);

            const fixedDate = `${dateUtils.setAPIDateOnly(
              values.selectedDate
            )} 00:00:00`;
            const relativeDate = dateUtils.setAPIDateOnly(values.selectedDate);
            await client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name:
                    type === 'terminatePetReport'
                      ? 'terminated-pets-report-job'
                      : 'download-aging-report',
                  params: JSON.stringify(
                    type === 'terminatePetReport'
                      ? { relativeDate }
                      : {
                          reportType: values.reportType,
                          selectedDate: fixedDate,
                        }
                  ),
                },
              })
              .then((response) => {
                const results = JSON.parse(
                  get(response, 'data.remoteAction.data', '{}')
                );
                downloadData({
                  s3BucketName: config.amplify.Storage.AWSS3.bucket,
                  s3KeyName:
                    type === 'terminatePetReport'
                      ? results[0]
                      : get(results, 's3Key', ''),
                })
                  .then(() => {
                    setSuccessSnack(
                      `${
                        type === 'terminatePetReport'
                          ? 'Terminated pets'
                          : 'Aging'
                      } Report successfully downloaded`,
                      config.notificationDuration
                    );
                  })
                  .catch(() => {
                    setErrorSnack(
                      `No aging report found`,
                      config.notificationDuration
                    );
                  });
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error downloading the report: ${e.message}`,
                  config.notificationDuration
                )
              );
          };

          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                onSubmit={getAgingReport}
                render={({ handleSubmit, submitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          padding: 3,
                        }}
                      >
                        <Box flex="1" justifyContent="center">
                          {type !== 'terminatePetReport' && (
                            <Field
                              aria-label={t('component.reportType')}
                              component={InputDropdown}
                              label={labelHandler(t('component.reportType'), false)}
                              name="reportType"
                              options={[
                                {
                                  canEnglishValue: null, 
                                  frenchValue: 'Rapport sur le vieillissement de groupe',
                                  label: 'Group Aging Report',
                                  value: 'AUTO_GENERATED_GROUP_AGING',
                                },
                                // {
                                //   label: 'Employee Aging Report',
                                //   value: 'AUTO_GENERATED_AGING',
                                // },
                              ]}
                              validate={required}
                              wrapperSx={{ ...commonFieldSxHalf }}
                              {...rest}
                            />
                          )}
                          <Field
                            aria-label="Version Date"
                            component={InputDate}
                            label={labelHandler('Version Date', true)}
                            name="selectedDate"
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          <ToolbarButton
                            bg="accentLight"
                            isDisabled={submitting}
                            justifyContent="center"
                            label="Download"
                            m="20px auto 0"
                            px="0"
                            submitting={submitting}
                            sx={{ width: '150px' }}
                            type="submit"
                          />

                          <ToolbarButton
                            bg="accentDark"
                            label="Done"
                            m="20px auto"
                            onClick={() => navigate(`/billing/dashboard`)}
                            sx={{ width: '150px' }}
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};
AgingReport.defaultProps = {
  type: '',
};
AgingReport.propTypes = {
  location: PropTypes.shape({}).isRequired,
  type: PropTypes.string,
};

export default AgingReport;
