import React, { useContext, useState } from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import { ApolloConsumer } from '@apollo/client';
import { PulseLoader } from 'react-spinners';
import { get } from 'lodash';
import { useParams } from '@reach/router';
import { ThemeContext } from 'styled-components';
import { AuthContext } from '@basecomponents/Auth';
import { useTranslation } from 'react-i18next';
import numberWithCommas from "@src/utilities/number-with-commas";
import Icon from "@src/components/Icon";
import CoverageField from "@petcomponents/GroupEmployees/Upsert/CoverageTab/CoverageField";
import remoteActionQuery from "@queries/remote-action.gql";
import dateUtils from "@src/utilities/date-utils";
import GetData from "@src/utilities/get-data";

/**
 * @category Employee
 * @param {Array<any>} dependent
 * @param {string} petBackgroundColor
 * @param {function} refetch
 * @param {boolean} staticView
 * @param {boolean} showEmployerContribution
 * @returns {React.FC}
 */
const SelectablePetPremium = ({
  dependent,
  petBackgroundColor,
  refetch,
  staticView,
  showEmployerContribution,
  disabledClick,
}) => {
  const {
    payPeriodPremiumEmployer,
    payPeriodPremiumEmployee,
    dependentId,
    petName,
    status,
  } = dependent;
  let selected = status === 'PENDING';
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const queryParams = useParams();
  const theme = useContext(ThemeContext);
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const employeeId = get(queryParams, 'employeeId', '');
  const { apiData: employmentInfo } = GetData(
    'get-employment-info',
    JSON.stringify({
      employeeId,
    })
  );
  const employeeDetails = get(employmentInfo, '[0].employees', {});
  const currentDate =
    get(employeeDetails, 'relativeDatetime') === null
      ? dateUtils.getLocaleDateOnly(new Date())
      : dateUtils.setOffset2(get(employeeDetails, 'relativeDatetime'));
  return (
    <ApolloConsumer>
      {(client) => {
        const updateEmpDependent = () => {
          setLoading(true);
          const params = {
            dependentId,
            removalDate: dateUtils.setAPIDateOnly(currentDate),
            status: selected ? 'PENDING' : 'INACTIVE',
            userId,
          };

          return client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'update-employee-dependent',
                params: JSON.stringify(params),
              },
            })
            .then(() => refetch())
            .then(() => setTimeout(() => setLoading(false), 2000))
            .catch(() => setLoading(false));
        };
        return (
          <Box
            bg={petBackgroundColor}
            onClick={(e) => {
              if (!staticView && !loading && !disabledClick) {
                e.stopPropagation();
                selected = !selected;
                updateEmpDependent();
              }
            }}
            sx={{
              borderColor: 'primary',
              borderRadius: 4,
              borderSize: '1px',
              borderStyle: 'solid',
              cursor:
                (staticView && loading) || disabledClick
                  ? 'inherit'
                  : 'pointer',
              display: 'flex',
              flexDirection: 'column',
              m: 2,
              padding: 3,
              pointerEvents: loading || disabledClick ? 'none' : null,
            }}
          >
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              sx={{ margin: '0px 0px 0px 5px' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontWeight: 'bold',
                }}
              >
                {petName}
              </Box>
              {loading && (
                <PulseLoader
                  color={get(theme, 'colors.accent')}
                  css={{
                    alignItem: 'end',
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                  size={5}
                />
              )}
              {!staticView && !loading && (
                <Icon
                  svg={selected || disabledClick ? 'check' : 'add'}
                  sx={{
                    color: 'primary',
                    height: '30px',
                    mr: 4,
                    width: '30px',
                  }}
                />
              )}
            </Box>
            {showEmployerContribution && (
              <CoverageField
                label={t('groupEmployees.enrollmentPage.employerContribution')}
                value={`$${parseFloat(
                  numberWithCommas(payPeriodPremiumEmployer)
                ).toFixed(2)} ${t(
                      'groupEmployees.enrollmentPage.addPetSection.perPayCycle'
                    )}`}
              />
            )}
            <CoverageField
              label={t('groupEmployees.enrollmentPage.yourPremium')}
              value={`$${parseFloat(
                numberWithCommas(payPeriodPremiumEmployee)
              ).toFixed(2)} ${t(
                      'groupEmployees.enrollmentPage.addPetSection.perPayCycle'
                    )}`}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

SelectablePetPremium.propTypes = {
  dependent: PropTypes.arrayOf().isRequired,
  disabledClick: PropTypes.bool,
  petBackgroundColor: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  showEmployerContribution: PropTypes.bool,
  staticView: PropTypes.bool,
};

SelectablePetPremium.defaultProps = {
  disabledClick: false,
  showEmployerContribution: false,
  staticView: false,
};

export default SelectablePetPremium;
