import React, { useEffect, useState } from 'react';
import Box from '@basecomponents/Box';
import { pdf } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';
import { ApolloConsumer } from '@apollo/client';
import { get } from 'lodash';
import { useParams } from '@reach/router';
import remoteActionQuery from "@queries/remote-action.gql";
import PDFViewer from "@petcomponents/PDFViewer";
import Spinner from "@basecomponents/Spinner";
import useSnackbar from "@src/utilities/use-snackbar";
import config from "@src/config.json";

/**
 * @category Components
 * @param {React.ReactNode} children React child to render
 * @param {string} groupNumber Group number
 * @param {string} planChoiceId
 * @param {string} planId
 * @param {string} product
 * @returns {React.FC}
 */
const GeneratedPDFViewer = ({
  children,
  groupNumber,
  planChoiceId,
  planId,
  product,
}) => {
  const productName = product?.replace(/\s+/g, '-').toLowerCase();
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const queryParams = useParams();
  const [loading, setLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState(null);
  const groupId = get(queryParams, 'groupId', '');

  useEffect(() => {
    const child = React.Children.only(children);
    pdf(child)
      .toBlob()
      .then(async (blob) => {
        try {
          await Storage.get(
            `${groupNumber}/${planId}/${productName}-master-policy.pdf`,
            { download: true }
          )
            .then(async (response) => {
              if (response) {
                // Need to remove this code after font issue of pdf file is resolved
                await Storage.put(
                  `${groupNumber}/${planId}/${productName}-master-policy.pdf`,
                  blob,
                  {
                    contentType: 'application/pdf',
                  }
                );
              }
            })
            .catch(async () => {
              await Storage.put(
                `${groupNumber}/${planId}/${productName}-master-policy.pdf`,
                blob,
                {
                  contentType: 'application/pdf',
                }
              );
            });
        } catch (error) {
          setErrorSnack(
            `There was an error: ${error}`,
            config.notificationDuration
          );
        }
        return blob;
      })
      .then((blob) => setPdfUrl(URL.createObjectURL(blob)))
      .then(() => setLoading(false));
  }, [children]);

  return (
    <ApolloConsumer>
      {(client) => {
        const refreshList = () => {
          const apiCall = (pathName, params) =>
            client.query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: pathName,
                params: JSON.stringify(params),
              },
            });
          if (groupId) {
            const params = JSON.stringify({
              groupId,
              name: `${productName} Master Policy`,
              s3Key: `${groupNumber}/${planId}/${productName}-master-policy.pdf`,
            });
            apiCall('set-file', params);
          }
          if (planChoiceId) {
            const planChoiceData = {
              id: planChoiceId,
              masterPolicyPath: `${groupNumber}/${planId}/${productName}-master-policy.pdf`,
            };
            apiCall('update-master-policy', planChoiceData);
          }
        };
        if (loading) {
          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Spinner />
            </Box>
          );
        }
        if (!loading) {
          refreshList();
          return <PDFViewer file={pdfUrl} />;
        }
      }}
    </ApolloConsumer>
  );
};

GeneratedPDFViewer.propTypes = {
  children: PropTypes.node.isRequired,
  groupNumber: PropTypes.string.isRequired,
  planChoiceId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
};

export default GeneratedPDFViewer;
