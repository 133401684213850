import React, {useState} from 'react';
import {Field} from 'react-final-form';
import {get, isEmpty, reject} from 'lodash';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import useSsm from '@src/utilities/hooks/useSsm';
import InputDropdown from "@basecomponents/Dropdown";
import InputCheckbox from "@basecomponents/InputCheckbox";
import InputText from "@basecomponents/Input";
import AddressFormSection from "@petcomponents/AddressFormSection";
import InputMasked from "@basecomponents/InputMasked";
import {labelHandler} from "@src/utilities/label-utils";
import {
    billingAccountNumberMask,
    billingRoutingNumberMask,
    institutionNumberMask,
    phoneNumberMask,
    transitNumberMask,
} from "@src/utilities/masks";
import {
    billDaysPriorToPayroll,
    characterLimit,
    composeValidations,
    legalInstitutionNumber,
    legalRoutingNumber,
    legalTransitNumber,
    phoneNumber,
    required,
} from "@src/utilities/validations";
import InputDate from "@basecomponents/InputDate";
import GetData from "@src/utilities/get-data";
import Modal from "@basecomponents/Modal";
import Card from "@basecomponents/Card";
import KeyValuePairs from "@basecomponents/KeyValuePairs";
import getMetaData from "@src/utilities/get-meta-data";
import ToolbarButton from "@basecomponents/ToolbarButton";
import config from "@src/config.json";
import InputNumber from "@basecomponents/InputNumber";
import dateUtils from "@src/utilities/date-utils";

/**
 * @category Group
 * @param {string} accountNumber
 * @param {Object} values
 * @param {(Object|string)} billingAddress
 * @param {function} change
 * @param {string} groupStatus
 * @param {Object} billingInfoResponse
 * @param {*} rest
 * @returns {React.FC}
 */
const BillingFormSection = ({
  accountNumber,
  values,
  billingAddress,
  change,
  groupStatus,
  billingInfoResponse,
  isRenewal,
  ...rest
}) => {
  const [showAchFields, setShowAchFields] = useState(false);
  const [showAccountDetails, setAccountDetails] = useState(false);
  const [showModal, setModal] = useState(false);
  const commonFieldSxHalf = {
    padding: 3,
    width: ['23rem', null, '25rem'],
  };
  const chaseEnabled = useSsm('iaic-chase-enabled')
  const {
    apiData: {
      // eslint-disable-next-line prefer-const
      BillingAccountType = [],
      // eslint-disable-next-line prefer-const
      BillingType = [],
      PayrollCycleFrequency = [],
      BillingCycle = [],
    },
  } = GetData(
    'get-enum-options',
    JSON.stringify([
      {
        params: [
          ' BillingAccountType',
          'BillingType',
          'PayrollCycleFrequency',
          'BillingCycle',
        ],
      },
    ])
  );

  const { t } = useTranslation();

  const isValidDate = (date) =>
    date instanceof Date && !Number.isNaN(date.getTime());

  const billingMetaData = getMetaData({ moduleName: 'billing' });
  return (
    <>
      <Field
        aria-label="Use company address for billing address"
        component={InputCheckbox}
        label="Use company address for billing address"
        name="billingAddress"
        type="checkbox"
        wrapperSx={{
          display: 'flex',
          ...commonFieldSxHalf,
        }}
      />
      {get(values, 'billingAddress', false) && billingAddress ? (
        <Box sx={{ paddingTop: '1rem' }}>
          <Box
            sx={{
              fontWeight: 'bold',
              mx: 3,
              paddingBottom: 3,
            }}
          >
            {t('groups.billingAddress')}:
          </Box>
          <Box
            sx={{
              ...commonFieldSxHalf,
              py: [null, 3, null, 3],
            }}
          >
            {billingAddress.addressLine1}, {billingAddress.addressLine2},{' '}
            {billingAddress.city}, {billingAddress.state},{' '}
            {billingAddress.country}, {billingAddress.zipcode}
          </Box>
        </Box>
      ) : (
        <AddressFormSection
          label="billingInfo"
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      )}
      <Field
        aria-label={t('groupEmployees.enrollmentPage.phoneNumber')}
        component={InputMasked}
        label={labelHandler(
          t('groupEmployees.enrollmentPage.phoneNumber'),
          true
        )}
        mask={phoneNumberMask}
        name="phoneNumber"
        validate={composeValidations(required, phoneNumber)}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      <Field
        aria-label={t('groups.billingAccountName')}
        component={InputText}
        disabled={billingInfoResponse.accountName}
        label={labelHandler(t('groups.billingAccountName'), true)}
        name="accountName"
        validate={required}
        wrapperSx={{ ...commonFieldSxHalf }}
        {...rest}
      />
      {!showAccountDetails && accountNumber && (
        <Box
          sx={{
            borderRadius: 3,
            boxShadow: 7,
            m: 2,
            ...commonFieldSxHalf,
          }}
        >
          <Card>
            <KeyValuePairs
              columnCount={1}
              data={{ ...values, accountNumber }}
              fields={[
                { name: 'accountType' },
                { name: 'accountNumber' },
                { name: 'billingType' },
                { name: 'attnLine' },
                { name: 'contractNumber' },
                { name: 'poNumber' },
              ]}
              meta={billingMetaData}
            />
            <ToolbarButton
              bg="error"
              icon="edit"
              label="Change Account Details"
              m="0 auto"
              onClick={() => setModal(true)}
              sx={{ paddingLeft: '10px' }}
            />
          </Card>
        </Box>
      )}
      {(showAchFields || (!showAccountDetails && !accountNumber)) && (
        <>
          <Field
            aria-label={t('groups.paymentMethod')}
            component={InputDropdown}
            label={labelHandler(t('groups.paymentMethod'), true)}
            name="accountType"
            onChange={(value) => {
              if (value === 'ACH_AUTOMATIC') {
                setShowAchFields(true);
              } else {
                setShowAchFields(false);
                change('accountNumber', null);
                change('routingNumber', null);
              }
            }}
            options={
              chaseEnabled
                ? BillingAccountType
                : reject(BillingAccountType, (v) => v.value === 'ACH_AUTOMATIC')
            }
            validate={required}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          />
          <Field
            aria-label={t('groups.billingType')}
            component={InputDropdown}
            label={labelHandler(t('groups.billingType'), true)}
            name="billingType"
            options={BillingType}
            validate={required}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          />
          <Field
            aria-label="Contract Number"
            component={InputText}
            label={labelHandler('Contract Number', false)}
            name="contractNumber"
            validate={composeValidations(characterLimit)}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          />
          <Field
            aria-label="PO Number"
            component={InputText}
            label={labelHandler('PO Number', false)}
            name="poNumber"
            validate={composeValidations(characterLimit)}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          />
          <Field
            aria-label="ATTN Line"
            component={InputText}
            label={labelHandler('ATTN Line', false)}
            name="attnLine"
            validate={composeValidations(characterLimit)}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          />
        </>
      )}
      {showAchFields && (
        <>
          {config.canadaEnv ? (
            <>
              <Field
                aria-label="Institution Number"
                component={InputMasked}
                label={labelHandler(
                  t('claimsScreen.bankingInfo.institutionNumber'),
                  true
                )}
                mask={institutionNumberMask}
                maxLength={16}
                name="institutionNumber"
                type="InputMasked"
                validate={composeValidations(required, legalInstitutionNumber)}
                {...rest}
              />
              <Field
                aria-label="Transit Number"
                component={InputMasked}
                label={labelHandler(
                  t('claimsScreen.bankingInfo.transitNumber'),
                  true
                )}
                mask={transitNumberMask}
                maxLength={16}
                name="transitNumber"
                type="InputMasked"
                validate={composeValidations(required, legalTransitNumber)}
                {...rest}
              />
            </>
          ) : (
            <Field
              component={InputMasked}
              label={labelHandler('Billing Routing Number', true)}
              mask={billingRoutingNumberMask}
              name="routingNumber"
              type="tel"
              validate={composeValidations(required, legalRoutingNumber)}
              wrapperSx={{ ...commonFieldSxHalf }}
              {...rest}
            />
          )}
          <Field
            component={InputMasked}
            label={labelHandler('Billing Account Number', true)}
            mask={billingAccountNumberMask}
            name="accountNumber"
            type="tel"
            validate={required}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          />
        </>
      )}
      {showModal && (
        <Modal
          closeText={t('common.no')}
          isOpen={showModal}
          onClose={() => {
            setModal(false);
          }}
          onSubmit={() => {
            setShowAchFields(true);
            setAccountDetails(true);
            setModal(false);
          }}
          submitText={t('common.yes')}
          title="Change Account"
        >
          <Box>Do you want to change account details?</Box>
        </Modal>
      )}
      <Box
        mt={6}
        sx={{
          borderRadius: 3,
          boxShadow: 7,
          m: 2,
        }}
      >
        <Box sx={{ my: 4 }}>
          <Box
            sx={{
              ...commonFieldSxHalf,
              fontSize: 3,
              fontWeight: 'bold',
              pb: 0,
            }}
          >
            {t('groups.payrollInformation')}
          </Box>
          <Field
            aria-label={t('groups.billingCycle')}
            component={InputDropdown}
            disabled={groupStatus === 'ACTIVE'}
            label={labelHandler(t('groups.billingCycle'), true)}
            name="billingCycle"
            onChange={(value) => {
              if (value === '12') {
                change('billDaysPriorToPayroll', '0');
              } else {
                change('billDaysPriorToPayroll', '0');
                change('firstBillCreationDate', null);
                change('firstDueDate', null);
              }
            }}
            options={BillingCycle}
            searchable={false}
            validate={required}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          />
          <Field
            aria-label={t('metadata.payrollFrequency')}
            component={InputDropdown}
            defaultValue=""
            disabled={groupStatus === 'ACTIVE'}
            label={labelHandler(t('metadata.payrollFrequency'), true)}
            name="payrollFrequency"
            options={PayrollCycleFrequency}
            searchable={false}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          />
          <Field
            aria-label={t('groups.firstCoverageStartDate')}
            component={InputDate}
            disabled={groupStatus === 'ACTIVE'}
            label={labelHandler(t('groups.firstCoverageStartDate'), true)}
            name="firstCoverageDate"
            validate={required}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          />
          <Field
            aria-label={t('groups.firstPayrollStartDate')}
            component={InputDate}
            disabled={groupStatus === 'ACTIVE'}
            label={labelHandler(t('groups.firstPayrollStartDate'), true)}
            name="firstPayrollDate"
            validate={required}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          />
          {get(values, 'billingCycle') === '12' ? (
            <>
              <Field
                aria-label="First Bill Generation Date"
                component={InputDate}
                disabled={
                  groupStatus === 'ACTIVE' ||
                  !isValidDate(
                    dateUtils.setOffset2(get(values, 'firstCoverageDate'))
                  )
                }
                label={labelHandler('First Bill Generation Date', true)}
                maxDate={
                  isValidDate(get(values, 'firstCoverageDate'))
                    ? dateUtils.addDays(get(values, 'firstCoverageDate'), 40)
                    : null
                }
                minDate={
                  isValidDate(get(values, 'firstCoverageDate'))
                    ? dateUtils.subDays(get(values, 'firstCoverageDate'), 25)
                    : null
                }
                name="firstBillCreationDate"
                validate={required}
                wrapperSx={{ ...commonFieldSxHalf }}
                {...rest}
              />
              <Field
                aria-label="First Bill Due Date"
                component={InputDate}
                disabled={
                  groupStatus === 'ACTIVE' ||
                  !isValidDate(get(values, 'firstBillCreationDate'))
                }
                label={labelHandler('First Bill Due Date', true)}
                maxDate={
                  isValidDate(get(values, 'firstBillCreationDate'))
                    ? dateUtils.addDays(
                        get(values, 'firstBillCreationDate'),
                        80
                      )
                    : null
                }
                minDate={
                  isValidDate(get(values, 'firstBillCreationDate'))
                    ? dateUtils.addDays(get(values, 'firstBillCreationDate'), 1)
                    : null
                }
                name="firstDueDate"
                validate={required}
                wrapperSx={{ ...commonFieldSxHalf }}
                {...rest}
              />
            </>
          ) : (
            <>
              <Field
                aria-label={t('groups.billDaysPriorToPayroll')}
                component={InputNumber}
                disabled={groupStatus === 'ACTIVE'}
                label={labelHandler(t('groups.billDaysPriorToPayroll'), true)}
                name="billDaysPriorToPayroll"
                validate={composeValidations(required, billDaysPriorToPayroll)}
                wrapperSx={{ ...commonFieldSxHalf }}
                {...rest}
              />
            </>
          )}
          {/* <Field
            aria-label="Bill Days Prior To Payroll"
            component={InputNumber}
            disabled={groupStatus === 'ACTIVE'}
            label={labelHandler('Bill Days Prior To Payroll', true)}
            name="billDaysPriorToPayroll"
            validate={composeValidations(required, billDaysPriorToPayroll)}
            wrapperSx={{ ...commonFieldSxHalf }}
            {...rest}
          /> */}
        </Box>
      </Box>
    </>
  );
};

BillingFormSection.propTypes = {
  accountNumber: PropTypes.string,
  billingAddress: PropTypes.oneOfType([
    PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      state: PropTypes.string,
      zipcode: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  billingInfoResponse: PropTypes.shape({ accountName: PropTypes.string }),
  change: PropTypes.func,
  groupStatus: PropTypes.string.isRequired,
  isRenewal: PropTypes.bool,
  values: PropTypes.shape({}),
};

BillingFormSection.defaultProps = {
  accountNumber: null,
  billingAddress: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    state: '',
    zipcode: '',
  },
  billingInfoResponse: { accountName: '' },
  change: () => {},
  isRenewal: false,
  values: {},
};
export default BillingFormSection;
