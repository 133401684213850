import { get } from 'lodash';
import config from "@src/config.json";
import {
  composeValidations,
  required,
} from "@src/utilities/validators";
import {CA_PROVINCES, CONTACT_TYPE, US_STATES} from "@src/utilities/constants";
import { phoneNumberMask } from "@src/utilities/masks";
import { zipcode } from "@src/utilities/validations";

const locations = {
  fields: [
    {
      label: 'Location Name',
      name: 'name',
      type: 'Input',
      validate: required,
    },
    {
      label: 'Address Line 1',
      name: 'address.addressLine1',
      type: 'Input',
      validate: required,
    },
    {
      label: 'Address Line 2',
      name: 'address.addressLine2',
      type: 'Input',
    },
    {
      label: 'City',
      name: 'address.city',
      type: 'Input',
      validate: required,
    },
    {
      label: config.canadaEnv ? "Provinces" : "State",
      name: 'address.state',
      options: config.canadaEnv ? CA_PROVINCES  :US_STATES,
      type: 'Dropdown',
      validate: required,
    },
    {
      label: 'Zip Code',
      maxLength: 5,
      name: 'address.zipcode',
      type: 'Input',
      validate: composeValidations(required, zipcode),
    },
    {
      label: 'Contact Type',
      name: 'contact.contactType',
      options: CONTACT_TYPE,
      type: 'Dropdown',
      validate: required,
    },
    {
      label: 'Phonne Number',
      mask: phoneNumberMask,
      name: 'contact.phoneNumber',
      type: 'InputMasked',
      validate: required,
    },
  ],
  name: 'locations',
  sectionOperations: {
    canSkip: ({ stateValues }) => get(stateValues, 'groupId'),
    getCardTitle: ({ rowValues }) =>
      get(rowValues, 'isSitus') ? 'Situs Address' : 'Branch Address',
    isRemoveable: ({ rowValues }) => get(rowValues, 'locationId'),
    isRemoveDisabled: ({ rowValues }) => get(rowValues, 'isSitus'),
  },
  title: 'Locations',
  type: 'List',
};

export default locations;
