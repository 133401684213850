import Box from '@basecomponents/Box';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {ThemeContext} from 'styled-components';
import {v4 as uuid} from 'uuid';
import TooltipBox from '@basecomponents/TooltipBox';
import { useTranslation } from 'react-i18next';
import config from '@src/config.json';
import ToolbarButton from "@basecomponents/ToolbarButton";
import Variant from "@petcomponents/Variant";
import {LS_CELL_DENSITY, useCellDensities} from "@basecomponents/ListGrid/utils/constants";
import localStorage from "@src/utilities/local-storage";
import EditAccessRightsContext from "@petcomponents/EditAccessRights/Context";

const getTableData = (data, dataField) => {
    if (dataField) {
        const arr = get(data, dataField);
        return Array.isArray(arr) ? arr : [];
    }
    return Array.isArray(data) ? data : [];
};
/**
 * @category BaseComponents
 * @param {Array<Object>} data
 * @param {string} dataField
 * @param {function} getExpandedSection
 * @param {Object} grid
 * @param {Object} gridState
 * @param {Object} meta
 * @param {function} refetch
 * @param {boolean} isHighContrastOn
 * @param {boolean} isResponsive
 * @param {Object} listItemSx
 * @param {boolean} isExpandedSection
 * @param {boolean} isExpandedSectionMore
 * @returns {React.FC}
 */
const ListItem = ({
                      data,
                      dataField,
                      filterValues,
                      getExpandedSection,
                      grid,
                      gridState: {cellDensity},
                      meta,
                      refetch,
                      isHighContrastOn,
                      isResponsive,
                      listItemSx,
                      isExpandedSection,
                      isExpandedSectionMore,
                  }) => {
    const {t} = useTranslation()
    const cellDensities = useCellDensities()
    const theme = useContext(ThemeContext);
    const id = uuid();
    const lsCellDensity = localStorage.getItem(LS_CELL_DENSITY);
    const ear = useContext(EditAccessRightsContext);
    const [expandedRows, setExpandedRows] = useState([]);
    const individualLengthFactor = getTableData(data, dataField).map(
        (rowData) => {
            return get(grid, 'fields', []).map(({name}) => {
                const metaType = get(meta, `${name}.type`, 'string');
                if (metaType === 'name') {
                    return `${get(rowData, `${name}.firstName`, '')} ${get(
                        rowData,
                        `${name}.lastName`,
                        ''
                    )}`.length;
                }
                if (metaType === 'date') {
                    return 1;
                }
                if (metaType === 'moduleLink') {
                    return 2;
                }
                if (typeof rowData[name] === 'string') {
                    return get(rowData, `${name}`, '').length;
                }
                return 1;
            });
        }
    );

    const columnWidthFactor = get(individualLengthFactor, '0', [])
        .map((_, index) => individualLengthFactor.map((row) => row[index]))
        .map((lengthArray) => Math.max.apply(null, lengthArray));
    return (
        <>
            {data?.length ? (
                <Box
                    key={id}
                    height="100%"
                    sx={{
                        width: 'auto',
                    }}
                >
                    {getTableData(data, dataField).map((rowData, rowIx) => {
                        const rowActions = get(grid, 'actions', []);
                        const rowUIData = get(grid, 'fields', [])
                            .filter((field) => get(field, 'visible', true))
                            .map(({name, label, dynamicLabel, ...gridMeta}, rowDataIndex) => {
                                const calculatedItemWidth =
                                    get(
                                        cellDensities.find(({id}) =>
                                            cellDensity ? id === cellDensity : id === lsCellDensity
                                        ),
                                        'widthFactor',
                                        1
                                    ) * columnWidthFactor[rowDataIndex];
                                let itemWidth = '';
                                if (calculatedItemWidth < 150) {
                                    itemWidth = '150px';
                                }
                                if (calculatedItemWidth > 150 && calculatedItemWidth < 290) {
                                    itemWidth = `${calculatedItemWidth}px`;
                                }
                                if (calculatedItemWidth > 300) {
                                    itemWidth = '300px';
                                }
                                return (
                                    <Box
                                        key={`kvp-${rowIx}-${rowDataIndex}`}
                                        aria-label={dynamicLabel || label || get(meta, `${name}.label`, `#${name}`)}
                                        color="primary"
                                        fontSize={2}
                                        my={1}
                                        sx={{
                                            px: 3,
                                            py: get(
                                                cellDensities.find(({id}) =>
                                                    cellDensity
                                                        ? id === cellDensity
                                                        : id === lsCellDensity
                                                ),
                                                'py',
                                                5
                                            ),
                                            width: itemWidth,
                                        }}
                                    >
                                        <Box
                                            color="primary"
                                            fontSize={2}
                                            my={1}
                                            sx={{textTransform: 'uppercase'}}
                                        >
                                            {dynamicLabel || label || get(meta, `${name}.label`, `#${name}`)}
                                        </Box>
                                        <Box color="primaryDark" fontSize={3} fontWeight="bold">
                                            <Variant
                                                key={`row-${rowIx}-field-${name}-${rowDataIndex}`}
                                                data={rowData}
                                                filterValues={filterValues}
                                                gridMeta={gridMeta}
                                                meta={meta}
                                                name={name}
                                            />
                                        </Box>
                                    </Box>
                                );
                            });
                        if (rowActions.length > 0 || isExpandedSection) {
                            const actionUI = [];
                            let toggledColor = false;
                            rowActions.forEach((action, ix) => {
                                const {getProps, label, permission} = action;
                                const uniqueId = uuid();

                                const earVisible = permission
                                    ? ear.isVisible(permission)
                                    : true;

                                if (earVisible) {
                                    const buttonProps = getProps(rowData, refetch);
                                    const {visible = true} = buttonProps;
                                    if (visible) {
                                        actionUI.push(
                                            <>
                                                <Box
                                                    key={`action-${ix}`}
                                                    data-for={uniqueId}
                                                    data-tip={label}
                                                    mx={
                                                        (ix === 0 || ix === rowActions.length - 1) &&
                                                        rowActions.length !== 2
                                                            ? 0
                                                            : 2
                                                    }
                                                >
                                                    <ToolbarButton
                                                        aria-label={label}
                                                        bg={(() => {
                                                            toggledColor = !toggledColor;
                                                            return toggledColor
                                                                ? 'primary'
                                                                : 'accentSecondary';
                                                        })()}
                                                        permission={permission}
                                                        {...buttonProps}
                                                    />
                                                </Box>
                                                <TooltipBox
                                                    id={uniqueId}
                                                    tooltipProps={{effect: 'solid'}}
                                                />
                                            </>
                                        );
                                    }
                                }
                            });
                            if (getExpandedSection) {
                                const uniqueId = uuid();

                                actionUI.push(
                                    <Box>
                                        <Box
                                            data-for={uniqueId}
                                            data-tip={`${expandedRows.includes(rowIx)
                                                ? t('component.showLessDetails')
                                                : t('component.showMoreDetails')
                                            }`}
                                            mx={2}
                                        >
                                            <ToolbarButton
                                                aria-label={`${expandedRows.includes(rowIx)
                                                    ? t('component.showLessDetails')
                                                    : t('component.showMoreDetails')
                                                }`}
                                                bg={expandedRows.includes(rowIx) ? 'accent' : 'primary'}
                                                icon="kebab-menu"
                                                iconSx={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                                onClick={() => {
                                                    if (expandedRows.includes(rowIx)) {
                                                        const updatedRowIndex = [...expandedRows];
                                                        const index = updatedRowIndex.findIndex(
                                                            (v) => v === rowIx
                                                        );
                                                        updatedRowIndex.splice(index, 1);
                                                        setExpandedRows(updatedRowIndex);
                                                    } else {
                                                        const updatedRowIndex = [...expandedRows];
                                                        updatedRowIndex.push(rowIx);
                                                        setExpandedRows(updatedRowIndex);
                                                    }
                                                }}
                                            />
                                        </Box>
                                        <TooltipBox
                                            id={uniqueId}
                                            tooltipProps={{effect: 'solid'}}
                                        />
                                    </Box>
                                );
                            }
                            rowUIData.push(
                                <Box
                                    key={`kvp-${rowIx}`}
                                    alignItems={isResponsive ? 'center' : 'flex-start'}
                                    display="flex"
                                    flexDirection="column"
                                    sx={{
                                        px: 3,
                                        py: get(
                                            cellDensities.find(({id}) =>
                                                cellDensity ? id === cellDensity : id === lsCellDensity
                                            ),
                                            'py',
                                            5
                                        ),
                                    }}
                                >
                                    <Box
                                        aria-label={t('component.actions')}
                                        color="primary"
                                        fontSize={2}
                                        my={1}
                                        sx={{textTransform: 'uppercase'}}
                                    >
                                        {t('component.actions')}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        {actionUI}
                                    </Box>
                                </Box>
                            );
                        }
                        const conditionalBorder = () => {
                            if (isHighContrastOn) {
                                return `3px solid ${theme.colors.accent}`;
                            }
                            if (expandedRows.includes(rowIx)) {
                                return '1px solid transparent';
                            }
                            return null;
                        };
                        return (
                            <Box
                                key={`list-${rowIx}`}
                                sx={{
                                    '&:hover': {
                                        boxShadow: `${expandedRows.includes(rowIx) ? 6 : null}`,
                                        transform: `${expandedRows.includes(rowIx) ? 'scale(1.01)' : null
                                        }`,
                                    },
                                    alignItems: 'center',
                                    bg: `${expandedRows.includes(rowIx) ? 'white' : 'transparent'
                                    }`,
                                    borderRadius: 2,
                                    justifyContent: 'flex-start',
                                    mb: `${expandedRows.includes(rowIx) ? 2 : 0}`,
                                    transition: 'all ease-in-out 0.25s',
                                }}
                            >
                                <Box
                                    key={`mainbox-key-${rowIx}`}
                                    sx={{
                                        '&:hover': {
                                            border: conditionalBorder(),
                                            boxShadow: `${expandedRows.includes(rowIx) ? null : 6}`,
                                            transform: `${expandedRows.includes(rowIx) ? null : 'scale(1.01)'
                                            }`,
                                        },
                                        bg: 'white',
                                        borderRadius: 5,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        justifyContent: `${isExpandedSection || isExpandedSectionMore
                                            ? 'center'
                                            : 'flex-start'
                                        }`,
                                        m: '0 auto',
                                        mt: get(
                                            cellDensities.find(({id}) =>
                                                cellDensity ? id === cellDensity : id === lsCellDensity
                                            ),
                                            'py',
                                            5
                                        ),
                                        px: 2,
                                        py: get(
                                            cellDensities.find(({id}) =>
                                                cellDensity ? id === cellDensity : id === lsCellDensity
                                            ),
                                            'py',
                                            5
                                        ),
                                        transition: 'all ease-in-out 0.25s',
                                        width: 'fit-content',
                                        ...listItemSx,
                                    }}
                                >
                                    {rowUIData}
                                </Box>
                                {expandedRows.includes(rowIx) &&
                                    getExpandedSection(rowData, rowIx)}
                            </Box>
                        );
                    })}
                </Box>
            ) : (
                <Box
                    sx={{
                        alignItems: 'center',
                        color: (config.canadaEnv ? 'primary' : 'accent'),
                        display: 'flex',
                        fontSize: 6,
                        fontWeight: 'bold',
                        height: '200px',
                        justifyContent: 'center',
                    }}
                >
                    {t('common.noResults')}
                </Box>
            )}
        </>
    );
};

ListItem.defaultProps = {
    data: [],
    dataField: '',
    filterValues: null,
    getExpandedSection: null,
    grid: {
        actions: [],
        fields: [],
    },
    gridState: {},
    isExpandedSection: false,
    isExpandedSectionMore: false,
    listItemSx: {},
    meta: {},
};

ListItem.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    dataField: PropTypes.string,
    filterValues: PropTypes.shape({}),
    getExpandedSection: PropTypes.func,
    grid: PropTypes.shape({
        actions: PropTypes.arrayOf(PropTypes.shape({})),
        fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    gridState: PropTypes.shape({
        cellDensity: PropTypes.string,
        sortDirection: PropTypes.string,
        sortField: PropTypes.string,
    }),
    isExpandedSection: PropTypes.bool,
    isExpandedSectionMore: PropTypes.bool,
    isHighContrastOn: PropTypes.bool.isRequired,
    isResponsive: PropTypes.bool.isRequired,
    listItemSx: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    refetch: PropTypes.func.isRequired,
};

export default ListItem;
