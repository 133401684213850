import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { get, omit, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { diff } from 'deep-object-diff';
import { useTranslation } from 'react-i18next';
import { required } from "@src/utilities/validators";
import remoteActionQuery from "@queries/remote-action.gql";
import AddContact from "@petcomponents/GroupContacts/AddContact";
import GetData from "@src/utilities/get-data";
import useSnackbar from "@src/utilities/use-snackbar";
import Spinner from "@basecomponents/Spinner";
import ToolbarButton from "@basecomponents/ToolbarButton";
import DashboardFilterForm from "@petcomponents/DashboardFilterForm";
import TableWrapper from "@basecomponents/TableWrapper";
import Table from "@basecomponents/Table";
import setDashboardURL from "@src/utilities/set-dashboard-url";
import DashboardPaginator from "@petcomponents/DashboardPaginator";
import dashboardConstants from "@src/utilities/dashboard-constants";
import listingConfig from "@src/utilities/listing-config";
import ButtonGroups from "@basecomponents/ButtonGroups";
import { labelHandler, labelUtils } from "@src/utilities/label-utils";
import { USER_ROLE } from "@src/utilities/constants";
import Dropdown from "@basecomponents/Dropdown";
import generateRedirectPath from "@src/utilities/generate-redirect-path";
import config from "@src/config.json";

/**
 * @category Group
 * @param {Object} location
 * @param {string} redirectTo
 * @param {*} rest
 * @returns {any}
 */
const GroupContacts = ({ location, redirectTo, isRenewal, ...rest }) => {
  const type = 'groupContactsSection';
  const queryParams = useParams();
  const [nextLoading, setNextLoading] = useState(false);
  const [editContactInfo, setEditContactInfo] = useState({});
  const groupId = get(queryParams, 'groupId', '');
  const name = 'step';
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const { t } = useTranslation();

  const wrapperSx = {
    m: 0,
    p: 3,
    width: '25rem',
  };
  const { filters, pageCriteria } = dashboardConstants.getCriterias({
    search: location?.search,
    type,
  });

  const query = dashboardConstants.getQuery({
    filters,
    page: pageCriteria,
  });

  const { apiData: groupData = {} } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );
  const { apiData, loading, refetch } = GetData(
    'get-all-administrator-by-group-id',
    JSON.stringify([...query, { groupId }]),
    !groupId
  );
  const {
    apiData: { ContactType = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: 'ContactType' }]),
    !groupId
  );

  const {
    apiData: brokerApiData,
    loading: brokerLoading,
    refetch: brokerRefetch,
  } = GetData(
    'get-all-subproducer-to-group',
    JSON.stringify([...query, { groupId }, { deleted: false }]),
    !groupId
  );
  const { content = [], totalElements = 0, numberOfElements = 0 } = apiData;
  const {
    content: brokerInfo = [],
    totalElements: brokerTotalElements = 0,
    numberOfElements: brokerNumberOfElements = 0,
  } = brokerApiData;
  const administrators = content.map((item) => item.user);
  const brokerList = brokerInfo.map((item) => item.subProducer);
  const list = listingConfig(type);
  const adminBrokerList = listingConfig('groupContactsBrokerSection');

  const getRowData = ({ data, key, isAdmin }) => {
    const { gridHeaders, actions } = isAdmin ? list : adminBrokerList;
    const row = gridHeaders.map((headerItem) => {
      const rowItem = get(data, `${headerItem.name}`, '-');
      const contactType =
        ContactType.find((item) => item.value === rowItem) || {};
      if (Object.keys(contactType).length) {
        return contactType.label;
      }
      return rowItem;
    });
    if (actions && actions.length > 0 && isAdmin) {
      const actionOptions = actions.map((actionItem) => {
        const { action } = actionItem;
        switch (action) {
          case 'edit':
            return (
              <ToolbarButton
                key={`${key}-edit`}
                aria-label="edit"
                bg="primary"
                icon="edit"
                onClick={() => {
                  const editUserInfo = {
                    adminContactType: 'ManualInput',
                    altContactType: get(data, 'contacts[1].contactType', ''),
                    altPhoneNumber: get(data, 'contacts[1].phoneNumber', ''),
                    contacts: get(data, 'contacts', []),
                    contactType: get(data, 'contacts[0].contactType', ''),
                    email: get(data, 'email', ''),
                    firstName: get(data, 'firstName', ''),
                    lastName: get(data, 'lastName', ''),
                    phoneNumber: get(data, 'contacts[0].phoneNumber', ''),
                    userId: get(data, 'userId', ''),
                  };
                  setEditContactInfo(editUserInfo);
                }}
                round
              />
            );
          default:
        }
        return null;
      });
      row.push(<ButtonGroups leftAlign>{actionOptions}</ButtonGroups>);
    }
    return row;
  };

  const getHeaders = ({ headers, isAdmin }) => {
    const { actions, gridHeaders } = headers;
    const result = gridHeaders.map(({ name, sortable }) => {
      const label = labelUtils({
        key: name,
        type: isAdmin ? type : 'groupContactsBrokerSection',
      });
      if (sortable) {
        return {
          label,
          value: Array.isArray(name) ? name.join('.') : name,
        };
      }
      return label;
    });
    if (actions && actions.length > 0) {
      result.push('Actions');
    }
    return result;
  };

  const renderEmployerTable = (isAdmin) => {
    const dataList = isAdmin ? administrators : brokerList;
    return (
      <Box
        sx={{
          bg: 'white',
          borderRadius: 2,
          my: 3,
          width: '100%',
        }}
      >
        <DashboardFilterForm
          filters={filters}
          page={pageCriteria}
          showFilters={false}
        />
        <TableWrapper>
          <Table
            header={getHeaders({
              headers: isAdmin ? list : adminBrokerList,
              isAdmin,
            })}
            onSortUpdate={() => {
              return navigate(
                setDashboardURL({
                  filters,
                  filterValues: filters.values,
                  page: pageCriteria,
                  pathname: location.pathname,
                }),
                { replace: true }
              );
            }}
            rows={dataList?.map((rowItem, index) =>
              getRowData({
                data: rowItem,
                isAdmin,
                key: index,
              })
            )}
            tableSx={{ fontSize: 2 }}
          />
          <DashboardPaginator
            filters={{
              page: pageCriteria.offset,
            }}
            goToNextPage={() =>
              navigate(
                setDashboardURL({
                  filters,
                  filterValues: filters.values,
                  page: {
                    offset: pageCriteria.offset + pageCriteria.pageSize,
                    pageSize: pageCriteria.pageSize,
                  },
                  pathname: location.pathname,
                }),
                { replace: true }
              )
            }
            goToPreviousPage={() =>
              navigate(
                setDashboardURL({
                  filters,
                  filterValues: filters.values,
                  page: {
                    offset: pageCriteria.offset - pageCriteria.pageSize,
                    pageSize: pageCriteria.pageSize,
                  },
                  pathname: location.pathname,
                }),
                { replace: true }
              )
            }
            isClientSidePagination
            pageTotal={isAdmin ? numberOfElements : brokerNumberOfElements}
            sx={{
              bg: 'white',
              fontSize: 2,
              pt: 5,
            }}
            totalRecordCount={isAdmin ? totalElements : brokerTotalElements}
          />
        </TableWrapper>
      </Box>
    );
  };

  return (
    <ApolloConsumer>
      {(client) => {
        const apiCall = (pathName, params) =>
          client.query({
            fetchPolicy: 'no-cache',
            query: remoteActionQuery,
            variables: {
              name: pathName,
              params: JSON.stringify(params),
            },
          });
        const onClickAction = async (values, form) => {
          if (get(values, 'adminContactType') !== 'setBroker') {
            const contacts = [
              {
                contactType: get(values, 'contactType', ''),
                phoneNumber: get(values, 'phoneNumber', ''),
              },
            ];
            const altPhoneNumber = get(values, 'altPhoneNumber', '');
            const altContactType = get(values, 'altContactType', '');
            if (altContactType && altPhoneNumber) {
              contacts.push({
                contactType: altContactType,
                phoneNumber: altPhoneNumber,
              });
            }
            if (!isEmpty(editContactInfo)) {
              const editContactDiff = diff(editContactInfo, values);
              if (Object.keys(editContactDiff).length) {
                let editedFields = { ...editContactDiff };
                if (
                  editContactDiff.contactType ||
                  editContactDiff.phoneNumber ||
                  editContactDiff.altContactType ||
                  editContactDiff.altPhoneNumber || editContactDiff.email
                ) {
                  editedFields = omit(editContactDiff, [
                    'contactType',
                    'phoneNumber',
                    'altContactType',
                    'altPhoneNumber',
                  ]);
                  contacts[0].contactId = get(
                    editContactInfo.contacts,
                    '[0].contactId',
                    ''
                  );
                  if (contacts[1] && Object.keys(contacts[1]).length) {
                    contacts[1].contactId = get(
                      editContactInfo.contacts,
                      '[1].contactId',
                      ''
                    );
                  }
                  editedFields.contacts = contacts;
                  if (editContactDiff.email) {
                  await client
                    .query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name: 'update-user-email',
                        params: JSON.stringify({
                          email: editContactDiff.email,
                          userId: editContactInfo.userId,
                        }),
                      },
                    })
                    // .then(() => {
                    //   setEditContactInfo({});
                    //   form.restart();
                    //   refetch();
                    // })
                    .catch((e) =>
                      setErrorSnack(
                        `There was an error: ${e.message}`,
                        config.notificationDuration
                      )
                    );
                  }
                }
                  return client
                  .query({
                    fetchPolicy: 'no-cache',
                    query: remoteActionQuery,
                    variables: {
                      name: 'update-core-user',
                      params: JSON.stringify({
                        ...editedFields,
                        userId: editContactInfo.userId,
                      }),
                    },
                  })
                  .then(() => {
                    setEditContactInfo({});
                    form.restart();
                    refetch();
                  })
                  .catch((e) =>
                    setErrorSnack(
                      `There was an error: ${e.message}`,
                      config.notificationDuration
                    )
                  );
              }
              setEditContactInfo({});
              form.restart();
              return;
            }
            const params = {
              contacts,
              email: get(values, 'email', ''),
              employerRole: 'MAIN_ADMIN',
              firstName: get(values, 'firstName', ''),
              groupId,
              lastName: get(values, 'lastName', ''),
              mga: get(groupData, 'mga'),
              role: USER_ROLE.EMPLOYER.toUpperCase(),
              status: 'ACTIVE',
            };

            return apiCall('create-core-user', {
              type: 'create-employer',
              userData: params,
            })
              .then(() => {
                form.restart();
                refetch();
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );
          }
          return apiCall(
            'process-subproducer-to-group',
            JSON.stringify([
              {
                groupId,
                subProducerId: get(values, 'subProducerData.subProducerId'),
              },
            ])
          )
            .then(() => {
              form.restart();
              brokerRefetch();
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };

        const onNextClick = () => {
          setNextLoading(true);
          if (content.length > 0 || brokerList.length > 0) {
            setNextLoading(false);
            return navigate(
              generateRedirectPath({
                ...location,
                queryParams: { [name]: isRenewal ? 5 : 4 },
              })
            );
          }
          setNextLoading(false);
          return setErrorSnack(
            'No Employers exist in this group. Create at least one employer to move forward.',
            config.notificationDuration
          );
        };

        if (loading || brokerLoading) {
          return <Spinner />;
        }

        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={{ ...editContactInfo }}
              onSubmit={onClickAction}
              render={({ handleSubmit, submitting, values, form }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        padding: 3,
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box
                          as="h3"
                          color="black"
                          display="flex"
                          justifyContent="flex-start"
                          sx={{
                            color: 'primary',
                            margin: '20px 0px 20px 0px;',
                            padding: 3,
                            width: '25rem',
                          }}
                        >
                          {t('groups.groupContactsTitle')}
                        </Box>
                        <Field
                          aria-label={t('groups.contactType')}
                          component={Dropdown}
                          disabled={!isEmpty(editContactInfo)}
                          label={labelHandler(t('groups.contactType'), false)}
                          name="adminContactType"
                          onChange={() => {
                            form.change('email', null);
                            form.change('firstName', null);
                            form.change('lastName', null);
                          }}
                          options={[
                            {
                              canEnglishValue: null,
                              frenchValue: 'AJOUTER UN NOUVEL ADMINISTRATEUR',
                              label: t('groups.addNewAdmin'),
                              value: 'ManualInput',
                            },
                            {
                              canEnglishValue: null,
                              frenchValue: 'SÉLECTIONNER UN COURTIER EXISTANT',
                              label: t('groups.selectExistingBroker'),
                              value: 'setBroker',
                            },
                          ]}
                          wrapperSx={{ ...wrapperSx }}
                          {...rest}
                        />
                        {get(values, 'adminContactType') === 'setBroker' && (
                          <Field
                            key={get(values, `contactType`)}
                            aria-label="Select Broker"
                            component={Dropdown}
                            data-cy="BrokerName"
                            isClearable
                            label={labelHandler('Select Broker', true)}
                            loadOptions={async (data) => {
                              const options = await client
                                .query({
                                  fetchPolicy: 'no-cache',
                                  query: remoteActionQuery,
                                  skip:
                                    get(values, 'adminContactType') !==
                                    'setBroker',
                                  variables: {
                                    name: 'get-all-subproducer',
                                    params: JSON.stringify({
                                      active: true,
                                      isBroker: true,
                                      name: data,
                                      size: 5,
                                      status: 'ACTIVE',
                                    }),
                                  },
                                })
                                .then((response) => {
                                  const { content } = JSON.parse(
                                    get(response, 'data.remoteAction.data', {})
                                  );
                                  const temp = content.map((item) => {
                                    return {
                                      label: item.name,
                                      value: item,
                                    };
                                  });
                                  return temp;
                                });
                              return options;
                            }}
                            name="subProducerData"
                            onChange={(val) => {
                              form.change('email', get(val, 'email'));
                              form.change(
                                'firstName',
                                get(val, 'subProducerData.firstName')
                              );
                              form.change(
                                'lastName',
                                get(val, 'subProducerData.lastName')
                              );
                            }}
                            searchable
                            validate={required}
                            wrapperSx={{ ...wrapperSx }}
                            {...rest}
                          />
                        )}
                        <AddContact
                          contactType={ContactType}
                          editContactInfo={editContactInfo}
                          isRenewal={isRenewal}
                          setBroker={
                            get(values, 'adminContactType') === 'setBroker'
                          }
                          {...rest}
                        />
                        <ToolbarButton
                          bg="accentLight"
                          disabled={submitting}
                          label={
                            // eslint-disable-next-line no-nested-ternary
                            isEmpty(editContactInfo)
                              ? get(values, 'adminContactType') === 'setBroker'
                                ? 'Assign As Admin'
                                : t('groups.createAdmin')
                              : 'Edit Admin'
                          }
                          m="20px auto 0"
                          submitting={submitting}
                          sx={{ width: 'auto' }}
                          type="submit"
                        />
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          justifyContent: redirectTo
                            ? 'center'
                            : 'space-between',
                          my: 6,
                          p: 3,
                          width: '100%',
                        }}
                      >
                        {!redirectTo && (
                          <ToolbarButton
                            bg="primaryLight"
                            label={t('common.previous')}
                            ml={0}
                            my={2}
                            onClick={() =>
                              navigate(
                                generateRedirectPath({
                                  ...location,
                                  queryParams: {
                                    [name]: isRenewal ? 3 : 2,
                                  },
                                })
                              )
                            }
                            width="10rem"
                          />
                        )}
                        <ToolbarButton
                          bg="primaryDark"
                          label={redirectTo ? 'Done' : t('common.next')}
                          mr={0}
                          my={2}
                          onClick={() =>
                            redirectTo ? navigate(redirectTo) : onNextClick()
                          }
                          submitting={nextLoading}
                          width="10rem"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
            {administrators.length > 0 && (
              <Box
                sx={{
                  display: 'grid',
                }}
              >
                <Box as="h3" color="black" pt="8px">
                  Administrators Info:
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  {administrators && renderEmployerTable(true)}
                </Box>
              </Box>
            )}
            {brokerList.length > 0 && (
              <Box
                sx={{
                  display: 'grid',
                }}
              >
                <Box as="h3" color="black" pt="8px">
                  Brokers Info:
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  {brokerList && renderEmployerTable(false)}
                </Box>
              </Box>
            )}
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

GroupContacts.propTypes = {
  isRenewal: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  redirectTo: PropTypes.string,
};

GroupContacts.defaultProps = {
  isRenewal: false,
  redirectTo: '',
};

export default GroupContacts;
