import PropTypes from 'prop-types';
import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import Box from "@basecomponents/Box";
import InputWrapper from "@basecomponents/InputWrapper";

const TextArea = ({ input, textAreaSx, ...rest }) => (
  <InputWrapper input={input} {...rest}>
    {({ error, ...inputRest }) => (
      <Box
        as={TextareaAutosize}
        async
        sx={{ ...textAreaSx, borderColor: error ? 'error' : null }}
        variant="inputs.primary"
        width="100%"
        {...inputRest}
        {...input}
      />
    )}
  </InputWrapper>
);

TextArea.propTypes = {
  input: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
  textAreaSx: PropTypes.shape({}),
};

TextArea.defaultProps = {
  textAreaSx: {},
};
export default TextArea;
