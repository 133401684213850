import generateSearchLink from "@src/utilities/generate-search-link";

/**
 * @category Utils
 * @param {any} filters rest All the other Props
 * @param {number} pageSize Indicates the total size of the pages
 * @param {string} pathName Indicates the name of the path
 * @returns {Object}
 */

const generateDashboardOnPreviousClickSearchLink = ({
  filters,
  pageSize,
  pathname,
}) =>
  generateSearchLink({
    filters: {
      ...filters,
      page: (filters.page || 0) - pageSize,
    },
    pathname,
  });

export default generateDashboardOnPreviousClickSearchLink;
