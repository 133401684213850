import get from 'lodash/get';
import { BARREL_STATUS } from "@src/utilities/constants";

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const BarrelStatus = ({ value }) => {
  const barrelStatus = BARREL_STATUS.find((s) => s.value === value);
  return barrelStatus ? get(barrelStatus, 'label') : `#${value}`;
};

export default BarrelStatus;
