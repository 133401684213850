import Box from '@basecomponents/Box';
import arrayMutators from 'final-form-arrays';
import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { ApolloConsumer } from '@apollo/client';
import { get } from 'lodash';
import { required } from '@utils/validators';
import {CA_PROVINCES, notificationTypes, settingLevel, US_STATES} from '@utils/constants';
import GetData from "@src/utilities/get-data";
import ToolbarButton from "@basecomponents/ToolbarButton";
import Route from "@petcomponents/Route";
import Spinner from "@basecomponents/Spinner";
import Icon from "@src/components/Icon";
import Card from "@basecomponents/Card";
import NotificationSettingItem from "@petcomponents/Settings/Notifications/NotificationSettingItem";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import config from "@src/config.json";

const isGreaterThanOrEqualToGlobalIfNotZero = (value, values) => {
  if (values.globalSettings && value) {
    const globalNumeric = Number(
      values.globalSettings.match?.(/\d/g)?.join('')
    );
    const valueNumeric = Number(value.match?.(/\d/g)?.join(''));
    if (valueNumeric !== 0 && valueNumeric < globalNumeric) {
      return 'Must be greater than global settings value';
    }
  }
};

/**
 * @category Settings
 * @param {*} rest
 * @returns {React.FC}
 */
const AdministratorNotificationSettings = ({ ...rest }) => {
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const [isRefetching, setIsRefetching] = useState(null);
  const [isOBNRefetching, setIsOBNRefetching] = useState(null);
  const [visibility, setVisisbility] = useState({
    outstandingBalanceNotification: false,
    renewalNotifications: true,
  });

  const {
    apiData: globalSettingsData = {},
    loading: globalSettingDataLoading,
    refetch: globalSettingDataRefetch,
  } = GetData(
    'get-all-system-settings',
    JSON.stringify({
      settingLevel: 'GLOBAL',
      settingType: notificationTypes.adminNotifications,
    })
  );

  const {
    apiData: globalOBNSettingData = {},
    loading: globalOBNSettingDataLoading,
    refetch: globalOBNSettingDataRefetch,
  } = GetData(
    'get-all-system-settings',
    JSON.stringify({
      settingLevel: 'GLOBAL',
      settingType: notificationTypes.outstandingBalanceNotification,
    })
  );

  const globalOBNSettings = globalOBNSettingData?.content?.[0]?.settingValue;

  const {
    apiData: stateSettingsData = {},
    loading: stateSettingsDataLoading,
    refetch: stateSettingsDataRefetch,
  } = GetData(
    'get-all-system-settings',
    JSON.stringify({
      settingLevel: 'STATE',
      settingType: notificationTypes.adminNotifications,
      size: config.canadaEnv ? CA_PROVINCES.length :US_STATES.length,
    })
  );

  const { apiData: constantsData = {} } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: ['SettingType', 'SettingLevel'] }])
  );

  const globalSettings = globalSettingsData?.content?.[0]?.settingValue;

  const getStateSettingsValue = (value) => {
    return stateSettingsData?.content?.find(
      ({ identifier }) => identifier === value
    )?.settingValue;
  };

  let stateSettings = [];
  const stateList = config.canadaEnv ? CA_PROVINCES : US_STATES;

  stateSettings = stateList.map((item) => {
    const temp = item;
    temp.renewalNotificationDays =
      getStateSettingsValue(item.value) ?? globalSettings ?? '0 Days';
    return temp;
  });

  const getSettingLevel = (settingLevel) => {
    const item = constantsData?.SettingLevel?.find(
      ({ value }) => value === settingLevel
    );
    return item?.value;
  };

  const getSettingType = (settingType) => {
    const item = constantsData?.SettingType?.find(
      ({ value }) => value === settingType
    );
    return item?.value;
  };

  if (
    stateSettingsDataLoading ||
    globalSettingDataLoading ||
    globalOBNSettingDataLoading
  ) {
    return <Spinner />;
  }

  return (
    <Route isPrivate permission="setting.page.notifications" {...rest}>
      <Box as="h2" sx={{ py: 3 }}>
        Administrator Notification Settings
      </Box>
      <ApolloConsumer>
        {(client) => {
          const onAddGlobalAndStateSettings = async (values) => {
            setIsRefetching(true);
            const removeMaskedgGlobalSettingsValue = Number(
              get(values, 'globalSettings', '').match?.(/\d/g).join('')
            );
            const globalSetttings = {
              identifier: 'GLOBAL',
              settingLevel: getSettingLevel(settingLevel.global),
              settingType: getSettingType(notificationTypes.adminNotifications),
              settingValue: removeMaskedgGlobalSettingsValue,
            };

            await client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'create-system-settings',
                  params: JSON.stringify({ ...globalSetttings }),
                },
              })
              .then(() => {
                get(values, 'stateSettings', []).forEach(async (settings) => {
                  const removeMaskedStateSettingsValue =
                    Number(
                      get(settings, 'renewalNotificationDays', '')
                        .match?.(/\d/g)
                        ?.join('')
                    ) || 0;
                  let stateSettingsObj = {};
                  if (
                    removeMaskedStateSettingsValue !== 0 &&
                    removeMaskedStateSettingsValue !==
                      removeMaskedgGlobalSettingsValue
                  ) {
                    stateSettingsObj = {
                      identifier: settings?.value,
                      settingLevel: getSettingLevel(settingLevel.state),
                      settingType: getSettingType(
                        notificationTypes.adminNotifications
                      ),
                      settingValue: removeMaskedStateSettingsValue,
                    };
                  } else {
                    stateSettingsObj = {
                      identifier: settings?.value,
                      settingLevel: getSettingLevel(settingLevel.state),
                      settingType: getSettingType(
                        notificationTypes.adminNotifications
                      ),
                      settingValue: removeMaskedgGlobalSettingsValue,
                    };
                  }

                  await client
                    .query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name: 'create-system-settings',
                        params: JSON.stringify({ ...stateSettingsObj }),
                      },
                    })
                    .then(async () => {
                      setSuccessSnack(
                        'Notifications Updated Successfully',
                        config.notificationDuration
                      );
                      await globalSettingDataRefetch();
                      await stateSettingsDataRefetch();
                      setIsRefetching(false);
                    })
                    .catch((e) => {
                      setErrorSnack(
                        `There was an error: ${e.message}`,
                        config.notificationDuration
                      );
                      setIsRefetching(false);
                    });
                });
              })
              .catch((e) => {
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
                setIsRefetching(false);
              });
          };

          const onAddGlobalOBNSetting = async (values) => {
            setIsOBNRefetching(true);
            const removeMaskedgGlobalSettingsValue = Number(
              get(values, 'globalOBNSettings', '').match?.(/\d/g).join('')
            );
            const globalSetttings = {
              identifier: 'GLOBAL',
              settingLevel: getSettingLevel(settingLevel.global),
              settingType: getSettingType(
                notificationTypes.outstandingBalanceNotification
              ),
              settingValue: removeMaskedgGlobalSettingsValue,
            };

            await client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'create-system-settings',
                  params: JSON.stringify({ ...globalSetttings }),
                },
              })
              .then(async () => {
                setSuccessSnack(
                  'Notifications Updated Successfully',
                  config.notificationDuration
                );
                await globalOBNSettingDataRefetch();
                setIsOBNRefetching(false);
              })
              .catch((e) => {
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
                setIsOBNRefetching(false);
              });
          };

          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Card sx={{ width: '30rem' }}>
                {visibility.renewalNotifications ? (
                  <Box
                    data-testid="renewalNotification"
                    onClick={() =>
                      setVisisbility({
                        ...visibility,
                        renewalNotifications: !visibility.renewalNotifications,
                      })
                    }
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                      alignItems: 'center',
                      color: 'primary',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box as="h2" sx={{ py: 5 }}>
                      Renewal Notification Settings
                    </Box>
                    <Icon
                      svg="expand-more"
                      sx={{
                        height: '30px',
                        width: '30px',
                      }}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Box
                      onClick={() =>
                        setVisisbility({
                          ...visibility,
                          renewalNotifications:
                            !visibility.renewalNotifications,
                        })
                      }
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                        alignItems: 'center',
                        color: 'primary',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box as="h2" sx={{ py: 5 }}>
                        Renewal Notification Settings
                      </Box>
                      <Icon
                        svg="expand-less"
                        sx={{
                          height: '30px',
                          width: '30px',
                        }}
                      />
                    </Box>
                    <Box as="h4">
                      Set number of days prior to the policy expiration date at
                      which a notification dot should show on the group
                      indicating that a group is approaching the renewal date.
                      Individual group settings can be configured on the Group
                      Details Page.
                    </Box>
                    {isRefetching ? (
                      <Spinner />
                    ) : (
                      <FinalForm
                        initialValues={{
                          globalSettings,
                          stateSettings,
                        }}
                        mutators={{
                          ...arrayMutators,
                        }}
                        onSubmit={onAddGlobalAndStateSettings}
                        render={({ handleSubmit, submitting }) => {
                          return (
                            <form onSubmit={handleSubmit}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-end',
                                  mb: 1,
                                  mt: 6,
                                  width: '100%',
                                }}
                              >
                                <ToolbarButton
                                  bg="primary"
                                  isDisabled={submitting}
                                  isLoading={submitting}
                                  label="Save"
                                  mr={0}
                                  submitting={submitting}
                                  type="submit"
                                  width="150px"
                                />
                              </Box>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%',
                                }}
                              >
                                <Card
                                  cardSx={{
                                    boxShadow: 6,
                                    width: 'auto',
                                  }}
                                >
                                  <NotificationSettingItem
                                    name="globalSettings"
                                    title="Global Settings"
                                    validators={required}
                                  />
                                </Card>
                              </Box>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%',
                                }}
                              >
                                <Card
                                  cardSx={{
                                    boxShadow: 6,
                                    width: 'auto',
                                  }}
                                >
                                  <FieldArray name="stateSettings">
                                    {({ fields }) =>
                                      fields.map((name, index) => {
                                        return (
                                          <Box
                                            key={`${index}-notificationSettingItem`}
                                          >
                                            <NotificationSettingItem
                                              name={`${name}.renewalNotificationDays`}
                                              title={stateSettings[index].label}
                                              validators={
                                                isGreaterThanOrEqualToGlobalIfNotZero
                                              }
                                            />
                                          </Box>
                                        );
                                      })
                                    }
                                  </FieldArray>
                                </Card>
                              </Box>
                            </form>
                          );
                        }}
                      />
                    )}
                  </Box>
                )}
              </Card>
              <Card sx={{ width: '30rem' }}>
                {!visibility.outstandingBalanceNotification ? (
                  <Box
                    data-testid="outstandingNotificationBalance"
                    onClick={() =>
                      setVisisbility({
                        ...visibility,
                        outstandingBalanceNotification:
                          !visibility.outstandingBalanceNotification,
                      })
                    }
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                      },
                      alignItems: 'center',
                      color: 'primary',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box as="h2" sx={{ py: 5 }}>
                      Outstanding Balance Notification Settings
                    </Box>
                    <Icon
                      svg="expand-more"
                      sx={{
                        height: '30px',
                        width: '30px',
                      }}
                    />
                  </Box>
                ) : (
                  <Box>
                    <Box
                      onClick={() =>
                        setVisisbility({
                          ...visibility,
                          outstandingBalanceNotification:
                            !visibility.outstandingBalanceNotification,
                        })
                      }
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                        alignItems: 'center',
                        color: 'primary',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box as="h2" sx={{ py: 5 }}>
                        Outstanding Balance Notification Settings
                      </Box>
                      <Icon
                        svg="expand-less"
                        sx={{
                          height: '30px',
                          width: '30px',
                        }}
                      />
                    </Box>
                    <Box as="h4">
                      Set number of days prior to the policy expiration date of
                      a group at which a notification email should be sent to
                      the IAIC Administrators with a list of groups which have
                      outstanding balances.
                    </Box>
                    {isOBNRefetching ? (
                      <Spinner />
                    ) : (
                      <FinalForm
                        initialValues={{
                          globalOBNSettings,
                        }}
                        onSubmit={onAddGlobalOBNSetting}
                        render={({ handleSubmit, submitting }) => {
                          return (
                            <form onSubmit={handleSubmit}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-end',
                                  mb: 1,
                                  mt: 6,
                                  width: '100%',
                                }}
                              >
                                <ToolbarButton
                                  bg="primary"
                                  isDisabled={submitting}
                                  isLoading={submitting}
                                  label="Save"
                                  mr={0}
                                  submitting={submitting}
                                  type="submit"
                                  width="150px"
                                />
                              </Box>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '100%',
                                }}
                              >
                                <Card
                                  cardSx={{
                                    boxShadow: 6,
                                    width: 'auto',
                                  }}
                                >
                                  <NotificationSettingItem
                                    name="globalOBNSettings"
                                    title="Global Settings"
                                    validators={required}
                                  />
                                </Card>
                              </Box>
                            </form>
                          );
                        }}
                      />
                    )}
                  </Box>
                )}
              </Card>
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

export default AdministratorNotificationSettings;
