import React from 'react';
import get from 'lodash/get';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import { ApolloConsumer } from '@apollo/client';
import { ROLE } from '@utils/constants';
import { useTranslation } from 'react-i18next';
import Route from "@petcomponents/Route";
import ListGrid from "@basecomponents/ListGrid";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import HeaderRightContainer from "@basecomponents/HeaderRightContainer";
import config from "@src/config.json";

/**
 * @category User
 * @param {string} benAdminId
 * @param {Object} client
 * @param {*} rest
 * @returns {React.FC}
 */
const UsersDashboardPage = ({ benAdminId, client, ...rest }) => {
  const { t } = useTranslation()
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const filters = [
    {
      name: 'email',
      type: 'Input',
    },
    {
      label: t('filters.firstName'),
      name: 'firstName',
      type: 'Input',
    },
    {
      label: t('filters.lastName'),
      name: 'lastName',
      type: 'Input',
    },
    {
      label: t('groupEmployees.dashboardPage.role'),
      name: 'role',
      options: ROLE,
      type: 'MultiselectDropdown',

      // name: 'role',
      // options: Object.keys(ROLE).map((r) => ({
      //   canEnglishValue: capitalCase(r),
      //   frenchValue: capitalCase(r),
      //   label: capitalCase(r),
      //   value: r,
      // })),
      // type: 'MultiselectDropdown',
    },
    {
      name: 'active',
      type: 'DropdownBoolean',
    },
    {
      name: 'deleted',
      type: 'DropdownBoolean',
    },
  ];
  let defaultFilterValues = [];
  if (benAdminId) {
    defaultFilterValues = [{ benAdminId, sort: 'created,desc' }];
  }
  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'details',
            link: `/users/${get(data, 'userId')}/details`,
          };
        },
        label: 'View Detail',
        name: 'detail',
      },
      {
        getProps: (data, refetch) => {
          return {
            icon: 'manage',
            isDisabled: benAdminId
              ? !get(data, 'user.deleted', false)
              : !get(data, 'deleted', false),
            onClick: () => {
              return client
                .query({
                  fetchPolicy: 'no-cache',
                  query: remoteActionQuery,
                  variables: {
                    name: 'update-core-user',
                    params: JSON.stringify({
                      deleted: false,
                      userId: data.userId,
                    }),
                  },
                })
                .then(() => refetch())
                .catch((e) =>
                  setErrorSnack(
                    `There was an error: ${e.message}`,
                    config.notificationDuration
                  )
                );
            },
          };
        },
        label: 'Activate User',
        name: 'activate',
      },
      {
        getProps: (data, refetch) => {
          return {
            icon: 'manage',
            isDisabled: benAdminId
              ? get(data, 'user.deleted', true)
              : get(data, 'deleted', true),
            onClick: () => {
              return client
                .query({
                  fetchPolicy: 'no-cache',
                  query: remoteActionQuery,
                  variables: {
                    name: 'update-core-user',
                    params: JSON.stringify({
                      deleted: true,
                      userId: data.userId,
                    }),
                  },
                })
                .then(() => refetch())
                .catch((e) =>
                  setErrorSnack(
                    `There was an error: ${e.message}`,
                    config.notificationDuration
                  )
                );
            },
          };
        },
        label: 'Deactivate User',
        name: 'deactivate',
      },
      {
        getProps: (data, refetch) => {
          return {
            icon: 'assigned',
            // isDisabled: benAdminId
            //   ? get(data, 'user.isVerified', true)
            //   : get(data, 'isVerified', true),
            onClick: () => {
              return client
                .query({
                  fetchPolicy: 'no-cache',
                  query: remoteActionQuery,
                  variables: {
                    name: 'resend-verification-email',
                    params: JSON.stringify({
                      userId: data.userId,
                    }),
                  },
                })
                .then(() => {
                  setSucessSnack(
                    'Successfully Sent Verification Mail.',
                    config.notificationDuration
                  );
                  refetch();
                })
                .catch((e) => {
                  if (e.message.includes('UnsupportedUserStateException')) {
                    setErrorSnack(
                      'User already exists on Portal. Please request user to utilize "Forgot Password" function.',
                      config.notificationDuration
                    );
                  } else {
                    setErrorSnack(
                      `There was an error: ${e.message}`,
                      config.notificationDuration
                    );
                  }
                });
            },
          };
        },
        label: 'Resend Email Invitation',
        name: 'Verification',
      },
    ],
    fields: benAdminId
      ? [
          { name: 'user.email' },
          { name: 'user.firstName' },
          { name: 'user.lastName' },
          { name: 'user.role' },
          { name: 'active' },
          { name: 'deleted' },
        ]
      : [
          { name: 'email' },
          { name: 'firstName' },
          { name: 'lastName' },
          { name: 'role' },
          { name: 'active' },
          { name: 'deleted' },
        ],
  };
  return benAdminId ? (
    <ListGrid
      defaultFilterValues={defaultFilterValues}
      filters={filters}
      grid={grid}
      gridQueryName="get-all-ben-users"
      moduleName="users"
      {...rest}
    />
  ) : (
    <Route
      header={{
        rightContainer: (
          <HeaderRightContainer
            headerActions={[
              {
                action: `/users/create`,
                icon: 'users-add',
                label: 'Create User',
                permission: 'user.button.create',
              },
            ]}
            {...rest}
          />
        ),
        title: 'Dashboard',
        type: 'users',
      }}
      isPrivate
      permission="user.page.dashboard"
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Users Dashboard
      </Box>
      <ListGrid
        filters={filters}
        grid={grid}
        gridQueryName="get-all-users"
        moduleName="users"
        {...rest}
      />
    </Route>
  );
};

UsersDashboardPage.defaultProps = {
  benAdminId: null,
};

UsersDashboardPage.propTypes = {
  benAdminId: PropTypes.string,
  client: PropTypes.shape({
    query: PropTypes.func.isRequired,
  }).isRequired,
};

const UsersDashboardWithApolloConsumer = ({ extBenAdminId, ...rest }) => (
  <ApolloConsumer>
    {(client) => (
      <UsersDashboardPage
        benAdminId={extBenAdminId}
        client={client}
        {...rest}
      />
    )}
  </ApolloConsumer>
);

UsersDashboardWithApolloConsumer.defaultProps = {
  extBenAdminId: null,
};

UsersDashboardWithApolloConsumer.propTypes = {
  extBenAdminId: PropTypes.string,
};

export default UsersDashboardWithApolloConsumer;
