import { Router } from '@reach/router';
import React from 'react';
import DashboardPage from '@petcomponents/BillingDashboardPage';
import AccountsBook from '@petcomponents/AccountsBook';
import CommissionReport from '@petcomponents/CommissionReport';
import CashPremium from '@petcomponents/CashPremium';
import AgingReport from '@petcomponents/AgingReport';
import MasterCalendar from '@petcomponents/MasterCalendar';
import config from '@src/config.json';
import BillingDashboardUpdatedPage from '@petcomponents/BillingDashboardUpdatedPage';
import NotFoundPage from "@src/routes/ihc/404";

const Billing = (props) => (
  <Router>
    <NotFoundPage default path="/404" />
    {config.newBillingApiVersionEnable ? (
      <BillingDashboardUpdatedPage {...props} path="/billing/dashboard" />
    ) : (
      <DashboardPage {...props} path="/billing/dashboard" />
    )}
    <AccountsBook {...props} path="/billing/accountsBook" />
    <CommissionReport {...props} path="/billing/commissionReport" />
    <CashPremium {...props} path="/billing/cashPremium" />
    <CashPremium
      {...props}
      path="/billing/petCashPremium"
      type="petCashPremium"
    />
    <CashPremium
      {...props}
      path="/billing/employeeCashPremium"
      type="employeeCashPremium"
    />
    <AgingReport {...props} path="/billing/agingReport" />
    <AgingReport
      {...props}
      path="/billing/terminatePetReport"
      type="terminatePetReport"
    />
    <MasterCalendar {...props} path="/billing/masterCalendar" />
    <CashPremium
      {...props}
      path="/billing/masterCalendarReport"
      type="masterBillingCalendar"
    />
  </Router>
);

export default Billing;
