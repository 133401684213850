import Box from '@basecomponents/Box';
import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { required } from "@src/utilities/validators";
import config from "@src/config.json";
import stripSpecialCharacters from "@src/utilities/strip-special-characters";
import FileUploadInput from "@basecomponents/FileUploadInput";

/**
 * @category Claims
 * @param {Array<Object>} claimDocs
 * @param {string} employeeId Indicates the unique employee ID
 * @param {Function} form Instance of the react-final-form
 * @param {string} groupId Indicates the unique group ID
 * @param {object} wrapperSx Inidcates the Styling for form field
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const UploadDocumentPage = ({
  claimDocs,
  employeeId,
  form,
  groupId,
  wrapperSx,
  ...rest
}) => {
  const { t } = useTranslation()
  const fileList = [...claimDocs];
  return (
    <Box>
      <Box
        sx={{
          fontSize: 2,
          fontWeight: '600',
          mt: 2,
          ...wrapperSx,
        }}
      >
        {t('claimsScreen.uploadDocumentPage.description')}
      </Box>
      <Field
        acceptedFileTypes={[
          'application/pdf',
          'image/jpg',
          'image/jpeg',
          'image/png',
          'application/vnd.ms-excel',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ]}
        allowMultiple
        allowRevert
        aria-label={t('common.dragAndDropAdd')}
        bucket={config.amplify.Storage.AWSS3.bucket}
        component={FileUploadInput}
        credits={false}
        customPrefix={{ public: '' }}
        data-cy="claimDocs"
        fileRenameFunction={({ basename, extension }) => {
          const baseStripped = stripSpecialCharacters(basename);
          const timestamp = new Date().getTime();
          const filename = `${baseStripped}${extension}`;
          return `${groupId}/enrollees/${employeeId}/claims/uploadedFiles/${timestamp}/${filename}`;
        }}
        fileValidateTypeDetectType={(source, type) =>
          new Promise((resolve) =>
            source?.name?.split('.').pop() === 'edi'
              ? resolve('application/edi-x12')
              : resolve(type)
          )
        }
        labelIdle={`
                      <div class="filepond--heading">
                       ${t('common.dragAndDrop')}
                      </div>
                      <div class="filepond--or">
                        ${t('common.or')}
                      </div>
                      <div
                       class="filepond--label-action"
                      >
                        ${t('common.addFiles')}
                      </div>
                      <div class="filepond--sub-heading" style="margin-top:20px">
                        ${t('common.fileSizeLimit')}
                      </div>
                    `}
        name="claimDocs"
        onRemoveComplete={({ name }) => {
          fileList.splice(
            fileList.findIndex((f) => f.s3KeyName === name),
            1
          );
          return form.change('claimDocs', [
            ...claimDocs.filter((f) => f.s3KeyName !== name),
          ]);
        }}
        onUploadComplete={async ({ fileName }) => {
          fileList.push({
            s3BucketName: config.amplify.Storage.AWSS3.bucket,
            s3KeyName: `${fileName}`,
          });
          return form.change('claimDocs', fileList);
        }}
        validate={required}
        wrapperSx={{
          '.filepond--drop-label': {
            height: 'auto',
            padding: 3,
            py: [5, null, null, null, 8],
            width: '100%',
          },
          '.filepond--heading': {
            color: 'text.primary',
            fontSize: 5,
            fontWeight: 'bold',
            lineHeight: '1em',
          },
          '.filepond--label-action': (p) => ({
            mt: 5,
            textDecoration: 'none',
            ...p.variants.buttons.primary,
          }),
          '.filepond--or': {
            color: 'text.primary',
            fontSize: 2,
            fontWeight: 'bold',
            mt: 5,
            textTransform: 'uppercase',
          },
          '.filepond--supported': {
            fontSize: 2,
            mt: 5,
          },
          mb: 2,
          mt: 0,
          padding: 3,
          width: '25rem',
        }}
        {...rest}
      />
    </Box>
  );
};
UploadDocumentPage.defaultProps = {
  claimDocs: [],
  employeeId: '',
  form: { change: () => {} },
  groupId: '',
  wrapperSx: {},
};
UploadDocumentPage.propTypes = {
  claimDocs: PropTypes.arrayOf(PropTypes.shape({})),
  employeeId: PropTypes.string,
  form: PropTypes.shape({
    change: PropTypes.func,
  }),
  groupId: PropTypes.string,
  wrapperSx: PropTypes.shape({}),
};
export default UploadDocumentPage;
