import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
import get from 'lodash/get';
import Spinner from "@basecomponents/Spinner";
import ToolbarButton from "@basecomponents/ToolbarButton";

const SortableTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;

  &:after {
    content: '';
    flex: none;
    margin: -4px ${(p) => p.theme.space[3]} 0;
    border: 5px solid transparent;
    border-bottom-color: ${(p) => p.theme.colors.text.primary};
    opacity: 0.4;
  }

  &:hover:after {
    color: ${(p) => p.theme.colors.primary};
  }

  &.asc:after,
  &.desc:after {
    opacity: 1;
    color: ${(p) => p.theme.colors.black};
  }

  &.desc:after {
    margin-top: 6px;
    border-top-color: ${(p) => p.theme.colors.text.primary};
    border-bottom-color: transparent;
  }
`;

const CustomParent = styled(Box)`
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-track:horizontal {
    background-color: ${(p) => p.theme.colors.transparent};
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: #555;
  }
`;

const formatCell = (cell) => {
  if (React.isValidElement(cell)) {
    return cell;
  }

  if (Array.isArray(cell) || (typeof cell === 'object' && cell !== null)) {
    return <pre>{JSON.stringify(cell, null, 2)}</pre>;
  }

  return String(cell);
};

/**
 * @category BaseComponents
 * @param {number} activeRowIndex
 * @param {Object} cellSx
 * @param {boolean} expandLastColumn
 * @param {function} getExpandedSection
 * @param {('left'|'right')} stickyColumnPosition
 * @param {Array<string|Object>} header
 * @param {boolean} isLoading
 * @param {string} keyPrefix
 * @param {function} onRowClick
 * @param {function} onSortUpdate
 * @param {('asc'|'desc')} orderBy
 * @param {React.ReactNode} renderEmpty
 * @param {Array<Array<React.Node>>} rows
 * @param {string} sortBy
 * @param {number} stickyColumn
 * @param {Object} tableSx
 * @returns {React.FC}
 */
const Table = ({
  activeRowIndex,
  cellSx,
  expandLastColumn,
  getExpandedSection,
  stickyColumnPosition,
  header,
  isLoading,
  keyPrefix,
  onRowClick,
  onSortUpdate,
  orderBy,
  renderEmpty,
  rows,
  sortBy,
  stickyColumn,
  tableSx,
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const fixedSx = {
    position: 'sticky',
    [stickyColumnPosition]: 0,
    zIndex: 0,
  };

  const { defaultStyle = {} } = useContext(ThemeContext);
  const {
    table = {},
    tableHeaderBox = {},
    tableExpandedSectionHeader = {},
    tableHeader = {},
    emptyTableData = {},
  } = defaultStyle.defaultTable(tableSx, fixedSx);
  return (
    <CustomParent>
      <Box
        as="table"
        sx={{
          ...table,
        }}
      >
        {!!rows.length && (
          <thead>
            <Box
              as="tr"
              sx={{
                ...tableHeaderBox,
              }}
            >
              {getExpandedSection && (
                <Box
                  key={`expanded-${keyPrefix}`}
                  as="th"
                  className="fixed"
                  sx={{
                    ...tableExpandedSectionHeader,
                  }}
                />
              )}
              {header.map((item, headerIndex) => (
                <Box
                  key={keyPrefix + headerIndex}
                  as="th"
                  className={stickyColumn === headerIndex ? 'fixed' : null}
                  sx={{
                    ...tableHeader,
                  }}
                >
                  {typeof item === 'object' && item.value ? (
                    <Button
                      onClick={() =>
                        onSortUpdate(
                          get(item, 'sortName', null) === null
                            ? item.value
                            : item.sortName,
                          (item.sortName === sortBy || item.value === sortBy) &&
                            orderBy === 'asc'
                            ? 'desc'
                            : 'asc'
                        )
                      }
                      simple
                    >
                      <SortableTitle
                        className={
                          item.sortName === sortBy || item.value === sortBy
                            ? orderBy
                            : ''
                        }
                      >
                        {item.label}
                      </SortableTitle>
                    </Button>
                  ) : (
                    item
                  )}
                </Box>
              ))}
            </Box>
          </thead>
        )}
        <tbody>
          {rows.length ? (
            rows.map((row, rowIndex) => {
              const rowIsActive = rowIndex === activeRowIndex;
              const { trSx, tdSx } = defaultStyle.defaultTable(
                tableSx,
                fixedSx,
                onRowClick,
                rowIsActive,
                cellSx
              );
              const trData = [];
              trData.push(
                <Box
                  key={keyPrefix + rowIndex}
                  as="tr"
                  onClick={() => onRowClick && onRowClick(row, rowIndex)}
                  sx={{
                    ...trSx,
                  }}
                >
                  {getExpandedSection && (
                    <Box
                      key={`expand-${keyPrefix + rowIndex}`}
                      as="td"
                      sx={tdSx}
                    >
                      <Box alignItems="center" display="flex">
                        <Box>
                          <ToolbarButton
                            bg={
                              expandedRows.includes(rowIndex)
                                ? 'accent'
                                : 'primary'
                            }
                            icon={
                              expandedRows.includes(rowIndex)
                                ? 'expand-less'
                                : 'expand-more'
                            }
                            onClick={() => {
                              if (expandedRows.includes(rowIndex)) {
                                const updatedRowIndex = [...expandedRows];
                                const index = updatedRowIndex.findIndex(
                                  (v) => v === rowIndex
                                );
                                updatedRowIndex.splice(index, 1);
                                setExpandedRows(updatedRowIndex);
                              } else {
                                const updatedRowIndex = [...expandedRows];
                                updatedRowIndex.push(rowIndex);
                                setExpandedRows(updatedRowIndex);
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {row.map((cell, cellIndex) => (
                    <Box
                      key={keyPrefix + cellIndex}
                      as="td"
                      className={stickyColumn === cellIndex ? 'fixed' : null}
                      colSpan={
                        expandLastColumn && cellIndex === row.length - 1
                          ? header.length - row.length + 1
                          : 1
                      }
                      sx={tdSx}
                    >
                      {formatCell(cell)}
                    </Box>
                  ))}
                </Box>
              );
              if (expandedRows.includes(rowIndex)) {
                trData.push(
                  <Box key={`expand-tr-${keyPrefix + rowIndex}`} as="tr">
                    <Box
                      key={`expand-td-${keyPrefix + rowIndex}`}
                      as="td"
                      className={stickyColumn === rowIndex ? 'fixed' : null}
                      colSpan={row.length + 1}
                    >
                      {getExpandedSection(row, rowIndex)}
                    </Box>
                  </Box>
                );
              }
              return trData;
            })
          ) : (
            <Box as="tr">
              <Box
                as="td"
                colSpan={header.length}
                sx={{
                  ...emptyTableData,
                }}
              >
                {isLoading ? <Spinner /> : renderEmpty}
              </Box>
            </Box>
          )}
        </tbody>
      </Box>
    </CustomParent>
  );
};

Table.propTypes = {
  activeRowIndex: PropTypes.number,
  cellSx: PropTypes.shape({}),
  expandLastColumn: PropTypes.bool,
  getExpandedSection: PropTypes.func,
  header: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
  ).isRequired,
  isLoading: PropTypes.bool,
  keyPrefix: PropTypes.string,
  onRowClick: PropTypes.func,
  onSortUpdate: PropTypes.func,
  orderBy: PropTypes.oneOf(['asc', 'desc']),
  renderEmpty: PropTypes.node,
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
  sortBy: PropTypes.string,
  stickyColumn: PropTypes.number,
  stickyColumnPosition: PropTypes.oneOf(['left', 'right']),
  tableSx: PropTypes.shape({}),
};

Table.defaultProps = {
  activeRowIndex: null,
  cellSx: {},
  expandLastColumn: false,
  getExpandedSection: null,
  isLoading: false,
  keyPrefix: '',
  onRowClick: null,
  onSortUpdate: () => {},
  orderBy: 'asc',
  renderEmpty: 'No Results',
  sortBy: null,
  stickyColumn: null,
  stickyColumnPosition: 'left',
  tableSx: {},
};

export default Table;
