import { Router } from '@reach/router';
import React from 'react';
import InferLabels from '@petcomponents/InferLabels';
import FilePage from '@petcomponents/FileDashboardPage';
import FileDetailPage from '@petcomponents/FileDetailPage';
import NotFoundPage from "@src/routes/ihc/404";

const Files = (props) => (
  <Router>
    <FilePage {...props} path="/files/dashboard" sftp />
    <FileDetailPage path="/files/detail/:datasetId" />
    <InferLabels {...props} path="/files/columns/:datasetId" sftp />
    <NotFoundPage default path="/404" />
  </Router>
);

export default Files;
