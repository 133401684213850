import React from 'react';
import PropTypes from 'prop-types';
import KeyValuePairs from "@basecomponents/KeyValuePairs";
import GetData from "@src/utilities/get-data";
import getMetaData from "@src/utilities/get-meta-data";
import Spinner from "@basecomponents/Spinner";

/**
 * @category QLE
 * @param {string} employeeId
 * @returns {React.FC}
 */
const EmployeeDetailPage = ({ employeeId }) => {
  const { apiData = {}, loading } = GetData(
    'get-employee-by-id',
    JSON.stringify({ id: employeeId })
  );
  const groupEmployeesMetadata = getMetaData({ moduleName: 'group-employees' });
  return loading ? (
    <Spinner />
  ) : (
    <KeyValuePairs
      data={apiData}
      fields={[
        { name: 'empId' },
        { name: 'user' },
        { name: 'avgHrsPerWeek' },
        { name: 'dateOfHire' },
        { name: 'department' },
        { name: 'fullTimePartTimeCode' },
        { name: 'jobTitle' },
        { name: 'payrollFrequency' },
      ]}
      meta={groupEmployeesMetadata}
    />
  );
};

EmployeeDetailPage.propTypes = {
  employeeId: PropTypes.string.isRequired,
};

export default EmployeeDetailPage;
