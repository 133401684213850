import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { ApolloConsumer } from '@apollo/client';
import Box from '@basecomponents/Box';
import { AuthContext } from '@basecomponents/Auth';
import Modal from '@basecomponents/Modal';

import remoteActionQuery from '@src/graphql/queries/remote-action.gql';
import config from '@src/config.json';
import GetData from '@src/utilities/get-data';
import { useTranslation } from 'react-i18next';
import useSnackbar from "@src/utilities/use-snackbar";
import ListGrid from "@basecomponents/ListGrid";

/**
 * Group employees dashboard page
 *
 * @category Employee
 * @param {boolean} actionDisabled
 * @param {any} groupId
 * @param {*} rest
 * @returns {React.FC}
 */
const NotesDashboard = ({ actionDisabled, groupId, groupNumber, ...rest }) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });

  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [billingNotesId, setBillingNotesId] = useState(null);
  const [isApiRefetch, setIsApiRefetch] = useState(null);
  const {
    apiData: { BillingNotesCategory = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: 'BillingNotesCategory' }])
  );

  const { apiData: groups = {} } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  const billingInformationId = get(groups, 'billingInfo.billingInfoId', '');

  const {
    apiData: { content: payrollData = [] },
  } = GetData(
    'get-payroll-schedule',
    JSON.stringify({
      billingInfoId: billingInformationId,
      groupId,
      size: get(groups, 'billingInfo.billingCycle', ''),
      sort: 'cycleNo,asc',
    }),
    !groupId || !billingInformationId
  );

  const filters = [
    {
      fieldEnd: 'endDate',
      fieldStart: 'startDate',
      label: t('metadata.dateRange'),
      name: 'dateRange',
      type: 'DatePicker',
    },
    {
      label: t('metadata.commenterFullName'),
      name: 'commenterFullName',
      type: 'Input',
    },
    {
      label: t('metadata.category'),
      name: 'category',
      options: BillingNotesCategory,
      type: 'Dropdown',
    },
    {
      label: t('metadata.payrollScheduleId'),
      name: 'payrollScheduleId',
      options: payrollData?.map((item) => ({
        canEnglishValue: item?.cycleNo,
        frenchValue: item?.cycleNo,
        label: item?.cycleNo,
        value: item?.payrollScheduleId,
      })),
      type: 'Dropdown',
    },
  ];

  const defaultFilterValues =
    groupId && groupNumber
      ? [
        {
          groupId,
          groupNumber,
        },
        { sort: 'created,desc' },
      ]
      : [];

  const grid = {
    actions: [
      {
        getProps: (data) => {
          return {
            icon: 'details',
            link: `/groups/${get(data, 'billingNotesId')}/bill-notes`,
          };
        },
        label: 'View',
        name: 'view',
      },
      {
        getProps: (data, refetch) => {
          return {
            icon: 'remove',
            onClick: () => {
              setBillingNotesId(get(data, 'billingNotesId'));
              setIsApiRefetch({ refetch });
              setIsConfirmationModal(!isConfirmationModal);
            },
            visible: data?.userId === userId,
          };
        },
        label: 'Delete',
        name: 'delete',
      },
      {
        getProps: (data) => {
          return {
            icon: 'edit',
            link: `/groups/${get(data, 'billingNotesId')}/${get(
              data,
              'groupId'
            )}/addNotes/edit`,
            visible: data?.userId === userId,
          };
        },
        label: 'Edit',
        name: 'edit',
      },
    ],
    fields: [
      { name: 'created' },
      { name: 'lastModified' },
      { name: 'categories' },
      { name: 'userId' },
      { name: 'payrollCycleNo' },
    ],
  };

  return (
    <ApolloConsumer>
      {(client) => {
        const handlehandleBillingNote = async () => {
          await client
            .query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: 'delete-billing-note',
                params: JSON.stringify({ billingNotesId }),
              },
            })
            .then(() => {
              setSuccessSnack(
                'Notes deleted successfully',
                config.notificationDuration
              );
              setIsConfirmationModal(!isConfirmationModal);
              isApiRefetch?.refetch();
            })
            .catch((e) => {
              setIsConfirmationModal(!isConfirmationModal);
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              );
            });
        };

        return (
          <Box>
            <ListGrid
              defaultFilterValues={defaultFilterValues}
              filters={filters}
              grid={grid}
              gridQueryName="get-all-billing-notes"
              moduleName="billing-notes"
              {...rest}
            />
            {isConfirmationModal && (
              <Modal
                closeText={t('common.no')}
                isOpen={isConfirmationModal}
                onClose={() => setIsConfirmationModal(!isConfirmationModal)}
                onSubmit={handlehandleBillingNote}
                submitText={t('common.yes')}
                title={t('groupEmployees.dashboardPage.confirmSubmission')}
              >
                <Box>
                  By clicking yes, you are confirming to delete a Note.
                  <Box
                    sx={{
                      display: 'flex',
                      fontWeight: 'bold',
                      justifyContent: 'center',
                      my: 5,
                    }}
                  >
                    THIS ACTION CAN NOT BE UNDONE.
                  </Box>
                  Do you wish to continue?
                </Box>
              </Modal>
            )}
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

NotesDashboard.defaultProps = {
  actionDisabled: false,
  groupId: '',
  groupNumber: '',
};

NotesDashboard.propTypes = {
  actionDisabled: PropTypes.bool,
  groupId: PropTypes.string,
  groupNumber: PropTypes.string,
};

export default NotesDashboard;
