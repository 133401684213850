import { AuthContext } from '@basecomponents/Auth';
import { camelCase } from 'change-case';
import get from 'lodash/get';
import { useContext } from 'react';
import dateUtils from "@src/utilities/date-utils";

const getGridParams = ({ defaultFilterValues, filters, state }) => {
  const {
    filterValues,
    offset,
    pageSize,
    sortDirection,
    sortField,
    isListGrid,
  } = state;

  const result = Object.keys(filterValues).reduce((res, key) => {
    const filter = filters.find(({ name }) => name === key);
    const type = get(filter, 'type');
    const filterValue = filterValues[key];

    if (filter?.label === 'Export Status') {
      delete filterValues.enrollmentStatus;
    }
    if (filterValue === 'userType') {
      const { user } = useContext(AuthContext);
      const userId = get(user, 'customSystemUserId');
      res.push({
        assignedTo: userId,
      });
    } else if (filterValue === 'created') {
      const { user } = useContext(AuthContext);
      const userId = get(user, 'customSystemUserId');
      res.push({
        createdBy: userId,
      });
    } else if (filterValue === 'createdFor') {
      const { user } = useContext(AuthContext);
      const userId = get(user, 'customSystemUserId');
      res.push({
        createdFor: userId,
      });
    } else if (filterValue) {
      switch (type) {
        case 'DropdownRange': {
          const split = filterValue.split('-');
          if (split.length === 2) {
            res.push({ [camelCase(`min.${key}`)]: split[0] });
            res.push({ [camelCase(`max.${key}`)]: split[1] });
          }
          break;
        }
        case 'DropdownBoolean': {
          if (filterValue === '0') {
            res.push({ [key]: false });
          } else if (filterValue === '1') {
            res.push({ [key]: true });
          }
          break;
        }

        case 'DatePicker': {
          const { fieldStart, fieldEnd } = filter;
          if (filterValue) {
            if (Array.isArray(filterValue) && filterValue.length === 2) {
              res.push({
                [fieldStart]: dateUtils.setAPIDateOnly(filterValue[0], true),
              });
              res.push({
                [fieldEnd]: dateUtils.setAPIDateOnly(filterValue[1], true),
              });
            }
          }
          break;
        }

        case 'Date': {
          if (filterValue) {
            res.push({
              [key]: dateUtils.setAPIDateOnly(filterValue, true),
            });
          }
          break;
        }

        case 'MultiselectDropdown': {
          const value = filterValue.toString();
          res.push({ [key]: value });
          break;
        }

        default: {
          if (filterValue === 'UNSETTLED_AMOUNT') {
            res.push({ isSettle: false, [key]: 'PAID' });
          }

          if (filterValue === 'PAID') {
            res.push({ isSettle: true });
          }

          const keyVal = ['groupNameSort,asc', 'groupNameSort,desc'].includes(
            filterValue
          )
            ? 'groupNameSort'
            : key;
          res.push({
            [keyVal]:
              keyVal === 'groupNameSort'
                ? filterValue.split(',')[1]
                : filterValue,
          });
        }
      }
    }
    return res;
  }, []);
  if (defaultFilterValues && Array.isArray(defaultFilterValues)) {
    defaultFilterValues.forEach((dfv) => {
      result.push(dfv);
    });
  }

  if (sortField) {
    const exists =
      // eslint-disable-next-line no-prototype-builtins
      result.filter((o) => o.hasOwnProperty('groupNameSort')).length > 0;

    if (!exists) {
      if (sortField === 'groupNameSort') {
        result.push({
          groupNameSort: `${sortDirection}`,
        });
      } else {
        result.push({
          sort: isListGrid ? `${sortField},${sortDirection}` : `${sortField}`,
        });
      }
    }
  }
  result.push({
    page: offset,
    size: pageSize,
  });
  return JSON.stringify([...result].reverse());
};

export default getGridParams;
