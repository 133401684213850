import Box from '@basecomponents/Box';
import React from 'react';
import { Field } from 'react-final-form';
import InputCheckbox from '@basecomponents/InputCheckbox';
import PropTypes from 'prop-types';
import {
  billingAccountNumberMask,
  billingRoutingNumberMask,
  institutionNumberMask,
  transitNumberMask,
} from '@utils/masks';
import { composeValidations, legalInstitutionNumber, legalRoutingNumber, legalTransitNumber } from '@utils/validations';
import { required } from '@utils/validators';
import { labelHandler } from '@utils/label-utils';
import config from '@src/config.json';
import { useTranslation } from 'react-i18next';
import InputText from "@basecomponents/Input";
import InputDropdown from "@basecomponents/Dropdown";
import InputMasked from "@basecomponents/InputMasked";


/**
 * @category Claims
 * @param {string} acceptableMethods Indicating acceptable methods for payment
 * @param {object} wrapperSx Styling for the section
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const BankingPageInfo = ({ acceptableMethods, wrapperSx, ...rest }) => {
  const { t } = useTranslation()
  const methodInfo = [
    {
      id: 'check',
      name: t('claimsScreen.bankingInfo.check'),
    },
    {
      id: 'ach',
      name: t('claimsScreen.bankingInfo.ach'),
    },
  ];
  const accountType = [
    {
      id: 1,
      name: t('claimsScreen.bankingInfo.saving'),
    },
    {
      id: 2,
      name: t('claimsScreen.bankingInfo.checking'),
    },
  ];

  return (
    <>
      <Field
        aria-label={t('claimsScreen.bankingInfo.reimbursementMethods')}
        component={InputDropdown}
        label={labelHandler(t('claimsScreen.bankingInfo.reimbursementMethods'), true)}
        name="acceptableMethods"
        options={methodInfo.map((item) => {
          return {
            canEnglishValue: item.name,
            frenchValue: item.name,
            label: item.name,
            value: item.id,
          };
        })}
        searchable={false}
        validate={required}
        wrapperSx={{ textAlign: 'left', ...wrapperSx }}
        {...rest}
      />
      {acceptableMethods === 'ach' && (
        <>
          <Field
            aria-label={t('claimsScreen.bankingInfo.bankName')}
            component={InputText}
            label={labelHandler(t('claimsScreen.bankingInfo.bankName'))}
            name="bankName"
            wrapperSx={{
              textAlign: 'left',
              ...wrapperSx,
            }}
            {...rest}
          />
          {
            config.canadaEnv ?
              <>
                <Field
                  aria-label="Institution Number"
                  component={InputMasked}
                  label={labelHandler(t('claimsScreen.bankingInfo.institutionNumber'), true)}
                  mask={institutionNumberMask}
                  maxLength={16}
                  name="institutionNumber"
                  type="InputMasked"
                  validate={composeValidations(required, legalInstitutionNumber)}
                  wrapperSx={{ ...wrapperSx }}
                  {...rest}
                />
                <Field
                  aria-label="Transit Number"
                  component={InputMasked}
                  label={labelHandler(t('claimsScreen.bankingInfo.transitNumber'), true)}
                  mask={transitNumberMask}
                  maxLength={16}
                  name="transitNumber"
                  type="InputMasked"
                  validate={composeValidations(required, legalTransitNumber)}
                  wrapperSx={{ ...wrapperSx }}
                  {...rest}
                />
              </>
              :
              <Field
                aria-label={t('claimsScreen.bankingInfo.routingNumber')}
                component={InputMasked}
                label={labelHandler(t('claimsScreen.bankingInfo.routingNumber'), true)}
                mask={billingRoutingNumberMask}
                maxLength={16}
                name="routingNumber"
                type="InputMasked"
                validate={composeValidations(required, legalRoutingNumber)}
                wrapperSx={{ ...wrapperSx }}
                {...rest}
              />
          }
          <Field
            aria-label={t('claimsScreen.bankingInfo.accountNumber')}
            component={InputMasked}
            label={labelHandler(t('claimsScreen.bankingInfo.accountNumber'), true)}
            mask={billingAccountNumberMask}
            maxLength={16}
            name="accountNumber"
            type="InputMasked"
            validate={required}
            wrapperSx={{ ...wrapperSx }}
            {...rest}
          />
          <Field
            aria-label={t('claimsScreen.bankingInfo.accountType')}
            component={InputDropdown}
            label={labelHandler(t('claimsScreen.bankingInfo.accountType'), true)}
            name="accountType"
            options={accountType.map((item) => {
              return {
                canEnglishValue: item.name,
                frenchValue: item.name,
                label: item.name,
                value: item.id,
              };
            })}
            searchable={false}
            validate={required}
            wrapperSx={{
              textAlign: 'left',
              ...wrapperSx,
            }}
            {...rest}
          />
        </>
      )}
      <Box
        sx={{
          fontSize: 2,
          mt: 2,
          textAlign: 'justify',
          ...wrapperSx,
        }}
      >
        {t('claimsScreen.bankingInfo.thankYouLegend')}
      </Box>
      {
        config.canadaEnv &&
      <Box
        sx={{
          fontSize: 2,
          textAlign: 'justify',
          ...wrapperSx,
        }}
      >
        {t('claimsScreen.bankingInfo.submissionClaimLegend')}
      </Box>
}
      {
        config.canadaEnv ?
          < Box
            sx={{
              fontSize: 2,
              textAlign: 'justify',
              ...wrapperSx,
            }}
          >
            {t('claimsScreen.upsertClaimPage.offenceLegend')}
          </Box >
          :
          <Box
            sx={{
              fontSize: 2,
              textAlign: 'justify',
              ...wrapperSx,
            }}
          >
            {/* {t('claimsScreen.bankingInfo.stateLawLegend')} */}
            Do you confirm to the best of your knowledge that all the statements provided on this form are true. You hereby give authorization to request any and all medical records or financial information for the claimed pet and authorization to discuss the details of the this claim with the treating veterinarians, their authorized representative, or any other related party to this claim. You also certify that you have read and understand the applicable <a href="https://www.petpartners.com/insurance-fraud-warnings" rel="noreferrer" target="_blank">Fraud Warning</a> for your state.
          </Box>
      }
      <Field
        aria-label={t('claimsScreen.bankingInfo.agree')}
        component={InputCheckbox}
        inputWrapperSx={{
          display: 'inline-flex',
          float: 'left',
        }}
        label={t('claimsScreen.bankingInfo.agree')}
        labelSx={{
          display: 'inline-flex',
        }}
        name="acceptance"
        type="checkbox"
        validate={required}
        wrapperSx={{
          alignItems: 'center',
          display: 'flex',
          mt: 0,
          ...wrapperSx,
        }}
      />
    </>
  );
};
BankingPageInfo.defaultProps = {
  acceptableMethods: '',
  wrapperSx: {},
};
BankingPageInfo.propTypes = {
  acceptableMethods: PropTypes.string,
  wrapperSx: PropTypes.shape({}),
};
export default BankingPageInfo;
