import get from 'lodash/get';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { PulseLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import {useTranslation} from "react-i18next";
import GetData from "@src/utilities/get-data";

/**
 * @category Utils
 * @param {Object} apiData
 * @param {string} value
 * @returns {React.FC}
 */
const RenewalStatus = ({ apiData, value }) => {
  const { colors } = useContext(ThemeContext);
  const { i18n } = useTranslation();
  const data = [
    GetData('get-enum-options', JSON.stringify([{ params: 'RenewalStatus' }])),
  ];

  if (get(data, '0.loading', false)) {
    return <PulseLoader color={`${colors.accentSecondary}`} size={7} />;
  }
  if (get(apiData, 'isRenewable') === false) {
    return 'Forgo Renewal';
  }
  if (!value) {
    return 'NA';
  }

  const status = get(data, '0.apiData.RenewalStatus', []);
  const renewalStatus = status.find((item) => item.value === value);
  // eslint-disable-next-line no-nested-ternary
  return renewalStatus ? (i18n.language === 'fr' && get(renewalStatus, 'frenchValue', '') !== '') ? get(renewalStatus, 'frenchValue') : get(renewalStatus, 'label') : value;
};

RenewalStatus.propTypes = {
  apiData: PropTypes.shape({
    isRenewable: PropTypes.bool,
  }).isRequired,
  value: PropTypes.string.isRequired,
};

export default RenewalStatus;
