import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { navigate, useParams } from '@reach/router';
// import { useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { get } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import MasterPolicyPdfViewer from '@petcomponents/MasterPolicyPdfViewer';
import { useTranslation } from 'react-i18next';
import Route from "@petcomponents/Route";
// import ClassConfiguration from './ClassConfiguration';
import ClassConfiguration from "@petcomponents/ProductAdminPage/ClassConfiguration";
// import Commissions from './Commissions';
import Commissions from "@petcomponents/ProductAdminPage/Commissions";
// import MasterPolicy from './MasterPolicy';
// import MasterPolicy from '../ProductAdminPage/MasterPolicy';
import Modal from "@basecomponents/Modal";
// import PlanConfiguration from './PlanConfiguration';
import PlanConfiguration from "@petcomponents/ProductAdminPage/PlanConfiguration";
// import PlanPricing from './PlanPricing';
import PlanPricing from "@petcomponents/ProductAdminPage/PlanPricing";
// import RatingTable from './RatingTable';
import RatingTable from "@petcomponents/ProductAdminPage/RatingTable";
// import SummaryPage from './SummaryPage';
import SummaryPage from "@petcomponents/ProductAdminPage/SummaryPage";
import GetData from "@src/utilities/get-data";
import ProgressBar from "@basecomponents/ProgressBar";
// import InitialSetup from './InitialSetup';
import InitialSetup from "@petcomponents/UpsertGroupPage/InitialSetup";
// import BasicInformation from './BasicInformation';
import BasicInformation from "@petcomponents/UpsertGroupPage/BasicInformation";
// import EligibilityRules from './EligibilityRules';
import EligibilityRules from "@petcomponents/UpsertGroupPage/EligibilityRules";
// import GroupContacts from './GroupContacts';
import GroupContacts from "@petcomponents/GroupContacts";
// import BillingInformation from './BillingInformation';
import BillingInformation from "@petcomponents/UpsertGroupPage/BillingInformation";
import GroupCensus from "@petcomponents/Renewals/GroupCensus";
import HeaderRightContainer from "@basecomponents/HeaderRightContainer";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import config from "@src/config.json";

/**
 * @category Renewals
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const Renewals = ({ location, ...rest }) => {
  const { t } = useTranslation()
  const name = 'step';
  const [products, setProducts] = useState(
    ['ACCIDENT', 'ACCIDENT AND ILLNESS'].map((x) => {
      return { planName: x };
    })
  );
  const params = useParams();
  const groupId = get(params, 'groupId', '');
  const { apiData: groups = {} } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );
  const parentGroupId = get(groups, 'parentGroupId', '');
  const [showModal, setShowModal] = useState(false);
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });

  return (
    <Route
      header={{
        rightContainer: (
          <>
            <HeaderRightContainer
              headerActions={[
                {
                  action: () => setShowModal(true),
                  icon: 'remove',
                  isDisabled: get(groups, 'status', '') === 'EXPIRED',
                  label: 'Cancel Renewal',
                },
              ]}
              permission="groupEmployee.actions"
              {...rest}
            />
            {showModal === true && (
              <ApolloConsumer>
                {(client) => {
                  const cancelRenewalCall = async () => {
                    return client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          name: 'delete-group-renewal',
                          params: JSON.stringify({
                            groupId,
                          }),
                        },
                      })
                      .then(() => {
                        setSucessSnack(
                          'Cancelled Renewal',
                          config.notificationDuration
                        );
                        return navigate(`/groups/${parentGroupId}/details`);
                      })
                      .catch((e) => {
                        setShowModal(false);
                        return setErrorSnack(
                          `There was an error: ${e.message}`,
                          config.notificationDuration
                        );
                      });
                  };
                  return (
                    <Modal
                      closeText={t('common.no')}
                      isOpen
                      onClose={() => setShowModal(false)}
                      onSubmit={cancelRenewalCall}
                      submitText={t('common.yes')}
                      title={t('groupEmployees.dashboardPage.confirmSubmission')}
                    >
                      <Box>
                        By clicking yes, you are confirming renewal
                        cancellation.
                        <Box
                          sx={{
                            display: 'flex',
                            fontWeight: 'bold',
                            justifyContent: 'center',
                            my: 5,
                          }}
                        >
                          THIS ACTION CAN NOT BE UNDONE.
                        </Box>
                        Do you wish to continue?
                      </Box>
                    </Modal>
                  );
                }}
              </ApolloConsumer>
            )}
          </>
        ),
        title: 'Renewal',
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Renewal
      </Box>
      <ProgressBar
        defaultTab="0"
        name={name}
        slides={[
          'Group Census',
          'Initial Setup',
          'Basic Information',
          'Eligibility Rules',
          'Group Contacts',
          'Billing Information',
          'Class Configuration',
          'Plan Setup',
          'Plan Configuration',
          'Commissions',
          'Upload Rating Table',
          'Summary',
          'Master Policy',
        ]}
        tabs={[
          {
            render: () => <GroupCensus location={location} {...rest} />,
            value: '0',
          },
          {
            render: () => (
              <InitialSetup location={location} {...rest} isRenewal />
            ),
            value: '1',
          },
          {
            render: () => (
              <BasicInformation isRenewal location={location} {...rest} />
            ),
            value: '2',
          },
          {
            render: () => (
              <EligibilityRules isRenewal location={location} {...rest} />
            ),
            value: '3',
          },
          {
            render: () => (
              <GroupContacts location={location} {...rest} isRenewal />
            ),
            value: '4',
          },
          {
            render: () => (
              <BillingInformation location={location} {...rest} isRenewal />
            ),
            value: '5',
          },
          {
            render: () => (
              <ClassConfiguration
                isRenewal
                location={location}
                tableType="configureClassesSection"
                {...rest}
              />
            ),
            value: '6',
          },
          {
            render: () => (
              <PlanConfiguration
                isRenewal
                location={location}
                setProducts={setProducts}
                {...rest}
              />
            ),
            value: '7',
          },
          {
            render: () => (
              <PlanPricing
                location={location}
                products={products}
                {...rest}
                isRenewal
              />
            ),
            value: '8',
          },
          {
            render: () => (
              <Commissions isRenewal location={location} {...rest} />
            ),
            value: '9',
          },
          {
            render: () => (
              <RatingTable location={location} {...rest} isRenewal />
            ),
            value: '10',
          },
          {
            render: () => (
              <SummaryPage location={location} {...rest} isRenewal />
            ),
            value: '11',
          },
          {
            render: () => (
              <MasterPolicyPdfViewer location={location} {...rest} isRenewal />
            ),
            value: '12',
          },
        ]}
      />
    </Route>
  );
};

Renewals.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Renewals;
