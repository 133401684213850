import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import GetData from "@src/utilities/get-data";
import User from "@petcomponents/Variant/ModuleInfo/User";
import dateUtils from "@src/utilities/date-utils";
import EmptyVariant from "@petcomponents/Variant/EmptyVariant";

/**
 * Employee variant
 *
 * @category Components
 * @param {string} id
 * @param {string} type
 * @returns {React.FC}
 */
const Employee = ({ id, type }) => {
  const { apiData } = GetData(
    'get-employee-by-id',
    JSON.stringify({ id }),
    false
  );
  if (type === 'terminationDate') {
    const dValue = dateUtils.getUTCDateOnly(get(apiData, 'terminationDate'));
    if (get(apiData, 'terminationDate', '') !== '') {
      return dValue;
    }
    if (dValue === 'Invalid Date') {
      return <EmptyVariant value="Invalid Date" />;
    }
  }
  return <User id={get(apiData, 'userId', '')} />;
};

Employee.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Employee;
