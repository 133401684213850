import { Router } from '@reach/router';
import React from 'react';
import Box from '@basecomponents/Box';
import { useTranslation } from 'react-i18next';
import Route from "@petcomponents/Route";
import NotFoundPage from "@src/routes/ihc/404";
import Icon from "@src/components/Icon";

const imgSx = {
  alignItems: 'center',
  display: 'flex',
  height: '35px',
  justifyContent: 'center',
  mr: 2,
  width: '35px',
};

const containerSx = {
  alignItems: 'center',
  borderRadius: 5,
  boxShadow: 5,
  display: 'flex',
  justifyContent: 'center',
  m: 5,
  p: 1,
  width: '150px',
};

const HelplineContent = ({ ...rest }) => {
  const { t } = useTranslation()
  return (
    <Route
      header={{
        icon: 'helpcenter',
        title: '24/7 Vet Helpline',
        type: 'Helpline',
      }}
      isPrivate
      permission="provider.helpline.page.dashboard.::mga"
      {...rest}
    >
      <Box
        sx={{
          bg: 'white',
          borderRadius: 2,
          boxShadow: 1,
          display: 'flex',
          flexDirection: 'column',
          p: 5,
        }}
      >
        <Box
          sx={{
            color: 'primary',
            fontSize: 6,
            fontWeight: 'bold',
          }}
        >
          {t('helpLineScreen.veterinarySupport')}
        </Box>
        <Box
          sx={{
            fontSize: 2,
          }}
        >
          {t('helpLineScreen.certificateNumber')}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <Box as="a" href="tel:8882561119" sx={containerSx}>
            <Icon color="accentSecondaryDark" svg="phone" sx={imgSx} />
            <Box>888-256-1119</Box>
          </Box>
          <Box
            as="a"
            href="https://www.vet-fone.co.uk/petpartners/chat"
            sx={containerSx}
            target="_blank"
          >
            <Icon color="accentSecondaryDark" svg="mail" sx={imgSx} />
            <Box>{t('helpLineScreen.chatWithUs')}</Box>
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: 2,
          }}
        >
          <Box sx={{ fontWeight: 'bold' }}>Note:</Box>
          <Box>
            {t('helpLineScreen.petPartnersNote1')}
          </Box>
          <Box dangerouslySetInnerHTML={{ __html: t('helpLineScreen.petPartnersNote2', { url: "https://www.petpartners.com/vet-helpline-terms-of-use" }) }} />
        </Box>
      </Box>
      <Box
        sx={{
          bg: 'white',
          borderRadius: 2,
          boxShadow: 1,
          display: 'flex',
          flexDirection: 'column',
          mt: 3,
          p: 5,
        }}
      >
        <Box
          sx={{
            color: 'primary',
            fontSize: 5,
            fontWeight: 'bold',
            pb: 3,
            pt: 3,
          }}
        >
          {t('helpLineScreen.policyHolders')}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            as="a"
            href="https://petgeniusrx.com/petpartners/"
            sx={{ ...containerSx, background: 'white' }}
            target="_blank"
          >
            <Icon color="accentSecondaryDark" svg="pill" sx={imgSx} />
            <Box>{t('helpLineScreen.petMeds')}</Box>
          </Box>
        </Box>
        <Box
          sx={{
            fontSize: 2,
          }}
        >
          <Box sx={{ display: 'inline-block', fontWeight: 'bold', mr: 1 }}>
            {t('helpLineScreen.note')}
          </Box>
          <Box sx={{ color: '#808080', display: 'inline-block' }}>
            {t('helpLineScreen.petMedsNote')}
          </Box>
        </Box>
      </Box>
    </Route>
  );
};

const Helpline = (props) => (
  <Router>
    <HelplineContent {...props} path="/vet-helpline" />
    <NotFoundPage path="/404" />
  </Router>
);

export default Helpline;
