import Box from '@basecomponents/Box';
import { navigate } from '@reach/router';
import Button from '@basecomponents/Button';
import Input from '@basecomponents/Input';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React, { useContext, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Field, Form as FinalForm, Form } from 'react-final-form';
import styled, { ThemeContext } from 'styled-components';
import { print } from 'graphql';
import { get } from 'lodash';
import {
  checkRequired,
  composeValidations,
  email,
  required,
} from '@utils/validators';
import EmailSentModal from '@basecomponents/EmailSentModal';
import { EmailButton } from '@basecomponents/EmailButton';
import { PhoneButton } from '@basecomponents/PhoneButton';
import { labelHandler } from '@utils/label-utils';
import AddressFormSection from '@petcomponents/AddressFormSection';
import InputDate from '@basecomponents/InputDate';
import dateUtils from '@utils/date-utils';
import InputCheckbox from '@basecomponents/InputCheckbox';
import { useTranslation } from 'react-i18next';
import { OnChange } from 'react-final-form-listeners';
import Dropdown from '@basecomponents/Dropdown';
import i18n from '@src/utilities/i18n';
import Logo from '@petcomponents/Logo';
import ToolbarButton from '@basecomponents/ToolbarButton';
import config from '@src/config.json';
import pixelToNumber from '@src/utilities/pixel-to-number';
import useSnackbar from '@src/utilities/use-snackbar';
import mutationRemoteAction from '@mutations/mutation-remote-action.gql';
import useSsmData from '@utils/hooks/useSsmData';

const HoverableButton = styled(Button)`
  transition: 0.25s ease-in-out;

  &:hover {
    transition: 0.25s ease-in-out;
    background-color: ${(p) => p.theme.colors.accentLight};
  }
`;
const CLIENT_ID = config.amplify.Auth.userPoolWebClientId;
const USERPOOL_DOMAIN_NAME = config.amplify.Auth.userPoolDomainName;
const BASE_URL = config.baseURL;
const AddEmailID = ({ location }) => {
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [wasResetCodeSent, setWasResetCodeSent] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [redirectEmail, setRedirect] = useState(false);
  const [isSendingPasscode, setIsSendingPasscode] = useState(false);
  const [verifyPassCode, setVerifyPassCode] = useState(false);
  const [verifyPassCodeProcess, setVerifyPassCodeProcess] = useState(false);
  const [modal, setModal] = useState(false);
  const [isSignInHelpOpen, setIsSignInHelpOpen] = useState(false);
  const { data: defaultUserId, errors: userIdError } = useSsmData(
    'iaic-default-user-id'
  );
  const { breakpoints, shadows } = useContext(ThemeContext);
  const { t } = useTranslation();
  const height = window.innerHeight;
  const width = window.innerWidth;
  const selectedLang = localStorage.getItem('lang');
  // eslint-disable-next-line camelcase
  const { empId, groupNumber, groupId } = parse(location.search);
  const backgroundSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        backgroundImage: `url(${config.promoImageUrl})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '95%',
      };
    }
    return {};
  };

  const boxShadowSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        boxShadow: shadows[4],
      };
    }
    return {};
  };

  const createEmployee = async (currentValues) => {
    const { dateOfHire, terminationDate, ...values } = currentValues;
    setAddUser(true);
    const hireDate = dateOfHire || new Date();
    if (userIdError) {
      setErrorSnack('User Id Missing', config.notificationDuration);
    }
    const params = {
      employeeData: {
        dateOfHire: dateUtils.setAPIDateOnly(hireDate),
        empId: get(values, 'empId', ''),
        employmentStatus: 'ACTIVE',
        fullTimePartTimeCode: '',
        locationName: '',
      },
      groupId,
      user: {
        address: { ...get(values, 'address', ''), country: 'US' },
        contacts: [
          {
            contactType: '',
            phoneNumber: '',
          },
        ],
        email: get(values, 'email', ''),
        firstName: get(values, 'firstName', ''),
        lastName: get(values, 'lastName', ''),
        middleName: '',
      },
      userId: defaultUserId,
      isWorkday: true,
    };
    const EmployeeData = await (
      await fetch(config.apollo.url, {
        body: JSON.stringify({
          query: print(mutationRemoteAction),
          variables: {
            name: 'add-employee-census',
            params: JSON.stringify({ ...params }),
          },
        }),
        headers: {
          'content-type': 'application/json',
          'x-api-key': config.apollo.apiKey,
        },
        method: 'POST',
      })
    ).json();
    if (EmployeeData.data) {
      const rawJson = JSON.parse(EmployeeData.data?.mutationRemoteAction?.data);
      const emailExists = rawJson?.user?.email;
      setSucessSnack(
        t('snack.success.redirecting'),
        config.notificationDuration
      );
      setRedirect(emailExists);
    } else {
      const errorJson = EmployeeData?.errors;
      const errorCode = JSON.parse(errorJson[0]?.message);

      if (errorCode?.statusCode === 504) {
        setSucessSnack(
          'Your profile will take a few more minutes to generate. Please close this page and try again from your Workday profile in a few minutes',
          config.notificationDuration
        );
      } else {
        setErrorSnack(
          t('snack.error.notRegistered'),
          config.notificationDuration
        );
      }
    }
  };

  const sendCode = async (email) => {
    const usersWithEmailAddress = await (
      await fetch(config.apollo.url, {
        body: JSON.stringify({
          query: print(mutationRemoteAction),
          variables: {
            name: 'user-exists',
            params: JSON.stringify({ email }),
          },
        }),
        headers: {
          'content-type': 'application/json',
          'x-api-key': config.apollo.apiKey,
        },
        method: 'POST',
      })
    ).json();
    if (usersWithEmailAddress?.data) {
      const rawJson = JSON.parse(
        usersWithEmailAddress.data?.mutationRemoteAction?.data
      );
      const emailExists = rawJson?.content?.length > 0;
      if (emailExists) {
        setIsSendingPasscode(false);
        return setErrorSnack(
          t('snack.error.emailInUse'),
          config.notificationDuration
        );
      }
    } else {
      setIsSendingPasscode(false);
      return setErrorSnack(t('snack.error.error'), config.notificationDuration);
    }
    const resendVerification = await (
      await fetch(config.apollo.url, {
        body: JSON.stringify({
          query: print(mutationRemoteAction),
          variables: {
            name: 'send-email-code',
            params: JSON.stringify({ email }),
          },
        }),
        headers: {
          'content-type': 'application/json',
          'x-api-key': config.apollo.apiKey,
        },
        method: 'POST',
      })
    ).json();
    if (resendVerification.data) {
      setModal(t('sso.verificationCodeSent', { email }));
      setWasResetCodeSent(true);
    } else {
      setErrorSnack(t('snack.error.error'), config.notificationDuration);
    }
    setIsSendingPasscode(false);
  };

  const checkCode = async (groupNumber, empId, email, passcode) => {
    setVerifyPassCode(false);
    const resendVerification = await (
      await fetch(config.apollo.url, {
        body: JSON.stringify({
          query: print(mutationRemoteAction),
          variables: {
            name: 'check-email-code',
            params: JSON.stringify({
              email,
              empId,
              flow: 'IDP',
              groupNumber,
              passcode,
            }),
          },
        }),
        headers: {
          'content-type': 'application/json',
          'x-api-key': config.apollo.apiKey,
        },
        method: 'POST',
      })
    ).json();
    // redirect to '/login' when redirect=alternate in path
    if (resendVerification.data) {
      const { message, success } = JSON.parse(
        resendVerification.data.mutationRemoteAction.data
      );
      if (success) {
        setVerifyPassCodeProcess(false);
        setVerifyPassCode(true);
      } else {
        setVerifyPassCodeProcess(false);
        setVerifyPassCode(false);
        setErrorSnack(message, config.notificationDuration);
      }
    } else {
      setVerifyPassCodeProcess(false);
      setVerifyPassCode(false);
      setErrorSnack(
        t('snack.error.notRegistered'),
        config.notificationDuration
      );
    }
  };

  if (redirectEmail) {
    navigate(
      `${USERPOOL_DOMAIN_NAME}/authorize?response_type=token&identity_provider=${groupNumber}&client_id=${CLIENT_ID}&redirect_uri=${BASE_URL}sso`
    );
  }
  return (
    <Box
      bg={{ _: 'primary', lg: 'white' }}
      display="flex"
      flexDirection={{ _: 'column', lg: 'row' }}
      height={height}
      width={width}
    >
      <EmailSentModal modal={modal} setModal={setModal} />
      <Box
        bg={{ _: 'primary', lg: 'white' }}
        display="flex"
        height={{ lg: '100%' }}
        justifyContent={{ _: 'center', lg: 'flex-start' }}
        sx={{ ...backgroundSx() }}
        width={{ lg: '50%' }}
      >
        {width >= pixelToNumber(breakpoints[3]) ? (
          <Logo
            marginLeft={{ lg: 5 }}
            mono={width < pixelToNumber(breakpoints[3])}
            sx={{
              mt: '30px',
              top: '0',
            }}
          />
        ) : (
          <Logo
            mono
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              mt: '50px',
              p: 1,
              svg: {
                height: 'auto',
              },
              width: '20rem',
            }}
          />
        )}
        {width >= pixelToNumber(breakpoints[3]) && (
          <Box
            as="h1"
            color="primary"
            fontSize={7}
            sx={{
              ml: 5,
              mt: 5,
              position: [null, null, null, null, 'absolute'],
              top: '13rem',
              width: '30%',
            }}
          >
            <Box>{t('auth.subtitle1')}</Box>
            <Box>{t('auth.subtitle2')}</Box>
          </Box>
        )}
      </Box>
      <Box
        alignItems={['flex-start', null, 'center']}
        bg="primary"
        display="flex"
        height={{ _: 'auto', lg: '100%' }}
        justifyContent="center"
        maxHeight={{ _: 'auto', lg: height }}
        overflowY={{ _: 'none', lg: 'scroll' }}
        p={5}
        sx={{
          ...boxShadowSx(),
        }}
        width={{ lg: '50%' }}
      >
        <FinalForm
          onSubmit={async (value) => createEmployee(value)}
          render={(formContext) => {
            return (
              <Box
                height="100%"
                maxWidth="maxWidths.smallForm"
                mx="auto"
                width="100%"
              >
                {config.canadaEnv && (
                  <Box
                    sx={{
                      position: 'fixed',
                      right: '1rem',
                      top: '1rem',
                      width: 300,
                    }}
                  >
                    <Form
                      initialValues={{
                        language: selectedLang || i18n.language,
                      }}
                      onSubmit={() => {}}
                      render={() => (
                        <>
                          <Field
                            component={Dropdown}
                            isSearchable={false}
                            name="language"
                            options={
                              config.canadaEnv
                                ? [
                                    {
                                      canEnglishValue: null,
                                      frenchValue: 'Français Canadien',
                                      label: 'Français Canadien',
                                      value: 'fr',
                                    },
                                    {
                                      canEnglishValue: null,
                                      frenchValue: null,
                                      label: 'Canadian English',
                                      value: 'caEn',
                                    },
                                  ]
                                : [
                                    {
                                      canEnglishValue: null,
                                      frenchValue: 'Français Canadien',
                                      label: 'Français Canadien',
                                      value: 'fr',
                                    },
                                    {
                                      canEnglishValue: null,
                                      frenchValue: null,
                                      label: 'Canadian English',
                                      value: 'caEn',
                                    },
                                    {
                                      canEnglishValue: null,
                                      frenchValue: null,
                                      label: 'American English',
                                      value: 'en',
                                    },
                                  ]
                            }
                          />
                          <OnChange name="language">
                            {(lang) => {
                              i18n.changeLanguage(lang);
                              localStorage.setItem('lang', lang);
                            }}
                          </OnChange>
                        </>
                      )}
                    />
                  </Box>
                )}
                <Box
                  color="white"
                  fontSize={7}
                  mb={[4, null, 6]}
                  textAlign={{ _: 'center', lg: 'left' }}
                >
                  {t('sso.verifyEmail')}
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  width="100%"
                >
                  <Box
                    as="button"
                    color="accentSecondaryDark"
                    fontSize={4}
                    fontWeight={600}
                    onClick={() => setIsSignInHelpOpen(!isSignInHelpOpen)}
                  >
                    {t('sso.signInHelp')}
                  </Box>
                </Box>
                {!config.canadaEnv && isSignInHelpOpen && (
                  <Box color="white" my={5}>
                    <Box mb={4}>
                      {t('common.contactIaicAdminTechnicalQuestions')}
                    </Box>
                    <PhoneButton number="844-738-4242" />
                    <EmailButton email="IAIC.pets@independenceamerican.com" />
                  </Box>
                )}
                {config.canadaEnv && isSignInHelpOpen && (
                  <Box color="white" my={5}>
                    <Box mb={4}>
                      {t('sso.contactOnepackAdminTechnicalQuestion')}
                    </Box>
                    <PhoneButton number="833-310-7225" />
                    <EmailButton email="mypolicy@onepackplan.ca" />
                  </Box>
                )}
                <form onSubmit={formContext.handleSubmit}>
                  <Field
                    component={Input}
                    initialValue={empId}
                    label={t('sso.employeeId')}
                    labelSx={{
                      color: 'white',
                    }}
                    name="empId"
                    readOnly
                    style={{ pointerEvents: 'none' }}
                  />
                  <Field
                    aria-label={t('sso.firstName')}
                    component={Input}
                    label={labelHandler(t('sso.firstName'), true)}
                    labelSx={{
                      color: 'white',
                    }}
                    name="firstName"
                    validate={required}
                  />
                  <Field
                    aria-label={t('sso.lastName')}
                    component={Input}
                    label={labelHandler(t('sso.lastName'), true)}
                    labelSx={{
                      color: 'white',
                    }}
                    name="lastName"
                    validate={required}
                  />
                  <Field
                    component={Input}
                    label={labelHandler(t('sso.email'), true)}
                    labelSx={{
                      color: 'white',
                    }}
                    name="email"
                    readOnly={wasResetCodeSent || verifyPassCode}
                    style={
                      wasResetCodeSent
                        ? { pointerEvents: 'none' }
                        : { pointerEvents: 'auto' }
                    }
                    validate={composeValidations(required, email)}
                  />
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <HoverableButton
                      disabled={
                        (!formContext.values.empId &&
                          !formContext.values.email &&
                          !formContext.values.groupNumber) ||
                        !!formContext.errors.empId ||
                        !!formContext.errors.email ||
                        !!formContext.errors.groupNumber ||
                        wasResetCodeSent ||
                        verifyPassCode
                      }
                      onClick={async () => {
                        setIsSendingPasscode(true);
                        await sendCode(
                          formContext.values.email.toLowerCase().trim()
                        );
                      }}
                      submitting={isSendingPasscode}
                      sx={{
                        bg: 'accent',
                        mr: 3,
                      }}
                      width="100%"
                    >
                      {t('sso.sendVerificationCode')}
                    </HoverableButton>
                    {wasResetCodeSent && !verifyPassCode && (
                      <CountdownCircleTimer
                        colors={[
                          ['#7ED321', 0.33],
                          ['#FF7F00', 0.33],
                          ['#FF0000', 0.33],
                        ]}
                        duration={300}
                        initialRemainingTime={300}
                        isPlaying
                        onComplete={() => setWasResetCodeSent(false)}
                        size={30}
                        strokeLinecap="square"
                        strokeWidth={3}
                      />
                    )}
                  </Box>
                  {wasResetCodeSent && !verifyPassCode && (
                    <>
                      <Field
                        component={Input}
                        label={t('sso.verificationCode')}
                        labelSx={{
                          color: 'white',
                        }}
                        name="passcode"
                        readOnly={!wasResetCodeSent}
                        style={
                          !wasResetCodeSent
                            ? { pointerEvents: 'none' }
                            : { pointerEvents: 'auto' }
                        }
                        validate={composeValidations(required)}
                      />
                      <HoverableButton
                        disabled={
                          !formContext.values.email || verifyPassCodeProcess
                        }
                        onClick={async () => {
                          setVerifyPassCodeProcess(true);
                          await checkCode(
                            groupNumber,
                            empId,
                            formContext.values.email.toLowerCase().trim(),
                            formContext.values.passcode
                          );
                        }}
                        submitting={verifyPassCodeProcess}
                        sx={{
                          bg: 'accent',
                          mt: 3,
                        }}
                        width="100%"
                      >
                        {t('sso.verifyEmailLabel')}
                      </HoverableButton>
                    </>
                  )}
                  <AddressFormSection
                    label="address"
                    labelSx={{
                      color: 'white',
                    }}
                  />
                  <Field
                    aria-label="Date of Hire"
                    component={InputDate}
                    label={labelHandler(t('sso.dateOfHire'))}
                    labelSx={{
                      color: 'white',
                    }}
                    name="dateOfHire"
                  />
                  <Field
                    aria-label={t('common.iAgree')}
                    component={InputCheckbox}
                    errorSx={{
                      position: 'absolute',
                      top: '60px',
                    }}
                    inputWrapperSx={{
                      display: 'inline-flex',
                      float: 'left',
                      marginTop: '15px',
                    }}
                    label={
                      <Box ml={3}>
                        {t('sso.confimPPI')}
                        <Box
                          as="a"
                          href="https://www.petpartners.com/terms-of-use"
                          sx={{
                            color: 'white',
                            textDecoration: 'underline',
                          }}
                          target="_blank"
                        >
                          {t('sso.terms')}
                        </Box>{' '}
                        and{' '}
                        <Box
                          as="a"
                          href="https://www.petpartners.com/privacy-policy"
                          sx={{
                            color: 'white',
                            display: 'inline-flex',
                            textDecoration: 'underline',
                          }}
                          target="_blank"
                        >
                          {t('sso.privacyPolicy')}
                          <Box color="#B30000">*</Box>
                        </Box>{' '}
                      </Box>
                    }
                    labelSx={{
                      color: 'white',
                      display: 'inline-flex',
                    }}
                    name="acceptance"
                    type="checkbox"
                    validate={checkRequired}
                    wrapperSx={{
                      alignItems: 'center',
                      display: 'flex',
                      mt: 0,
                      padding: 3,
                      width: '25rem',
                    }}
                  />
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      my: 6,
                      pb: 6,
                      width: '100%',
                    }}
                  >
                    <ToolbarButton
                      isDisabled={addUser || !verifyPassCode}
                      label={t('common.submit')}
                      submitting={formContext.submitting}
                      sx={{
                        ml: 0,
                        mt: 0,
                      }}
                      type="submit"
                      width="100%"
                    />
                  </Box>
                </form>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

AddEmailID.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      empId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default AddEmailID;
