import AutoSave from '@basecomponents/AutoSave';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import noop from 'lodash/noop';
import { Form } from 'react-final-form';
import { Location } from '@reach/router';
import { navigate } from 'gatsby';
import { ParentSize } from '@vx/responsive';
import { ThemeContext } from 'styled-components';
import DashboardFilterFormSection from "@petcomponents/DashboardFilterForm/DashboardFilterFormSection";
import setDashboardURL from "@src/utilities/set-dashboard-url";
import ToolbarButton from "@basecomponents/ToolbarButton";

const NUM_OF_FILTERS = 5;

/**
 * @category Components
 * @param {Function} applyFilter
 * @param {object} filters
 * @param {object} page
 * @param {boolean} showFilters
 * @param {Function} sort
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const DashboardFilterForm = ({
  applyFilter,
  filters,
  page,
  showFilters,
  sort,
}) => {
  const { filterOrder, values } = filters;
  const theme = useContext(ThemeContext);

  return (
    <Box
      sx={{
        height: 'auto',
        maxHeight: showFilters ? '300px' : 0,
        overflow: showFilters ? 'visible' : 'hidden',
        transition: 'max-height 0.5s ease-in-out',
      }}
    >
      <ParentSize>
        {({ width }) => {
          const fieldWidth = (width - 75) / NUM_OF_FILTERS;
          return (
            <Location>
              {({ location: { pathname } }) => (
                <Form
                  initialValues={values}
                  onSubmit={noop}
                  render={() => (
                    <Box
                      sx={{
                        alignItems: 'center',
                        border: `1px solid ${theme.colors.accent}`,
                        borderRadius: 2,
                        display: 'flex',
                        flexWrap: 'wrap',
                        fontSize: 2,
                        justifyContent: 'center',
                        mb: 5,
                      }}
                    >
                      <AutoSave
                        save={(all, changed, added, deleted, values) => {
                          return applyFilter
                            ? applyFilter(values)
                            : navigate(
                                setDashboardURL({
                                  filters,
                                  filterValues: values,
                                  page: {
                                    offset: 0,
                                    pageSize: page.pageSize,
                                  },
                                  pathname,
                                  sort,
                                }),
                                { replace: true }
                              );
                        }}
                      />
                      {filterOrder.map((key) => {
                        return (
                          <Box
                            key={key}
                            sx={{
                              minWidth: '180px',
                              p: 5,
                              width: fieldWidth,
                            }}
                          >
                            <DashboardFilterFormSection
                              filter={filters[key]}
                              filterKey={key}
                            />
                          </Box>
                        );
                      })}
                      <Box sx={{ p: 5 }}>
                        <ToolbarButton
                          alt="clear filter"
                          aria-label="clear filter"
                          bg="error"
                          icon="close"
                          name="clear filter"
                          onClick={() => navigate(pathname)}
                          type="button"
                        />
                      </Box>
                    </Box>
                  )}
                />
              )}
            </Location>
          );
        }}
      </ParentSize>
    </Box>
  );
};

DashboardFilterForm.propTypes = {
  applyFilter: PropTypes.func,
  filters: PropTypes.shape({
    filterOrder: PropTypes.arrayOf(PropTypes.string),
    values: PropTypes.shape({}),
  }).isRequired,
  page: PropTypes.shape({
    offset: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  showFilters: PropTypes.bool.isRequired,
  sort: PropTypes.string,
};
DashboardFilterForm.defaultProps = {
  applyFilter: null,
  page: {},
  sort: '',
};

export default DashboardFilterForm;
