import Box from '@basecomponents/Box';
import React, {useContext, useState} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import {navigate, useParams} from '@reach/router';
import {get, isEmpty} from 'lodash';
import {ApolloConsumer} from '@apollo/client';
import {AuthContext} from '@basecomponents/Auth';
import {required} from '@utils/validators';
import {labelHandler} from '@utils/label-utils';
import {E_CONSENT_URLS, QLE_TYPE} from '@utils/constants';
import SingleDatePicker from '@basecomponents/SingleDatePicker';
import dateUtils from '@utils/date-utils';
import {useTranslation} from 'react-i18next';
import i18n from '@src/utilities/i18n';
import Modal from '@basecomponents/Modal';
import AddPet from '@petcomponents/GroupEmployees/Upsert/PetInfo/AddPet';
import ToolbarButton from '@basecomponents/ToolbarButton';
import Route from '@petcomponents/Route';
import GetData from '@src/utilities/get-data';
import Spinner from '@basecomponents/Spinner';
import UploadFile from '@petcomponents/UploadFile';
import InputDropdown from '@basecomponents/Dropdown';
import remoteActionQuery from '@queries/remote-action.gql';
import useSnackbar from '@src/utilities/use-snackbar';
import InputText from '@basecomponents/Input';
import InputCheckbox from '@basecomponents/InputCheckbox';
import gaEvent from '@src/utilities/gaEvent';
import config, {variation} from '@src/config.json';

/**
 * @category Employee
 * @param {*} rest
 * @returns {React.FC}
 */
const LateEnrollment = ({ ...rest }) => {
  const start = Date.now();
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const { t } = useTranslation();

  const [agreeMPI, setAgreeMPI] = useState(false);
  const [agreeCASL, setAgreeCASL] = useState(null);
  const [agreeFW, setAgreeFW] = useState(false);
  const [agreeAA, setAgreeAA] = useState(false);
  const [agreeEC, setAgreeEC] = useState(null);
  const [invalidSelection, setInvalidSelection] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);
  const [showFinalModal, setShowFinalModal] = useState(false);
  const [createQle, setCreateQle] = useState(false);
  const [otherPremiumAmount, setOtherPremiumAmount] = useState(-1);
  const [canSubmit, setCanSubmit] = useState(false);
  const [premiumChanges, setPremiumChanges] = useState(false);
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });

  const onSubmit = () => {
    if (
      agreeMPI && agreeFW && agreeAA && agreeEC !== null && config.canadaEnv
        ? agreeCASL !== null
        : agreeCASL === null && premiumChanges
    ) {
      setShowFinalModal(true);
      setShowAgreement(false);
    } else {
      setShowAgreement(true);
    }
  };

  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const userId = get(user, 'customSystemUserId', '');
  const queryParams = useParams();
  const employeeId = get(queryParams, 'employeeId', '');
  const {
    apiData: { ReasonType = [] },
    loading: enumLoader,
  } = GetData('get-enum-options', JSON.stringify([{ params: 'ReasonType' }]));
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const { apiData: breeds, loading } = GetData(
    'get-all-breeds',
    JSON.stringify([])
  );
  const { apiData: employee = {}, loading: employeeLoader } = GetData(
    'get-group-employee-by-id',
    JSON.stringify({ id: employeeId })
  );
  const oldId = get(employee, 'parentEmployee.employeeId', null);
  const groupId = get(employee, 'groupId', null);
  const { apiData: groups = {}, loading: groupLoading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );
  const parentGroupId = get(groups, 'parentGroupId');
  const { apiData: parentGroups = {}, loading: parentGroupLoading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: parentGroupId }),
    !parentGroupId
  );
  const renewalGroupId = get(groups, 'renewalGroup.groupId');
  const { apiData: renewalGroup = {}, loading: renewalGroupLoading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: renewalGroupId }),
    !renewalGroupId
  );
  const { apiData: coverageInfo, loading: coverageLoader } = GetData(
    'get-coverage-info',
    JSON.stringify({
      employeeId,
    }),
    employeeId === null
  );
  const { apiData: parentCoverageInfo = {}, loading: parentCoverageLoader } =
    GetData(
      'get-coverage-info',
      JSON.stringify({
        employeeId: oldId,
      }),
      oldId === null
    );

  const {
    apiData: renewalCoverageInfo = {},
    loading: renewalParentCoverageLoader,
  } = GetData(
    'get-coverage-info',
    JSON.stringify({
      employeeId: get(employee, 'renewalEmployee.employeeId'),
    }),
    isEmpty(get(employee, 'renewalEmployee')) ||
      get(employee, 'renewalEmployee.employeeId') === null
  );
  const { coverages, planChoiceEmployees = [] } = coverageInfo;
  const {
    coverages: parentCoverages,
    planChoiceEmployees: parentPlanChoiceEmployees = [],
    employeeDates: parentEmployeeDates,
  } = parentCoverageInfo;
  const {
    coverages: renewalCoverages,
    planChoiceEmployees: renewalPlanChoiceEmployees = [],
    employeeDates: renewalEmployeeDates,
  } = renewalCoverageInfo;
  const { planChoiceId: selectedPlan } =
    planChoiceEmployees.find(
      ({ acceptGroupPlanChoice }) => acceptGroupPlanChoice
    ) || {};
  const productPlanId = selectedPlan
    ? coverages.find(({ planChoiceId }) => planChoiceId === selectedPlan)
        .productPlanId
    : null;

  const { apiData: summary = {}, loading: summaryLoader } = GetData(
    'get-enrollment-summary',
    JSON.stringify({ employeeId })
  );

  const { apiData: paramsData = {}, PALoading } = GetData(
    'get-ssm-params',
    JSON.stringify({ paramName: `${config.environment}_modelLaw_PA` })
  );

  const { apiData: paramsOHData = {}, loading: OHLoading } = GetData(
    'get-ssm-params',
    JSON.stringify({ paramName: `${config.environment}_modelLaw_OH` })
  );

  const { groupResponse = {} } = summary;
  const groupMga = get(groupResponse, 'mga', '');
  let url = '';
  if (i18n.language === 'fr' && groupMga === 'PTZ_SODACO') {
    url = E_CONSENT_URLS.PTZ_SODACO_FR;
  } else {
    url = variation === 'demo' ? 'DEMO' : groupMga;
  }
  const { planChoiceId: parentSelectedPlan } =
    parentPlanChoiceEmployees.find(
      ({ acceptGroupPlanChoice }) => acceptGroupPlanChoice
    ) || {};
  const parentProductPlanId = parentSelectedPlan
    ? parentCoverages.find(
        ({ planChoiceId }) => planChoiceId === parentSelectedPlan
      ).productPlanId
    : null;
  const { planChoiceId: renewalSelectedPlan } =
    renewalPlanChoiceEmployees.find(
      ({ acceptGroupPlanChoice }) => acceptGroupPlanChoice
    ) || {};
  const renewalProductPlanId = renewalSelectedPlan
    ? renewalCoverages.find(
        ({ planChoiceId }) => planChoiceId === renewalSelectedPlan
      ).productPlanId
    : null;
  const currentDate =
    get(employee, 'relativeDatetime') === null
      ? dateUtils.getLocaleDateOnly(new Date())
      : dateUtils.getLocaleDateOnly(get(employee, 'relativeDatetime'));

  const enrollmentEndDate = dateUtils.getUTCDateOnly(
    get(groups, 'plan.planChoices.0.enrollmentToDate')
  );
  const renewalEnrollmentEndDate = dateUtils.getUTCDateOnly(
    get(renewalGroup, 'plan.planChoices.0.enrollmentToDate')
  );
  const parentEnrollmentEndDate = dateUtils.getUTCDateOnly(
    get(parentGroups, 'plan.planChoices.0.enrollmentToDate')
  );
  // Prod #441 - Compare date values as dates and not strings
  const isLate = Date.parse(enrollmentEndDate) < Date.parse(currentDate);
  const parentIsLate = parentEnrollmentEndDate
    ? Date.parse(parentEnrollmentEndDate) < Date.parse(currentDate)
    : false;
  const renewalIsLate = renewalEnrollmentEndDate
    ? Date.parse(renewalEnrollmentEndDate) < Date.parse(currentDate)
    : false;

  const modalMenu = (form, handleSubmit) => {
    if (form.getState().valid) {
      if (invalidSelection) {
        return (
          <Modal
            closeText="Ok"
            isOpen
            onClose={() => {
              setShowFinalModal(false);
            }}
            title="Ineligible Pet"
          >
            <Box>
              This selection of pet criteria is not supported, this pet is not
              eligible for coverage.
            </Box>
          </Modal>
        );
      }
      return (
        <Modal
          closeText={t('common.no')}
          isOpen
          onClose={() => {
            setShowFinalModal(false);
          }}
          onSubmit={handleSubmit}
          submitText={t('common.yes')}
          title={t('groupEmployees.enrollmentPage.confirmSubmission')}
        >
          <Box>
            {t('groupEmployees.enrollmentPage.agreeToEnroll')}
            <Box
              sx={{
                display: 'flex',
                fontWeight: 'bold',
                justifyContent: 'center',
                my: 5,
              }}
            >
              {t('common.cannotBeUndone')}
            </Box>
            {t('common.wishToContinue')}
          </Box>
        </Modal>
      );
    }
    form.submit();
    return (
      <Modal
        closeText={t('common.close')}
        isOpen
        onClose={() => {
          setShowFinalModal(false);
        }}
        title={t(
          'groupEmployees.enrollmentPage.lateEnrollmentPage.missingFields'
        )}
      >
        <Box>
          {t(
            'groupEmployees.enrollmentPage.lateEnrollmentPage.fillMissingPetInfo'
          )}
        </Box>
      </Modal>
    );
  };

  if (
    loading ||
    groupLoading ||
    parentGroupLoading ||
    renewalGroupLoading ||
    employeeLoader ||
    coverageLoader ||
    parentCoverageLoader ||
    renewalParentCoverageLoader ||
    summaryLoader ||
    PALoading ||
    OHLoading ||
    enumLoader
  ) {
    return <Spinner />;
  }
  return (
    <ApolloConsumer>
      {(client) => {
        const apiCall = (pathName, params) =>
          client.query({
            fetchPolicy: 'no-cache',
            query: remoteActionQuery,
            variables: {
              name: pathName,
              params: JSON.stringify(params),
            },
          });
        const onAddPet = (values) => {
          gaEvent('Add Pet', 'Pet Information Set', role, start);
          const { acquisitionDate, fileUpload = [], pet, reasonType } = values;
          if (createQle) {
            const addedPets = {
              addedDependents: [
                {
                  deleted: false,
                  dependentData: { ...pet },
                  employeeId: isEmpty(get(employee, 'renewalEmployee'))
                    ? get(employee, 'parentEmployee.employeeId')
                    : get(employee, 'renewalEmployee.employeeId'),
                  relation: 'pet',
                  status: 'INACTIVE',
                },
              ],
              employeeId: isEmpty(get(employee, 'renewalEmployee'))
                ? get(employee, 'parentEmployee.employeeId')
                : get(employee, 'renewalEmployee.employeeId'),
              isWithinGroupOpenEnrollment: isEmpty(
                get(employee, 'renewalEmployee')
              )
                ? !renewalIsLate
                : !parentIsLate,
              relativeDate: dateUtils.setAPIDateOnly(currentDate),
              updatedDependents: [],
              userId,
            };
            apiCall('update-employee-dependents', addedPets)
              .then((response) => {
                const dependentResponse = JSON.parse(
                  get(response, 'data.remoteAction.data', '{}')
                );
                const employeeDependentId = get(
                  dependentResponse,
                  '0.0.employeeDependentId'
                );
                if (employeeDependentId && (parentIsLate || renewalIsLate)) {
                  const qleData = {
                    acquisitionDate: dateUtils.setAPIDateOnly(acquisitionDate),
                    createdBy: userId,
                    createdFor: get(employee, 'userId'),
                    dependentData: get(dependentResponse, '0'),
                    employeeId: isEmpty(get(employee, 'renewalEmployee'))
                      ? get(employee, 'parentEmployee.employeeId')
                      : get(employee, 'renewalEmployee.employeeId'),
                    groupId: isEmpty(get(employee, 'renewalEmployee'))
                      ? get(employee, 'parentGroup.groupId')
                      : get(employee, 'renewalGroup.groupId'),
                    groupName: get(employee, 'groupName'),
                    qleType: QLE_TYPE.NEW_PET,
                    reasonType,
                    requestedDate: new Date(),
                    requestStatus: 'UNASSIGNED',
                    supportingDocs: fileUpload,
                    userId,
                  };
                  apiCall('create-qle', qleData).catch((e) =>
                    setErrorSnack(
                      t('snack.error.errorWithMessage', { message: e.message }),
                      config.notificationDuration
                    )
                  );
                }
              })
              .catch((e) =>
                setErrorSnack(
                  t('snack.error.errorWithMessage', { message: e.message }),
                  config.notificationDuration
                )
              );
          }
          const addedPets = {
            addedDependents: [
              {
                deleted: false,
                dependentData: { ...pet },
                employeeId,
                relation: 'pet',
                status: 'INACTIVE',
              },
            ],
            employeeId,
            isWithinGroupOpenEnrollment: !isLate,
            relativeDate: dateUtils.setAPIDateOnly(currentDate),
            updatedDependents: [],
            userId,
          };

          return apiCall('update-employee-dependents', addedPets)
            .then((response) => {
              const dependentResponse = JSON.parse(
                get(response, 'data.remoteAction.data', '{}')
              );
              const employeeDependentId = get(
                dependentResponse,
                '0.0.employeeDependentId'
              );
              if (employeeDependentId && isLate) {
                const qleData = {
                  acquisitionDate: dateUtils.setAPIDateOnly(acquisitionDate),
                  createdBy: userId,
                  createdFor: get(employee, 'userId'),
                  dependentData: get(dependentResponse, '0'),
                  employeeId,
                  groupId: get(employee, 'groupId'),
                  groupName: get(employee, 'groupName'),
                  qleType: QLE_TYPE.NEW_PET,
                  reasonType,
                  requestedDate: new Date(),
                  requestStatus: 'UNASSIGNED',
                  supportingDocs: fileUpload,
                  userId,
                };
                return apiCall('create-qle', qleData)
                  .then(() => {
                    setSuccessSnack(
                      t('snack.success.eventCreatedSuccess'),
                      config.notificationDuration
                    );
                    navigate(`/group-employees/${employeeId}/details`, {
                      replace: true,
                    });
                  })
                  .catch((e) =>
                    setErrorSnack(
                      t('snack.error.errorWithMessage', { message: e.message }),
                      config.notificationDuration
                    )
                  );
              }
              if (employeeDependentId) {
                return navigate(`/group-employees/${employeeId}/details`, {
                  replace: true,
                });
              }
              setErrorSnack(
                t('snack.error.tryAgain'),
                config.notificationDuration
              );
            })
            .catch((e) =>
              setErrorSnack(
                t('snack.error.errorWithMessage', { message: e.message }),
                config.notificationDuration
              )
            );
        };

        const getPremiumAmountForOtherPolicy = (values) => {
          const attributes = {};
          const { species, age, weight, breed } = get(values, 'pet', {});
          if (species) {
            attributes.species = species;
          } else {
            delete attributes.species;
          }

          if (weight || weight === 0) {
            // if (species === 'Cat') {
            // attributes.breed = '(blank)';
            // } else {
            attributes.weight = weight;
            // }
          } else {
            delete attributes.weight;
          }

          if (age || age === 0) {
            attributes.age = age;
          } else {
            delete attributes.age;
          }
          if (species) {
            if (species === 'Cat') {
              // attributes.breed = '(blank)';
            } else {
              attributes.breed =
                breed === 'Mixed Breed' ? 'MIXED BREED' : 'PUREBRED';
            }
          } else {
            delete attributes.breed;
          }

          if (Object.keys(attributes).length > 0) {
            client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'get-plan-rates',
                  params: JSON.stringify({
                    groupId: isEmpty(get(employee, 'renewalEmployee'))
                      ? get(employee, 'parentGroup.groupId')
                      : get(employee, 'renewalGroup.groupId'),
                    productPlanId: isEmpty(get(employee, 'renewalEmployee'))
                      ? parentProductPlanId
                      : renewalProductPlanId,
                    ...attributes,
                  }),
                },
              })
              .then((response) => {
                const results = JSON.parse(
                  get(response, 'data.remoteAction.data', '')
                );
                if (
                  get(results, 'content[0].payPeriodPremiumAmount', -1) > -1
                ) {
                  setOtherPremiumAmount(
                    get(results, 'content[0].payPeriodPremiumAmount')
                  );
                  setInvalidSelection(
                    !createQle &&
                      !get(results, 'content[0].payPeriodPremiumAmount')
                  );
                } else {
                  setErrorSnack(
                    t('snack.error.unableCalculatePremium', {
                      policyType: isEmpty(get(employee, 'renewalEmployee'))
                        ? 'existing policy.'
                        : 'renewal policy.',
                    }),
                    config.notificationDuration
                  );
                  setCreateQle(!createQle);
                }
              })
              .catch(() =>
                setErrorSnack(
                  t('snack.error.calculatePrice'),
                  config.notificationDuration
                )
              );
          }
        };

        const renderDisclosureContent = () => {
          let disclosureContent;

          switch (true) {
            case paramsOHData.value === 'true' &&
              paramsOHData.name === `${config.environment}_modelLaw_OH`:
              disclosureContent = t('common.insurerDisclosureOH', {
                url: config.insurerDisclosuresUrlV3 || '#',
              });
              break;

            case paramsData.value === 'true':
              disclosureContent = t('common.insurerDisclosurePA', {
                url: config.insurerDisclosuresUrlV2 || '#',
              });
              break;

            default:
              disclosureContent = t('common.insurerDisclosure', {
                url: config.insurerDisclosuresUrl || '#',
              });
              break;
          }
          return disclosureContent;
        };

        return (
          <Route
            header={{
              title: t(
                'groupEmployees.enrollmentPage.lateEnrollmentPage.addNewPet'
              ),
              type: 'group-employees',
            }}
            isPrivate
            {...rest}
          >
            <Box as="h2" sx={{ py: 3 }}>
              {t('groupEmployees.enrollmentPage.lateEnrollmentPage.addNewPet')}
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                initialValues={{}}
                mutators={arrayMutators}
                onSubmit={(values) => onAddPet(values)}
                render={({ handleSubmit, values, form }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            flexWrap: 'wrap',
                            justifyContent: 'space-around',
                            maxWidth: '98vw',
                          }}
                        >
                          <Box
                            sx={{
                              bg: 'white',
                              borderRadius: 4,
                              boxShadow: 7,
                              m: 2,
                              pb: 2,
                            }}
                          >
                            <Box
                              as="h3"
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                ml:
                                  config.canadaEnv && i18n.language === 'fr'
                                    ? 0
                                    : 3,
                                my: 4,
                                width: '100%',
                              }}
                            >
                              {t(
                                'groupEmployees.enrollmentPage.petInformation'
                              )}
                            </Box>

                            <Field
                              aria-label={t(
                                'groupEmployees.enrollmentPage.lateEnrollmentPage.reasonForAddingPet'
                              )}
                              component={InputDropdown}
                              label={labelHandler(
                                t(
                                  'groupEmployees.enrollmentPage.lateEnrollmentPage.reasonForAddingPet'
                                ),
                                true
                              )}
                              name="reasonType"
                              options={ReasonType}
                              searchable={false}
                              validate={required}
                              wrapperSx={{
                                mb: 6,
                                mx: 'auto',
                                padding: 3,
                                width: '25rem',
                              }}
                              {...rest}
                            />
                            <AddPet
                              breeds={breeds}
                              canSubmit={setCanSubmit}
                              form={form}
                              groupId={get(employee, 'groupId', '')}
                              name="pet"
                              productPlanId={productPlanId}
                              province={get(
                                coverageInfo,
                                'location.address.state'
                              )}
                              setInvalidSelection={setInvalidSelection}
                              triggers={() => {
                                setCreateQle(false);
                                setOtherPremiumAmount(-1);
                              }}
                              values={get(values, 'pet', {})}
                              {...rest}
                            />
                            <Field
                              aria-label={t(
                                'groupEmployees.enrollmentPage.lateEnrollmentPage.petAcquisitionDate'
                              )}
                              component={SingleDatePicker}
                              label={labelHandler(
                                t(
                                  'groupEmployees.enrollmentPage.lateEnrollmentPage.petAcquisitionDate'
                                ),
                                true
                              )}
                              name="acquisitionDate"
                              validate={required}
                              wrapperSx={{ ...commonFieldSxHalf }}
                              {...rest}
                            />
                            {((get(
                              employee,
                              'parentGroup.isActiveGroup',
                              false
                            ) === true &&
                              ['ENROLLED'].includes(
                                get(employee, 'parentEmployee.status')
                              )) ||
                              ['ENROLLED'].includes(
                                get(employee, 'renewalEmployee.status')
                              )) && (
                              <InputCheckbox
                                checked={createQle}
                                input={{
                                  name: 'createQle',
                                  value: createQle ? '1' : '0',
                                }}
                                label={
                                  <Box ml={3}>
                                    {t(
                                      'groupEmployees.enrollmentPage.lateEnrollmentPage.addPetToPolicy',
                                      {
                                        policyName: isEmpty(
                                          get(employee, 'renewalEmployee')
                                        )
                                          ? 'ACTIVE'
                                          : 'RENEWAL',
                                      }
                                    )}
                                  </Box>
                                }
                                labelSx={{
                                  fontSize: '12px',
                                  margin: '0 0 3px',
                                  ml: 0,
                                  textTransform: 'capitalize',
                                }}
                                meta={{ error: '', touched: true }}
                                onChange={() => {
                                  setCreateQle(!createQle);
                                  getPremiumAmountForOtherPolicy(values);
                                }}
                                wrapperSx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  paddingLeft: '10px',
                                }}
                              />
                            )}
                            {createQle && otherPremiumAmount > -1 && (
                              <Box
                                sx={{
                                  ...commonFieldSxHalf,
                                  fontSize: '12px',
                                  fontWeight: 600,
                                  margin: '0 0 3px',
                                  ml: 0,
                                  textTransform: 'capitalize',
                                }}
                              >
                                {t(
                                  'groupEmployees.enrollmentPage.lateEnrollmentPage.policyPremiumIncreased',
                                  {
                                    policyStatus: isEmpty(
                                      get(employee, 'renewalEmployee')
                                    )
                                      ? 'ACTIVE'
                                      : 'RENEWAL',
                                    premiumAmount: otherPremiumAmount,
                                  }
                                )}
                              </Box>
                            )}
                            <Box
                              sx={{
                                borderRadius: 3,
                                m: 2,
                              }}
                            >
                              <Box
                                as="h3"
                                sx={{
                                  color: 'black',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  mb: 3,
                                  ml: 3,
                                  mt: 6,
                                  width: '100%',
                                }}
                              >
                                {t(
                                  'groupEmployees.enrollmentPage.lateEnrollmentPage.documentation'
                                )}
                              </Box>
                              <Box fontSize={3} m={3} maxWidth="300px">
                                {t(
                                  'groupEmployees.enrollmentPage.provideProofEvent'
                                )}
                                <br />
                                {t(
                                  'groupEmployees.enrollmentPage.ifYouAdpotedPet'
                                )}
                                <Box ml={6}>
                                  <ul style={{ listStyleType: 'disc' }}>
                                    <li>
                                      {t(
                                        'groupEmployees.enrollmentPage.billBreeder'
                                      )}
                                    </li>
                                    <li>
                                      {t(
                                        'groupEmployees.enrollmentPage.invoiceShelter'
                                      )}
                                    </li>
                                  </ul>
                                </Box>
                              </Box>
                              <Field
                                aria-label={t(
                                  'groupEmployees.enrollmentPage.lateEnrollmentPage.fileName'
                                )}
                                component={InputText}
                                label={labelHandler(
                                  t(
                                    'groupEmployees.enrollmentPage.lateEnrollmentPage.fileName'
                                  )
                                )}
                                name="fileName"
                                wrapperSx={{ ...commonFieldSxHalf }}
                                {...rest}
                              />
                              {(values.fileName || otherPremiumAmount) && (
                                <UploadFile
                                  allowMultiple
                                  employeeId={employeeId}
                                  files={get(values, 'fileUpload', [])}
                                  flow="lateEnrollment"
                                  form={form}
                                  groupId={get(employee, 'groupId', '')}
                                  name={values.fileName}
                                />
                              )}
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            bg: 'white',
                            borderRadius: 4,
                            boxShadow: 7,
                            m: 2,
                            pb: 2,
                          }}
                        >
                          <Box
                            sx={{
                              margin: '20px 10px 0',
                              padding: '0 20px',
                              textAlign: 'justify',
                            }}
                          >
                            <Box>
                              <Box fontWeight={600} mb="10px">
                                {t(
                                  'groupEmployees.enrollmentPage.addPetSection.misstatementPetInfo'
                                )}
                              </Box>
                              {t(
                                'groupEmployees.enrollmentPage.addPetSection.agreeLegend'
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '20px 0 0 0',
                              }}
                            >
                              <InputCheckbox
                                input={{
                                  name: 'agreeMpi',
                                  value: agreeMPI ? '1' : '0',
                                }}
                                label={<Box ml={3}>{t('common.agree')}</Box>}
                                labelSx={{
                                  fontSize: '12px',
                                  margin: '0 0 3px',
                                  ml: 0,
                                  textTransform: 'capitalize',
                                }}
                                meta={{ error: '', touched: true }}
                                onChange={() => setAgreeMPI(!agreeMPI)}
                                wrapperSx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              margin: '20px 10px 0',
                              padding: '0 20px',
                              textAlign: 'justify',
                            }}
                          >
                            <Box>
                              <Box fontWeight={600} mb="10px">
                                {t(
                                  'groupEmployees.enrollmentPage.addPetSection.fraudWarning'
                                )}
                              </Box>
                              {config.canadaEnv && (
                                <Box>
                                  {t(
                                    'groupEmployees.enrollmentPage.addPetSection.fraudWarningLegend'
                                  )}
                                </Box>
                              )}
                              {!config.canadaEnv && (
                                <Box>
                                  I acknowledge and understand that any person
                                  who knowingly provides false, incomplete, or
                                  misleading information to an insurance company
                                  for the purpose of defrauding the company is
                                  guilty of a crime and may be subject to fines,
                                  imprisonment, and denial of insurance
                                  benefits. See state specific fraud notice:{' '}
                                  <a
                                    href="https://www.petpartners.com/insurance-fraud-warnings"
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    Insurance Fraud Warnings
                                  </a>
                                </Box>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '20px 0 0 0',
                              }}
                            >
                              <InputCheckbox
                                input={{
                                  name: 'agreeFW',
                                  value: agreeFW ? '1' : '0',
                                }}
                                label={<Box ml={3}>{t('common.agree')}</Box>}
                                labelSx={{
                                  fontSize: '12px',
                                  margin: '0 0 3px',
                                  ml: 0,
                                  textTransform: 'capitalize',
                                }}
                                meta={{ error: '', touched: true }}
                                onChange={() => setAgreeFW(!agreeFW)}
                                wrapperSx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              margin: '20px 10px 0',
                              padding: '0 20px',
                              textAlign: 'justify',
                            }}
                          >
                            <Box>
                              <Box fontWeight={600} mb="10px">
                                {t(
                                  'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreement'
                                )}
                              </Box>
                              {t(
                                'groupEmployees.enrollmentPage.addPetSection.acknowledgmentAgreementLegend'
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '20px 0 0 0',
                              }}
                            >
                              <InputCheckbox
                                input={{
                                  name: 'agreeAA',
                                  value: agreeAA ? '1' : '0',
                                }}
                                label={<Box ml={3}>{t('common.agree')}</Box>}
                                labelSx={{
                                  fontSize: '12px',
                                  margin: '0 0 3px',
                                  ml: 0,
                                  textTransform: 'capitalize',
                                }}
                                meta={{ error: '', touched: true }}
                                onChange={() => setAgreeAA(!agreeAA)}
                                wrapperSx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              margin: '20px 10px 0',
                              padding: '0 20px',
                              textAlign: 'justify',
                            }}
                          >
                            <Box>
                              <Box fontWeight={600} mb="10px">
                                {t(
                                  'groupEmployees.enrollmentPage.addPetSection.premiumChanges'
                                )}
                              </Box>
                              {t(
                                'groupEmployees.enrollmentPage.addPetSection.premiumChangesLegend'
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                margin: '20px 0 0 0',
                              }}
                            >
                              <InputCheckbox
                                input={{
                                  name: 'premiumChanges',
                                  value: premiumChanges ? '1' : '0',
                                }}
                                label={<Box ml={3}>{t('common.agree')}</Box>}
                                labelSx={{
                                  fontSize: '12px',
                                  margin: '0 0 3px',
                                  ml: 0,
                                  textTransform: 'capitalize',
                                }}
                                meta={{ error: '', touched: true }}
                                onChange={() =>
                                  setPremiumChanges(!premiumChanges)
                                }
                                wrapperSx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              margin: '20px 10px 0',
                              padding: '0 20px',
                              textAlign: 'justify',
                            }}
                          >
                            <Box>
                              <Box fontWeight={600} mb="10px">
                                {t(
                                  'groupEmployees.enrollmentPage.addPetSection.eConsent'
                                )}
                              </Box>
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: t(
                                    'groupEmployees.enrollmentPage.addPetSection.eConsentLegend',
                                    { url: E_CONSENT_URLS[url] || url }
                                  ),
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: 'inline-flex',
                                flexDirection: 'row',
                                margin: '20px 0 0 0',
                              }}
                            >
                              <InputCheckbox
                                checked={agreeEC}
                                input={{ name: 'agreeEC', value: 'agreeEC' }}
                                label={<Box ml={3}>{t('common.agree')}</Box>}
                                labelSx={{
                                  fontSize: '12px',
                                  margin: '0 0 3px',
                                  ml: 0,
                                  textTransform: 'capitalize',
                                }}
                                meta={{ error: '', touched: true }}
                                onChange={() => setAgreeEC(true)}
                                type="radio"
                                wrapperSx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: 'inline-flex',
                                flexDirection: 'row',
                                margin: '10px 0 0 40px',
                              }}
                            >
                              <InputCheckbox
                                checked={agreeEC === false}
                                input={{
                                  name: 'disagreeEC',
                                  value: 'disagreeEC',
                                }}
                                label={<Box ml={3}>{t('common.disagree')}</Box>}
                                labelSx={{
                                  fontSize: '12px',
                                  margin: '0 0 3px',
                                  ml: 0,
                                  textTransform: 'capitalize',
                                }}
                                meta={{ error: '', touched: true }}
                                onChange={() => setAgreeEC(false)}
                                type="radio"
                                wrapperSx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              />
                            </Box>
                          </Box>
                          {config.canadaEnv && (
                            <Box
                              sx={{
                                margin: '20px 10px 0',
                                padding: '0 20px',
                                textAlign: 'justify',
                              }}
                            >
                              <Box>
                                <Box fontWeight={600} mb="10px">
                                  {t('component.caslDisclosure')}
                                </Box>
                                <Box
                                  dangerouslySetInnerHTML={{
                                    __html: t(
                                      'groupEmployees.enrollmentPage.addPetSection.eConsentLegendCanada',
                                      {
                                        url:
                                          i18n.language === 'fr'
                                            ? 'https://www.onepackplan.ca/fr/politique-protection-renseignements'
                                            : 'https://www.onepackplan.ca/privacy-policy',
                                      }
                                    ),
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: 'inline-flex',
                                  flexDirection: 'row',
                                  margin: '20px 0 0 0',
                                }}
                              >
                                <InputCheckbox
                                  checked={agreeCASL}
                                  input={{
                                    name: 'agreeCASL',
                                    value: 'agreeCASL',
                                  }}
                                  label={<Box ml={3}>{t('common.agree')}</Box>}
                                  labelSx={{
                                    fontSize: '12px',
                                    margin: '0 0 3px',
                                    ml: 0,
                                    textTransform: 'capitalize',
                                  }}
                                  meta={{ error: '', touched: true }}
                                  onChange={() => setAgreeCASL(true)}
                                  type="radio"
                                  wrapperSx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: 'inline-flex',
                                  flexDirection: 'row',
                                  margin: '10px 0 0 40px',
                                }}
                              >
                                <InputCheckbox
                                  checked={agreeCASL === false}
                                  input={{
                                    name: 'agreeCASL',
                                    value: 'agreeCASL',
                                  }}
                                  label={
                                    <Box ml={3}>{t('common.disagree')}</Box>
                                  }
                                  labelSx={{
                                    fontSize: '12px',
                                    margin: '0 0 3px',
                                    ml: 0,
                                    textTransform: 'capitalize',
                                  }}
                                  meta={{ error: '', touched: true }}
                                  onChange={() => setAgreeCASL(false)}
                                  type="radio"
                                  wrapperSx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                          {!config.canadaEnv && (
                            <Box
                              sx={{
                                margin: '20px 10px 0',
                                padding: '0 20px',
                                textAlign: 'justify',
                              }}
                            >
                              <Box fontWeight={600} mb="10px">
                                {t('common.insurerDisclosureTitle')}
                              </Box>
                              <Box
                                dangerouslySetInnerHTML={{
                                  __html: renderDisclosureContent(),
                                }}
                              />

                              {/* <Box
                                dangerouslySetInnerHTML={{
                                  __html: t('common.insurerDisclosure', {
                                    url: config.insurerDisclosuresUrl || '#',
                                  }),
                                }}
                              /> */}
                            </Box>
                          )}
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              margin: '15px auto',
                              maxWidth: '25rem',
                              width: '100%',
                            }}
                          >
                            <ToolbarButton
                              bg="primaryDark"
                              isDisabled={loading || !canSubmit}
                              label={t('common.submit')}
                              mr={0}
                              onClick={onSubmit}
                              submitting={loading}
                              width="10rem"
                            />
                          </Box>
                        </Box>
                        {showAgreement ? (
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: t(
                                'groupEmployees.enrollmentPage.lateEnrollmentPage.agreement'
                              ),
                            }}
                            sx={{
                              color: 'error',
                              fontSize: 2,
                              margin: '20px 10px',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Box>
                      {showFinalModal === true && modalMenu(form, handleSubmit)}
                    </form>
                  );
                }}
              />
            </Box>
          </Route>
        );
      }}
    </ApolloConsumer>
  );
};

export default LateEnrollment;
