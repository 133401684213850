import React, { useContext } from 'react';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';
import convertCamelCaseToTitleCase from "@src/utilities/convert-camel-case-to-title-case";
import Route from "@petcomponents/Route";
import config from "@src/config.json";
import NotFoundPage from "@src/routes/ihc/404";

const ThemeViewer = () => {
  const { colors, fontSizes, letterSpacings, fontWeights, shadows } =
    useContext(ThemeContext);
  const BOX_SIZE = '100px';
  const commonSx = {
    borderRadius: 2,
    boxShadow: 1,
    height: BOX_SIZE,
    m: 4,
    p: 4,
    width: BOX_SIZE,
  };
  const commonSectionSx = {
    mt: 8,
  };

  const renderColors = () => {
    return Object.keys(colors).map((item, index) => {
      if (Array.isArray(colors[item])) {
        return (
          <Box
            key={`${item}-${index}`}
            sx={{
              alignItems: 'center',
              bg: 'white',
              borderRadius: 2,
              boxShadow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              mb: 3,
              mt: 6,
              mx: 6,
              p: 3,
            }}
          >
            <Box sx={{ mx: 6 }}>{convertCamelCaseToTitleCase(item)}</Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                maxWidth: '50vw',
              }}
            >
              {colors[item].map((element, index) => {
                return (
                  <Box
                    key={`${element}-${index}`}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        bg: element,
                        ...commonSx,
                      }}
                    />
                    <Box sx={{ mx: 6 }}>
                      {convertCamelCaseToTitleCase(index)}
                    </Box>
                    <Box
                      sx={{
                        fontSize: 2,
                        mx: 6,
                      }}
                    >
                      {element}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      }

      if (typeof colors[item] === 'object' && !Array.isArray(colors[item])) {
        return Object.keys(colors[item]).map((innerItem, index) => {
          return (
            <Box
              key={`${innerItem}-${index}`}
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  bg: colors[item][innerItem],
                  ...commonSx,
                  mx: 6,
                }}
              />
              <Box sx={{ mx: 6 }}>{convertCamelCaseToTitleCase(innerItem)}</Box>
              <Box
                sx={{
                  fontSize: 2,
                  mx: 6,
                }}
              >
                {colors[item][innerItem]}
              </Box>
            </Box>
          );
        });
      }

      return (
        <Box
          key={`${item}-${index}`}
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              bg: colors[item],
              ...commonSx,
              mx: 6,
            }}
          />
          <Box>{convertCamelCaseToTitleCase(item)}</Box>
          <Box
            sx={{
              fontSize: 2,
              mx: 6,
            }}
          >
            {colors[item]}
          </Box>
        </Box>
      );
    });
  };

  const renderFontSizes = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {fontSizes.map((size, index) => (
          <Box key={`fontSize-${index}`}>
            <Box
              sx={{
                bg: 'white',
                borderRadius: 2,
                boxShadow: 1,
                fontSize: size,
                mb: 2,
                mt: 6,
                px: 3,
                py: 4,
              }}
            >
              The quick brown fox jumps over the lazy dog.
            </Box>
            <Box>{`Font Size ${index} - ${size}`}</Box>
          </Box>
        ))}
      </Box>
    );
  };

  const renderFontWeights = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {Object.keys(fontWeights).map((weight, index) => (
          <Box key={`fontSize-${index}`}>
            <Box
              sx={{
                bg: 'white',
                borderRadius: 2,
                boxShadow: 1,
                fontSize: 5,
                fontWeight: weight,
                mb: 2,
                mt: 6,
                px: 3,
                py: 4,
              }}
            >
              The quick brown fox jumps over the lazy dog.
            </Box>
            <Box>{`Font weight - ${weight}`}</Box>
          </Box>
        ))}
      </Box>
    );
  };

  const renderFontLetterSpacing = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {letterSpacings.map((spacing, index) => (
          <Box key={`fontSize-${index}`}>
            <Box
              sx={{
                bg: 'white',
                borderRadius: 2,
                boxShadow: 1,
                fontSize: 5,
                letterSpacing: spacing,
                mb: 2,
                mt: 6,
                px: 3,
                py: 4,
              }}
            >
              The quick brown fox jumps over the lazy dog.
            </Box>
            <Box>{`Font Letter Spacing ${index} - ${spacing}`}</Box>
          </Box>
        ))}
      </Box>
    );
  };

  const renderShadows = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {shadows.map((shadow, index) => (
          <Box key={`fontSize-${index}`}>
            <Box
              sx={{
                ...commonSx,
                bg: 'white',
                boxShadow: shadow,
              }}
            />
            <Box>{`Box Shadow ${index} - ${shadow}`}</Box>
          </Box>
        ))}
      </Box>
    );
  };

  if (!config.developerMode) {
    return <NotFoundPage />;
  }

  return (
    <Route
      header={{
        icon: 'dashboard',
        title: 'Theme Viewer',
        type: 'UX',
        typeLabel: 'User Experience',
      }}
      isPrivate
    >
      <Box as="h1" sx={{ ...commonSectionSx, mt: 3 }}>
        Colors
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          maxWidth: '98vw',
        }}
      >
        {renderColors()}
      </Box>
      <Box as="h1" sx={{ ...commonSectionSx }}>
        Font Sizes
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          maxWidth: '98vw',
        }}
      >
        {renderFontSizes()}
      </Box>
      <Box as="h1" sx={{ ...commonSectionSx }}>
        Font Weights
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          maxWidth: '98vw',
        }}
      >
        {renderFontWeights()}
      </Box>
      <Box as="h1" sx={{ ...commonSectionSx }}>
        Font Letter Spacings
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          maxWidth: '98vw',
        }}
      >
        {renderFontLetterSpacing()}
      </Box>
      <Box as="h1" sx={{ ...commonSectionSx }}>
        Shadows
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          maxWidth: '98vw',
        }}
      >
        {renderShadows()}
      </Box>
    </Route>
  );
};

export default ThemeViewer;
