import get from 'lodash/get';
import moment from 'moment';
import convertCurrencyToInt from "@src/utilities/convert-currency-to-int";
import dateUtils from "@src/utilities/date-utils";
import i18n from "@src/utilities/i18n"

export const groupNumber = (value) => {
  if (value && !/[A-Z,a-z,0-9][A-Z,a-z,0-9]-\d\d\d\d/.test(value)) {
    i18n.t('validation.invalidGroupNumber');
  }
};

export const legalIdentificationNumber = (value) => {
  if (value && !/\d\d-\d\d\d\d\d\d\d/.test(value)) {
    return i18n.t('validation.invalidIDnumber');
  }
};

export const legalBusinessNumber = (value) => {
  if (value && !/\d\d\d\d\d\d\d\d\d/.test(value)) {
    return i18n.t('validation.invalidIDnumber');
  }
};

export const socialSecurityNumber = (value) => {
  if (value && !/\d\d\d-\d\d-\d\d\d\d/.test(value)) {
    return i18n.t('validation.invalidSSN');
  }
};

export const NAICCodeNumber = (value) => {
  if (value && !/\d\d\d\d\d\d/.test(value)) {
    return i18n.t('validation.invalidValue');
  }
};

export const SICCodeNumber = (value) => {
  if (value && !/\d\d\d\d/.test(value)) {
    return i18n.t('validation.invalidNumber');
  }
};

export const writingNumber = (value) => {
  if (value && !/[A-Z,a-z][A-Z,a-z][A-Z,a-z][A-Z,a-z]/.test(value)) {
    return i18n.t('validation.invalidWritingNumber');
  }
};

export const phoneNumber = (value) => {
  if (value === undefined || value === '') return;
  const temp = value?.match(/\d+/g)?.join()?.replaceAll(',', '');
  if (temp?.length < 10) {
    return i18n.t('validation.invalidPhone');
  }
};
export const legalRoutingNumber = (value) => {
  if (value && !/\d\d\d\d\d\d\d\d\d/.test(value)) {
    return i18n.t('validation.invalidRouting');
  }
};

export const legalTransitNumber = (value) => {
  if (value && !/\d\d\d\d\d/.test(value)) {
    return i18n.t('validation.invalidTransit');
  }
};

export const legalInstitutionNumber = (value) => {
  if (value && !/\d\d\d/.test(value)) {
    return i18n.t('validation.invalidTransit');
  }
};

export const zipcode = (value) => {
  if (value && !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
    return i18n.t('validation.invalidZipcode');
  }
};

export const postalCode = (value) => {
  if (
    value &&
    (!/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z]\s?[0-9][A-Z][0-9]$/.test(value) ||
      value.length < 7)
  ) {
    return i18n.t('validation.invalidPostal');
  }
};

export const billDaysPriorToPayroll = (value) => {
  if (value && (value < 0 || value > 45)) {
    return i18n.t('validation.invalidBillDays');
  }
};

export const required = (value) => {
  if (value === undefined || value === '' || value === null) {
    return i18n.t('validation.required');
  }
};

export const reasonRequired = (value) => {
  if (value === undefined || value === '' || value === null) {
    return i18n.t('validation.reason');
  }
};

export const positive = (value) => {
  if (value && value < 0) return i18n.t('validation.invalidNumber');
};

export const FSRAcode = (value) => {
  if (value && value.length < 8) return i18n.t('validation.invalidFSRA');
};

export const maxLength = (length, customError = '') => {
  return (value) => {
    if (value && value.length > length) {
      return customError || i18n.t('validation.invalidLength');
    }
  };
};

export const maxLength3 = maxLength(3);

export const refundAmount = (compareValue) => (value) => {
  if (value && convertCurrencyToInt(value) <= 0) {
    return i18n.t('validation.moreThan0');
  }
  if (compareValue < convertCurrencyToInt(value)) {
    return i18n.t('validation.lessThanCredit');
  }
};

export const moreThanZero = (value) => {
  if (value && convertCurrencyToInt(value) < 0) {
    return i18n.t('validation.moreThan0');
  }
};
export const minDateVal = (relativeDatetime) => (value) => {
  const today = new Date();
  const currentDateVal =
    dateUtils.setOffset2(relativeDatetime) ||
    new Date(today.setDate(today.getDate() - 1));
  if (value && value < currentDateVal) {
    return i18n.t('validation.atLeastCurrentDate');
  }
};
export const totalCommission = (value) => {
  if (value && parseFloat(value) > 32.5) {
    return i18n.t('validation.totalCommission');
  }
};

export const totalEmployerContribution = (value) => {
  if (value && parseFloat(value) > 100) {
    return i18n.t('validation.employerContrib');
  }
};

export const commissionMatch = (value, allValues) => {
  const totalCommission = parseFloat(
    get(allValues, 'totalCommissionPercentage', -1)
  );
  const commissionsTotal = value?.reduce(
    (acc, current) => parseFloat(get(current, 'commissionPercentage', 0)) + acc,
    0
  );
  // Check if any field is empty
  const isEmptyField = value.some(
    (commission) => !commission || !commission?.producerDetail?.producerId
  );
  if (isEmptyField) {
    return i18n.t('validation.commissionFields');
  }
  if (totalCommission === -1) {
    return i18n.t('validation.commissionTotalField');
  }
  if (totalCommission > commissionsTotal) {
    return i18n.t('validation.totalCommissionGreaterThanSum');
  }
  if (totalCommission < commissionsTotal) {
    return i18n.t('validation.totalCommissionLesserThanSum');
  }
  return null;
};

export const validateNumbers = (value) => {
  let error = '';
  if (value) {
    const values = value.replace(/\s*,\s*/g, ',').split(',');
    values.forEach((val) => {
      if (Number.isNaN(Number(val))) {
        error = i18n.t('validation.numberType');
      }
    });
  }
  return error;
};

export const payrollScheduleBeforeAndAfterDays = (
  value,
  allValues,
  fieldState
) => {
  const fieldName = {
    billCreationDate: 'generatedBillCreationDate',
    paidDate: 'generatedPaidDate',
  };
  const compareField = fieldName[fieldState.name.split('.')[1]];
  const date = get(
    allValues,
    `${fieldState.name.split('.')[0]}.${compareField}`
  );
  if (value && moment(value).diff(moment(date), 'days') < -15) {
    return i18n.t('validation.date15daysbefore', {
      date: moment(date).format('L'),
    });
  }
  if (value && moment(value).diff(moment(date), 'days') > 15) {
    return i18n.t('validation.date15daysAfter', {
      date: moment(date).format('L'),
    });
  }
};

export const dateBeforeAndAfterDays = (value, allValues, fieldState) => {
  const nextCycleNumber = fieldState.name.split('.')[0];

  const regex = /\d+/g;
  const matches = parseInt(nextCycleNumber.match(regex)[0], 10) + 1;

  const nextCycleStartDate = get(
    allValues,
    `payrollSchedule[${matches}].payrollCalendarStartDate`
  );

  const startDateOfTheCycle = get(
    allValues,
    `${fieldState.name.split('.')[0]}.payrollCalendarStartDate`
  );

  if (value && moment(value) <= moment(startDateOfTheCycle)) {
    return i18n.t('validation.dateGreater', {
      date: moment(startDateOfTheCycle).format('L'),
    });
  }

  if (
    nextCycleStartDate &&
    value &&
    moment(value) >= moment(nextCycleStartDate)
  ) {
    return i18n.t('validation.dateLessThan', {
      date: moment(nextCycleStartDate).format('L'),
    });
  }
};

export const composeValidations =
  (...validators) =>
  (value, allValues, fieldState) => {
    return validators.reduce(
      (error, validator) => error || validator(value, allValues, fieldState),
      undefined
    );
  };

export const requiredWithMinLength = (value) => {
  if (value === undefined || value === '') return i18n.t('validation.required');
  if (value.length < 5) return i18n.t('validation.5characters');
};

export const characterLimit = (value) => {
  if (value && value.length > 128) return 'Limit is 128 characters';
};

export const validateDate = (value) => {
  if (!value) return i18n.t('validation.required');
  if (value) {
    if (value.getTime() > new Date().getTime()) {
      return i18n.t('validation.previousDate');
    }
  }
};

export const ismmyyyy = (value) => {
  if (!value.match(/^([01]\d)\/(\d{4})$/)) {
    return i18n.t('validation.invalidDate');
  }
};

export const mmyyyyIsPast = (value) => {
  const regex = /^([01]\d)\/(\d{4})$/;
  const match = value.match(regex);

  const month = parseInt(match[1], 10);
  const year = parseInt(match[2], 10);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;

  if (year > currentYear || (year === currentYear && month > currentMonth)) {
    return i18n.t('validation.datePast');
  }
};
