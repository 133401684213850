import moment from 'moment';
import jstz from 'jstimezonedetect';
import { DEFAULT_LOCALE, DEFAULT_LOCALE_CA, DEFAULT_LOCALE_US } from "@src/utilities/constants";

// https://bugs.chromium.org/p/chromium/issues/detail?id=580195
const timeZone = jstz.determine().name().replace('Calcutta', 'Kolkata');

class dateUtils {
  static fromNow(v) {
    const d = new Date(v);
    return moment(d).fromNow();
  }

  static fromNowCA(v) {
    const d = moment(v, 'DD/MM/YYYY HH:mm');
    return d.locale('en-GB').fromNow();
  }

  /**
   * Utc for pre populate date in input date
   * @param {Date} date
   * @returns {Date}
   */
  static setOffset(beforeOffset, subtract) {
    if ((beforeOffset ?? '') !== '') {
      const utcDate = subtract ? beforeOffset : new Date(beforeOffset);
      const offset = new Date().getTimezoneOffset();
      const minutes = subtract
        ? utcDate.getMinutes() - offset
        : utcDate.getMinutes() + offset;
      utcDate.setMinutes(minutes);
      return utcDate;
    }
  }

  static setOffset2(beforeOffset) {
    if ((beforeOffset ?? '') !== '') {
      const utcDate = new Date(beforeOffset);
      const newDate = new Date(
        utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000
      );

      // const offset = utcDate.getTimezoneOffset() / 60;
      // const hours = utcDate.getHours();

      newDate.setHours(0, 0, 0, 0);

      return newDate;
    }
  }

  static addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  static subDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };

  /**
   * Utc for day of month only to prevent rounding off
   * @param {Date} date
   * @returns {Date}
   */
  static getFormattedDate(v) {
    const d = new Date(v);
    return d.toLocaleDateString(DEFAULT_LOCALE, { timeZone });
  }

  static getFormattedDateCA(v) {
    const d = new Date(v);
    return d.toLocaleDateString(DEFAULT_LOCALE_CA, { timeZone });
  }

  static getFormattedDateUS(v) {
    const d = new Date(v);
    return d.toLocaleDateString(DEFAULT_LOCALE_US, { timeZone });
  }

  static getFormattedDateString(v) {
    const d = new Date(v);
    return d.toLocaleDateString(DEFAULT_LOCALE, { timeZone }).split('T')[0];
  }

  static getFormattedDateTime(v) {
    return `${this.getFormattedDate(v)} ${this.getFormattedTime(v)}`;
  }

  static getFormattedDateTimeCA(v) {
    return `${this.getFormattedDateCA(v)} ${this.getFormattedTimeCA(v)}`;
  }

  static getFormattedDateTimeUS(v) {
    return `${this.getFormattedDateUS(v)} ${this.getFormattedTimeUS(v)}`;
  }

  static getFormattedDateTimeComponents(v) {
    return {
      date: `${this.getFormattedDate(v)}`,
      time: `${this.getFormattedTime(v)}`,
      timeZone,
    };
  }

  static getFormattedDateTimeComponentsCA(v) {
    return {
      date: `${this.getFormattedDateCA(v)}`,
      time: `${this.getFormattedTime(v)}`,
      timeZone,
    };
  }

  static getFormattedDateTimeComponentsUS(v) {
    return {
      date: `${this.getFormattedDateUS(v)}`,
      time: `${this.getFormattedTime(v)}`,
      timeZone,
    };
  }

  static getUTCFormattedDateTimeComponents(v) {
    return {
      date: `${this.getUTCDateOnly(v)}`,
      time: `${this.getUTCFormattedTime(v)}`,
      timeZone,
    };
  }

  static getFormattedTime(v) {
    const d = new Date(v);
    return d.toLocaleTimeString(DEFAULT_LOCALE, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone,
    });
  }

  static getFormattedTimeCA(v) {
    const d = new Date(v);
    return d.toLocaleTimeString(DEFAULT_LOCALE_CA, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone,
    });
  }

  static getFormattedTimeUS(v) {
    const d = new Date(v);
    return d.toLocaleTimeString(DEFAULT_LOCALE_US, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone,
    });
  }

  static getUTCFormattedTime(v) {
    const d = new Date(v);
    return d.toLocaleTimeString(DEFAULT_LOCALE, {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
    });
  }

  static getFormattedDateTimeWithDiff(v) {
    return `${this.fromNow(v)}. ${this.getFormattedDateTime(v).toUpperCase()}`;
  }

  static getDateDifferenceInHours(v) {
    const d = moment(new Date());
    const duration = moment.duration(d.diff(v));
    return duration.asHours();
  }

  static getDateDifferenceInDays(
    { renewalNotificationDays },
    coverageDate,
    today
  ) {
    const coverageDateinDays = moment(coverageDate).subtract(
      renewalNotificationDays,
      'days'
    );

    const differenceInDaysFromToday = Math.ceil(
      moment.duration(moment(today).diff(coverageDateinDays)).asDays()
    );

    return differenceInDaysFromToday;
  }

  static getSQLDateTime(v) {
    const d = new Date(v);
    const yrs = d.getUTCFullYear();
    const mon = d.getUTCMonth() + 1;
    const dat = d.getUTCDate();
    const hrs = d.getUTCHours();
    const min = d.getUTCMinutes();
    const sec = d.getUTCSeconds();
    const date = `${yrs}-${String(mon).padStart(2, 0)}-${String(dat).padStart(
      2,
      0
    )}`;
    const time = `${String(hrs).padStart(2, 0)}:${String(min).padStart(
      2,
      0
    )}:${String(sec).padStart(2, 0)}`;
    return `${date} ${time}`;
  }

  // Use this function to display ONLY the dates which are stored on the server irrespective of the timezone.
  static getUTCDateOnly(date) {
    return new Date(new Date(date)).toLocaleDateString(DEFAULT_LOCALE, {
      timeZone: 'UTC',
    });
  }

  static getUTCDateOnlyCA(date) {
    return new Date(new Date(date)).toLocaleDateString(DEFAULT_LOCALE_CA, {
      timeZone: 'UTC',
    });
  }

  static getUTCDateOnlyUS(date) {
    return new Date(new Date(date)).toLocaleDateString(DEFAULT_LOCALE_US, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'UTC',
    });
  }

  static getLocaleDateOnly(date) {
    return new Date(new Date(date)).toLocaleDateString(DEFAULT_LOCALE);
  }

  // required 'fr-CA' for supporting YYYY-MM-DD for mat to send data to api
  static setAPIDateOnly(date, time) {
    const formatter = new Intl.DateTimeFormat('fr-CA', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const newDate = new Date(date);
    if (time && (newDate ?? '') !== '') {
      // return `${new Date(date).toLocaleDateString('fr-CA', {
      //   day: '2-digit',
      //   month: '2-digit',
      //   year: 'numeric',
      // })} 00:00:00`;
      return `${formatter.format(newDate)} 00:00:00`;
    }

    if ((date ?? '') !== '') {
      return formatter.format(newDate);
      // return new Date(date).toLocaleDateString('fr-CA', {
      //   day: '2-digit',
      //   month: '2-digit',
      //   year: 'numeric',
      // });
    }
  }
}

export default dateUtils;
