import {navigate, useParams} from '@reach/router';
import Box from '@basecomponents/Box';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {ApolloConsumer} from '@apollo/client';
import {Field, Form as FinalForm} from 'react-final-form';
import {labelHandler} from '@utils/label-utils';
import {useTranslation} from 'react-i18next';
import InputDropdown from "@basecomponents/Dropdown";
import ToolbarButton from "@basecomponents/ToolbarButton";
import UploadFile from "@petcomponents/UploadFile";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import generateRedirectPath from "@src/utilities/generate-redirect-path";
import config from "@src/config.json";

/**
 * @category Renewals
 * @param {Object} location
 * @param {string} name
 * @param {boolean} showFooterButtons
 * @param {any} rest
 * @returns {React.FC}
 */
const GroupCensus = ({location, name, showFooterButtons, ...rest}) => {
    const {t} = useTranslation();
    const parameters = useParams();
    const [setErrorSnack] = useSnackbar({color: 'error'});
    // const [datasetId, setDatasetId] = useState(null);
    const commonFieldSxHalf = {
        padding: 3,
        width: '25rem',
    };
    const groupId = get(parameters, 'groupId', '');
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const uploadMethods = [
        {
            label: 'Manual Input',
            value: 'ManualInput',
        },
        {
            label: 'Group Template File Upload',
            value: 'CensusFileUpload',
        },
    ];

    return (
        <ApolloConsumer>
            {(client) => {
                return (
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <FinalForm
                            onSubmit={(value) => {
                                if (value?.censusType === 'CensusFileUpload') {
                                    client
                                        .query({
                                            fetchPolicy: 'no-cache',
                                            query: remoteActionQuery,
                                            variables: {
                                                // name: config.barrelS3Bucket
                                                //   ? 'barrel-file-detail'
                                                //   : 'get-tasks',
                                                // params: config.barrelS3Bucket
                                                //   ? JSON.stringify({
                                                //       datasetId,
                                                //       page: 0,
                                                //       size: 1,
                                                //       sort: 'created,desc',
                                                //     })
                                                //   : JSON.stringify({
                                                //       flow: 'renewalGroupAdmin',
                                                //       groupId,
                                                //       page: 0,
                                                //       size: 1,
                                                //       sort: 'created,desc',
                                                //     }),
                                                name: 'get-tasks',
                                                params: JSON.stringify({
                                                    flow: config.canadaEnv ? "renewalGroupAdminCanada" : "renewalGroupAdmin",
                                                    groupId,
                                                    page: 0,
                                                    size: 1,
                                                    sort: 'created,desc',
                                                }),
                                            },
                                        })
                                        .then((response) => {
                                            // const task = config.barrelS3Bucket
                                            //   ? JSON.parse(response.data.remoteAction.data)
                                            //   : JSON.parse(response.data.remoteAction.data)
                                            //       .content[0];
                                            const task = JSON.parse(response.data.remoteAction.data)
                                                .content[0];
                                            if (
                                                task.complete ||
                                                (task.status === 'pipeline-completed' &&
                                                    task?.errorCount === 0)
                                            ) {
                                                return navigate(
                                                    generateRedirectPath({
                                                        ...location,
                                                        queryParams: {
                                                            [name]: 1,
                                                        },
                                                    })
                                                );
                                            }
                                            if (task?.errorCount > 0) {
                                                return setErrorSnack(
                                                    `There was an error please check the file`,
                                                    config.notificationDuration
                                                );
                                            }
                                            return setErrorSnack(
                                                'Please wait for group template to finish processing before continuing.',
                                                config.notificationDuration
                                            );
                                        })
                                        .catch((e) =>
                                            setErrorSnack(
                                                `There was an error: ${e.message}`,
                                                config.notificationDuration
                                            )
                                        );
                                } else if (value?.censusType === 'ManualInput') {
                                    return navigate(
                                        generateRedirectPath({
                                            ...location,
                                            queryParams: {
                                                [name]: 1,
                                            },
                                        }),
                                        {replace: true}
                                    );
                                }
                            }}
                            render={({handleSubmit, values, form}) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Box
                                            sx={{
                                                bg: 'white',
                                                borderRadius: 4,
                                                boxShadow: 1,
                                                padding: 3,
                                            }}
                                        >
                                            <Box
                                                as="h3"
                                                color="black"
                                                display="flex"
                                                justifyContent="flex-start"
                                                sx={{
                                                    color: 'primary',
                                                    margin: '20px 0px 20px 0px;',
                                                    padding: 3,
                                                    width: '25rem',
                                                }}
                                            >
                                                Renewal Type
                                            </Box>
                                            <Box data-cy="files" flex="1" justifyContent="center">
                                                <Field
                                                    aria-label="Renewal Type"
                                                    component={InputDropdown}
                                                    label={labelHandler('Renewal Type', false)}
                                                    name="censusType"
                                                    onChange={(val) =>
                                                        val === 'CensusFileUpload'
                                                            ? setButtonDisabled(true)
                                                            : setButtonDisabled(false)
                                                    }
                                                    options={uploadMethods}
                                                    wrapperSx={{...commonFieldSxHalf}}
                                                    {...rest}
                                                />
                                                {get(values, 'censusType', '') ===
                                                    'CensusFileUpload' ? (
                                                    <Box
                                                        sx={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        <UploadFile
                                                            fileTypes={[
                                                                'text/plain',
                                                                'application/vnd.ms-excel',
                                                                'text/x-csv',
                                                                'application/csv',
                                                                'application/x-csv',
                                                                'text/csv',
                                                                'text/comma-separated-values',
                                                                'text/x-comma-separated-values',
                                                                'text/tab-separated-values',
                                                            ]}
                                                            flow={config.canadaEnv ? "renewalGroupAdminCanada" : "renewalGroupAdmin"}
                                                            form={form}
                                                            groupId={groupId}
                                                            onUploadComplete={async () => {
                                                                // eslint-disable-next-line no-unused-expressions
                                                                // config.barrelS3Bucket
                                                                //   ? await setDatasetId(response)
                                                                //   : null;
                                                                setButtonDisabled(false);
                                                            }}
                                                            {...rest}
                                                        />
                                                    </Box>
                                                ) : null}
                                                <ToolbarButton
                                                    aria-label={t('common.next')}
                                                    bg="accentDark"
                                                    disabled={buttonDisabled}
                                                    label={t('common.next')}
                                                    sx={{margin: '0 auto', width: '120px'}}
                                                    type="submit"
                                                />
                                            </Box>
                                        </Box>
                                    </form>
                                );
                            }}
                        />
                    </Box>
                );
            }}
        </ApolloConsumer>
    );
};

GroupCensus.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
    }).isRequired,
    name: PropTypes.string,
    redirectTo: PropTypes.string,
    showFooterButtons: PropTypes.bool,
};

GroupCensus.defaultProps = {
    name: 'step',
    redirectTo: '',
    showFooterButtons: false,
};

export default GroupCensus;
