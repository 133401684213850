import Box from '@basecomponents/Box';
import React from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import {
  required,
  composeValidations,
} from "@src/utilities/validators";
import InputText from "@basecomponents/Input";
import Dropdown from "@basecomponents/Dropdown";
import ToolbarButton from "@basecomponents/ToolbarButton";
import SingleDatePicker from "@basecomponents/InputDate";
import InputHours from "@basecomponents/InputHours";
import InputMoney from "@basecomponents/InputMoney";
import InputPercentage from "@basecomponents/InputPercentage";
import { labelHandler } from "@src/utilities/label-utils";
import { validateNumbers } from "@src/utilities/validations";

/**
 * @category ProductAdmin
 * @param {string} name
 * @param {function} validation
 * @param {Array<Object>} classDrivers
 * @param {*} rest
 * @returns {React.FC}
 */
const ClassDriversSection = ({ name, validation, classDrivers, ...rest }) => {
  const { t } = useTranslation();
  const commonConditionalWrapperSx = { m: 0, p: 3 };
  const commonFieldSxHalf = (index) => ({
    padding: 3,
    width: `${index === 0 ? '23rem' : '20rem'}`,
  });

  const renderConditionalFields = (keyValue, name) => {
    const { type, dataType } =
      keyValue && classDrivers.length
        ? classDrivers.find((x) => x.keyValue === keyValue)
        : {};
    switch (type) {
      case 'RANGE':
        return (
          <>
            <Field
              aria-label="Maximum"
              component={InputMoney}
              data-test={`${name}.${keyValue}_${type}_max`}
              label={labelHandler('Maximum', true)}
              name={`${name}.${keyValue}_${type}_max`}
              validate={required}
              wrapperSx={commonConditionalWrapperSx}
              {...rest}
            />
            <Field
              aria-label="Minimum"
              component={InputMoney}
              data-test={`${name}.${keyValue}_${type}_min`}
              label={labelHandler('Minimum', true)}
              name={`${name}.${keyValue}_${type}_min`}
              validate={required}
              wrapperSx={commonConditionalWrapperSx}
              {...rest}
            />
          </>
        );
      case 'FIELD':
        if (dataType === 'Integer') {
          return (
            <Field
              aria-label="Enter Numbers (comma separated)"
              component={InputText}
              data-test={`${name}.${keyValue}_${type}`}
              label={labelHandler('Enter Numbers (comma separated)', true)}
              name={`${name}.${keyValue}_${type}`}
              validate={composeValidations(required, validateNumbers)}
              wrapperSx={commonConditionalWrapperSx}
              {...rest}
            />
          );
        }
        return (
          <Field
            aria-label={t('groups.enterValuesCommaSeparated')}
            component={InputText}
            data-test={`${name}.${keyValue}_${type}`}
            label={labelHandler(t('groups.enterValuesCommaSeparated'), true)}
            name={`${name}.${keyValue}_${type}`}
            validate={required}
            wrapperSx={commonConditionalWrapperSx}
            {...rest}
          />
        );
      case 'DROPDOWN':
        return (
          <Field
            aria-label={t('groups.selectOption')}
            component={Dropdown}
            inputProps={{ 'data-test': `${keyValue}_${type}` }}
            label={labelHandler(t('groups.selectOption'), true)}
            name={`${name}.${keyValue}_${type}`}
            options={
              classDrivers.find((x) => x.keyValue === keyValue).possibleValues
            }
            searchable={false}
            validate={required}
            wrapperSx={commonConditionalWrapperSx}
            {...rest}
          />
        );
      case 'DATE':
        return (
          <>
            <Field
              aria-label={t('groups.startDate')}
              component={SingleDatePicker}
              data-test={`${name}.${keyValue}_${type}_start`}
              label={labelHandler(t('groups.startDate'), true)}
              name={`${name}.${keyValue}_${type}_start`}
              validate={required}
              wrapperSx={commonConditionalWrapperSx}
              {...rest}
            />
            <Field
              aria-label={t('groups.endDate')}
              component={SingleDatePicker}
              data-test={`${name}.${keyValue}_${type}_end`}
              label={labelHandler(t('groups.endDate'), true)}
              name={`${name}.${keyValue}_${type}_end`}
              validate={required}
              wrapperSx={commonConditionalWrapperSx}
              {...rest}
            />
          </>
        );
      case 'HOUR_RANGE':
        return (
          <>
            <Field
              aria-label={t('groups.minimumHours')}
              component={InputHours}
              data-test={`${name}.${keyValue}_${type}_minhour`}
              label={labelHandler(t('groups.minimumHours'), true)}
              name={`${name}.${keyValue}_${type}_minhour`}
              validate={required}
              wrapperSx={commonConditionalWrapperSx}
              {...rest}
            />
            <Field
              aria-label={t('groups.maximumHours')}
              component={InputHours}
              data-test={`${name}.${keyValue}_${type}_maxhour`}
              label={labelHandler(t('groups.maximumHours'), true)}
              name={`${name}.${keyValue}_${type}_maxhour`}
              validate={required}
              wrapperSx={commonConditionalWrapperSx}
              {...rest}
            />
          </>
        );
      case 'PERCENT_RANGE':
        return (
          <>
            <Field
              aria-label="Minimum Rate"
              component={InputPercentage}
              data-test={`${name}.${keyValue}_${type}_minrate`}
              label={labelHandler('Minimum Rate', true)}
              name={`${name}.${keyValue}_${type}_minrate`}
              validate={required}
              wrapperSx={commonConditionalWrapperSx}
              {...rest}
            />
            <Field
              aria-label="Maximum Rate"
              component={InputPercentage}
              data-test={`${name}.${keyValue}_${type}_maxrate`}
              label={labelHandler('Maximum Rate', true)}
              name={`${name}.${keyValue}_${type}_maxrate`}
              validate={required}
              wrapperSx={commonConditionalWrapperSx}
              {...rest}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <FieldArray name={name} validate={validation} {...rest}>
      {({ fields, meta }) => (
        <Box>
          {fields.map((name, index) => {
            const keyValue = fields.value[index] && fields.value[index].name;
            return (
              <Box
                key={`${name}-${index}`}
                my={2}
                py={5}
                sx={{
                  alignItems: 'center',
                  borderRadius: 3,
                  boxShadow: 7,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Field
                    aria-label="Choose a driver"
                    component={Dropdown}
                    data-cy={`tagName${index}`}
                    label={labelHandler('Choose a driver', true)}
                    name={`${name}.name`}
                    options={classDrivers.map((driver) => ({
                      canEnglishValue: driver.keyCanadaEnglish,
                      frenchValue: driver.keyCanadaFrench,
                      label: driver.key,
                      value: driver.keyValue,
                    }))}
                    placeholder="Drivers"
                    validate={required}
                    wrapperSx={commonFieldSxHalf(index)}
                    {...rest}
                  />
                  <Box
                    p={index > 0 ? 4 : 0}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {index > 0 && (
                      <ToolbarButton
                        aria-label="close"
                        bg="error"
                        buttonSx={{
                          borderRadius: '50%',
                          height: '30px',
                          width: '30px',
                        }}
                        icon="close"
                        iconSx={{
                          height: '20px',
                          width: '20px',
                        }}
                        onClick={() => fields.remove(index)}
                      />
                    )}
                  </Box>
                </Box>
                {renderConditionalFields(keyValue, name)}
              </Box>
            );
          })}
          <Box display="flex" justifyContent="center" mt={6}>
            <ToolbarButton
              bg="accent"
              icon="add"
              iconSx={{
                height: '20px',
                mr: [0, null, null, 4],
                width: '20px',
              }}
              label="Add Driver"
              onClick={() => fields.push({})}
            />
          </Box>
          {meta.error && meta.touched && !isArray(meta.error) && (
            <Box
              sx={{
                color: 'error',
                fontSize: 2,
                mt: 1,
              }}
            >
              {meta.error}
            </Box>
          )}
        </Box>
      )}
    </FieldArray>
  );
};

ClassDriversSection.propTypes = {
  classDrivers: PropTypes.arrayOf(PropTypes.shape({})),
  name: PropTypes.string,
  validation: PropTypes.func,
};

ClassDriversSection.defaultProps = {
  classDrivers: [],
  name: 'driverValue',
  validation: () => {},
};

export default ClassDriversSection;
