/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import Box from '@basecomponents/Box';
import Redirect from '@basecomponents/Redirect';
import {composeValidations, email, minLength, required,} from '@utils/validators';
import {navigate} from 'gatsby';
import {get, isEmpty} from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import {Field, Form as FinalForm, Form} from 'react-final-form';
import {ThemeContext} from 'styled-components';
import {print} from 'graphql';
import {lighten} from 'polished';
import {parse} from 'query-string';
import PropTypes from 'prop-types';
import EmailSentModal from '@basecomponents/EmailSentModal';
import {EmailButton} from '@basecomponents/EmailButton';
import {PhoneButton} from '@basecomponents/PhoneButton';
import {Auth} from 'aws-amplify';
import mutationCall from '@src/utilities/mutationCall';
import useWindowSize from '@utils/hooks/windowSize';
import {OnChange} from 'react-final-form-listeners';
import i18n from '@src/utilities/i18n';
import {useTranslation} from 'react-i18next';
import Dropdown from '@basecomponents/Dropdown';
import Input from "@basecomponents/Input";
import Logo from "@petcomponents/Logo";
import ToolbarButton from "@basecomponents/ToolbarButton";
import config from "@src/config.json";
import AuthContainer from "@src/containers/AuthContainer";
import pixelToNumber from "@src/utilities/pixel-to-number";
import useSnackbar from "@src/utilities/use-snackbar";
import InputPassword from "@basecomponents/InputPassword";
import localStorage from "@src/utilities/local-storage";
import Modal from "@basecomponents/Modal";
import mutationRemoteAction from "@mutations/mutation-remote-action.gql";

async function getFlow(username) {
    return (await fetch(config.apollo.url, {
            body: JSON.stringify({
                query: print(mutationRemoteAction), variables: {
                    name: 'get-login-flow', params: JSON.stringify({
                        email: username,
                    }),
                },
            }), headers: {
                'content-type': 'application/json', 'x-api-key': config.apollo.apiKey,
            }, method: 'POST',
        })).json();
}

async function AWSLoginHandler({
                                   alternateRegistration,
                                   flow,
                                   setErrorSnack,
                                   setIsIncorrect,
                                   setModal,
                                   setUnAuthCounter,
                                   setErrorModal,
                                   signIn,
                                   unAuthCounter,
                                   userId,
                                   username,
                                   values,
                               }) {
    try {
        const data = {
            password: get(values, 'password', '').trim(), username: get(values, 'username', '').toLowerCase().trim(),
        };
        await signIn(data);
        if (alternateRegistration) {
            const altUserId = localStorage.getItem('AltUserId') || userId;
            // eslint-disable-next-line no-unused-vars
            const addEmailResponse = await (await fetch(config.apollo.url, {
                    body: JSON.stringify({
                        query: print(mutationRemoteAction), variables: {
                            name: 'alternative-registration-add-user-email', params: JSON.stringify({
                                email: get(values, 'username', '').toLowerCase().trim(), userId: altUserId,
                            }),
                        },
                    }), headers: {
                        'content-type': 'application/json', 'x-api-key': config.apollo.apiKey,
                    }, method: 'POST',
                })).json();
            if (addEmailResponse?.errors?.length > 0) {
                await Auth.signOut().then(navigate(`/login?errorModal=true`));
            }
        }
        return true;
    } catch (e) {
        if (e.code === 'PasswordResetRequiredException') {
            await navigate('/password-reset', {
                state: {
                    username: values.username,
                },
            });
        } else if (e.code === 'NotAuthorizedException' && e.message.match(/temporary\s+password.*?expired/i)) {
            const isAltRegistration = flow === 'ALTERNATE_REGISTER_WITH_COGNITO_USER' || alternateRegistration;
            const actionParams = isAltRegistration ? JSON.stringify({
                email: username,
            }) : JSON.stringify({
                username,
            });
            const actionName = isAltRegistration ? 'resend-cognito-passcode' : 'resend-verification-email-by-email-id';
            const resendVerification = await mutationCall(actionName, actionParams);
            if (resendVerification.data) {
                return setModal(i18n.t('modal.tempPasswordExpired'));
            }

            return setErrorSnack(i18n.t('snack.error.contactAdmin'), config.notificationDuration);
        } else if (e.code === 'NotAuthorizedException' && e.message.match(/incorrect.*?password/i) && flow === 'INITIAL_LOGIN' && !e.message.match(/temporary\s+password.*?expired/i)) {
            return setErrorModal(i18n.t('modal.tempPasswordSent', {username: get(values, 'username', '').toLowerCase().trim()}));
        } else if (e.message === 'User is disabled.' && unAuthCounter === 0) {
            return setErrorSnack('Access denied', config.notificationDuration);
        } else if (e.code === 'NotAuthorizedException' && unAuthCounter === 0) {
            setUnAuthCounter(unAuthCounter + 1);
            return setErrorSnack(i18n.t('snack.error.incorrectEmailPassword'), config.notificationDuration);
        }
        if (e.code === 'NotAuthorizedException' && unAuthCounter >= 1) {
            // setUnAuthCounter(unAuthCounter + 1);
            return setIsIncorrect(true);
        }
        // if (e.code === 'NotAuthorizedException' && unAuthCounter >= 2) {
        //   setUnAuthCounter(unAuthCounter + 1);
        //   return setExpired(true);
        // }
        return setErrorSnack(i18n.t('snack.error.errorWithMessage', {message: e.message}), config.notificationDuration);
    }
}

/**
 * @category Login
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
function LoginPage({location}) {
    const {groupNumber, errorModal} = parse(location.search);
    const {breakpoints, shadows, colors} = useContext(ThemeContext);
    const [isExpired, setExpired] = useState(false);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [unAuthCounter, setUnAuthCounter] = useState(0);
    const [username, setUsername] = useState(null);
    const [isResendingPassword, setIsResendingPassword] = useState(false);
    const [modal, setModal] = useState(false);
    const [errorModalMessage, setErrorModal] = useState(false);
    // const width = window.innerWidth;
    const {width} = useWindowSize();
    // const height = window.innerHeight;
    const [setErrorSnack] = useSnackbar({color: 'error'});
    const [setSuccessSnack] = useSnackbar({color: 'accent'});
    const [loginFlow, setLoginFlow] = useState('');
    const [userId, setUserId] = useState('');
    const {
        altLogin = false, alternateRegistration = false, email: emailId = null,
    } = parse(location.search);
    const {t} = useTranslation()
    const selectedLang = localStorage.getItem('lang');

    useEffect(() => {
        if(selectedLang){
            i18n.changeLanguage(selectedLang);
        }
    }, [])

    // make a state if SignInHelp modal is open or not
    const [isSignInHelpOpen, setIsSignInHelpOpen] = useState(false);
    const [redirectToCompleteSignup, setRedirectToCompleteSignup] = useState(false);

    useEffect(() => {
        // localStorage.setItem('secondaryAuthSuccessful', false);
        localStorage.setItem('secondaryAuthSuccessful', true);
        if (!selectedLang && config.canadaEnv) {
            localStorage.setItem('lang', 'caEn')
        }
        if (alternateRegistration) {
            const user = decodeURI(emailId).toLowerCase().trim();
            setUsername(user);
            setLoginFlow('INITIAL_LOGIN');
        }
        if (altLogin && emailId) {
            const user = decodeURI(emailId).toLowerCase().trim();
            setUsername(user);
            setLoginFlow('ALTERNATE_LOGIN');
        }
        if (emailId) {
            const user = decodeURI(emailId).toLowerCase().trim();
            setUsername(user);
        }
        if (errorModal) {
            return setErrorModal(t('snack.error.contactIAICAdmin'));
        }
    }, []);

    const commonSimpleButtonSx = {
        '&:hover': {
            color: `${lighten(0.1, `${colors.accent}`)}`, transition: 'color ease-in-out 0.25s',
        },
        color: 'white',
        display: 'flex',
        fontSize: 2,
        fontWeight: 'bold',
        mb: 5,
        mt: 5,
        transition: 'color ease-in-out 0.25s',
        width: '100%',
    };
    const backgroundSx = () => {
        if (width >= pixelToNumber(breakpoints[3])) {
            return {
                backgroundImage: `url(${config.promoImageUrl})`,
                backgroundPosition: 'center bottom',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '95%',
            };
        }
        return {};
    };

    const boxShadowSx = () => {
        if (width >= pixelToNumber(breakpoints[3])) {
            return {
                boxShadow: shadows[4],
            };
        }
        return {};
    };

    return (<AuthContainer>
            {({newPasswordRequired, signIn}) => (<Box
                    bg="primary"
                    display="flex"
                    flexDirection={{_: 'column', lg: 'row'}}
                    height="100vh"
                    width="100%"
                >
                    <Redirect
                        state={{
                            alternateRegistration,
                        }}
                        to="/complete-signup"
                        when={newPasswordRequired || redirectToCompleteSignup}
                    />
                    <Box
                        bg={{_: 'primary', lg: 'white'}}
                        display="flex"
                        height={{lg: '100%'}}
                        justifyContent={{_: 'center', lg: 'flex-start'}}
                        sx={{...backgroundSx()}}
                        width={{lg: '50%'}}
                    >
                        {width >= pixelToNumber(breakpoints[3]) ? (<Logo
                                marginLeft={{lg: 5}}
                                mono={width < pixelToNumber(breakpoints[3])}
                                sx={{
                                    mt: '30px', top: '0',
                                }}
                            />) : (<Logo
                                mono
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: '50px',
                                    p: 1,
                                    svg: {
                                        height: 'auto',
                                    },
                                    width: '20rem',
                                }}
                            />)}

                        {width >= pixelToNumber(breakpoints[3]) && (<Box
                                as="h1"
                                color="primary"
                                fontSize={7}
                                sx={{
                                    ml: 5,
                                    mt: 5,
                                    position: [null, null, null, null, 'absolute'],
                                    top: '13rem',
                                    width: '30%',
                                }}
                            >
                                <Box>{t('auth.subtitle1')}</Box>
                                <Box>{t('auth.subtitle2')}</Box>
                            </Box>)}
                    </Box>
                    <Box
                        alignItems="center"
                        bg="primary"
                        display="flex"
                        flexWrap="wrap"
                        height={{lg: '100%'}}
                        justifyContent="center"
                        paddingBottom={6}
                        px={5}
                        sx={{
                            ...boxShadowSx(),
                        }}
                        width={{lg: '50%'}}
                    >
                        <FinalForm
                        mutators={{
                            validate: (fields) => {
                                fields.forEach(field => {
                                    field.focus()
                                    field.blur()
                                });
                            },
                        }}
                            onSubmit={async (values) => {

                                /**
                                 * @param {string} username
                                 * @returns {Promise<void>}
                                 */
                                async function flowHandler(username) {
                                    const arg = {
                                        flow: loginFlow,
                                        setErrorModal,
                                        setErrorSnack,
                                        setIsIncorrect,
                                        setModal,
                                        setUnAuthCounter,
                                        signIn,
                                        unAuthCounter,
                                        userId,
                                        username,
                                        values,
                                    };
                                    switch (loginFlow) {
                                        case 'NORMAL_LOGIN':
                                            await AWSLoginHandler({alternateRegistration, ...arg});
                                            break;
                                        case 'INITIAL_LOGIN':
                                            // eslint-disable-next-line no-case-declarations
                                            const loginSuccessful = await AWSLoginHandler({
                                                alternateRegistration, ...arg,
                                            });
                                            if (loginSuccessful) {
                                                setRedirectToCompleteSignup(true);
                                            }
                                            break;
                                        case 'ALTERNATE_REGISTER_WITH_COGNITO_USER':
                                            // eslint-disable-next-line no-case-declarations
                                            const altLoginResponse = await AWSLoginHandler({
                                                alternateRegistration: true, ...arg,
                                            });
                                            if (altLoginResponse) {
                                                setRedirectToCompleteSignup(true);
                                            }
                                            break;
                                        case 'ALTERNATE_REGISTER':
                                            // handled right after fetching login flow
                                            break;
                                        case 'ALTERNATE_LOGIN':
                                            // eslint-disable-next-line no-case-declarations
                                            const loginResponse = await AWSLoginHandler({
                                                alternateRegistration, ...arg,
                                            });
                                            if (loginResponse === true) {
                                                setRedirectToCompleteSignup(true);
                                            }
                                            break;

                                        case 'NO_MATCH_FLOW_FOUND':
                                            setErrorSnack(t('snack.error.noUserFound'), config.notificationDuration);
                                            break;
                                        default:
                                    }
                                }

                                /**
                                 * Additional steps after fetching login flow
                                 * @param {string} flow
                                 * @param {string} email
                                 * @returns {void}
                                 */
                                function preprocessFlow(flow, email) {
                                    switch (flow) {
                                        case 'NORMAL_LOGIN':
                                            // no step required
                                            break;
                                        case 'ALTERNATE_REGISTER_WITH_COGNITO_USER':
                                            // no step required
                                            break;
                                        case 'INITIAL_LOGIN':
                                            // temporary password is sent while adding the employee
                                            setModal(t('modal.tempPasswordCheckSpam', {email}));
                                            break;
                                        case 'ALTERNATE_REGISTER': {
                                            if (groupNumber) {
                                                return navigate('/alternate-email', {
                                                    state: {
                                                        email, groupNumber,
                                                    },
                                                });
                                            }
                                            setUsername(null);
                                            setErrorSnack(t('snack.error.emailNotFoundContactIaicAdmin'), config.notificationDuration);
                                            break;
                                        }
                                        case 'ALTERNATE_LOGIN':
                                            // temporary password is sent while adding secondary email
                                            setModal(t('modal.tempPasswordCheckSpam', {email}));
                                            break;
                                        case 'NO_MATCH_FLOW_FOUND': {
                                            setUsername(null);
                                            throw new Error(t('snack.error.noUserFound'));
                                        }
                                        default:
                                    }
                                }

                                try {
                                    if (alternateRegistration) {
                                        setUsername(get(values, 'username', '').toLowerCase().trim());
                                        return flowHandler(get(values, 'username', '').toLowerCase().trim());
                                    }
                                    if (loginFlow === '') {
                                        // NO LOGIN FLOW PRESENT, FETCH IT
                                        const {username: email} = values;
                                        const flowResponse = await getFlow(email);
                                        const {data, errors} = flowResponse;
                                        if (!isEmpty(errors)) {
                                            return setErrorSnack(`${errors[0].message}`, config.notificationDuration);
                                        }
                                        const {
                                            mutationRemoteAction: {data: responseData},
                                        } = data;
                                        const {status: flow = '', userId = ''} = JSON.parse(responseData);
                                        setUserId(userId);
                                        setLoginFlow(flow || '');
                                        // if (flow === 'INITIAL_LOGIN') {
                                        setUsername(get(values, 'username', '').toLowerCase().trim());
                                        // }
                                        preprocessFlow(flow, email);
                                    } else {
                                        setUsername(get(values, 'username', '').toLowerCase().trim());
                                        await flowHandler(get(values, 'username', '').toLowerCase().trim());
                                    }
                                } catch (e) {
                                    setErrorSnack(t('snack.error.errorWithMessage', {message: e.message}), config.notificationDuration);
                                }


                            }}
                            render={(formContext) => (< Box maxWidth="maxWidths.smallForm" mx="auto" width="100%">
                                    {config.canadaEnv && <Box
                                        sx={{
                                            position: 'fixed', right: '1rem', top: '1rem', width: 300,
                                        }}
                                    >
                                        <Form
                                         initialValues={{
                                                language: selectedLang
                                            }}
                                            onSubmit={() => {
                                            }}
                                            render={() => (<>
                                                    <Field
                                                        component={Dropdown}
                                                        initialValue={selectedLang || 'caEn'}
                                                        isSearchable={false}
                                                        name="language"
                                                        options={config.canadaEnv ? [{
                                                            canEnglishValue: null,
                                                            frenchValue: 'Français Canadien',
                                                            label: "Français Canadien",
                                                            value: "fr"
                                                        }, {
                                                            canEnglishValue: null,
                                                            frenchValue: null,
                                                            label: "Canadian English",
                                                            value: "caEn"
                                                        },] : [{
                                                            canEnglishValue: null,
                                                            frenchValue: 'Français Canadien',
                                                            label: "Français Canadien",
                                                            value: "fr"
                                                        }, {
                                                            canEnglishValue: null,
                                                            frenchValue: null,
                                                            label: "Canadian English",
                                                            value: "caEn"
                                                        }, {
                                                            canEnglishValue: null,
                                                            frenchValue: null,
                                                            label: "American English",
                                                            value: "en"
                                                        },]}
                                                    />
                                                    <OnChange name='language'>
                                                        {(lang) => {
                                                            i18n.changeLanguage(lang);
                                                            localStorage.setItem('lang', lang)
                                                            formContext?.form?.mutators?.validate()
                                                        }}
                                                    </OnChange>
                                                </>)}
                                        />
                                    </Box>}
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        justifyContent="space-between"
                                    >
                                        <Box
                                            color="white"
                                            fontSize={6}
                                            mb={3}
                                            textAlign={{_: 'center', lg: 'left'}}
                                        >
                                            {t('login.signIn')}
                                        </Box>
                                        <Box
                                            as="button"
                                            color="accentSecondaryDark"
                                            fontWeight={600}
                                            onClick={() => setIsSignInHelpOpen(!isSignInHelpOpen)}
                                        >
                                            {t('login.signInHelp')}
                                        </Box>
                                    </Box>
                                    {!config.canadaEnv && isSignInHelpOpen && (<Box color="white" my={5}>
                                            <Box mb={4}>
                                                {t('login.pleaseContactIaicAdminTechnicalQuestions')}
                                            </Box>
                                            <PhoneButton number="844-738-4242"/>
                                            <EmailButton email="IAIC.pets@independenceamerican.com"/>
                                        </Box>)}
                                    {config.canadaEnv && isSignInHelpOpen && (<Box color="white" my={5}>
                                            <Box mb={4}>
                                                {t('login.pleaseContactOnepackAdminTechnicalQuestion')}
                                            </Box>
                                            <PhoneButton number="833-310-7225"/>
                                            <EmailButton email="mypolicy@onepackplan.ca"/>
                                        </Box>)}
                                    <form autoComplete="off" onSubmit={formContext.handleSubmit}>
                                        <Field
                                            component={Input}
                                            data-cy="email"
                                            initialValue={username}
                                            label={t('login.email')}
                                            labelSx={{
                                                color: 'white',
                                            }}
                                            name="username"
                                            readOnly={loginFlow && username !== null}
                                            sx={{
                                                transition: 'all 80ms',
                                            }}
                                            validate={composeValidations(required, email)}
                                        />
                                        <OnChange name="username">
                                            {() => {
                                                if (!alternateRegistration && !altLogin) {
                                                    setLoginFlow('');
                                                }
                                            }}
                                        </OnChange>
                                        {loginFlow === 'NORMAL_LOGIN' && (<>
                                                <Password/>
                                                <ForgotPassword
                                                    commonSimpleButtonSx={commonSimpleButtonSx}
                                                    email={get(formContext, 'values.username', '')}
                                                />
                                            </>)}
                                        {['ALTERNATE_REGISTER_WITH_COGNITO_USER', 'INITIAL_LOGIN', 'ALTERNATE_LOGIN',].includes(loginFlow) && (<>
                                                <Password/>
                                                <ResendTemporaryPassword
                                                    alternateRegistration={alternateRegistration}
                                                    commonSimpleButtonSx={commonSimpleButtonSx}
                                                    flow={loginFlow}
                                                    setErrorSnack={setErrorSnack}
                                                    setExpired={setExpired}
                                                    setSuccessSnack={setSuccessSnack}
                                                    username={username}
                                                />
                                            </>)}
                                        <Box sx={{my: 4}}>
                                            <ToolbarButton
                                                bg="accentSecondaryDark"
                                                data-cy="submit"
                                                label={t('login.signIn')}
                                                m={0}
                                                submitting={formContext.submitting}
                                                type="submit"
                                                width="100%"
                                            />
                                        </Box>
                                    </form>
                                    <IncorrectPasswordModal
                                        isIncorrect={isIncorrect}
                                        setIsIncorrect={setIsIncorrect}
                                    />
                                    <ResendVerificationModal
                                        alternateRegistration={alternateRegistration}
                                        flow={loginFlow}
                                        isExpired={isExpired}
                                        isResendingPassword={isResendingPassword}
                                        setErrorSnack={setErrorSnack}
                                        setExpired={setExpired}
                                        setIsResendingPassword={setIsResendingPassword}
                                        setModal={setModal}
                                        username={formContext.values.username}
                                    />
                                    <EmailSentModal modal={modal} setModal={setModal}/>
                                    <EmailSentModal
                                        modal={errorModalMessage}
                                        setModal={setErrorModal}
                                        title={t('common.error')}
                                    />
                                </Box>)}
                        />
                    </Box>
                </Box>)}
        </AuthContainer>);
}

export default LoginPage;
LoginPage.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.shape,
    }),
};

LoginPage.defaultProps = {
    location: {search: {}},
};


function ResendTemporaryPassword({
                                     commonSimpleButtonSx,
                                     flow,
                                     alternateRegistration,
                                     username,
                                     setSuccessSnack,
                                     setErrorSnack,
                                 }) {
    const {t} = useTranslation()
    return (<ToolbarButton
            label={t('login.resendTempPassword')}
            onClick={async () => {
                const params = flow === 'ALTERNATE_REGISTER_WITH_COGNITO_USER' || alternateRegistration ? JSON.stringify({
                    email: username,
                }) : JSON.stringify({
                    username,
                });
                try {
                    const resolverName = flow === 'ALTERNATE_REGISTER_WITH_COGNITO_USER' || alternateRegistration ? 'resend-cognito-passcode' : 'resend-verification-email-by-email-id';
                    const resendVerification = await (await fetch(config.apollo.url, {
                            body: JSON.stringify({
                                query: print(mutationRemoteAction), variables: {
                                    name: resolverName, params,
                                },
                            }), headers: {
                                'content-type': 'application/json', 'x-api-key': config.apollo.apiKey,
                            }, method: 'POST',
                        })).json();
                    if (resendVerification.data) {
                        return setSuccessSnack(t('modal.tempPasswordSentToEmail'));
                    }

                    return setErrorSnack(t('snack.error.contactAdmin'), config.notificationDuration);
                } catch (e) {
                    return setErrorSnack(t('snack.error.temporaryPasswordUsed'), config.notificationDuration);
                }
            }}
            simple
            sx={{
                ...commonSimpleButtonSx, justifyContent: 'flex-end',
            }}
        />);
}

function ForgotPassword({commonSimpleButtonSx, email, ...rest}) {
    const {t} = useTranslation()
    return (<ToolbarButton
            label={t('login.forgotPassword')}
            onClick={() => {
                navigate('/password-reset', {
                    state: {
                        username: email,
                    },
                });
            }}
            simple
            sx={{
                ...commonSimpleButtonSx, justifyContent: 'flex-end',
            }}
            {...rest}
        />);
}

function Password() {
    const {t} = useTranslation()
    return (<Field
            component={InputPassword}
            data-cy="password"
            data-testid="password"
            label={t('login.password')}
            labelSx={{
                color: 'white',
            }}
            name="password"
            validate={composeValidations(minLength(config.minPasswordLength), required)}
        />);
}

function ResendVerificationModal({
                                     alternateRegistration,
                                     flow,
                                     isExpired,
                                     setExpired,
                                     setIsResendingPassword,
                                     username,
                                     setModal,
                                     setErrorSnack,
                                     isResendingPassword,
                                 }) {
    const {t} = useTranslation()
    return (<Modal
            closeText={t('common.ok')}
            isOpen={isExpired}
            onClose={() => {
                setExpired(false);
            }}
            onSubmit={async () => {
                setIsResendingPassword(true);
                const params = flow === 'ALTERNATE_REGISTER_WITH_COGNITO_USER' || alternateRegistration ? JSON.stringify({
                    email: username,
                }) : JSON.stringify({
                    username,
                });
                try {
                    const resolverName = flow === 'ALTERNATE_REGISTER_WITH_COGNITO_USER' || alternateRegistration ? 'resend-cognito-passcode' : 'resend-verification-email-by-email-id';
                    const resendVerification = await (await fetch(config.apollo.url, {
                            body: JSON.stringify({
                                query: print(mutationRemoteAction), variables: {
                                    name: resolverName, params,
                                },
                            }), headers: {
                                'content-type': 'application/json', // using this api key is potentially risky if the UI
                                // isn't behind a firewall... maybe there's a cleaner
                                // way to handle this?
                                'x-api-key': config.apollo.apiKey,
                            }, method: 'POST',
                        })).json();
                    if (resendVerification.data) {
                        setIsResendingPassword(false);

                        setExpired(false);
                        return setModal(t('modal.tempPasswordSentToEmail'));
                    }

                    setIsResendingPassword(false);
                    setExpired(false);
                    return setErrorSnack(t('snack.error.contactAdmin'), config.notificationDuration);
                } catch (e) {
                    setIsResendingPassword(false);
                    setExpired(false);
                    return setErrorSnack(t('snack.error.temporaryPasswordUsed'), config.notificationDuration);
                }
            }}
            submitText={t('login.resendTempPassword')}
            submitting={isResendingPassword}
            title={t('modal.emailOrPasswordIncorrect')}
        >
            <Box>{t('modal.incorrectEmailPasswordAgain')}</Box>
            <Box mt={4}>
                {t('modal.clickForgotPasswordIfPortalUsed')}
            </Box>
            <Box mt={4}>
                {t('modal.tempPasswordSent7DaysAgo')}      </Box>
        </Modal>);
}

// eslint-disable-next-line react/prop-types
function IncorrectPasswordModal({isIncorrect, setIsIncorrect}) {
    const {t} = useTranslation()
    return (<Modal
            closeText={t('common.ok')}
            isOpen={isIncorrect}
            onClose={() => {
                setIsIncorrect(false);
            }}
            title={t('snack.error.incorrectEmailPassword')}
        >
            <Box>
                {t('modal.incorrectMoreThanOnce')}
            </Box>
            <Box mt={4}>
                {t('modal.clickForgotPassword')}
            </Box>
        </Modal>);
}
