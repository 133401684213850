import {PropTypes} from 'prop-types';
import get from 'lodash/get';
import {PulseLoader} from 'react-spinners';
import React, {useContext} from 'react';
import {ThemeContext} from 'styled-components';
import GetData from "@src/utilities/get-data";

/**
 * @category Utils
 * @param {any} {data}
 * @returns {any}
 */
const RenewalType = ({ data }) => {
  const { colors } = useContext(ThemeContext);
  const { apiData = {}, loading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: get(data, 'groupId') })
  );

  if (loading) {
    return <PulseLoader color={`${colors.accentSecondary}`} size={7} />;
  }

  if (
    get(apiData, 'policyTerm') === 1 &&
    !get(apiData, 'postEnrollmentGroup')
  ) {
    return 'NOT APPLICABLE';
  }
  if (
    get(apiData, 'policyTerm') > 1 &&
    get(apiData, 'passiveRenewal') === true &&
    !get(apiData, 'postEnrollmentGroup')
  ) {
    return 'PASSIVE';
  }
  if (
    get(apiData, 'policyTerm') > 1 &&
    get(apiData, 'passiveRenewal') === false &&
    !get(apiData, 'postEnrollmentGroup')
  ) {
    return 'ACTIVE';
  }
  if (get(apiData, 'groupType') === 'BEN_ADMIN_SYSTEM') {
    return 'ENROLLMENT VIA BENADMIN';
  }
};

RenewalType.propTypes = {
  data: PropTypes.shape({
    groupId: PropTypes.string.isRequired,
  }).isRequired,
};

export default RenewalType;
