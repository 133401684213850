import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Form as FinalForm } from 'react-final-form';
import { get, keys, pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import { produce } from 'immer';
import useSnackbar from '@utils/use-snackbar';
import GetData from '@utils/get-data';
import generateRedirectPath from '@utils/generate-redirect-path';
import dateUtils from '@utils/date-utils';
import Spinner from '@basecomponents/Spinner';
import ToolbarButton from '@basecomponents/ToolbarButton';
import config from '@src/config.json';
import remoteActionQuery from '@queries/remote-action.gql';
import ClassTimeline from "@petcomponents/ProductAdminPage/PlanPricing/ClassTimeline/index";

const parsePlans = (plans) => {
  const response = {};
  plans.forEach((plan, index) => {
    response[`pet-${index}`] = {
      employerContribution: plan?.employerContribution,
    };
    response[`planChoiceId_${plan.productPlanId}`] = plan?.planChoiceId;
    Object.keys(plan).forEach((key) => {
      if (
        [
          'coverageFromDate',
          'coverageToDate',
          'enrollmentFromDate',
          'enrollmentToDate',
        ].includes(key)
      ) {
        if (plan[key]) {
          response[key] = dateUtils.setOffset2(plan[key]);
        }
      } else if (key === 'planChoiceClasses') {
        // Dropdown only supports one class right now ... ?
        response[`approvedAmount_${plan.productPlanId}`] = plan[key][0]
          ? plan[key][0].approvedAmount
          : null;
        response[`classId_${plan.productPlanId}`] = plan[key][0]
          ? plan[key][0].eligibilityClassId
          : null;
      }
    });
    response[`pet-${plan.planId}`] = {
      employerContribution: get(plan, `employerContribution`, 0),
    };
  });
  return response;
};

/**
 * @category ProductAdmin
 * @param {Object} location
 * @param {string} name
 * @param {*} rest
 * @returns {React.FC}
 */
const PlanPricing = ({ location, name, isRenewal, ...rest }) => {
  const { t } = useTranslation();
  const parameters = useParams();
  const groupId = get(parameters, 'groupId', '');
  const planId = get(parameters, 'planId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const isEditing = get(location, 'pathname', '').includes('/upsert-plan');

  const { apiData: planData = [], loading: planPricingLoading } = GetData(
    'get-plan-pricing',
    JSON.stringify({
      planId,
    })
  );

  const { apiData = {} } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );


  const noteId = apiData?.groupNote?.groupNoteId

  const { apiData: groupNote, loading: groupNoteLoading } = GetData(
    'get-group-note',
    JSON.stringify({
      noteId
    }),
    noteId == null
  )

  const { apiData: morePlanData = [], loading: planConfigLoading } = GetData(
    'get-plan-config',
    JSON.stringify({
      group: groupId,
    })
  );

  if (planPricingLoading || planConfigLoading) {
    return <Spinner />;
  }

  let productDetails = morePlanData.sort((first, second) => {
    if (first.instance > second.instance) {
      return 1;
    }

    if (first.instance < second.instance) {
      return -1;
    }

    return 0;
  });

  productDetails = productDetails.map((x) => {
    return {
      id: x.planId,
      name: x.name,
      product: x.product,
    };
  });

  const formatPlans = (values) => {
    const trfVal = {};
    const shared = {};
    Object.keys(values).forEach((field) => {
      const sharedFlag = field.indexOf('_');
      if (sharedFlag > 0) {
        const key = field.substr(0, sharedFlag);
        const productPlanId = field.substr(sharedFlag + 1);

        if (!(productPlanId in trfVal)) trfVal[productPlanId] = {};
        if (key === 'planChoiceId') {
          trfVal[productPlanId][key] = values[field];
        }
        if (key === 'approvedAmount' && typeof values[field] === 'string') {
          trfVal[productPlanId][key] = parseInt(
            values[field].replace('$', '').replace(',', ''),
            10
          );
        } else {
          trfVal[productPlanId][key] = values[field];
        }
      } else if (
        [
          'coverageFromDate',
          'coverageToDate',
          'enrollmentFromDate',
          'enrollmentToDate',
        ].includes(field)
      ) {
        shared[field] = dateUtils.setAPIDateOnly(`${values[field]}`);
      } else {
        shared[field] = values[field];
      }
    });
    const plans = productDetails.map((data) => {
      return {
        planId: data.id,
        productId: data.product,
        ...shared,
        ...trfVal[data.id],
      };
    });
    return { planId, plans };
  };

  return (
    <ApolloConsumer>
      {(client) => {
        const apiCall = (pathName, params) =>
          client.query({
            fetchPolicy: 'no-cache',
            query: remoteActionQuery,
            variables: {
              name: pathName,
              params: JSON.stringify(params),
            },
          });
        const apiCallData = async (name, values) => {
          const apiValue = values;
          let pickModel = {};
          if (isRenewal) {
            pickModel = {
              approvedAmount: null,
              classId: null,
              coverageFromDate: null,
              coverageToDate: null,
              enrollmentFromDate: null,
              enrollmentToDate: null,
              planId: null,
              productId: null,
            };
          } else if (!isEditing) {
            pickModel = {
              approvedAmount: null,
              classId: null,
              coverageFromDate: null,
              coverageToDate: null,
              enrollmentFromDate: null,
              enrollmentToDate: null,
              planChoiceId: null,
              planId: null,
              productId: null,
            };
          } else {
            pickModel = {
              enrollmentFromDate: null,
              enrollmentToDate: null,
              planChoiceId: null,
            };
          }

          apiValue.plans = apiValue.plans.map((data) => {
            const employerContribution = parseFloat(
              get(data, `pet-${data.planId}.employerContribution`)
            );
            const responseData = pick(data, keys(pickModel));
            if (isRenewal) {
              return { ...responseData, employerContribution };
            }
            return isEditing
              ? { ...responseData }
              : { ...responseData, employerContribution };
          });
          if (isEditing) {
            const apiVal = {
              planId: apiValue?.planId,
              plans: [apiValue?.plans[0]],
            };
            return apiCall('update-plan-choice', apiVal)
              .then((response) => {
                const data = JSON.parse(
                  get(response, 'data.remoteAction.data', JSON.stringify({}))
                );
                if (data.statusCode >= 400) {
                  setErrorSnack(data.response.detailMessage);
                } else {
                  navigate(`/groups/${groupId}/details`);
                }
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );
          }
          return apiCall(name, apiValue)
            .then(() => {
              navigate(
                generateRedirectPath({
                  ...location,
                  queryParams: {
                    step: isRenewal ? 9 : 3,
                  },
                })
              );
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };

        const parsedPlanData = parsePlans(planData);
        const initialValues = produce(parsedPlanData, draft => {
          draft.coverageFromDate = dateUtils?.setOffset2(
            get(apiData, 'billingInfo.firstCoverageDate', '')
          )
          if (draft.enrollmentFromDate == null) {
            draft.enrollmentFromDate = groupNote?.enrollmentStartDate && dateUtils?.setOffset2(groupNote?.enrollmentStartDate)
          }
          if (draft.enrollmentToDate == null) {
            draft.enrollmentToDate = groupNote?.enrollmentEndDate && dateUtils?.setOffset2(groupNote?.enrollmentEndDate)
          }
        })

        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={initialValues}
              onSubmit={async (values) => {
                await apiCallData('create-plan-pricing', formatPlans(values));
              }}
              render={(formContext) => {
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: 3,
                      }}
                    >
                      <Box
                        as="h3"
                        color="black"
                        display="flex"
                        justifyContent="flex-start"
                        sx={{
                          alignSelf: 'flex-start',
                          color: 'primary',
                          margin: '20px 0px 20px 0px;',
                          padding: 3,
                          width: '100%',
                        }}
                      >
                        Plan Configuration
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: ['column', null, null, null, 'row'],
                        }}
                      >
                        {productDetails.map((data, index) => {
                          const icon =
                            data.product === 'Accident Only'
                              ? 'accident-only'
                              : 'accident-illness';
                          return (
                            <ClassTimeline
                              key={index}
                              groupId={isRenewal ? null : groupId}
                              icon={icon}
                              id={data.id}
                              isEditing={isRenewal ? false : isEditing}
                              isRenewal={isRenewal}
                              noOfEmployees={get(apiData, 'noOfEmployees', 0)}
                              title={data.name}
                              values={formContext.values}
                              {...rest}
                            />
                          );
                        })}
                      </Box>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          // eslint-disable-next-line no-nested-ternary
                          justifyContent: isRenewal
                            ? 'space-between'
                            : isEditing
                              ? 'center'
                              : 'space-between',
                          my: 6,
                          p: 1,
                          width: '25rem',
                        }}
                      >
                        <ToolbarButton
                          bg="primaryLight"
                          label={t('common.previous')}
                          ml={0}
                          onClick={() =>
                            navigate(
                              generateRedirectPath({
                                ...location,
                                queryParams: {
                                  step: isRenewal ? 7 : 1,
                                },
                              })
                            )
                          }
                          visible={!isEditing}
                          width="10rem"
                        />
                        <ToolbarButton
                          bg="primaryDark"
                          data-testid="submitButton"
                          isDisabled={formContext.submitting}
                          label={
                            // eslint-disable-next-line no-nested-ternary
                            isRenewal ? t('common.next') : isEditing ? t('common.submit') : t('common.next')
                          }
                          mr={0}
                          submitting={formContext.submitting}
                          type="submit"
                          width="10rem"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

PlanPricing.propTypes = {
  isRenewal: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  name: PropTypes.string,
};

PlanPricing.defaultProps = {
  isRenewal: false,
  name: 'step',
};

export default PlanPricing;
