import React from 'react';
import get from 'lodash/get';
import DOEDates from "@petcomponents/Variant/ModuleInfo/DOEDates";
import Breed from "@petcomponents/Variant/ModuleInfo/Breed";
import Reason from "@petcomponents/Variant/ModuleInfo/Reason";
import EmployeeDependentStatus from "@petcomponents/Variant/ModuleInfo/EmployeeDependentStatus";
import User from "@petcomponents/Variant/ModuleInfo/User";
import Employee from "@petcomponents/Variant/ModuleInfo/Employee";
import EnrollmentStatus from "@petcomponents/Variant/ModuleInfo/EnrollmentStatus";
import QleStatusCode from "@petcomponents/Variant/ModuleInfo/QleStatusCode";
import GroupNameByUserId from "@petcomponents/Variant/ModuleInfo/GroupNameByUserId";
import GroupNotificationStatus from "@petcomponents/Variant/ModuleInfo/GroupNotificationStatus";
import MasterCalendarSlaMarker from "@petcomponents/Variant/ModuleInfo/MasterCalendarSlaMarker";

/**
 * Variant aggregator
 *
 * @category Components
 * @param {Object} data
 * @param {string} moduleId
 * @param {string} type
 * @returns {React.FC}
 */
const ModuleInfo = ({ data, moduleId, type, filterValues }) => {
  let result = null;

  switch (type) {
    case 'employee': {
      result = <Employee id={moduleId} />;
      break;
    }
    case 'user': {
      result = <User id={moduleId} />;
      break;
    }
    case 'enrollmentStatus': {
      result = <EnrollmentStatus status={moduleId} />;
      break;
    }
    case 'employeeDependentStatus': {
      result = <EmployeeDependentStatus status={moduleId} />;
      break;
    }
    case 'isbenAdmin': {
      result = moduleId === true ? 'Ben Admin' : 'IAIC Admin';
      break;
    }
    case 'qleStatusCode': {
      result = <QleStatusCode data={data} qleNumber={moduleId} />;
      break;
    }
    case 'groupNotificationStatus': {
      result = <GroupNotificationStatus data={data} qleNumber={moduleId} />;
      break;
    }
    case 'groupNameWithMidYearTransition': {
      if (
        ['FAILED', 'IN_PROGRESS'].includes(get(data, 'groupTransitionStatus'))
      ) {
        result = `${get(data, 'groupName')} (Mid Year Transition ${get(
          data,
          'groupTransitionStatus'
        )})`;
      } else {
        result = get(data, 'groupName');
      }
      break;
    }
    case 'groupTransitionStatus': {
      if (['IN_PROGRESS'].includes(get(data, 'groupTransitionStatus'))) {
        result = 'Processing';
      } else {
        result = get(data, 'groupTransitionStatus');
      }
      break;
    }
    case 'groupName': {
      result = <GroupNameByUserId data={data} />;
      break;
    }
    case 'terminationDate': {
      result = <Employee id={moduleId} type={type} />;
      break;
    }
    case 'masterCalendarSlaMarker': {
      result = (
        <MasterCalendarSlaMarker data={data} filterValues={filterValues} />
      );
      break;
    }
    case 'enrollmentDOE': {
      result = (
        <DOEDates data={data} type={type}  />
      );
      break;
    }
    case 'terminationDOE': {
      result = (
        <DOEDates data={data} type={type}  />
      );
      break;
    }
     case 'breed': {
      result = (
        <Breed value={moduleId}  />
      );
      break;
    }
    case 'reason': {
      result = (
        <Reason value={moduleId}  />
      );
      break;
    }
    case 'identifier': {
      if (get(data, type) === 'MISSING_PET_REPORT') {
        result = 'Missing Pet Report';
      } else if (get(data, type) === 'INELIGIBLE_PET_REPORT') {
        result = 'Ineligible Pet Report';
      } else {
        result = 'Employee Report';
      }

      break;
    }
    default: {
      result = `#${type} missing`;
    }
  }
  return result;
};

export default ModuleInfo;
