import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import Box from '@basecomponents/Box';
import { ThemeContext } from 'styled-components';
import InputWrapper from "@basecomponents/InputWrapper";
import ToolbarButton from "@basecomponents/ToolbarButton";

/**
 * @category BaseComponents
 * @param {Object} input
 * @param {boolean} disabled
 * @param {*} rest
 * @returns {React.FC}
 */
const InputPassword = ({ input, disabled, ...rest }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const { defaultStyle = {} } = useContext(ThemeContext);
  return (
    <InputWrapper
      disabled={disabled}
      input={input}
      wrapperSx={{
        bg: null,
        opacity: disabled ? '0.3' : null,
        pointerEvents: disabled ? 'none' : null,
      }}
      {...rest}
    >
      {({ error, ...inputRest }) => (
        <>
          <Box
            as="input"
            id={input.name}
            sx={defaultStyle.defaultInputPassword(error)}
            type={isPasswordVisible ? 'text' : 'password'}
            variant="inputs.primary"
            width="100%"
            {...inputRest}
            {...input}
          />
          <ToolbarButton
            aria-label="Show/Hide Password"
            buttonSx={{
              borderRadius: '50%',
              height: '30px',
              position: 'absolute',
              right: '1rem',
              top: '2rem',
              width: '30px',
            }}
            icon={isPasswordVisible ? 'visibilityoff' : 'visibility'}
            iconSx={{
              height: '15px',
              width: '15px',
            }}
            isDisabled={disabled}
            onClick={() => setPasswordVisible(!isPasswordVisible)}
          />
        </>
      )}
    </InputWrapper>
  );
};
InputPassword.defaultProps = {
  disabled: false,
};
InputPassword.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.shape({ error: PropTypes.bool, name: PropTypes.string })
    .isRequired,
};

export default InputPassword;
