import { get } from 'lodash';
import {
  composeValidations,
  email,
  required,
} from "@src/utilities/validators";
import { CONTACT_TYPE } from "@src/utilities/constants";
import { phoneNumberMask } from "@src/utilities/masks";

const administrators = {
  fields: [
    {
      isDisabled: ({ rowValues }) => get(rowValues, 'administratorId'),
      label: 'First Name',
      name: 'firstName',
      type: 'Input',
      validate: required,
    },
    {
      isDisabled: ({ rowValues }) => get(rowValues, 'administratorId'),
      label: 'Last Name',
      name: 'lastName',
      type: 'Input',
      validate: required,
    },
    {
      isDisabled: ({ rowValues }) => get(rowValues, 'administratorId'),
      label: 'Email',
      name: 'email',
      type: 'Input',
      validate: composeValidations(email, required),
    },
    {
      isDisabled: ({ rowValues }) => get(rowValues, 'deleted'),
      label: 'Primary Contact Type',
      name: 'contacts.0.contactType',
      options: CONTACT_TYPE,
      type: 'Dropdown',
      validate: required,
    },
    {
      isDisabled: ({ rowValues }) => get(rowValues, 'deleted'),
      label: 'Primary Phone Number',
      mask: phoneNumberMask,
      name: 'contacts.0.phoneNumber',
      type: 'InputMasked',
      validate: required,
    },
    {
      isDisabled: ({ rowValues }) => get(rowValues, 'deleted'),
      label: 'Alt. Contact Type',
      name: 'contacts.1.contactType',
      options: CONTACT_TYPE,
      type: 'Dropdown',
    },
    {
      isDisabled: ({ rowValues }) => get(rowValues, 'deleted'),
      label: 'Alt. Phone Number',
      mask: phoneNumberMask,
      name: 'contacts.1.phoneNumber',
      type: 'InputMasked',
    },
  ],
  name: 'administrators',
  sectionOperations: {
    canSkip: () => true,
    getCardTitle: ({ rowIndex }) => `Contact ${rowIndex + 1}`,
    isRemoveable: ({ rowValues }) => get(rowValues, 'administratorId'),
    isRemoveDisabled: () => false,
  },
  title: 'Administrators',
  type: 'List',
};

export default administrators;
