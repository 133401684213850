import React, { useState } from 'react';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import moment from 'moment';
import { OnChange } from 'react-final-form-listeners';
import { required } from "@src/utilities/validators";
import Route from "@petcomponents/Route";
import config from "@src/config.json";
import NotFoundPage from "@src/routes/ihc/404";
import MasterPolicyGenerator from "@petcomponents/MasterPolicyGenerator";
import Dropdown from "@basecomponents/Dropdown";
import { PRODUCT_PLAN, US_STATES } from "@src/utilities/constants";
import { labelHandler } from "@src/utilities/label-utils";
import ToolbarButton from "@basecomponents/ToolbarButton";

const MasterPolicyViewer = (props) => {
  const [mpState, setMpState] = useState(null);
  const [mpProduct, setMpProduct] = useState(null);
  const [mpGroupNumber, setMpGroupNumber] = useState(null);
  const [zoomLevel, setZoomLevel] = useState('25%');
  if (!config.developerMode) {
    return <NotFoundPage />;
  }

  return (
    <Route
      header={{
        icon: 'dashboard',
        title: 'Master Policy Viewer',
        type: 'UX',
        typeLabel: 'User Experience',
      }}
      isPrivate
      {...props}
    >
      <FinalForm
        onSubmit={(values) => {
          setMpState(values.state);
          setMpProduct(values.product);
          setMpGroupNumber(values.MGA);
        }}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  alignItems: 'center',
                  bg: 'accent',
                  borderRadius: 5,
                  boxShadow: 6,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  p: 4,
                }}
              >
                <Box m={3} width="350px">
                  <Field
                    aria-label="State"
                    component={Dropdown}
                    label={labelHandler('State', true)}
                    name="state"
                    options={US_STATES}
                    validate={required}
                  />
                </Box>
                <Box m={3} width="350px">
                  <Field
                    aria-label="product"
                    component={Dropdown}
                    label={labelHandler('Product', true)}
                    name="product"
                    options={PRODUCT_PLAN}
                    validate={required}
                  />
                </Box>
                <Box m={3} width="350px">
                  <Field
                    aria-label="MGA"
                    component={Dropdown}
                    label={labelHandler('MGA', true)}
                    name="MGA"
                    options={[
                      {
                        label: 'FIGO',
                        value: 'GFPI0000',
                      },
                      {
                        label: 'PPI',
                        value: 'GPPI0000',
                      },
                    ]}
                    validate={required}
                  />
                </Box>
                <ToolbarButton
                  bg="accentSecondary"
                  label="Generate MP"
                  m={2}
                  type="submit"
                />
              </Box>
            </form>
          );
        }}
      />
      {mpProduct && mpState && mpGroupNumber && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <FinalForm
            initialValues={{ zoomLevel }}
            onSubmit={() => {}}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      p: 4,
                    }}
                  >
                    <Box m={3} width="350px">
                      <Field
                        aria-label="zoomLevel"
                        component={Dropdown}
                        defaultValue={{
                          label: '25%',
                          value: '25%',
                        }}
                        label={labelHandler('Zoom Level', true)}
                        name="zoomLevel"
                        options={[
                          {
                            label: '25%',
                            value: '25%',
                          },
                          {
                            label: '50%',
                            value: '50%',
                          },
                          {
                            label: '75%',
                            value: '75%',
                          },
                          {
                            label: '100%',
                            value: '100%',
                          },
                        ]}
                        validate={required}
                      />
                      <OnChange name="zoomLevel">
                        {(value) => {
                          setZoomLevel(value);
                        }}
                      </OnChange>
                    </Box>
                  </Box>
                </form>
              );
            }}
          />
          <Box style={{ width: zoomLevel }}>
            <MasterPolicyGenerator
              address={{
                addressLine1: 'Sample Address Line 1',
                addressLine2: 'Sample Address Line 2',
                city: 'Sample City',
                state: mpState,
                zipcode: '00000',
              }}
              coverageFromDate={moment().format('MM/DD/yyyy')}
              groupName="Sample Group Inc."
              groupNumber={mpGroupNumber}
              planChoiceId=""
              planId=""
              product={mpProduct}
              renewalDate={moment().add(1, 'year').format('MM/DD/yyyy')}
              stateOfIssue={mpState}
              type={mpState}
            />
          </Box>
        </Box>
      )}
    </Route>
  );
};

export default MasterPolicyViewer;
