import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import GetData from "@src/utilities/get-data";
import ModuleContainer from "@petcomponents/Variant/ModuleInfo/Container";
import EmptyVariant from "@petcomponents/Variant/EmptyVariant";
import { USER_ROLE } from "@src/utilities/constants";

/**
 * @category Components
 * @param {Object} data
 * @returns {React.FC}
 */
const GroupNameByUserId = ({ data }) => {
  const {
    apiData: { content = [] },
    loading,
  } = GetData(
    'get-group-employees',
    JSON.stringify({ skipActiveCheck: true, userId: get(data, 'userId') }),
    [USER_ROLE.EMPLOYER].includes(get(data, 'role', '').toLowerCase()),
    'cache-first'
  );
  const {
    apiData: { content: employerData = [], loading: loading2 },
  } = GetData(
    'get-all-administrator-by-group-id',
    JSON.stringify({ userId: get(data, 'userId') }),
    ![USER_ROLE.EMPLOYER].includes(get(data, 'role', '').toLowerCase()),
    'cache-first'
  );

  const { apiData: group = {}, loading: loading3 } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: get(employerData, '0.groupId', '') }),
    ![USER_ROLE.EMPLOYER].includes(get(data, 'role', '').toLowerCase())
  );
  let result = '';
  if ([USER_ROLE.EMPLOYER].includes(get(data, 'role', '').toLowerCase())) {
    result = get(group, 'groupName');
  } else {
    result = content.length ? (
      `${get(content, '0.groupName', '')}`
    ) : (
      <EmptyVariant />
    );
  }

  return (
    <ModuleContainer
      displayValue={result}
      loading={loading || loading2 || loading3}
    />
  );
};

GroupNameByUserId.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default GroupNameByUserId;
