import { get } from 'lodash';
import { print } from 'graphql';
import mutationRemoteAction from "@mutations/mutation-remote-action.gql";
import config from "@src/config.json";

async function updateLastLogin(username) {
  const result = await (
    await fetch(config.apollo.url, {
      body: JSON.stringify({
        query: print(mutationRemoteAction),
        variables: {
          name: 'set-login-timestamp',
          params: JSON.stringify({ email: username }),
        },
      }),
      headers: {
        'content-type': 'application/json',
        'x-api-key': config.apollo.apiKey,
      },
      method: 'POST',
    })
  ).json();
  const mutationRes = JSON.parse(
    get(result, 'data.mutationRemoteAction.data', '{}')
  );
  const { updated } = mutationRes;
  if (updated) {
    return updated;
  }
  throw new Error('Unable to update last login');
}

export default updateLastLogin;
