import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
import { diff } from 'deep-object-diff';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  composeValidations,
  email,
  required,
} from "@src/utilities/validators";
import remoteActionQuery from "@queries/remote-action.gql";
import { CONTACT_TYPE } from "@src/utilities/constants";
import { labelHandler } from "@src/utilities/label-utils";
import { phoneNumberMask } from "@src/utilities/masks";
import useSnackbar from "@src/utilities/use-snackbar";
import AddressFormSection from "@petcomponents/AddressFormSection";
import InputDropdown from "@basecomponents/Dropdown";
import InputText from "@basecomponents/Input";
import InputMasked from "@basecomponents/InputMasked";
import Modal from "@basecomponents/Modal";
import ToolbarButton from "@basecomponents/ToolbarButton";
import generateRedirectPath from "@src/utilities/generate-redirect-path";
import config from "@src/config.json";

/**
 * @category Producer
 * @param {Object} location
 * @param {Object} producerInfo
 * @param {*} rest
 * @returns {React.FC}
 */
const ProducerContact = ({ location, producerInfo, ...rest }) => {
  const { t } = useTranslation()
  const queryParams = useParams();
  const producerId = get(queryParams, 'producerId', '');
  const isEditing = get(location, 'pathname', '').includes('/groups/edit/');
  const name = 'step';
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [isSubmitModalOpen, toggleSubmitModal] = useState(false);
  const [setSuccessSnack] = useSnackbar({ color: 'primary' });

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  const contactAddress = get(producerInfo, 'contactAddress', {});
  const contact = get(producerInfo, 'contact', {});

  const contactAddressInfo = {
    addressLine1: get(contactAddress, 'addressLine1', ''),
    addressLine2: get(contactAddress, 'addressLine2', ''),
    city: get(contactAddress, 'city', ''),
    state: get(contactAddress, 'state', ''),
    zipcode: get(contactAddress, 'zipcode', ''),
  };

  const contactAddressId = get(producerInfo, 'contactAddressId', '');
  const contactId = get(producerInfo, 'contactId', '');

  const contactInfo = {
    contactType: get(contact, 'contactType', ''),
    phoneNumber: get(contact, 'phoneNumber', ''),
  };

  const producerContactInfo = {
    contactEmail: get(producerInfo, 'contactEmail', ''),
    contactName: get(producerInfo, 'contactName', ''),
  };

  if (!producerId) {
    return navigate(
      generateRedirectPath({
        ...location,
        queryParams: { [name]: 0 },
      })
    );
  }

  return (
    <>
      <ApolloConsumer>
        {(client) => {
          const onClickAction = async (values) => {
            const { contactAddress, contactName, contactEmail } = values;
            const apiCall = (pathName, params) =>
              client.query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: pathName,
                  params: JSON.stringify(params),
                },
              });
            if (isEditing || contactAddressId || contactId) {
              const promises = [];
              const contactAddressDiff = diff(
                contactAddressInfo,
                contactAddress
              );
              const contactDiff = diff(contactInfo, {
                contactType: values.contactType,
                phoneNumber: values.phoneNumber,
              });
              const producerContactDiff = diff(producerContactInfo, {
                contactEmail,
                contactName,
              });
              if (Object.keys(contactAddressDiff).length) {
                const contactAddressPromise = new Promise((resolve, reject) => {
                  apiCall('update-producer-address', {
                    ...contactAddressDiff,
                    id: contactAddressId,
                  })
                    .then((response) => resolve(response))
                    .catch((e) => {
                      setErrorSnack(
                        `There was an error: ${e.message}`,
                        config.notificationDuration
                      );
                      reject(e);
                    });
                });
                promises.push(contactAddressPromise);
              }
              if (Object.keys(contactDiff).length) {
                const contactPromise = new Promise((resolve, reject) => {
                  apiCall('update-producer-contact', {
                    ...contactDiff,
                    id: contactId,
                  })
                    .then((response) => resolve(response))
                    .catch((e) => {
                      setErrorSnack(
                        `There was an error: ${e.message}`,
                        config.notificationDuration
                      );
                      reject(e);
                    });
                });
                promises.push(contactPromise);
              }
              if (Object.keys(producerContactDiff).length) {
                const producerContactPromise = new Promise(
                  (resolve, reject) => {
                    apiCall('update-producer', {
                      ...producerContactDiff,
                      id: producerId,
                    })
                      .then((response) => resolve(response))
                      .catch((e) => {
                        setErrorSnack(
                          `There was an error: ${e.message}`,
                          config.notificationDuration
                        );
                        reject(e);
                      });
                  }
                );
                promises.push(producerContactPromise);
              }
              if (promises.length) {
                return Promise.all(promises)
                  .then(() => {
                    setSuccessSnack(
                      'Producer Details updated successfully!',
                      config.notificationDuration
                    );
                    return navigate('/producers/dashboard');
                  })
                  .catch((e) =>
                    setErrorSnack(
                      `There was an error: ${e.message}`,
                      config.notificationDuration
                    )
                  );
              }
              return navigate('/producers/dashboard');
            }
            return apiCall('create-producer-contact', {
              contactType: values.contactType,
              phoneNumber: values.phoneNumber,
            })
              .then((response) => {
                return apiCall('create-producer-address', contactAddress).then(
                  (addressResponse) => {
                    const contactId = get(
                      JSON.parse(get(response, 'data.remoteAction.data', '')),
                      'contactId',
                      ''
                    );

                    const contactAddressId = get(
                      JSON.parse(
                        get(addressResponse, 'data.remoteAction.data', '')
                      ),
                      'addressId',
                      ''
                    );
                    if (!(contactId && contactAddressId)) {
                      return setErrorSnack(
                        'Something went wrong. Please try again.',
                        config.notificationDuration
                      );
                    }
                    return apiCall('update-producer', {
                      contactAddressId,
                      contactEmail,
                      contactId,
                      contactName,
                      id: producerId,
                    }).then((response) => {
                      const savedContactId = get(
                        JSON.parse(get(response, 'data.remoteAction.data', '')),
                        'contactId',
                        ''
                      );
                      if (savedContactId !== contactId) {
                        return setErrorSnack(
                          'Something went wrong. Please try again.',
                          config.notificationDuration
                        );
                      }
                      return toggleSubmitModal(true);
                    });
                  }
                );
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );
          };

          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                initialValues={
                  contactAddressId || contactId
                    ? {
                        ...contactInfo,
                        ...producerContactInfo,
                        contactAddress: contactAddressInfo,
                      }
                    : {}
                }
                onSubmit={onClickAction}
                render={(formContext) => {
                  return (
                    <form onSubmit={formContext.handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          padding: 3,
                        }}
                      >
                        <Box
                          as="h3"
                          color="black"
                          display="flex"
                          justifyContent="flex-start"
                          sx={{
                            color: 'primary',
                            margin: '20px 0px 20px 0px;',
                            padding: 3,
                            width: '25rem',
                          }}
                        >
                          Point Of Contact
                        </Box>
                        <Field
                          aria-label="Name of Contact"
                          component={InputText}
                          label={labelHandler('Name of Contact', true)}
                          name="contactName"
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <AddressFormSection
                          label="contactAddress"
                          wrapperSx={commonFieldSxHalf}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.phoneNumberType')}
                          component={InputDropdown}
                          label={labelHandler(t('groupEmployees.enrollmentPage.phoneNumberType'), true)}
                          name="contactType"
                          options={CONTACT_TYPE}
                          searchable={false}
                          validate={required}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label={t('groupEmployees.enrollmentPage.phoneNumber')}
                          component={InputMasked}
                          label={labelHandler(t('groupEmployees.enrollmentPage.phoneNumber'), true)}
                          mask={phoneNumberMask}
                          name="phoneNumber"
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Field
                          aria-label="Email Address"
                          component={InputText}
                          label={labelHandler('Email Address', true)}
                          name="contactEmail"
                          validate={composeValidations(required, email)}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            my: 6,
                          }}
                        >
                          <Button
                            onClick={() =>
                              navigate(
                                generateRedirectPath({
                                  ...location,
                                  queryParams: { [name]: 1 },
                                })
                              )
                            }
                            sx={{
                              '&:hover': { bg: 'primaryLight' },
                              bg: 'primary',
                              mx: 'auto',
                              width: '10rem',
                            }}
                          >
                            Previous
                          </Button>

                          <ToolbarButton
                            bg="primaryDark"
                            label={t('common.submit')}
                            mr={0}
                            submitting={formContext.submitting}
                            type="submit"
                            width="10rem"
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
      {isSubmitModalOpen && (
        <Modal
          closeText={t('common.no')}
          isOpen={isSubmitModalOpen}
          onClose={() => navigate('/producers/dashboard')}
          onSubmit={() => {
            if (!producerId) {
              toggleSubmitModal(false);
              setErrorSnack(
                'Something went wrong.',
                config.notificationDuration
              );
              return navigate('/producers/dashboard');
            }
            return navigate(
              generateRedirectPath({
                pathname: '/sub-producers/upsert',
                queryParams: { producerId },
                search: '',
              })
            );
          }}
          submitText={t('common.yes')}
        >
          <Box>Do you want to add sub-producers under this producer?</Box>
        </Modal>
      )}
    </>
  );
};

ProducerContact.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  producerInfo: PropTypes.shape({}),
};

ProducerContact.defaultProps = {
  producerInfo: {},
};

export default ProducerContact;
