import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from '@reach/router';
import { get } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import { required } from "@src/utilities/validators";
import Route from "@petcomponents/Route";
import remoteActionQuery from "@queries/remote-action.gql";
import { labelHandler } from "@src/utilities/label-utils";
import ToolbarButton from "@basecomponents/ToolbarButton";
import useSnackbar from "@src/utilities/use-snackbar";
import InputDate from "@basecomponents/InputDate";
import downloadData from "@src/utilities/download-file";
import config from "@src/config.json";
import dateUtils from "@src/utilities/date-utils";

/**
 * @category Billing
 * @param {string} type Type of cash premium
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const CashPremium = ({ type, ...rest }) => {
  // const [dates, setDates] = useState([]);
  let reportType = 'AUTO_GENERATED_CASH_PREMIUM';
  let title = 'Download Cash Premium Report';
  let title2 = 'Cash Premium Report';

  switch (type) {
    case 'employeeCashPremium': {
      reportType = 'AUTO_GENERATED_EMPLOYEE_CASH_PREMIUM';
      title = 'Download Employee Cash Premium Report';
      title2 = 'Employee Cash Premium Report';
      break;
    }
    case 'petCashPremium': {
      reportType = 'AUTO_GENERATED_PET_CASH_PREMIUM';
      title = 'Download Pet Cash Premium Report';
      title2 = 'Pet Cash Premium Report';
      break;
    }
    case 'masterBillingCalendar': {
      reportType = 'MASTER_BILLING_CALENDAR';
      title = 'Download Billing Calendar Report';
      title2 = 'Master Calendar Billing Report';
      break;
    }
    default: {
      reportType = 'AUTO_GENERATED_CASH_PREMIUM';
      title = 'Download Cash Premium Report';
      title2 = 'Cash Premium Report';
      break;
    }
  }

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });

  return (
    <Route
      header={{
        title,
        type: 'billing',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {title2}
      </Box>
      <ApolloConsumer>
        {(client) => {
          const getCashPremium = async (values) => {
            // let fixedDate = new Date(values.selectedDate.getTime());
            // fixedDate = dateUtils.setOffset(fixedDate, true);
            // fixedDate.setDate(fixedDate.getDate() + 1);
            const fixedDate = `${dateUtils.setAPIDateOnly(
              values.selectedDate
            )} 00:00:00`;
            await client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'download-cash-premium',
                  params: JSON.stringify({
                    reportType,
                    selectedDate: fixedDate,
                  }),
                },
              })
              .then((response) => {
                const results = JSON.parse(
                  get(response, 'data.remoteAction.data', '{}')
                );
                downloadData({
                  s3BucketName: config.amplify.Storage.AWSS3.bucket,
                  s3KeyName: get(results, 's3Key', ''),
                })
                  .then(() => {
                    setSuccessSnack(
                      'Report successfully downloaded',
                      config.notificationDuration
                    );
                  })
                  .catch(() => {
                    setErrorSnack(
                      `No ${title2} found`,
                      config.notificationDuration
                    );
                  });
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error downloading the report: ${e.message}`,
                  config.notificationDuration
                )
              );
          };

          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                onSubmit={getCashPremium}
                render={({ handleSubmit, submitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          padding: 3,
                        }}
                      >
                        <Box flex="1" justifyContent="center">
                          <Field
                            aria-label="Confirmed Date"
                            component={InputDate}
                            label={labelHandler('Confirmed Date', true)}
                            name="selectedDate"
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          <ToolbarButton
                            bg="accentLight"
                            isDisabled={submitting}
                            justifyContent="center"
                            label="Download"
                            m="20px auto 0"
                            px="0"
                            submitting={submitting}
                            sx={{ width: '150px' }}
                            type="submit"
                          />

                          <ToolbarButton
                            bg="accentDark"
                            label="Done"
                            m="20px auto"
                            onClick={() => navigate(`/billing/dashboard`)}
                            sx={{ width: '150px' }}
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};
CashPremium.defaultProps = {
  type: '',
};
CashPremium.propTypes = {
  location: PropTypes.shape({}).isRequired,
  type: PropTypes.string,
};

export default CashPremium;
