import get from 'lodash/get';
import {useTranslation} from "react-i18next";
import { EXISTING_CONDITION } from "@src/utilities/constants";

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const ExistingCondition = ({ value }) => {
  const { i18n } = useTranslation();
  const existingCondition = EXISTING_CONDITION.find(
    (s) => s.value === (parseInt(value, 10) ? parseInt(value, 10) : 0)
  );
  // return existingCondition ? (i18n.language === 'fr' && get(existingCondition, 'frenchValue', '') !== '') ? get(existingCondition, 'frenchValue'): get(existingCondition, 'label') : `#${value}`;
  let result;

if (existingCondition) {
    if (i18n.language === 'fr' && get(existingCondition, 'frenchValue', '') !== '') {
        result = get(existingCondition, 'frenchValue');
    } else {
        result = get(existingCondition, 'label');
    }
} else {
    result = `#${value}`;
}

return result;
};

export default ExistingCondition;
