import { AuthContext } from '@basecomponents/Auth';
import Box from '@basecomponents/Box';
import { get, isEmpty, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import qs from 'query-string';
import Moment from 'moment';
import { ApolloConsumer } from '@apollo/client';
import { Field, Form as FinalForm } from 'react-final-form';
import { BarLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';
import { composeValidations, required } from '@utils/validators';
import { refundAmount } from '@utils/validations';
import { labelHandler } from '@utils/label-utils';
import { USER_ROLE } from '@utils/constants';
import config from '@src/config.json';
import ListGrid from '@basecomponents/ListGrid';
import { useTranslation } from 'react-i18next';
import useSsm from '@src/utilities/hooks/useSsm';
import InputMoney from '@basecomponents/InputMoney';
import useLazyData from '@src/utilities/get-lazy-data';
import parseRemoteAction from '@src/utilities/parse-remote-action';
import ToolbarButton from '@basecomponents/ToolbarButton';
import GetData from '@src/utilities/get-data';
import getMetaData from '@src/utilities/get-meta-data';
import BillingReports from '@petcomponents/BillingReports';
import Modal from '@basecomponents/Modal';
import Card from '@basecomponents/Card';
import FileViewSection from '@petcomponents/FileViewSection';
import GroupAdministratorsDashboard from '@petcomponents/GroupAdministrators/Dashboard';
import GroupEmployeesDashboard from '@petcomponents/GroupEmployees/Dashboard';
import KeyValuePairs from '@basecomponents/KeyValuePairs';
import Route from '@petcomponents/Route';
import Tabs from '@basecomponents/Tabs';
import InputCheckbox from '@basecomponents/InputCheckbox';
import TimeTraversal from '@petcomponents/GroupDetails/TimeTraversal';
import PaymentHistory from '@petcomponents/GroupDetails/PaymentHistory';
import EditAccessRightsContext from '@petcomponents/EditAccessRights/Context';
import BillingCalender from '@petcomponents/GroupDetails/BillingCalender';
import useSnackbar from '@src/utilities/use-snackbar';
import HeaderRightContainer from '@basecomponents/HeaderRightContainer';
import remoteActionQuery from '@queries/remote-action.gql';
import InputDropdown from '@basecomponents/Dropdown';
import dateUtils from '@src/utilities/date-utils';
import NotesDashboard from '@petcomponents/GroupDetails/NotesDashboard';
import ExportDetails from '@petcomponents/GroupDetails/ExportDetails';

export const cleanMoney = (amount) =>
  parseFloat(String(amount).replace('$', '').replace(',', ''));

/**
 * Show Group details
 *
 * @category Group
 * @param {Object} location
 * @param {string} groupId
 * @param {boolean} applyPermission
 * @param {*} rest
 * @returns {React.FC}
 */
const GroupDetails = ({ location, groupId, applyPermission, ...rest }) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { colors } = useContext(ThemeContext);
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const [deletedEmployee, setDeletedEmployee] = useState(false);
  const [deletedEmployeeLoading, setDeletedEmployeeLoading] = useState(false);
  const [callApi, setApiCall] = useState(false);
  const [showWarning, setWarning] = useState(false);
  const [retryEnrollment, setRetryEnrollment] = useState(false);
  const [isRetryEnrollmentDisabled, setIsRetryEnrollmentDisabled] =
    useState(false);
  const [fetchGroupData] = useLazyData(
    'get-group-by-id',
    JSON.stringify({ id: groupId })
  );
  const {
    apiData: { GroupTerminationReasonType = [], CancellationReason = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([
      { params: ['GroupTerminationReasonType', 'CancellationReason'] },
    ])
  );
  const userRole = get(user, 'customRole', '');
  const userId = get(user, 'customSystemUserId', '');
  const groupMgaName = get(user, 'customMga', 'ppi').toLowerCase();
  const {
    apiData = {},
    loading,
    refetch: refetchGroupData,
  } = GetData('get-group-by-id', JSON.stringify({ id: groupId }), !groupId);

  const { apiData: benAdminData = {} } = GetData(
    'get-ben-admin-by-id',
    JSON.stringify({ id: apiData?.groupBenAdminXref?.benAdminId }),
    apiData?.groupType !== 'BEN_ADMIN_SYSTEM'
  );

  const { apiData: { content: groupCreditData = [] } = {} } = GetData(
    'get-all-bills',
    JSON.stringify({
      groupId,
      page: 0,
      size: 1,
      sort: 'payrollSchedule.cycleNo,desc',
    })
  );
  const { apiData: failedToEnrollEmployees, loading: failedToEnrollLoading } =
    GetData(
      'get-all-group-employees',
      JSON.stringify({
        enrollmentStatus: 'FAILED_TO_ENROLL',
        groupId,
        page: 0,
        size: 1,
      }),
      !groupId
    );
  // const totalRefundAmount = ['PAID'].includes(get(groupCreditData, '0.status'))
  //   ? get(groupCreditData, '0.remainingCredit', 0)
  //   : get(groupCreditData, '0.currentCredit', 0);
  const totalRefundAmount = get(groupCreditData, '0.unappliedFunds', 0);
  const billingInformationId = get(apiData, 'billingInfo.billingInfoId', '');
  const {
    apiData: { content: payrollData = [] },
  } = GetData(
    'get-payroll-schedule',
    JSON.stringify({
      billingInfoId: billingInformationId,
      groupId,
      size: get(apiData, 'billingInfo.billingCycle', ''),
      sort: 'cycleNo,asc',
    }),
    !groupId || !billingInformationId
  );
  const {
    apiData: groupVersionData = [],
    loading: groupVersionDataLoading,
    refetch: refetchGroupVersion,
  } = GetData(
    'get-group-by-groupNumber',
    JSON.stringify({ groupNumber: get(apiData, 'groupNumber', null) }),
    !get(apiData, 'groupNumber', null)
  );
  const { apiData: plansData = [], loading: planConfigLoading } = GetData(
    'get-plan-config',
    JSON.stringify([
      {
        group: groupId,
      },
    ])
  );
  const chaseEnabled = useSsm('iaic-chase-enabled');

  const [showModal, setShowModal] = useState(false);
  const [showModalEnrollment, setShowModalEnrollment] = useState(false);
  const [cancelRenewal, setCancelRenwal] = useState(false);
  const [employeeMigration, setEmployeeMigration] = useState(false);
  const [groupTermination, setGroupTermination] = useState(false);
  const [resetGroup, setResetGroupCredit] = useState(false);
  const [deleteAllEmployees, setDeleteAllEmployees] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const queryParams = qs.parse(location.search);
  const groupPaymentRecordId = get(queryParams, 'groupPaymentRecordId', null);
  const payrollScheduleId = get(queryParams, 'payrollScheduleId', null);
  const ear = useContext(EditAccessRightsContext);

  const date = get(apiData, 'relativeDatetime', null)
    ? dateUtils.setOffset2(apiData.relativeDatetime)
    : new Date();
  const options =
    payrollData.length > 0
      ? payrollData
          .filter(
            (item) => item?.endDate !== null && new Date(item?.endDate) > date
          )
          .map((item) => {
            return {
              canEnglishValue: null,
              frenchValue: null,
              label: dateUtils.getUTCDateOnly(item.endDate),
              value: dateUtils.setOffset2(item.endDate),
            };
          })
      : [];
  const plans = get(apiData, 'plan.planChoices', []).map((item) => {
    if (Array.isArray(plansData)) {
      const product = plansData.find(
        (plan) => plan.planId === item.productPlanId
      );
      return {
        ...item,
        ...product,
      };
    }
    return item;
  });
  const meta = getMetaData({ moduleName: 'groups' });
  const forgoRenewal = !get(apiData, 'isRenewable', true);

  useEffect(() => {
    const disableStatus = isEmpty(failedToEnrollEmployees?.content);

    setIsRetryEnrollmentDisabled(disableStatus);
  }, [failedToEnrollLoading]);

  const groupDetailsFields = [
    { name: 'groupName' },
    { name: 'groupNumber' },
    { name: 'groupType' },
    { name: 'groupTransitionStatus' },
    { name: 'mga' }, // { name: 'fin' },
    { name: 'companyCode' }, // { name: 'sicCode' },
    { name: 'noOfEmployees' },
    { name: 'alternateRegistration' },
    { name: 'billingInfo.billingType' },
    { name: 'billingInfo.billingCycle' },
    { name: 'billingInfo.payrollFrequency' },
    { name: 'ratingFrequency' },
    { name: 'billingInfo.accountType' },
    { name: 'renewalNotificationDate' },
    { name: 'cancellationReason' },
    { name: 'payrollDeductionReportLevel' },
    { name: 'benAdminName' },
    { name: 'renewalType' },
  ];
  if (config.canadaEnv) {
    groupDetailsFields.splice(5, 0, {
      dynamicLabel: t('groups.businessNumber'),
      name: 'fin',
    });
    groupDetailsFields.splice(7, 0, {
      dynamicLabel: 'NAIC Code',
      name: 'sicCode',
    });
  } else {
    groupDetailsFields.splice(5, 0, {
      dynamicLabel: 'Federal Number',
      name: 'fin',
    });
    groupDetailsFields.splice(7, 0, {
      dynamicLabel: 'SIC Code',
      name: 'sicCode',
    });
  }

  if (![USER_ROLE.EMPLOYEE, USER_ROLE.TERMINATED_EMPLOYEE].includes(userRole)) {
    groupDetailsFields.push({ name: 'registrationLink' });
  }

  const registrationLink = `${window.location.origin}/login?groupNumber=${get(
    apiData,
    'groupNumber'
  )}`;

  const defaultFilterValues = [
    {
      groupId,
    },
  ];

  // Response body schema for EPR records
  // String firstName;
  // String lastName;
  // UUID employeeId;
  // String status;
  // BigDecimal amountDue;
  // BigDecimal outstandingBalance;
  // BigDecimal totalAmountDeducted;
  // Integer cycleNo;
  // Timestamp billCreationDate;
  // UUID payrollScheduleId;
  // eslint-disable-next-line no-unused-vars
  const filters = [
    {
      label: t('filters.firstName'),
      name: 'firstName',
      type: 'Input',
    },
    {
      label: t('filters.lastName'),
      name: 'lastName',
      type: 'Input',
    },
    {
      name: 'employeeNumber',
      type: 'Input',
    },
    {
      name: 'status',
      options: [
        {
          canEnglishValue: null,
          frenchValue: 'Facturé',
          label: 'Billed',
          value: 'BILLED',
        },
        {
          canEnglishValue: null,
          frenchValue: 'Montant déduit',
          label: 'Amount Deducted',
          value: 'AMOUNT_DEDUCTED',
        },
        {
          canEnglishValue: null,
          frenchValue: 'Partiellement payé',
          label: 'Partially Paid',
          value: 'PARTIALLY_PAID',
        },
        {
          canEnglishValue: null,
          frenchValue: 'Payé',
          label: 'Paid',
          value: 'PAID',
        },
      ],
      type: 'Dropdown',
    },
    {
      name: 'cycleNo',
      type: 'Input',
    },
    {
      label: t('groupEmployees.dashboardPage.outstandingBalance'),
      name: 'outstandingBalance',
      type: 'Input',
    },
  ];
  // eslint-disable-next-line no-unused-vars
  const grid = {
    fields: [
      {
        name: 'firstName',
      },
      {
        name: 'lastName',
      },
      {
        name: 'empId',
      },
      {
        name: 'status',
      },
      {
        name: 'amountDue',
      },
      {
        name: 'outstandingBalance',
      },
      {
        name: 'cashRemitted',
      },
      {
        name: 'groupCreditUsed',
      },
      {
        name: 'cycleNo',
      },
      {
        name: 'billCreationDate',
      },
      {
        label: 'Write-Off Amount',
        name: 'writeOffUsed',
        type: 'currency',
      },
    ],
  };
  const renewalGroupId = get(apiData, 'renewalGroup.groupId') || null;
  const { apiData: { content: groupRenewalCreditData = [] } = {} } = GetData(
    'get-all-bills',
    JSON.stringify({
      groupId: renewalGroupId,
      page: 0,
      size: 1,
      sort: 'payrollSchedule.cycleNo,desc',
    }),
    !renewalGroupId
  );

  const retryEnrollmentHidden =
    isRetryEnrollmentDisabled ||
    ['POLICY_EXPIRED', 'TERMINATED'].includes(get(apiData, 'status')) ||
    get(apiData, 'active') === false;
  const postEnrollmentConfigEnabled = useSsm('iaic-post-enrollment');
  const isPostEnrollmentGroup =
    get(apiData, 'postEnrollmentGroup', false) && postEnrollmentConfigEnabled;
  const groupEnrollmentStartDate = get(
    apiData,
    'plan.planChoices[0].enrollmentFromDate',
    null
  )
    ? dateUtils.setOffset2(apiData.plan.planChoices[0].enrollmentFromDate)
    : null;
  const groupEnrollmentEndDate = get(
    apiData,
    'plan.planChoices[0].enrollmentToDate',
    null
  )
    ? dateUtils.setOffset2(apiData.plan.planChoices[0].enrollmentToDate)
    : null;
  const disableGroupEmployeeActions =
    isPostEnrollmentGroup ||
    ['POLICY_EXPIRED', 'TERMINATED'].includes(get(apiData, 'status')) ||
    (get(apiData, 'renewalStatus') === 'RENEWED' && !get(apiData, 'active'));
  const disableGroupEmployeeTerminateActions =
    isPostEnrollmentGroup ||
    ['POLICY_EXPIRED', 'TERMINATED'].includes(get(apiData, 'status'));
  const tabs = [
    {
      label: 'Enrollments',
      render: () => (
        <GroupEmployeesDashboard
          actionDisabled={disableGroupEmployeeActions}
          actionDisabledTerminate={disableGroupEmployeeTerminateActions}
          groupId={groupId}
          groupPostEnrollmentGroup={get(apiData, 'postEnrollmentGroup', false)}
          {...rest}
        />
      ),
    },
    {
      label: 'Documents',
      render: () => (
        <FileViewSection
          actionDisabled={
            ['POLICY_EXPIRED', 'TERMINATED'].includes(get(apiData, 'status')) ||
            get(apiData, 'active') === false
          }
          groupId={groupId}
          setFigoMsg={
            [USER_ROLE.EMPLOYER, USER_ROLE.EMPLOYEE].includes(userRole) &&
            groupMgaName === 'figo'
          }
          {...rest}
        />
      ),
    },
    {
      label: 'Educational Materials',
      render: () => (
        <FileViewSection
          actionDisabled={
            ['POLICY_EXPIRED', 'TERMINATED'].includes(get(apiData, 'status')) ||
            get(apiData, 'active') === false
          }
          groupId={groupId}
          isEducational
        />
      ),
    },
    {
      label: 'Billing Calendar',
      render: () => (
        <BillingCalender
          actionDisabled={
            ['POLICY_EXPIRED', 'TERMINATED'].includes(get(apiData, 'status')) ||
            get(apiData, 'active') === false
          }
          groupId={groupId}
        />
      ),
    },
    {
      label: t('groups.billingReports'),
      render: () => (
        <BillingReports
          actionDisabled={['POLICY_EXPIRED', 'TERMINATED'].includes(
            get(apiData, 'status')
          )}
          disableRegenerate={groupRenewalCreditData.length > 0}
          groupId={groupId}
          groupNumber={get(apiData, 'groupNumber', groupId)}
          payrollDeductionReportLevel={get(
            apiData,
            'payrollDeductionReportLevel'
          )}
          payrollScheduleId={payrollScheduleId}
          reconIssueFound={apiData?.autoReconIssueFound}
        />
      ),
    },
    {
      label: t('component.employeePaymentStatus'),
      render: () => {
        return (
          <ListGrid
            defaultFilterValues={defaultFilterValues}
            filters={filters}
            grid={grid}
            gridQueryName="get-all-epr"
            moduleName="epr"
          />
        );
      },
    },
  ];

  if ([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(userRole)) {
    tabs.push({
      label: 'Notes',
      render: () => (
        <NotesDashboard
          {...rest}
          groupId={groupId}
          groupNumber={get(apiData, 'groupNumber')}
        />
      ),
    });
  }

  const exportEmployeeConfigEnabled = useSsm('iaic-export-employee');
  if (
    [
      USER_ROLE.ADMIN,
      USER_ROLE.SUPER_ADMIN,
      USER_ROLE.BEN_BROKER,
      USER_ROLE.EMPLOYER,
      USER_ROLE.BEN_ADMIN,
    ].includes(userRole) &&
    exportEmployeeConfigEnabled
  ) {
    tabs.push({
      label: t('common.export'),
      render: () => (
        <ExportDetails
          {...rest}
          groupId={groupId}
          groupNumber={get(apiData, 'groupNumber')}
        />
      ),
    });
  }
  const isGroupActive = get(apiData, 'active', false);
  const isParentGroupIdPresent = get(apiData, 'parentGroupId', null) !== null;
  const isRenewalStatusNotPresent =
    get(apiData, 'renewalStatus', null) === null;
  const isNotRenewed = get(apiData, 'renewalStatus', null) !== 'RENEWED';
  const isGroupNotActive = get(apiData, 'active') === false;
  const isGroupStatusRenewed = get(apiData, 'renewalStatus', '') === 'RENEWED';
  const isExpiredOrTerminatedStatus = ['POLICY_EXPIRED', 'TERMINATED'].includes(
    get(apiData, 'status')
  );
  const isInProgress = ['IN_PROGRESS'].includes(
    get(apiData, 'groupTransitionStatus')
  );
  const hideAddEmployee =
    isExpiredOrTerminatedStatus ||
    isInProgress ||
    (isGroupStatusRenewed && isGroupNotActive) ||
    (isPostEnrollmentGroup &&
      groupEnrollmentStartDate > date &&
      isNotRenewed &&
      isGroupNotActive) ||
    (isParentGroupIdPresent &&
      isPostEnrollmentGroup &&
      date < groupEnrollmentStartDate &&
      (isRenewalStatusNotPresent || isGroupActive));
  const removeEmployeeConfigEnabled = useSsm('iaic-remove-employee-enabled');
  const deleteGroupConfigEnabled = useSsm('iaic-remove-employee-enabled');
  return (
    <Route
      header={{
        rightContainer: (
          <HeaderRightContainer
            headerActions={[
              {
                action: async () => {
                  try {
                    const groupResponse = await fetchGroupData();
                    const groupData = parseRemoteAction(groupResponse);
                    if (groupData?.disableImportEmployee === true) {
                      return setShowModalEnrollment(true);
                    }
                    return navigate(`/groups/${groupId}/add-employee`);
                  } catch (err) {
                    return navigate(`/groups/${groupId}/add-employee`);
                  }
                },
                icon: 'users-add',
                isHidden: hideAddEmployee,
                label: 'Add Employee',
                permission: 'group.button.addEmployee',
              },
              {
                action: `/groups/${groupId}/upsert-administrators`,
                icon: 'edit',
                isHidden:
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ) ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ) ||
                  (get(apiData, 'renewalStatus', '') === 'RENEWED' &&
                    get(apiData, 'active') === false),
                label: 'Edit Administrators',
                permission: 'group.button.upsertAdministrators',
              },
              {
                action: `/groups/${groupId}/upsert-locations`,
                icon: 'edit-location',
                isHidden:
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ) ||
                  (get(apiData, 'renewalStatus', '') === 'RENEWED' &&
                    get(apiData, 'active') === false),
                label: 'Edit Locations',
                permission: 'group.button.upsertLocations',
              },
              {
                action: `/groups/${groupId}/add-document`,
                icon: 'file',
                isHidden:
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ) ||
                  (get(apiData, 'renewalStatus', '') === 'RENEWED' &&
                    get(apiData, 'active') === false),
                label: 'Add Documents',
                permission: 'group.button.addDocument',
              },
              {
                action: `/groups/${groupId}/add-educational-material`,
                icon: 'add',
                isHidden:
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ) ||
                  (get(apiData, 'renewalStatus', '') === 'RENEWED' &&
                    get(apiData, 'active') === false),
                label: 'Add Educational Material',
                permission: 'group.button.educationalMaterial',
              }, // {
              //   action: `/groups/${groupId}/upload-remittance`,
              //   icon: 'upload',
              //   // isDisabled: get(apiData, 'status') !== 'ACTIVE',
              //   // isHidden: get(apiData, 'active') === false,
              //   isDisabled: get(apiData, 'disableProcessPayment', false),
              //   label: 'Upload Remittance',
              //   permission: 'group.button.uploadRemittance',
              // },
              {
                action: `/groups/${groupId}/add-credit`,
                icon: 'add',
                isDisabled: get(apiData, 'active') === false,
                isHidden: get(apiData, 'active') === false,
                label: 'Add Credit',
                permission: 'group.button.addCredit',
              },
              {
                action: `/groups/${groupId}/upsert-commission`,
                icon: 'edit',
                isDisabled: get(apiData, 'status') !== 'ACTIVE',
                isHidden:
                  get(apiData, 'active') === false ||
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ),
                label: 'Edit Commission',
                permission: 'group.button.upsertCommission',
              },
              {
                action: `/groups/${groupId}/upload-logo`,
                icon: 'add',
                isHidden:
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ) ||
                  (get(apiData, 'renewalStatus', '') === 'RENEWED' &&
                    get(apiData, 'active') === false),
                label: 'Upload Group Logo',
                permission: 'group.button.uploadLogo',
              },
              {
                action: () =>
                  !isEmpty(get(apiData, 'renewalGroup'))
                    ? navigate(
                        `/groups/${get(apiData, 'renewalGroup.groupId')}/${get(
                          apiData,
                          'renewalGroup.plan.planId'
                        )}/renewal?step=1`
                      )
                    : setShowModal(true),
                icon: 'renewal',
                isHidden:
                  get(apiData, 'renewalStatus') === 'RENEWED' ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ) ||
                  get(apiData, 'active') === false ||
                  get(apiData, 'status') !== 'ACTIVE' ||
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ),
                label: `${
                  isEmpty(get(apiData, 'renewalGroup'))
                    ? 'Create Renewal'
                    : 'Continue Renewal'
                }`,
                permission: 'group.button.renewals',
              },
              {
                action: () => setCancelRenwal(true),
                icon: 'remove',
                isHidden:
                  isEmpty(get(apiData, 'renewalGroup')) ||
                  get(apiData, 'active') === false,
                label: 'Cancel Renewal',
                permission: 'group.button.cancel',
              },
              {
                action: `/groups/${groupId}/groupsettings`,
                icon: 'settings',
                isHidden:
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ) ||
                  (get(apiData, 'renewalStatus', '') === 'RENEWED' &&
                    get(apiData, 'active') === false),
                label: 'Group Settings',
                permission: 'group.button.notification',
              },
              {
                action: () => {
                  if (get(apiData, 'renewalStatus') === 'IN_PROGRESS') {
                    return setWarning(true);
                  }
                  return setGroupTermination(true);
                },
                icon: 'remove',
                isHidden:
                  (!isEmpty(get(apiData, 'renewalGroup')) &&
                    get(apiData, 'renewalStatus') !== 'IN_PROGRESS') ||
                  get(apiData, 'active') === false ||
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ),
                label: 'Terminate Group',
                permission: 'group.button.termination',
              },
              {
                action: () => setResetGroupCredit(true),
                icon: 'refresh',
                isHidden:
                  [0, 0.0].includes(
                    Number(get(groupCreditData, '0.unappliedFunds', 0))
                  ) ||
                  get(apiData, 'active') === false ||
                  (get(groupCreditData, '0.status', '') === 'ADVANCE_PAYMENT' &&
                    get(groupCreditData, '0.payrollSchedule.cycleNo', 0) === 1),
                label: 'Refund Group Credit',
                permission: 'group.button.refundCredit',
              },
              {
                action: () => setRetryEnrollment(!retryEnrollment),
                icon: 'refresh',
                isHidden:
                  retryEnrollmentHidden ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ),
                label: 'Retry Enrollment',
                permission: 'group.button.enrollment',
              },
              {
                action: `/groups/${groupId}/upsert-account`,
                icon: 'edit',
                isHidden:
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ) ||
                  get(apiData, 'active') === false ||
                  get(apiData, 'billingInfo.accountType') !== 'ACH_AUTOMATIC' ||
                  chaseEnabled === false,
                label: 'Update Banking Info',
                permission: 'group.button.updateBillInfo',
              },
              {
                action: `/groups/${groupId}/${get(
                  apiData,
                  'plan.planId'
                )}/upsert-plan`,
                icon: 'calendar',
                isHidden:
                  get(apiData, 'status') !== 'ACTIVE' ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ) ||
                  (get(apiData, 'renewalStatus', '') === 'RENEWED' &&
                    get(apiData, 'active') === false),
                label: 'Update Open Enrollment Period',
                permission: 'group.button.updatePlanChoice',
              },
              {
                action: () => setEmployeeMigration(true),
                icon: 'users-add',
                isHidden:
                  get(apiData, 'renewalStatus', '') === 'RENEWED' ||
                  ['IN_PROGRESS'].includes(
                    get(apiData, 'groupTransitionStatus')
                  ) ||
                  get(apiData, 'active') === true ||
                  get(apiData, 'postEnrollmentGroup') === true,
                label: 'Employee Migration',
                permission: 'group.button.updateEmployeeMigration',
              },
              {
                action: () => setDeleteAllEmployees(true),
                icon: 'remove',
                isHidden:
                  !removeEmployeeConfigEnabled ||
                  (['RENEWED', 'IN_PROGRESS'].includes(
                    get(apiData, 'renewalStatus', '')
                  ) &&
                    get(apiData, 'active') === false) ||
                  get(apiData, 'noOfEmployees', 0) === 0,
                label: 'Remove All Employees',
                permission: 'group.button.removeAllEmployees',
              },
              {
                action: () => {
                  setShowDeleteGroupModal(true);
                },
                icon: 'remove',
                isHidden:
                  !deleteGroupConfigEnabled ||
                  (['RENEWED', 'IN_PROGRESS'].includes(
                    get(apiData, 'renewalStatus', '')
                  ) &&
                    get(apiData, 'active') === false),
                label: 'Delete Group',
                permission: 'group.button.deleteGroup',
              },
              {
                action: `/groups/${groupId}/addNotes`,
                icon: 'notes',
                isHidden: ['POLICY_EXPIRED', 'TERMINATED'].includes(
                  get(apiData, 'status')
                ),
                label: t('notes.addNotes'),
                permission: 'group.page.notes',
              },
              {
                action: `/groups/regenerate-master-policy/${groupId}/${get(
                  apiData,
                  'plan.planId'
                )}`,
                icon: 'refresh',
                isHidden:
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ) ||
                  get(apiData, 'active') === false ||
                  config.canadaEnv,
                label: 'Regenerate Master Policy',
                permission: 'group.page.regenerateMasterPolicy',
              },
            ]}
            // isHidden={
            //   ['POLICY_EXPIRED', 'TERMINATED'].includes(get(apiData, 'status')) ||
            //   (get(apiData, 'renewalStatus', '') === 'RENEWED' &&
            //     get(apiData, 'active') === false)
            // }
            permission={applyPermission ? 'group.actions' : ''}
            sx={{
              maxHeight: '66.3vh',
              overflowY: 'scroll',
            }}
            {...rest}
          />
        ),
        title: get(apiData, 'groupName', groupId),
        type: 'groups',
      }}
      isPrivate
      permission={applyPermission ? 'group.page.detail' : ''}
      {...rest}
    >
      <ApolloConsumer>
        {(client) => {
          const callRenewals = () => {
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'create-group-renewal',
                  params: JSON.stringify({
                    groupId,
                    userId,
                  }),
                },
              })
              .then(async (response) => {
                const renewalGroupId = await get(
                  JSON.parse(get(response, 'data.remoteAction.data', '')),
                  'groupId',
                  null
                );
                const planId = await get(
                  JSON.parse(get(response, 'data.remoteAction.data', '')),
                  'plan.planId',
                  null
                );
                if (renewalGroupId) {
                  if (get(apiData, 'postEnrollmentGroup') === true) {
                    return client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          name: 'update-group',
                          params: JSON.stringify({
                            groupId: renewalGroupId,
                            postEnrollmentGroup: true,
                            userId,
                          }),
                        },
                      })
                      .then(() => {
                        setApiCall(false);
                        return navigate(
                          `/groups/${renewalGroupId}/${planId}/renewal`
                        );
                      })
                      .catch((e) => {
                        setErrorSnack(
                          `There was an error: ${e.message}`,
                          config.notificationDuration
                        );
                        setApiCall(false);
                      });
                  }
                  setApiCall(false);
                  return navigate(
                    `/groups/${renewalGroupId}/${planId}/renewal`
                  );
                }
              })
              .catch((e) => {
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
                setApiCall(false);
              });
          };
          const callMigration = () => {
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'employee-migration',
                  params: JSON.stringify({
                    renewalGroupId: groupId,
                    runDependentMigration: groupEnrollmentStartDate <= date,
                  }),
                },
              })
              .then(async () => {
                setEmployeeMigration(false);
                setApiCall(false);
              })
              .catch((e) => {
                setEmployeeMigration(false);
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
                setApiCall(false);
              });
          };
          const updateGroup = async (values) => {
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'update-group',
                  params: JSON.stringify({
                    cancellationReason:
                      get(values, 'terminationType') !== 'CANCELLED'
                        ? null
                        : get(values, 'cancellationReason', ''),
                    groupId,
                    terminationDate: dateUtils.setAPIDateOnly(
                      get(values, 'terminationDate', '')
                    ),
                    terminationType: get(values, 'terminationType'),
                    userId,
                  }),
                },
              })
              .then(() => {
                setSuccessSnack(
                  `Group is now scheduled to terminate on ${Moment(
                    new Date(get(values, 'terminationDate', ''))
                  ).format('MM/DD/YYYY')}`,
                  config.notificationDuration
                );
                setGroupTermination(false);
              })
              .catch((e) => {
                setGroupTermination(false);
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
                setApiCall(false);
              });
          };
          const cancelRenewalCall = async () => {
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'delete-group-renewal',
                  params: JSON.stringify({
                    groupId: get(apiData, 'renewalGroup.groupId'),
                  }),
                },
              })
              .then(() => {
                setSuccessSnack(
                  'Cancelled Renewal',
                  config.notificationDuration
                );
                refetchGroupData();
                refetchGroupVersion();
                setCancelRenwal(false);
              })
              .catch((e) => {
                setCancelRenwal(false);
                return setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
              });
          };
          const handleDeleteGroup = async () => {
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'delete-group',
                  params: JSON.stringify({
                    groupId,
                  }),
                },
              })
              .then(() => {
                setSuccessSnack(
                  'Successfully deleted a Group',
                  config.notificationDuration
                );
                setShowDeleteGroupModal(false);
                navigate('/groups/dashboard');
              })
              .catch((e) => {
                setShowDeleteGroupModal(false);
                return setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
              });
          };
          const handleDeleteAllEmployees = async () => {
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'remove-all-employees',
                  params: JSON.stringify({ groupId }),
                },
              })
              .then(() => {
                setSuccessSnack(
                  'Successfully removed all employees',
                  config.notificationDuration
                );
                setDeleteAllEmployees(false);
              })
              .catch((e) => {
                setDeleteAllEmployees(false);
                return setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
              });
          };

          return (
            <>
              <Modal
                closeText="OK"
                isOpen={showModalEnrollment}
                onClose={() => {
                  refetchGroupData();
                  setShowModalEnrollment(false);
                }}
                shouldCloseOnOverlayClick={false}
              >
                <Box>
                  Please wait until the previous enrollment file has completed
                  processing before uploading another
                </Box>
              </Modal>
              <Modal
                closeText={t('common.no')}
                isOpen={showModal}
                onClose={() => {
                  setShowModal(false);
                }}
                onSubmit={() => {
                  setApiCall(true);
                  callRenewals();
                }}
                shouldCloseOnOverlayClick={false}
                submitText={t('common.yes')}
                submitting={callApi}
                title="Group Renewal"
              >
                <Box>
                  Would you like to proceed with the renewal of group{' '}
                  {get(apiData, 'groupName')}?
                </Box>
              </Modal>
              <Modal
                closeText={t('common.no')}
                isOpen={employeeMigration}
                onClose={() => {
                  setEmployeeMigration(false);
                }}
                onSubmit={() => {
                  setApiCall(true);
                  callMigration();
                }}
                shouldCloseOnOverlayClick={false}
                submitText={t('common.yes')}
                submitting={callApi}
                title="Group Employee Migration"
              >
                <Box>
                  Would you like to proceed with the employee migration of group{' '}
                  {get(apiData, 'groupName')}?
                </Box>
              </Modal>
              {cancelRenewal === true && (
                <Modal
                  closeText={t('common.no')}
                  isOpen
                  onClose={() => setCancelRenwal(false)}
                  onSubmit={() => cancelRenewalCall()}
                  submitText={t('common.yes')}
                  title={t('groupEmployees.dashboardPage.confirmSubmission')}
                >
                  <Box>
                    By clicking yes, you are confirming renewal cancellation.
                    <Box
                      sx={{
                        display: 'flex',
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        my: 5,
                      }}
                    >
                      THIS ACTION CAN NOT BE UNDONE.
                    </Box>
                    Do you wish to continue?
                  </Box>
                </Modal>
              )}
              {showWarning === true && (
                <Modal
                  closeText={t('common.cancel')}
                  isOpen
                  onClose={() => setWarning(false)}
                  title="Warning"
                >
                  <Box>
                    <Box sx={{ fontSize: 4, p: 3 }}>
                      Please cancel renewal for this group before terminating it
                    </Box>
                  </Box>
                </Modal>
              )}
              {showDeleteGroupModal && (
                <Modal
                  closeText={t('common.no')}
                  isOpen
                  onClose={() => setShowDeleteGroupModal(false)}
                  onSubmit={() => handleDeleteGroup()}
                  submitText={t('common.yes')}
                  title={t('groupEmployees.dashboardPage.confirmSubmission')}
                >
                  <Box>
                    By clicking yes, you are confirming to delete the group
                    <Box
                      sx={{
                        display: 'flex',
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        my: 5,
                      }}
                    >
                      THIS ACTION CAN NOT BE UNDONE.
                    </Box>
                    Do you wish to continue?
                  </Box>
                </Modal>
              )}
              {deleteAllEmployees && (
                <Modal
                  closeText={t('common.no')}
                  isOpen
                  onClose={() => setDeleteAllEmployees(false)}
                  onSubmit={() => {
                    handleDeleteAllEmployees();
                  }}
                  submitText={t('common.yes')}
                  title={t('groupEmployees.dashboardPage.confirmSubmission')}
                >
                  <Box>
                    By clicking yes, you are confirming to delete all the
                    Employees of this active and renewal group.
                    <Box
                      sx={{
                        display: 'flex',
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        my: 5,
                      }}
                    >
                      THIS ACTION CAN NOT BE UNDONE.
                    </Box>
                    Do you wish to continue?
                  </Box>
                </Modal>
              )}
              {groupTermination === true && (
                <Modal
                  isOpen
                  onClose={() => setGroupTermination(false)}
                  title="Termination"
                >
                  <FinalForm
                    initialValues={{}}
                    onSubmit={(values) => updateGroup(values)}
                    render={({ values, submitting, handleSubmit }) => (
                      <form onSubmit={handleSubmit}>
                        <Box>
                          By submitting, you are confirming the termination of
                          your group
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <Field
                              aria-label="Termination Date"
                              component={InputDropdown}
                              label={labelHandler('Termination Date', true)}
                              name="terminationDate"
                              options={options}
                              validate={required}
                              wrapperSx={{ ...commonFieldSxHalf }}
                              {...rest}
                            />
                            <Field
                              aria-label={t(
                                'groupEmployees.dashboardPage.terminationType'
                              )}
                              component={InputDropdown}
                              label={labelHandler(
                                t(
                                  'groupEmployees.dashboardPage.terminationType'
                                ),
                                true
                              )}
                              name="terminationType"
                              options={GroupTerminationReasonType}
                              searchable={false}
                              validate={required}
                              wrapperSx={commonFieldSxHalf}
                              {...rest}
                            />
                            {get(values, 'terminationType', '') ===
                            'CANCELLED' ? (
                              <>
                                <Field
                                  aria-label="Cancellation"
                                  component={InputDropdown}
                                  label="Cancellation Reason"
                                  name="cancellationReason"
                                  options={CancellationReason}
                                  validate={required}
                                  wrapperSx={commonFieldSxHalf}
                                  {...rest}
                                />
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    mt: '30px',
                                  }}
                                >
                                  <ToolbarButton
                                    g="error"
                                    label={t('common.cancel')}
                                    onClick={() => {
                                      setGroupTermination(false);
                                    }}
                                    sx={{
                                      bg: 'red',
                                    }}
                                    width="150px"
                                  />
                                  <ToolbarButton
                                    g="error"
                                    label={t('common.submit')}
                                    submitting={submitting}
                                    type="submit"
                                    width="150px"
                                  />
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    mt: '30px',
                                  }}
                                >
                                  <ToolbarButton
                                    g="error"
                                    label={t('common.cancel')}
                                    onClick={() => {
                                      setGroupTermination(false);
                                    }}
                                    sx={{
                                      bg: 'red',
                                    }}
                                    width="150px"
                                  />
                                  <ToolbarButton
                                    g="error"
                                    label={t('common.submit')}
                                    submitting={submitting}
                                    type="submit"
                                    width="150px"
                                  />
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                      </form>
                    )}
                  />
                </Modal>
              )}
              {resetGroup === true && (
                <Modal
                  isOpen
                  onClose={() => {
                    setResetGroupCredit(false);
                  }}
                  title="Refund Group Credit"
                >
                  <FinalForm
                    initialValues={{}}
                    onSubmit={async (values) => {
                      const { amount = 0 } = values;
                      await client
                        .query({
                          fetchPolicy: 'no-cache',
                          query: remoteActionQuery,
                          variables: {
                            name: 'refund-group-credit',
                            params: JSON.stringify({
                              groupId,
                              groupNumber: get(apiData, 'groupNumber'),
                              refundAmount:
                                Number(totalRefundAmount) === cleanMoney(amount)
                                  ? 0
                                  : cleanMoney(amount),
                            }),
                          },
                        })
                        .then(() => {
                          setSuccessSnack(
                            'Refunded Credit Successfully!',
                            config.notificationDuration
                          );
                          setResetGroupCredit(false);
                        })
                        .catch((e) => {
                          setErrorSnack(
                            `There was an error ${e.message}`,
                            config.notificationDuration
                          );
                          setResetGroupCredit(false);
                        });
                    }}
                    render={(formContext) => (
                      <form onSubmit={formContext.handleSubmit}>
                        <Box>
                          <Field
                            aria-label="Amount"
                            component={InputMoney}
                            label={labelHandler('Amount', false)}
                            name="amount"
                            validate={composeValidations(
                              required,
                              refundAmount(Number(totalRefundAmount))
                            )}
                            wrapperSx={{ padding: 3, width: '25rem' }}
                            {...rest}
                          />
                          <Box>
                            <Box>Group Credit : ${totalRefundAmount}</Box>
                            By clicking yes, you are confirming refund credit.
                            <Box
                              sx={{
                                display: 'flex',
                                fontWeight: 'bold',
                                justifyContent: 'center',
                                my: 5,
                              }}
                            >
                              THIS ACTION CAN NOT BE UNDONE.
                            </Box>
                            Do you wish to continue?
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              mt: '30px',
                            }}
                          >
                            <ToolbarButton
                              g="error"
                              label={t('common.cancel')}
                              onClick={() => {
                                setResetGroupCredit(false);
                              }}
                              sx={{
                                bg: 'red',
                              }}
                              width="150px"
                            />
                            <ToolbarButton
                              g="error"
                              label="Yes"
                              submitting={formContext.submitting}
                              type="submit"
                              width="150px"
                            />
                          </Box>
                        </Box>
                      </form>
                    )}
                  />
                </Modal>
              )}
            </>
          );
        }}
      </ApolloConsumer>

      <Box as="h2" sx={{ py: 3 }}>
        Group Details
      </Box>
      {['IN_PROGRESS'].includes(get(apiData, 'groupTransitionStatus')) && (
        <Box
          as="h4"
          sx={{
            color: 'error',
            margin: '10px 0px 0px 5px;',
            wordWrap: 'break-word',
          }}
        >
          Your group is currently under maintenance. Some features may be
          temporarily unavailable, Please check back later
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        {groupVersionData.length > 1 && !groupVersionDataLoading && (
          <Card cardSx={{ width: '25rem' }}>
            <FinalForm
              initialValues={{ versionId: groupId }}
              onSubmit={noop}
              render={() => {
                return (
                  <form onSubmit={noop}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                      }}
                    >
                      <Field
                        aria-label="version"
                        component={InputDropdown}
                        isClearable={false}
                        label={labelHandler('Select Group Version', true)}
                        name="versionId"
                        onChange={(val) => {
                          navigate(`/groups/${val}/details`);
                        }}
                        options={groupVersionData
                          .filter((x) => x.status === 'ACTIVE')
                          .map((item, index) => {
                            return {
                              label: `${t('common.policyTerm')} ${
                                index + 1
                              } : ${Moment(
                                dateUtils.setOffset2(item.coverageFromDate)
                              ).format('L')} - ${Moment(
                                dateUtils.setOffset2(item.coverageToDate)
                              ).format('L')} `,
                              value: item.groupId,
                            };
                          })}
                        wrapperSx={{ padding: 3, width: '25rem' }}
                      />
                    </Box>
                  </form>
                );
              }}
            />
          </Card>
        )}
        {groupVersionDataLoading && (
          <Card cardSx={{ width: '25rem' }}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '25rem',
                p: 3,
              }}
            >
              <BarLoader color={`${colors.accentSecondary}`} />
            </Box>
          </Card>
        )}
        {!['POLICY_EXPIRED'].includes(get(apiData, 'status')) &&
          !['RENEWED', 'IN_PROGRESS'].includes(get(apiData, 'renewalStatus')) &&
          [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(userRole) &&
          !loading && (
            <Card
              cardSx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '25rem',
              }}
            >
              <ApolloConsumer>
                {(client) => {
                  const updateRenewalStatus = async () => {
                    return client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          name: 'update-group',
                          params: JSON.stringify({
                            groupId,
                            isRenewable: forgoRenewal,
                            userId,
                          }),
                        },
                      })
                      .then(() => {
                        setSuccessSnack(
                          'Setting Updated Successfully!',
                          config.notificationDuration
                        );
                        refetchGroupData();
                      })
                      .catch((e) => {
                        setErrorSnack(
                          `There was an error: ${e.message}`,
                          config.notificationDuration
                        );
                      });
                  };
                  return (
                    <FinalForm
                      initialValues={{
                        forgoRenewalCheckbox: forgoRenewal,
                      }}
                      onSubmit={noop}
                      render={() => {
                        return (
                          <form onSubmit={noop}>
                            <Box
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                              }}
                            >
                              <Field
                                aria-label="forgo renewal checkbox"
                                component={InputCheckbox}
                                label={labelHandler(
                                  'Group wants to forgo renewal',
                                  false
                                )}
                                name="forgoRenewalCheckbox"
                                onClick={updateRenewalStatus}
                                type="checkbox"
                                wrapperSx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                }}
                              />
                            </Box>
                          </form>
                        );
                      }}
                    />
                  );
                }}
              </ApolloConsumer>
            </Card>
          )}
        {loading && (
          <Card
            cardSx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '25rem',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                maxWidth: '25rem',
                p: 3,
              }}
            >
              <BarLoader color={`${colors.accentSecondary}`} />
            </Box>
          </Card>
        )}
      </Box>

      {[USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN].includes(userRole) &&
        config.developerMode && <TimeTraversal />}
      {deletedEmployee && (
        <ApolloConsumer>
          {(client) => {
            return (
              <Modal
                footerSx={{
                  p: 0,
                }}
                isOpen
                title="Remove Group Employees"
              >
                <Box>
                  By clicking yes, you are confirming that the Employees will be
                  removed from your group plan.
                  <Box
                    sx={{
                      display: 'flex',
                      fontWeight: 'bold',
                      justifyContent: 'center',
                      my: 5,
                    }}
                  >
                    THIS ACTION CAN NOT BE UNDONE.
                  </Box>
                  Do you wish to continue?
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      mt: '30px',
                    }}
                  >
                    <ToolbarButton
                      bg="error"
                      buttonSx={{
                        bg: 'red',
                      }}
                      label="No"
                      onClick={() => {
                        setDeletedEmployee(false);
                      }}
                      width="150px"
                    />
                    <ToolbarButton
                      isLoading={deletedEmployeeLoading}
                      label="Yes"
                      onClick={async () => {
                        setDeletedEmployeeLoading(true);
                        await client
                          .query({
                            fetchPolicy: 'no-cache',
                            query: remoteActionQuery,
                            variables: {
                              name: 'remove-group-employees',
                              params: JSON.stringify({
                                groupNumber: get(apiData, 'groupNumber', []),
                              }),
                            },
                          })
                          .then(async () => {
                            setSuccessSnack(
                              'Employees Removed Successfully!',
                              config.notificationDuration
                            );
                            setDeletedEmployee(false);
                            setDeletedEmployeeLoading(false);
                          })
                          .catch((e) => {
                            setErrorSnack(
                              `There was an error: ${e.message}`,
                              config.notificationDuration
                            );
                            setDeletedEmployee(false);
                            setDeletedEmployeeLoading(false);
                          });
                      }}
                      type="submit"
                      width="150px"
                    />
                  </Box>
                </Box>
              </Modal>
            );
          }}
        </ApolloConsumer>
      )}
      {retryEnrollment && (
        <ApolloConsumer>
          {(client) => {
            return (
              <Modal
                closeText={t('common.cancel')}
                isOpen={retryEnrollment}
                onClose={() => {
                  setRetryEnrollment(!retryEnrollment);
                }}
                onSubmit={async () => {
                  client
                    .query({
                      fetchPolicy: 'no-cache',
                      query: remoteActionQuery,
                      variables: {
                        name: 'retry-failed-enrollment',
                        params: JSON.stringify({ groupId }),
                      },
                    })
                    .then(async () => {
                      setRetryEnrollment(!retryEnrollment);
                      setIsRetryEnrollmentDisabled(true);
                      navigate(`/groups/${groupId}/details/`);
                    })
                    .catch((e) => {
                      setErrorSnack(
                        `There was an error: ${e.message}`,
                        config.notificationDuration
                      );
                      setRetryEnrollment(!retryEnrollment);
                    });
                }}
                submitText={t('common.yes')}
                title="Retry Enrollment"
              >
                <Box as="h4" ml={3}>
                  Are you sure you want to retry the Failed Enrollment
                </Box>
              </Modal>
            );
          }}
        </ApolloConsumer>
      )}
      <Card loading={loading}>
        <KeyValuePairs
          columnCount={2}
          data={{
            benAdminName: benAdminData?.name,
            registrationLink,
            ...apiData,
          }}
          fields={groupDetailsFields}
          meta={meta}
        />
      </Card>
      <Tabs
        loading={loading || planConfigLoading}
        tabs={[
          {
            label: 'Eligibility Rules',
            render: () => (
              <KeyValuePairs
                columnCount={2}
                data={apiData}
                fields={[
                  { name: 'eligibilityRule.newHireWaitingPeriod' },
                  { name: 'eligibilityRule.isFirstFollowingWaitingPeriod' },
                  { name: 'eligibilityRule.enrollmentPeriod' },
                ]}
                meta={meta}
              />
            ),
          },
          ...plans.map((plan, index) => {
            const dynamicFields = {
              'Accident & Illness': [
                { name: 'productDetails.name' }, // { name: 'coverageFromDate' },
                // { name: 'coverageToDate' },
                { name: 'annualLimit' },
                { name: 'annualDeductible' },
                { name: 'coinsurance' },
                { name: 'diminishingDeductible' },
                { name: 'copay' },
                { name: '' },
                { name: 'wellness' },
                { name: 'riderRPT' }, // { name: 'riderICC' },
                { name: 'riderOETC' },
                { name: 'riderABC' },
                { name: 'riderFR' },
                { name: 'riderRD' },
                { name: 'poaAR' },
                { name: '' },
                { name: 'bwpInjuries' },
                { name: 'bwpIllness' },
                { name: 'bwpCLI' },
                { name: 'existingCondition' },
                { name: 'maxAge' },
                { name: 'coverageCredit' },
                { name: 'enrollmentFromDate' },
                { name: 'enrollmentToDate' },
              ],
              'Accident Only': [
                { name: 'productDetails.name' }, // { name: 'coverageFromDate' },
                // { name: 'coverageToDate' },
                { name: 'annualLimit' },
                { name: 'annualDeductible' },
                { name: 'coinsurance' },
                { name: 'riderRPT' },
                { name: 'riderOETC' },
                { name: 'riderAC' },
                { name: 'riderFR' },
                { name: 'riderRD' },
                { name: 'poaAR' },
                { name: 'bwpInjuries' },
                { name: 'bwpCLI' },
                { name: 'existingCondition' },
                { name: 'enrollmentFromDate' },
                { name: 'enrollmentToDate' },
              ],
            };

            if (config.canadaEnv) {
              dynamicFields['Accident & Illness'].splice(1, 0, {
                dynamicLabel: t('metadata.coverageFromDateCanada'),
                name: 'coverageFromDate',
              });
              dynamicFields['Accident Only'].splice(1, 0, {
                dynamicLabel: t('metadata.coverageFromDateCanada'),
                name: 'coverageFromDate',
              });
              dynamicFields['Accident & Illness'].splice(2, 0, {
                dynamicLabel: t('metadata.coverageToDateCanada'),
                name: 'coverageToDate',
              });
              dynamicFields['Accident Only'].splice(2, 0, {
                dynamicLabel: t('metadata.coverageToDateCanada'),
                name: 'coverageToDate',
              });
              dynamicFields['Accident & Illness'].splice(11, 0, {
                dynamicLabel: t('metadata.inheritedConditionRider'),
                name: 'riderICC',
              });
            } else {
              dynamicFields['Accident & Illness'].splice(1, 0, {
                name: 'coverageFromDate',
              });
              dynamicFields['Accident Only'].splice(1, 0, {
                name: 'coverageFromDate',
              });
              dynamicFields['Accident & Illness'].splice(2, 0, {
                name: 'coverageToDate',
              });
              dynamicFields['Accident Only'].splice(2, 0, {
                name: 'coverageToDate',
              });
              dynamicFields['Accident & Illness'].splice(11, 0, {
                name: 'riderICC',
              });
            }

            return {
              label: t('groupEmployees.detailsPage.planDetails', {
                planName: plan.name,
              }),
              render: () => (
                <KeyValuePairs
                  columnCount={3}
                  data={plans[index]}
                  fields={dynamicFields[plan.productDetails.name]}
                  meta={meta.plan}
                />
              ),
            };
          }),
          {
            label: t('groups.administratorsList'),
            render: () => (
              <GroupAdministratorsDashboard
                actionDisabled={
                  ['POLICY_EXPIRED', 'TERMINATED'].includes(
                    get(apiData, 'status')
                  ) ||
                  (get(apiData, 'renewalStatus') === 'RENEWED' &&
                    !get(apiData, 'active'))
                }
                groupId={groupId}
                {...rest}
              />
            ),
          },
          {
            label: t('groupEmployees.detailsPage.benBrokerList'),
            render: () => (
              <GroupAdministratorsDashboard
                actionDisabled={['POLICY_EXPIRED', 'TERMINATED'].includes(
                  get(apiData, 'status')
                )}
                groupId={groupId}
                isBenBroker
                {...rest}
              />
            ),
          },
          {
            label: 'Location Details',
            render: () => (
              <KeyValuePairs
                columnCount={2}
                data={apiData}
                fields={[
                  { name: 'locationDetails.address' },
                  { name: 'locationDetails.contact' },
                ]}
                meta={meta}
              />
            ),
          },
        ]}
      />
      <Tabs
        defaultTab={groupPaymentRecordId ? 3 : 0}
        loading={loading || planConfigLoading}
        tabs={tabs}
      />
      {ear.isVisible('group.paymentHistory') && groupPaymentRecordId && (
        <PaymentHistory
          groupId={groupId}
          groupPaymentRecordId={groupPaymentRecordId}
        />
      )}
      {/* {!ear.isVisible('group.page.notes') && groupId && (
        <NotesDashboard groupId={groupId} />
      )} */}
    </Route>
  );
};

GroupDetails.propTypes = {
  applyPermission: PropTypes.bool.isRequired,
  groupId: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
GroupDetails.defaultProps = {
  groupId: '',
  location: {},
};

export default GroupDetails;
