import {navigate, useParams} from '@reach/router';
import Box from '@basecomponents/Box';
import {get} from 'lodash';
import React, {useContext, useState} from 'react';
import {Field, Form as FinalForm} from 'react-final-form';
import PropTypes from 'prop-types';
import {ApolloConsumer} from '@apollo/client';
import {AuthContext} from '@basecomponents/Auth';
import {useTranslation} from 'react-i18next';
import {required} from "@src/utilities/validators";
import UploadFile from "@petcomponents/UploadFile";
import ToolbarButton from "@basecomponents/ToolbarButton";
import Route from "@petcomponents/Route";
import InputDropdown from "@basecomponents/Dropdown";
import {labelHandler} from "@src/utilities/label-utils";
import InputText from "@basecomponents/Input";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import GetData from "@src/utilities/get-data";
import config from "@src/config.json";

/**
 * @category Group
 * @param {boolean} isEducational Indicating the type of document uploaded
 * @param {boolean} isLogoUpload  Indicating the logo to be uploaded for the group
 * @param {boolean} isPromoFlag Indicating the promotional video to be uploaded for the group
 * @param {boolean} isVideo Indicating the video to be uploaded for the group
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const AddGroupDocuments = ({
  isEducational,
  isLogoUpload,
  isPromoFlag,
  isVideo,
  ...rest
}) => {
  const { t } = useTranslation();
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const parameters = useParams();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const groupId = get(parameters, 'groupId', '');
  const { user } = useContext(AuthContext);
  const userId = get(user, 'customSystemUserId', '');
  const { apiData } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );

  let title = '';
  if (isEducational) {
    title = 'Add Educational Material';
  } else if (isLogoUpload) {
    title = 'Upload Logo';
  } else if (isPromoFlag) {
    title = 'Promotional Video';
  } else {
    title = 'Add Documents';
  }

  const fileTypes = () => {
    if (isVideo) {
      return ['video/mp4'];
    }
    if (isLogoUpload) {
      return ['image/svg', 'image/jpg', 'image/jpeg', 'image/png'];
    }
  };

  const maxFileSize = () => {
    if (isVideo) {
      return '100MB';
    }
    if (isLogoUpload) {
      return '100KB';
    }
    return '50MB';
  };

  return (
    <Route
      header={{
        title,
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {title}
      </Box>
      <ApolloConsumer>
        {(client) => {
          const uploadLogo = async (values) => {
            setButtonDisabled(true);
            client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'update-group',
                  params: JSON.stringify({
                    groupId,
                    logoPath: get(values, `fileUpload.0.s3KeyName`),
                    logoUrl: get(values, `fileUpload.0.s3KeyName`),
                    userId,
                  }),
                },
              })
              .then(() => {
                navigate(`/groups/${groupId}/details`);
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );
            setButtonDisabled(false);
          };
          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  bg: 'white',
                  borderRadius: 4,
                  boxShadow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 3,
                }}
              >
                <FinalForm
                  initialValues={
                    isLogoUpload
                      ? { fileName: `${get(apiData, 'groupName')}_logo` }
                      : {}
                  }
                  onSubmit={async (values) => {
                    const isEmbedLink =
                      get(values, 'uploadType', '') === 'link';
                    client
                      .query({
                        fetchPolicy: 'no-cache',
                        query: remoteActionQuery,
                        variables: {
                          name: 'set-file',
                          params: JSON.stringify({
                            groupId,
                            isEducational,
                            isEmbedLink,
                            isPromoFlag,
                            name: get(values, 'fileName', ''),
                            s3Key: get(values, 'embedLink', ''),
                          }),
                        },
                      })
                      .then(() => {
                        navigate(`/groups/${groupId}/details`);
                      })
                      .catch((e) =>
                        setErrorSnack(
                          `There was an error: ${e.message}`,
                          config.notificationDuration
                        )
                      );
                  }}
                  render={({ values, submitting, handleSubmit, form }) => (
                    <form onSubmit={handleSubmit}>
                      {(isEducational || isPromoFlag) && (
                        <Field
                          aria-label="UPLOAD TYPE"
                          component={InputDropdown}
                          initialValue="uploadFile"
                          label={labelHandler('UPLOAD TYPE', false)}
                          name="uploadType"
                          options={[
                            {
                              canEnglishValue: null,
                              frenchValue: 'Téléverser un fichier',
                              label: 'Upload File',
                              value: 'uploadFile',
                            },
                            {
                              canEnglishValue: null,
                              frenchValue: 'Lien',
                              label: 'Link',
                              value: 'link',
                            },
                          ]}
                          wrapperSx={{ ...commonFieldSxHalf }}
                          {...rest}
                        />
                      )}
                      <Field
                        aria-label={t(
                          'groupEmployees.enrollmentPage.lateEnrollmentPage.fileName'
                        )}
                        component={InputText}
                        disabled={isLogoUpload}
                        label={labelHandler(
                          t(
                            'groupEmployees.enrollmentPage.lateEnrollmentPage.fileName'
                          )
                        )}
                        name="fileName"
                        validate={required}
                        wrapperSx={{ ...commonFieldSxHalf }}
                        {...rest}
                      />
                      {get(values, 'uploadType', '') === 'link' ? (
                        <>
                          <Field
                            aria-label="Link"
                            component={InputText}
                            label={labelHandler('Link', true)}
                            name="embedLink"
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          <ToolbarButton
                            bg="accentDark"
                            isDisabled={submitting}
                            label="Done"
                            mr={0}
                            my={5}
                            submitting={submitting}
                            type="submit"
                            width="150px"
                          />
                        </>
                      ) : (
                        <>
                          {values.fileName ? (
                            <>
                              <UploadFile
                                allowImagePreview={!isLogoUpload}
                                allowImageValidateSize={isLogoUpload}
                                allowMultiple={!isLogoUpload}
                                allowReorder={!isLogoUpload}
                                fileTypes={fileTypes()}
                                flow="groupDetails"
                                form={form}
                                groupId={groupId}
                                imageValidateSizeMinHeight="60px"
                                imageValidateSizeMinWidth="180px"
                                isEducational={isEducational}
                                isLogo={isLogoUpload}
                                isPromoFlag={isPromoFlag}
                                maxFileSize={maxFileSize()}
                                name={values.fileName}
                                onUploadComplete={() =>
                                  setButtonDisabled(false)
                                }
                              />
                              {isLogoUpload && (
                                <Box sx={commonFieldSxHalf}>
                                  <Box>Notes:</Box>
                                  <Box>
                                    Image size should be less than 100 KB.
                                  </Box>
                                  <Box>
                                    Minimum height of image must be 60 pixels.
                                  </Box>
                                  <Box>
                                    Minimum width of image must be 180 pixels.
                                  </Box>
                                  <Box>
                                    Aspect ratio (height:width) should be 1:3.
                                  </Box>
                                </Box>
                              )}
                              {isVideo && (
                                <Box sx={commonFieldSxHalf}>
                                  <Box>Notes:</Box>
                                  <Box>
                                    MP4 Videos of size less than 100 MB
                                    accepted.
                                  </Box>
                                </Box>
                              )}
                            </>
                          ) : null}
                          <ToolbarButton
                            bg="accentDark"
                            disabled={buttonDisabled}
                            label="Done"
                            mx="auto"
                            my={5}
                            onClick={() =>
                              isLogoUpload
                                ? uploadLogo(values)
                                : navigate(`/groups/${groupId}/details`)
                            }
                            sx={{
                              width: '150px',
                            }}
                          />
                        </>
                      )}
                    </form>
                  )}
                />
              </Box>
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

AddGroupDocuments.propTypes = {
  isEducational: PropTypes.bool,
  isLogoUpload: PropTypes.bool,
  isPromoFlag: PropTypes.bool,
  isVideo: PropTypes.bool,
};

AddGroupDocuments.defaultProps = {
    isEducational: false,
    isLogoUpload: false,
    isPromoFlag: false,
    isVideo: false,
};

export default AddGroupDocuments;
