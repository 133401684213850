import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import GetData from "@src/utilities/get-data";
import ModuleContainer from "@petcomponents/Variant/ModuleInfo/Container";

/**
 *
 * @category Components
 * @param {string} id
 * @param {Object} displaySx
 * @returns {React.FC}
 */
const User = ({ id, displaySx }) => {
  const { apiData, loading } = GetData(
    'get-user-by-id',
    JSON.stringify({ userId: id }),
    false
  );
  const userName = `${get(apiData, 'fullName', '')}`;

  return (
    <ModuleContainer
      displaySx={displaySx}
      displayValue={userName}
      loading={loading}
    />
  );
};

User.defaultProps = {
  displaySx: {},
};

User.propTypes = {
  displaySx: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
};

export default User;
