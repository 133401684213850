import get from 'lodash/get';
import config from "@src/config.json";
import {CA_PROVINCES, US_STATES} from "@src/utilities/constants";

/**
 * @category Utils
 * @param {any} {value}
 * @returns {any}
 */
const USState = ({ value }) => {
  const stateData = config.canadaEnv ? CA_PROVINCES  :US_STATES;
  const usState = stateData.find((s) => s.value === value);
  return usState ? get(usState, 'label') : `#${value}`;
};

export default USState;
