import {PropTypes} from 'prop-types';
import get from 'lodash/get';
import {PulseLoader} from 'react-spinners';
import React, {useContext} from 'react';
import {ThemeContext} from 'styled-components';
import {useTranslation} from 'react-i18next';
import GetData from "@src/utilities/get-data";

/**
 * @category Utils
 * @param {any} {data}
 * @returns {any}
 */
const RateFileFrequency = ({ data }) => {
  const { i18n } = useTranslation();
  const { colors } = useContext(ThemeContext);
  const { apiData: ratingData = {}, loading } = GetData(
    'get-tasks',
    JSON.stringify({
      complete: true,
      flow: 'rating',
      groupId: get(data, 'groupId'),
      page: 0,
      size: 1,
      sort: 'created,desc',
    })
  );
  if (loading) {
    return <PulseLoader color={`${colors.accentSecondary}`} size={7} />;
  }

  return get(ratingData, 'content[0].context.billingCycle', 'NA');
};

RateFileFrequency.propTypes = {
  data: PropTypes.shape({
    groupId: PropTypes.string.isRequired,
  }).isRequired,
};

export default RateFileFrequency;
