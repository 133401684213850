/* eslint-disable react/prop-types */
import React, {useEffect, useReducer} from 'react';
import Box from '@basecomponents/Box';
import FormHeader from "@basecomponents/SectionForm/FormHeader";
import Toolbar from "@petcomponents/DataFormPage/Section/Toolbar";
import reducer from "@basecomponents/SectionForm/utils/reducer";

const SectionData = ({
                         client,
                         form,
                         sections = [],
                         submitting,
                         values,
                     }) => {
    const [state, dispatch] = useReducer(reducer, {
        activeSection: 0,
        stateValues: values,
    });
    const {activeSection, stateValues} = state;

    const commonFieldSxHalf = {
        boxSizing: 'border-box',
        flex: 'inherit',
        margin: '0 !important',
        minWidth: 0,
        padding: '0.75rem',
        width: '25%',
    };
    useEffect(() => {
        if (activeSection === sections.length) {
            // onComplete(stateValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSection]);

    return (
        <Box key="section-container" width="100%">
            {sections.map(
                ({componentData, title}, sectionIx) => {
                    let sectionOrder = 'middle';
                    if (sectionIx === 0) {
                        sectionOrder = 'first';
                    } else if (sectionIx === sections.length - 1) {
                        sectionOrder = 'last';
                    }
                    const render = componentData({
                        'wrapperSx': commonFieldSxHalf,
                        values,
                        form,
                        client,
                    });
                    return (
                        <>
                            <Box
                                key={`section-${sectionIx}`}
                                fontSize={2}
                                p={4}
                                sx={{transition: 'all 1s'}}
                            >
                                <Box
                                    bg="white"
                                    sx={{
                                        transition: 'all 1s',
                                    }}
                                >
                                    <FormHeader active={activeSection === sectionIx}
                                                currentIndex={sectionIx} dispatch={dispatch}
                                                loading={false} title={title}/>

                                    {activeSection === sectionIx && (
                                        <>
                                            <Box fontSize={5} fontWeight="bold" p={4}>
                                                {title}
                                            </Box>
                                            <Box alignItems="flex-start" display="flex" flex='inherit'
                                                 flexWrap="wrap">
                                                {render}
                                            </Box>
                                            <Toolbar
                                                dispatch={dispatch}
                                                sectionOrder={sectionOrder}
                                                stateValues={stateValues}
                                                submitting={submitting}
                                                values={values}
                                            />
                                        </>
                                    )}
                                </Box>
                            </Box>

                        </>
                    );

                }
            )}
        </Box>
    );
};

export default SectionData;
