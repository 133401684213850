import PropTypes from 'prop-types';
import React from 'react';
import { Location, navigate } from '@reach/router';
import Paginator from "@basecomponents/Paginator";
import Icon from "@src/components/Icon";
import generateDashboardOnNextClickSearchLink from "@petcomponents/DashboardPaginator/utilities/generate-dashboard-on-next-click-search-link";
import generateDashboardOnPreviousClickSearchLink from "@petcomponents/DashboardPaginator/utilities/generate-dashboard-on-previous-click-search-link";

const PAGE_SIZE = 10;

/**
 * @category Components
 * @param {object} filters
 * @param {Function} goToNextPage Method for going to next page
 * @param {Function} goToPreviousPage Method for going to previous page
 * @param {boolean} isClientSidePagination Indicates whether the client side pagination is enabled
 * @param {number} pageTotal Indicates total number of pages
 * @param {number} totalRecordCount Indicates total record count
 * @param {Function} onPageClick Method when the user clicks on page count
 * @param {Function} enableJumpToPage Enable the user to jump on page when onPageClick occurs
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const DashboardPaginator = ({
  filters,
  goToNextPage,
  goToPreviousPage,
  isClientSidePagination,
  pageTotal,
  totalRecordCount,
  onPageClick,
  enableJumpToPage,
  ...rest
}) => (
  <Location>
    {({ location: { pathname } }) => (
      <Paginator
        enableJumpToPage={enableJumpToPage}
        leftButtonContent={
          <Icon height="1rem" svg="left-chevron" width="1rem" />
        }
        offset={filters.page}
        onNextClick={() =>
          isClientSidePagination
            ? goToNextPage()
            : navigate(
                generateDashboardOnNextClickSearchLink({
                  filters,
                  pageSize: PAGE_SIZE,
                  pathname,
                })
              )
        }
        onPageClick={onPageClick}
        onPreviousClick={() =>
          isClientSidePagination
            ? goToPreviousPage()
            : navigate(
                generateDashboardOnPreviousClickSearchLink({
                  filters,
                  pageSize: PAGE_SIZE,
                  pathname,
                })
              )
        }
        pageSize={PAGE_SIZE}
        pageTotal={pageTotal}
        rightButtonContent={
          <Icon height="1rem" svg="right-chevron" width="1rem" />
        }
        total={totalRecordCount}
        {...rest}
      />
    )}
  </Location>
);
DashboardPaginator.propTypes = {
  enableJumpToPage: PropTypes.bool,
  filters: PropTypes.shape({
    page: PropTypes.number,
  }).isRequired,
  goToNextPage: PropTypes.func,
  goToPreviousPage: PropTypes.func,
  isClientSidePagination: PropTypes.bool,
  onPageClick: PropTypes.func,
  pageTotal: PropTypes.number,
  totalRecordCount: PropTypes.number,
};

DashboardPaginator.defaultProps = {
  enableJumpToPage: true,
  goToNextPage: null,
  goToPreviousPage: null,
  isClientSidePagination: false,
  onPageClick: () => {},
  pageTotal: 0,
  totalRecordCount: 0,
};

export default DashboardPaginator;
