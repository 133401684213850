import { useLazyQuery } from '@apollo/client';
import get from 'lodash/get';
import remoteActionQuery from '@queries/remote-action.gql';

export default function useLazyData(name, params) {
  const [fetch, { data, loading, error }] = useLazyQuery(remoteActionQuery, {
    variables: {
      name,
      params,
    },
  });
  const remoteActionData = JSON.parse(
    get(data, 'remoteAction.data', JSON.stringify({}))
  );
  let errorMsg = null;
  if (error) {
    errorMsg = error?.message;
  }
  return [
    fetch,
    {
      data: remoteActionData,
      error: errorMsg,
      loading,
    },
  ];
}