import Box from '@basecomponents/Box';
import { print } from 'graphql';
import React, { useContext, useState } from 'react';
import { Field, Form as FinalForm, Form } from 'react-final-form';
import { ThemeContext } from 'styled-components';
import { get, isEmpty, noop } from 'lodash';
import Redirect from '@basecomponents/Redirect';
import {
  composeValidations,
  ismmyyyy,
  mmyyyyIsPast,
  required,
  zipcode,
} from '@utils/validations';
import { useLocation } from '@reach/router';
import InputMasked from '@basecomponents/InputMasked';
import { mmyyyyMask } from '@src/utilities/masks';
import { EmailButton } from '@basecomponents/EmailButton';
import { PhoneButton } from '@basecomponents/PhoneButton';
import EmailSentModal from '@basecomponents/EmailSentModal';
import useWindowSize from '@utils/hooks/windowSize';
import i18n from '@src/utilities/i18n';
import { useTranslation } from 'react-i18next';
import { OnChange } from 'react-final-form-listeners';
import Dropdown from '@basecomponents/Dropdown';
import Input from "@basecomponents/Input";
import Logo from "@petcomponents/Logo";
import ToolbarButton from "@basecomponents/ToolbarButton";
import config from "@src/config.json";
import pixelToNumber from "@src/utilities/pixel-to-number";
import useSnackbar from "@src/utilities/use-snackbar";
import mutationRemoteAction from "@mutations/mutation-remote-action.gql";

const errorMessage =
  'The email provided does not match our records, there may be another email on file. If this problem persists, please contact an IAIC administrator. Click "Sign-In Help?” for more details.';

const AlternateEmailPage = () => {
  const { breakpoints, shadows } = useContext(ThemeContext);
  const location = useLocation();
  const email = get(location, 'state.email', '');
  const groupNumber = get(location, 'state.groupNumber', '');
  const { width } = useWindowSize();
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [redirect, setRedirect] = useState('');
  const [emailModal, setEmailModal] = useState('');
  const [isSignInHelpOpen, setIsSignInHelpOpen] = useState(false);
  const [emailId, setEmail] = useState(null);
  const [multipleWeakRecordsFound, setMultipleWeakRecordsFound] =
    useState(false);
  const [multipleOrNoRecordsFound, setMultipleorNoRecordsFound] =
    useState(false);
  const { t } = useTranslation();
  const selectedLang = localStorage.getItem('lang');

  /**
   * @description - Append groupNumber to the values and transform date of hire from mm/yyyy to yyyy-mm if present
   */
  function preprocessValues(values) {
    const newValue = { ...values };
    delete newValue.email;

    if (!newValue.dateOfHire) return newValue;
    // transform date of hire from mm/yyyy to yyyy-mm
    const [month, year] = newValue.dateOfHire.split('/');
    newValue.dateOfHire = `${year}-${month}`;
    return newValue;
  }

  /**
   * @description - It is a weak check because it only responds with a count of the number of users with the same first name, last name, and zip code.
   * @param {Object} values
   * @param {string} values.firstName
   * @param {string} values.lastName
   * @param {string} values.zipCode
   * @returns {Promise<{count: number}>}
   */
  async function weakCheckUser(values) {
    const newValue = preprocessValues({ groupNumber, ...values });
    const userCheckResponse = await (
      await fetch(config.apollo.url, {
        body: JSON.stringify({
          query: print(mutationRemoteAction),
          variables: {
            name: 'alternate-verification',
            params: JSON.stringify(newValue),
          },
        }),
        headers: {
          'content-type': 'application/json',
          'x-api-key': config.apollo.apiKey,
        },
        method: 'POST',
      })
    ).json();
    const { data, errors } = userCheckResponse;
    if (!isEmpty(errors)) {
      throw Error(errors[0].message);
    } else {
      const {
        mutationRemoteAction: { data: responseData },
      } = data;
      return JSON.parse(responseData);
    }
  }

  /**
   * @description - Does a strong check as it includes the date of hire. This check responds with employee records that match the first name, last name, zip code, and date of hire.
   * @param {Object} values
   * @param {string} values.firstName
   * @param {string} values.lastName
   * @param {string} values.zipCode
   * @param {string} [values.dateOfHire]
   * @returns {Promise<Array<{empId, groupNumber}>>}
   */
  async function strongCheckUser(values) {
    const newValue = preprocessValues({ groupNumber, ...values });
    const userCheckResponse = await (
      await fetch(config.apollo.url, {
        body: JSON.stringify({
          query: print(mutationRemoteAction),
          variables: {
            name: 'portal-user-data',
            params: JSON.stringify(newValue),
          },
        }),
        headers: {
          'content-type': 'application/json',
          'x-api-key': config.apollo.apiKey,
        },
        method: 'POST',
      })
    ).json();
    const { data, errors } = userCheckResponse;
    if (!isEmpty(errors)) {
      throw Error(errors[0].message);
    } else {
      const {
        mutationRemoteAction: { data: responseData },
      } = data;
      return JSON.parse(responseData);
    }
  }

  async function checkUser(values) {
    // duplicateFound is true when multiple employees are found with weakCheck
    if (multipleWeakRecordsFound) {
      // resolve it by strong check
      try {
        const foundEmployees = await strongCheckUser(values);
        if (foundEmployees.length === 1) {
          const [{ empId, groupNumber, email: foundEmail = null }] =
            foundEmployees;
          if (foundEmail) {
            return setEmailModal(errorMessage);
          }
          setEmail(email);
          setRedirect(
            `/add-email-id?empId=${empId}&groupNumber=${groupNumber}&redirect=alternate`
          );
        }
      } catch {
        setMultipleorNoRecordsFound(true);
      }
    }
    // if duplicateFound is false, do a weak check
    const { count } = await weakCheckUser(values);
    if (count === 0) {
      // if count is 0, show error
      return setEmailModal(i18n.t('alternateMail.noMatchRecords'));
    }
    if (count === 1) {
      // if count is 1, resolve it by strong check
      const foundEmployees = await strongCheckUser(values);
      // if no employee is found with strong check, show error
      if (foundEmployees.length === 0) {
        return setEmailModal(errorMessage);
        // if one employee is found with strong check, redirect to `add-email-id`
      }
      if (foundEmployees.length === 1) {
        const [{ empId, groupNumber, email: foundEmail = null }] =
          foundEmployees;
        if (foundEmail) {
          return setEmailModal(errorMessage);
        }
        setEmail(email);
        setRedirect(
          `/add-email-id?empId=${empId}&groupNumber=${groupNumber}&redirect=alternate`
        );
        // if more than one employee is found with strong check, show error
      } else {
        noop();
      }
    } else if (count > 1) {
      setMultipleWeakRecordsFound(true);
    }
  }

  // const commonSimpleButtonSx = {
  //   '&:hover': {
  //     color: `${lighten(0.1, `${colors.accent}`)}`,
  //     transition: 'color ease-in-out 0.25s',
  //   },
  //   color: 'white',
  //   display: 'flex',
  //   fontSize: 2,
  //   fontWeight: 'bold',
  //   mb: 5,
  //   mt: 5,
  //   transition: 'color ease-in-out 0.25s',
  //   width: '100%',
  // };

  const backgroundSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        backgroundImage: `url(${config.promoImageUrl})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '95%',
      };
    }
    return {};
  };

  const boxShadowSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        boxShadow: shadows[4],
      };
    }
    return {};
  };

  return (
    <Box
      bg={{ _: 'primary', lg: 'white' }}
      display="flex"
      flexDirection={{ b_: 'column', lg: 'row' }}
      height="100vh"
      width="100%"
    >
      <EmailSentModal
        modal={emailModal}
        setModal={setEmailModal}
        title={t('common.error')}
      />
      <Redirect state={{ emailId }} to={redirect} when={redirect !== ''} />
      <Box
        bg={{ _: 'primary', lg: 'white' }}
        display="flex"
        height={{ lg: '100%' }}
        justifyContent={{ _: 'center', lg: 'flex-start' }}
        sx={{ ...backgroundSx() }}
        width={{ lg: '50%' }}
      >
        {width >= pixelToNumber(breakpoints[3]) ? (
          <Logo
            marginLeft={{ lg: 5 }}
            mono={width < pixelToNumber(breakpoints[3])}
            sx={{
              mt: '30px',
              top: '0',
            }}
          />
        ) : (
          <Logo
            mono
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              mt: [5, null, null, 7],
              p: 1,
              svg: {
                height: 'auto',
              },
              width: '20rem',
            }}
          />
        )}

        {width >= pixelToNumber(breakpoints[3]) && (
          <Box
            as="h1"
            color="primary"
            fontSize={7}
            sx={{
              ml: 5,
              mt: 5,
              position: [null, null, null, null, 'absolute'],
              top: '13rem',
              width: '30%',
            }}
          >
            <Box>{t('auth.subtitle1')}</Box>
            <Box>{t('auth.subtitle2')}</Box>
          </Box>
        )}
      </Box>
      <Box
        alignItems={['flex-start', null, 'center']}
        bg="primary"
        display="flex"
        height="100%"
        justifyContent="center"
        p={5}
        sx={{
          ...boxShadowSx(),
          overflowY: 'auto',
        }}
        width={{ lg: '50%' }}
      >
        <FinalForm
          initialValues={{
            email,
          }}
          onSubmit={async (values) => {
            try {
              await checkUser(values);
            } catch (e) {
              setErrorSnack(
                t('snack.error.errorWithMessage', { message: e.message }),
                config.notificationDuration
              );
            }
          }}
          render={(formContext) => {
            return (
              <Box
                height="inherit"
                maxWidth="maxWidths.smallForm"
                mx="auto"
                width="100%"
              >
                {config.canadaEnv && (
                  <Box
                    sx={{
                      position: 'fixed',
                      right: '1rem',
                      top: '1rem',
                      width: 300,
                    }}
                  >
                    <Form
                      initialValues={{
                        language: selectedLang || i18n.language,
                      }}
                      onSubmit={() => {}}
                      render={() => (
                        <>
                          <Field
                            component={Dropdown}
                            isSearchable={false}
                            name="language"
                            options={
                              config.canadaEnv
                                ? [
                                    {
                                      canEnglishValue: null,
                                      frenchValue: 'Français Canadien',
                                      label: 'Français Canadien',
                                      value: 'fr',
                                    },
                                    {
                                      canEnglishValue: null,
                                      frenchValue: null,
                                      label: 'Canadian English',
                                      value: 'caEn',
                                    },
                                  ]
                                : [
                                    {
                                      canEnglishValue: null,
                                      frenchValue: 'Français Canadien',
                                      label: 'Français Canadien',
                                      value: 'fr',
                                    },
                                    {
                                      canEnglishValue: null,
                                      frenchValue: null,
                                      label: 'Canadian English',
                                      value: 'caEn',
                                    },
                                    {
                                      canEnglishValue: null,
                                      frenchValue: null,
                                      label: 'American English',
                                      value: 'en',
                                    },
                                  ]
                            }
                          />
                          <OnChange name="language">
                            {(lang) => {
                              i18n.changeLanguage(lang);
                              localStorage.setItem('lang', lang);
                            }}
                          </OnChange>
                        </>
                      )}
                    />
                  </Box>
                )}
                <Box
                  as="h1"
                  color="white"
                  mb={[4, null, 6]}
                  mt="27px"
                  textAlign={{ _: 'center', lg: 'left' }}
                >
                  {t('alternateMail.confirmInfo')}
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  width="100%"
                >
                  <Box
                    as="h3"
                    color="accentSecondaryDark"
                    fontWeight={600}
                    onClick={() => setIsSignInHelpOpen(!isSignInHelpOpen)}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    {t('alternateMail.signInHelp')}
                  </Box>
                </Box>

                {!config.canadaEnv && isSignInHelpOpen && (
                  <Box color="white" my={5}>
                    <Box mb={4}>
                      {t('alternateMail.contactIaicAdminTechnicalQuestions')}
                    </Box>
                    <PhoneButton number="844-738-4242" />
                    <EmailButton email="IAIC.pets@independenceamerican.com" />
                  </Box>
                )}
                {config.canadaEnv && isSignInHelpOpen && (
                  <Box color="white" my={5}>
                    <Box mb={4}>
                      {t('alternateMail.contactOnepackAdminTechnicalQuestion')}
                    </Box>
                    <PhoneButton number="833-310-7225" />
                    <EmailButton email="mypolicy@onepackplan.ca" />
                  </Box>
                )}
                <form autoComplete="off" onSubmit={formContext.handleSubmit}>
                  <Field
                    component={Input}
                    label={t('alternateMail.email')}
                    labelSx={{
                      color: 'white',
                    }}
                    name="email"
                    readOnly
                    style={{ pointerEvents: 'none' }}
                    validate={required}
                  />
                  <Box
                    color="white"
                    fontSize={3}
                    mb={6}
                    textAlign={{ _: 'center', lg: 'left' }}
                  >
                    {t('alternateMail.confirmInformation')}
                  </Box>
                  {/* {multipleWeakRecordsFound && !multipleOrNoRecordsFound && (
                    <Box color="red">
                      Multiple records found, please enter your date of hire to
                      resolve
                    </Box>
                  )} */}
                  {multipleOrNoRecordsFound && (
                    <Box color="red">
                      {t('alternateMail.uniqueRecordNotFound')}
                    </Box>
                  )}
                  <Field
                    component={Input}
                    label={t('alternateMail.firstName')}
                    labelSx={{
                      color: 'white',
                    }}
                    name="firstName"
                    validate={required}
                  />
                  <Field
                    component={Input}
                    label={t('alternateMail.lastName')}
                    labelSx={{
                      color: 'white',
                    }}
                    name="lastName"
                    validate={required}
                  />
                  <Field
                    component={Input}
                    label={t('alternateMail.zipCode')}
                    labelSx={{
                      color: 'white',
                    }}
                    name="zipCode"
                    validate={composeValidations(required, zipcode)}
                  />
                  {multipleWeakRecordsFound && (
                    <Field
                      component={InputMasked}
                      label={t('alternateMail.dateOfHire')}
                      labelSx={{
                        color: 'white',
                      }}
                      mask={mmyyyyMask}
                      name="dateOfHire"
                      validate={composeValidations(
                        required,
                        ismmyyyy,
                        mmyyyyIsPast
                      )}
                    />
                  )}
                  <ToolbarButton
                    bg="accentSecondaryDark"
                    data-cy="submit"
                    label={t('common.verify')}
                    m={0}
                    mt={6}
                    submitting={formContext.submitting}
                    type="submit"
                    width="100%"
                  />
                  {/* <ToolbarButton
                    label="Go to Login Screen"
                    link="/login/?redirect=/secondary-auth/"
                    marginLeft={0}
                    simple
                    sx={{
                      ...commonSimpleButtonSx,
                      justifyContent: 'center',
                      textTransform: 'uppercase',
                    }}
                  /> */}
                </form>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default AlternateEmailPage;
