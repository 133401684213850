import React, { useContext } from 'react';
import get from 'lodash/get';
import { Link } from 'gatsby';
import { AuthContext } from '@basecomponents/Auth';
import EmptyVariant from "@petcomponents/Variant/EmptyVariant";
import { USER_ROLE } from "@src/utilities/constants";

/**
 * Variant which redirects to modules
 *
 * @category Components
 * @param {Object} data
 * @param {string} displayField
 * @param {string} moduleId
 * @param {string} moduleName
 * @returns {React.FC}
 */
const ModuleLink = ({ data, displayField, moduleId, moduleName }) => {
  let result = null;
  const { user } = useContext(AuthContext);
  const role = get(user, 'customRole', '');
  const displayValue = get(data, displayField);
  if (displayField) {
    let link = null;
    switch (moduleName) {
      case 'group': {
        link = ![
          USER_ROLE.EMPLOYEE,
          USER_ROLE.TERMINATED_EMPLOYEE,
          USER_ROLE.RENEWAL_POLICY_DELETED,
        ].includes(role)
          ? `/groups/${moduleId}/details`
          : null;
        break;
      }
      default: {
        // link = `#${moduleName} missing`;
      }
    }
    result = link ? <Link to={link}>{displayValue}</Link> : displayValue;
  } else {
    result = <EmptyVariant />;
  }

  return result;
};

export default ModuleLink;
