import React from 'react';
import SpraoiFileUpload from "@petcomponents/FileUpload";
import InputWrapper from "@basecomponents/InputWrapper";

const FileUploadInput = (props) => (
  <InputWrapper {...props}>
    {(rest) => <SpraoiFileUpload {...rest} />}
  </InputWrapper>
);

export default FileUploadInput;
