import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from '@reach/router';
import { get } from 'lodash';
import Route from "@petcomponents/Route";
import InitialSetup from "@petcomponents/BenAdmin/UpsertBenAdminPage/InitialSetup";

/**
 * @category BenAdmin
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const UpsertBenAdmin = ({ location, ...rest }) => {
  const title = 'Ben Admin Configuration';
  const queryParams = useParams();
  const benAdminId = get(queryParams, 'benAdminId', '');
  return (
    <Route
      header={{
        title,
        type: 'Ben Admin',
      }}
      isPrivate
      permission="benAdmin.page.upsert"
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        {benAdminId ? 'Edit' : 'Create'} Ben Admin
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <InitialSetup location={location} {...rest} />
      </Box>
    </Route>
  );
};

UpsertBenAdmin.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string.isRequired }).isRequired,
};

export default UpsertBenAdmin;
