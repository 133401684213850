import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ListGrid from "@basecomponents/ListGrid";
import getMetadata from "@src/utilities/get-meta-data";

const fields = ['rowNumber', 'column', 'message'];
const dataFields = ['rootRowNumber', 'columnName', 'errorMessage'];

/**
 * @category File
 * @param {*} rest All the other Props
 * @param {string} datasetId Indicates the unique data set ID
 * @returns {React.FC}
 */
const ErrorSection = ({ datasetId }) => {
  const defaultFilterValues = [{ datasetId }];
  const meta = getMetadata({ moduleName: 'sftpErrors' });

  return (
    <ListGrid
      defaultFilterValues={defaultFilterValues}
      filters={fields.map((field) => ({
        label: get(meta, `${field}.label`, field),
        name: field,
        resolver: 'barrel-error-filter-values',
        resolverParams: { datasetId, filter: field },
        type: 'DropdownQuery',
      }))}
      grid={{
        actions: [],
        fields: dataFields.map((field) => ({ name: field })),
      }}
      gridQueryName="sftp-errors"
      moduleName="sftpErrors"
    />
  );
};

ErrorSection.propTypes = {
  datasetId: PropTypes.string.isRequired,
};

export default ErrorSection;
