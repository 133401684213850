import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import {
  composeValidations,
  email,
  required,
} from "@src/utilities/validators";
import InputText from "@basecomponents/Input";
import Dropdown from "@basecomponents/Dropdown";
import { TIME_CODE } from "@src/utilities/constants";
import { labelHandler } from "@src/utilities/label-utils";
import { phoneNumberMask } from "@src/utilities/masks";
import { phoneNumber } from "@src/utilities/validations";
import AddressFormSection from "@petcomponents/AddressFormSection";
import InputDate from "@basecomponents/InputDate";
import InputMasked from "@basecomponents/InputMasked";
import GetData from "@src/utilities/get-data";
import Spinner from "@basecomponents/Spinner";

const maxChar = (charLimit) => (value) => {
  if (value) {
    const { length } = value;
    if (length > charLimit) {
      return `Please enter only ${charLimit} character(s)`;
    }
  }
  return null;
};

const preOrPostSpace = (value) => {
  const initialLength = value?.length;
  const finalLength = value?.trim().length;

  if (initialLength > finalLength) {
    return 'Remove spaces before or after the ID.';
  }
  return null;
};

/**
 * @category Employee
 * @param {string} groupId Indicates the unique Group ID
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */
const ManualInput = ({ groupId, ...rest }) => {
  const { t } = useTranslation()
  const {
    apiData: { content = [] },
    loading,
  } = GetData(
    'get-all-group-locations',
    JSON.stringify({ groupId, queryParams: [{ page: 0 }] })
  );

  const {
    apiData: { EmployeeEmploymentStatus = [] },
  } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: 'EmployeeEmploymentStatus' }])
  );

  const locationOptions = content.map((item) => ({
    canEnglishValue: item.name,
    frenchValue: item.name,
    label: item.name,
    value: item.name,
  }));

  const commonFieldSxHalf = {
    padding: 3,
    width: '23rem',
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        borderRadius: 3,
        boxShadow: 7,
        display: 'flex',
        flexDirection: 'column',
        mt: 6,
      }}
    >
      <Box
        sx={{
          color: 'accent',
          fontSize: 4,
          fontWeight: 'bold',
          my: 3,
          padding: 3,
          width: '25rem',
        }}
      >
        {t('groupEmployees.enrollmentPage.personalInformation')}
      </Box>
      <Box>
        <Field
          aria-label={t('filters.employeeId')}
          component={InputText}
          label={labelHandler(t('filters.employeeId'), true)}
          maxLength={20}
          name="empId"
          validate={composeValidations(required, preOrPostSpace)}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        <Field
          aria-label={t('filters.firstName')}
          component={InputText}
          label={labelHandler(t('filters.firstName'), true)}
          name="firstName"
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        <Field
          aria-label={t('groupEmployees.enrollmentPage.middleInitial')}
          component={InputText}
          label={labelHandler(t('groupEmployees.enrollmentPage.middleInitial'), false)}
          maxLength={1}
          name="middleName"
          validate={maxChar(1)}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        <Field
          aria-label={t('groupEmployees.enrollmentPage.lastName')}
          component={InputText}
          label={labelHandler(t('groupEmployees.enrollmentPage.lastName'), true)}
          name="lastName"
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        {/* <Field
          component={Dropdown}
          label={labelHandler('Gender', true)}
          name="gender"
          options={GENDER}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
        /> */}
        {/* <Field
          component={InputDate}
          label={labelHandler('Birth Date', true)}
          name="dateOfBirth"
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
        /> */}
      </Box>
      <Box
        color="accent"
        sx={{
          fontSize: 4,
          fontWeight: 'bold',
          mb: 3,
          mt: 6,
          padding: 3,
          width: '25rem',
        }}
      >
        Contact Information
      </Box>
      <Box>
        <AddressFormSection
          label="address"
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        <Field
          aria-label="Email"
          component={InputText}
          label={labelHandler('Email')}
          name="email"
          validate={email}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        <Field
          aria-label={t('groupEmployees.enrollmentPage.phoneType')}
          component={Dropdown}
          label={labelHandler(t('groupEmployees.enrollmentPage.phoneType'))}
          name="phoneType"
          options={[
            { canEnglishValue: null, frenchValue: 'Cellulaire', label: 'Cell', value: 'CELL' },
            { canEnglishValue: null, frenchValue: 'TRAVAIL', label: 'Home', value: 'HOME' },
            { canEnglishValue: null, frenchValue: 'DOMICILE', label: 'Work', value: 'WORK' },
          ]}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        <Field
          aria-label={t('groupEmployees.enrollmentPage.phoneNumber')}
          component={InputMasked}
          label={labelHandler(t('groupEmployees.enrollmentPage.phoneNumber'))}
          mask={phoneNumberMask}
          name="phoneNumber"
          validate={phoneNumber}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      </Box>
      <Box
        color="accent"
        sx={{
          fontSize: 4,
          fontWeight: 'bold',
          mb: 3,
          mt: 6,
          padding: 3,
          width: '25rem',
        }}
      >
        Employment Information
      </Box>
      <Box>
        <Field
          aria-label="Office Location"
          component={Dropdown}
          label={labelHandler('Office Location', true)}
          name="locationName"
          options={locationOptions}
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        <Field
          aria-label="Date of Hire"
          component={InputDate}
          label={labelHandler('Date of Hire', true)}
          name="dateOfHire"
          validate={required}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        <Field
          aria-label="Termination Date"
          component={InputDate}
          label="Termination Date"
          name="terminationDate"
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        <Field
          aria-label="Employment Status"
          component={Dropdown}
          label={labelHandler('Employment Status', true)}
          name="employmentStatus"
          options={EmployeeEmploymentStatus}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
        <Field
          aria-label="Time Code"
          component={Dropdown}
          label={labelHandler('Time Code', false)}
          name="fullTimePartTimeCode"
          options={TIME_CODE}
          wrapperSx={{ ...commonFieldSxHalf }}
          {...rest}
        />
      </Box>
    </Box>
  );
};

ManualInput.propTypes = {
  groupId: PropTypes.string.isRequired,
};

export default ManualInput;
