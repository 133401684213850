import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { diff } from 'deep-object-diff';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '@basecomponents/Auth';
import { ApolloConsumer } from '@apollo/client';
import { Form as FinalForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { produce } from 'immer';
import remoteActionQuery from '@queries/remote-action.gql';
import GetData from '@utils/get-data';
import useSnackbar from '@utils/use-snackbar';
import dateUtils from '@utils/date-utils';
import generateRedirectPath from '@utils/generate-redirect-path';
import Spinner from '@basecomponents/Spinner';
import ToolbarButton from '@basecomponents/ToolbarButton';
import config from '@src/config.json';
import PayrollSchedule from "@petcomponents/UpsertGroupPage/BillingInformation/PayrollSchedule";
import BillingFormSection from "@petcomponents/UpsertGroupPage/BillingInformation/BillingFormSection";

/**
 * @category Group
 * @param {Object} location
 * @param {*} rest
 * @returns {React.FC}
 */
const BillingInformation = ({ location, isRenewal, ...rest }) => {
    const { t } = useTranslation();
    const queryParams = useParams();
    const groupId = get(queryParams, 'groupId', '');
    const name = 'step';
    const [setErrorSnack] = useSnackbar({ color: 'error' });
    const [setSuccessSnack] = useSnackbar({ color: 'primary' });
    const [showPayrollSchedule, setShowPayrollSchedule] = useState(false);

    const { apiData: groups = {}, loading } = GetData(
        'get-group-by-id',
        JSON.stringify({ id: groupId }),
        !groupId
    );

    const noteId = groups?.groupNote?.groupNoteId

    const { apiData: groupNote, loading: groupNoteLoading } = GetData(
        'get-group-note',
        JSON.stringify({
            noteId
        }),
        noteId == null
    )

    const { user } = useContext(AuthContext);
    const userId = get(user, 'customSystemUserId', '');

    const {
        apiData: { content = [] },
    } = GetData(
        'get-all-group-locations',
        JSON.stringify({
            groupId,
            queryParams: [{ page: 0 }],
        })
    );

    const { apiData: administrator = {} } = GetData(
        'get-all-administrator-by-group-id',
        JSON.stringify([{ groupId }])
    );

    const administratorId = get(administrator, 'content[0].administratorId', '');

    const primaryLocation = content.find((item) => item.isSitus);

    // Billing info id null recreates records
    const billingInformationId = get(groups, 'billingInfo.billingInfoId', '');

    const groupStatus = get(groups, 'status', '');

    const isBillingAddressSameAsCompanyAddress =
        get(groups, 'billingInfo.addressId', '') ===
        get(primaryLocation, 'address.addressId', '');

    const {
        apiData: { content: payrollData = [] },
        loading: payrollScheduleLoading,
    } = GetData(
        'get-payroll-schedule',
        JSON.stringify({
            billingInfoId: billingInformationId,
            groupId,
            size: get(groups, 'billingInfo.billingCycle', ''),
            sort: 'cycleNo,asc',
        }),
        !groupId || !billingInformationId
    );

    useEffect(() => {
        if (payrollData && payrollData.length > 0) {
            setShowPayrollSchedule(!showPayrollSchedule);
        }
    }, [payrollScheduleLoading]);


    const billingInfoResponse = {
        accountName: get(groups, 'billingInfo.accountName', ''),
        accountType: get(groups, 'billingInfo.accountType', null),
        attnLine: get(groups, 'billingInfo.attnLine', ''),
        billDaysPriorToPayroll: get(
            groups,
            'billingInfo.billDaysPriorToPayroll'
        )?.toString() || '0',
        billingAddress: isBillingAddressSameAsCompanyAddress,
        billingCycle: get(groups, 'billingInfo.billingCycle', null),
        billingInfo: {
            addressLine1: get(groups, 'billingInfo.address.addressLine1', null),
            addressLine2: get(groups, 'billingInfo.address.addressLine2', null),
            city: get(groups, 'billingInfo.address.city', null),
            country: get(groups, 'billingInfo.address.country', null),
            state: get(groups, 'billingInfo.address.state', null),
            zipcode: get(groups, 'billingInfo.address.zipcode', null),
        },
        billingType: get(groups, 'billingInfo.billingType', null),
        contractNumber: get(groups, 'billingInfo.contractNumber', ''),
        firstDueDate: get(groups, 'billingInfo.firstDueDate', '')
            ? dateUtils.setOffset2(groups.billingInfo.firstDueDate)
            : '',
        firstBillCreationDate: get(groups, 'billingInfo.firstBillCreationDate', '')
            ? dateUtils.setOffset2(groups.billingInfo.firstBillCreationDate)
            : '',
        firstCoverageDate: get(groups, 'billingInfo.firstCoverageDate', null)
            ? dateUtils.setOffset2(groups.billingInfo.firstCoverageDate)
            : null,
        firstPayrollDate: get(groups, 'billingInfo.firstPayrollDate', '')
            ? dateUtils.setOffset2(groups.billingInfo.firstPayrollDate)
            : '',
        payrollFrequency: get(groups, 'billingInfo.payrollFrequency', null),
        phoneNumber: get(groups, 'billingInfo.contact.phoneNumber', ''),
        poNumber: get(groups, 'billingInfo.poNumber', ''),
    }
    const initialValues = produce(billingInfoResponse, draft => {
        draft.billingCycle ??= groupNote.billingFrequency
        draft.payrollFrequency ??= groupNote?.payrollFrequency
        const [line1, line2] = groupNote?.billingAddress?.split(',').map(x => x?.trim()) || ['', '']
        draft.billingInfo = {
            addressLine1: groups?.billingInfo?.address?.addressLine1 ?? line1,
            addressLine2: groups?.billingInfo?.address?.addressLine2 ?? line2,
            city: groups?.billingInfo?.address?.city ?? groupNote?.billingCity,
            country: groups?.billingInfo?.address?.country,
            state: groups?.billingInfo?.address?.state ?? groupNote?.billingState,
            zipcode: groups?.billingInfo?.address?.zipcode ?? groupNote?.billingZipcode,
        }
        draft.phoneNumber ??= groupNote?.billingPhoneNumber
        draft.billingType ??= groupNote?.billingType
        draft.firstCoverageDate ??= groupNote?.coverageStartDate
        draft.accountType ??= groupNote?.accountType
    });
    const accountNumber = get(groups, 'billingInfo.accountNumber', null);
    if (loading || groupNoteLoading) {
        return <Spinner />;
    }

    const payrollInformationDiff = (billingInfoResponse, formValues) => {
        return !!(
            billingInfoResponse.billingCycle !==
            get(formValues, 'billingCycle', '') ||
            billingInfoResponse.billingType !== get(formValues, 'billingType', '') ||
            billingInfoResponse.attnLine !== get(formValues, 'attnLine', '') ||
            billingInfoResponse.contractNumber !== get(formValues, 'contractNumber', '') ||
            billingInfoResponse.poNumber !== get(formValues, 'poNumber', '') ||
            billingInfoResponse.billDaysPriorToPayroll !==
            get(formValues, 'billDaysPriorToPayroll', '') ||
            (billingInfoResponse.firstPayrollDate &&
                formValues.firstPayrollDate &&
                billingInfoResponse.firstPayrollDate.toString() !==
                formValues.firstPayrollDate.toString()) ||
            (billingInfoResponse.firstCoverageDate &&
                formValues.firstCoverageDate &&
                billingInfoResponse.firstCoverageDate.toString() !==
                formValues.firstCoverageDate.toString()) ||
            (billingInfoResponse.firstBillCreationDate &&
                formValues.firstBillCreationDate &&
                billingInfoResponse.firstBillCreationDate.toString() !==
                formValues.firstBillCreationDate.toString()) ||
            (billingInfoResponse.firstDueDate &&
                formValues.firstDueDate &&
                billingInfoResponse.firstDueDate.toString() !==
                formValues.firstDueDate.toString())
        );
    };
    return (
        <ApolloConsumer>
            {(client) => {
                const onClickAction = async (values) => {
                    const firstPayrollDate = dateUtils.setAPIDateOnly(
                        get(values, 'firstPayrollDate', '')
                    );
                    const firstCoverageDate = dateUtils.setAPIDateOnly(
                        get(values, 'firstCoverageDate', '')
                    );
                    const firstBillCreationDate = dateUtils.setAPIDateOnly(
                        get(values, 'firstBillCreationDate', '')
                    );
                    const firstDueDate = dateUtils.setAPIDateOnly(
                        get(values, 'firstDueDate', '')
                    );

                    const apiCall = (pathName, params) =>
                        client.query({
                            fetchPolicy: 'no-cache',
                            query: remoteActionQuery,
                            variables: {
                                name: pathName,
                                params: JSON.stringify(params),
                            },
                        });

                    if (billingInformationId) {
                        const promises = [];
                        const addressParams = {
                            ...get(values, 'billingInfo', {}),
                            country: 'US',
                        };
                        const addressDiffPayload = diff(
                            billingInfoResponse.billingInfo,
                            addressParams
                        );
                        if (Object.keys(addressDiffPayload).length) {
                            const addressPromise = new Promise((resolve, reject) => {
                                apiCall('update-core-address', {
                                    ...addressDiffPayload,
                                    id: get(groups, 'billingInfo.addressId', ''),
                                })
                                    .then((response) => resolve(response))
                                    .catch((error) => {
                                        reject(error);
                                    });
                            });
                            promises.push(addressPromise);
                        }
                        if (
                            billingInfoResponse.phoneNumber !== get(values, 'phoneNumber', '')
                        ) {
                            const contactPromise = new Promise((resolve, reject) => {
                                apiCall('update-core-contact', {
                                    id: get(groups, 'billingInfo.contactId', ''),
                                    phoneNumber: get(values, 'phoneNumber', ''),
                                })
                                    .then((response) => resolve(response))
                                    .catch((error) => {
                                        reject(error);
                                    });
                            });
                            promises.push(contactPromise);
                        }
                        const payrollInfoDiff = payrollInformationDiff(
                            billingInfoResponse,
                            values
                        );
                        if (
                            payrollInfoDiff ||
                            billingInfoResponse.accountName !==
                            get(values, 'accountName', '') ||
                            billingInfoResponse.accountType !==
                            get(values, 'accountType', '') ||
                            (get(values, 'accountNumber', null) !== null &&
                                get(values, 'routingNumber', null) !== null ||
                                billingInfoResponse?.firstDueDate?.toString() !==
                                values?.firstDueDate?.toString() ||
                                billingInfoResponse?.firstBillCreationDate?.toString() !==
                                values?.firstBillCreationDate?.toString()
                            )
                        ) {
                            const billingInfoPromise = new Promise((resolve, reject) => {
                                const billingParams = {
                                    accountName: get(values, 'accountName', ''),
                                    accountType: get(values, 'accountType', ''),
                                    attnLine: get(values, 'attnLine', ''),
                                    billDaysPriorToPayroll: get(
                                        values,
                                        'billDaysPriorToPayroll',
                                        ''
                                    ),
                                    billingCycle: get(values, 'billingCycle', ''),
                                    billingType: get(values, 'billingType', ''),
                                    contractNumber: get(values, 'contractNumber', ''),
                                    firstCoverageDate,
                                    firstPayrollDate,
                                    firstBillCreationDate,
                                    firstDueDate,
                                    id: get(groups, 'billingInfo.billingInfoId', ''),
                                    payrollFrequency: get(values, 'payrollFrequency'),
                                    poNumber: get(values, 'poNumber', ''),
                                };

                                if (
                                    get(values, 'accountNumber', null) !== null &&
                                    get(values, 'routingNumber', null) !== null
                                ) {
                                    billingParams.accountNumber = get(
                                        values,
                                        'accountNumber',
                                        ''
                                    );
                                    billingParams.routingNumber = get(
                                        values,
                                        'routingNumber',
                                        ''
                                    );
                                }
                                apiCall('update-billing-info', {
                                    ...billingParams,
                                })
                                    .then((response) => {
                                        resolve(response);
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            });
                            promises.push(billingInfoPromise);
                        }
                        const payrollSchedulePromise = new Promise((resolve, reject) => {
                            const billingInfo = {
                                billingInfoId: billingInformationId,
                                groupId,
                            };

                            // Bind the convertTImestamp func to payrolschedule here
                            const { payrollSchedule } = values;
                            const newPayrollData = payrollSchedule.filter(o => !o.payrollScheduleId);
                            const convertedPayrollSchedule = payrollSchedule.map((item) => {
                                const {
                                    startDate,
                                    endDate,
                                    generatedPaidDate,
                                    paidDate,
                                    generatedBillCreationDate,
                                    billCreationDate,
                                    dueDate,
                                    generatedDueDate,
                                    payrollCalendarStartDate,
                                    payrollCalendarEndDate,
                                } = item;
                                return {
                                    ...item,
                                    billCreationDate: dateUtils.setAPIDateOnly(billCreationDate),
                                    dueDate: dateUtils.setAPIDateOnly(dueDate),
                                    endDate: dateUtils.setAPIDateOnly(endDate),
                                    generatedBillCreationDate: dateUtils.setAPIDateOnly(
                                        generatedBillCreationDate
                                    ),
                                    generatedDueDate: dateUtils.setAPIDateOnly(generatedDueDate),
                                    generatedPaidDate:
                                        dateUtils.setAPIDateOnly(generatedPaidDate),
                                    paidDate: dateUtils.setAPIDateOnly(paidDate),
                                    payrollCalendarEndDate: dateUtils.setAPIDateOnly(
                                        payrollCalendarEndDate
                                    ),
                                    payrollCalendarStartDate: dateUtils.setAPIDateOnly(
                                        payrollCalendarStartDate
                                    ),
                                    startDate: dateUtils.setAPIDateOnly(startDate),
                                };
                            });
                            const condition = newPayrollData.length > 0;
                            const payrollAPI = condition
                                ? 'create-payroll-schedule'
                                : 'update-payroll-schedule';
                            apiCall(payrollAPI, {
                                billingInfo,
                                payrollSchedule: convertedPayrollSchedule,
                            })
                                .then((response) => {
                                    resolve(response);
                                })
                                .catch((error) => {
                                    reject(error);
                                });
                        });
                        promises.push(payrollSchedulePromise);
                        return Promise.all(promises)
                            .then(async () => {
                                if (groupStatus !== 'ACTIVE') {
                                    await apiCall('update-group', {
                                        groupId,
                                        status: ['BILLING_INFO_REQUIRED'].includes(
                                            get(groups, 'status')
                                        )
                                            ? 'ACTIVE'
                                            : 'GROUP_ACTIVATION',
                                        userId,
                                    }).then(() => {
                                        if (isRenewal) {
                                            setSuccessSnack(
                                                'Group Billing details updated successfully.',
                                                config.notificationDuration
                                            );
                                            return navigate(
                                                generateRedirectPath({
                                                    ...location,
                                                    queryParams: { [name]: 6 },
                                                })
                                            );
                                        }

                                        setSuccessSnack(
                                            'Group details updated successfully.',
                                            config.notificationDuration
                                        );
                                        return navigate('/groups/dashboard');
                                    });
                                }
                                if (isRenewal) {
                                    setSuccessSnack(
                                        'Group Billing details updated successfully.',
                                        config.notificationDuration
                                    );
                                    return navigate(
                                        generateRedirectPath({
                                            ...location,
                                            queryParams: { [name]: 6 },
                                        })
                                    );
                                }
                                setSuccessSnack(
                                    'Group details updated successfully.',
                                    config.notificationDuration
                                );
                                return navigate('/groups/dashboard');
                            })
                            .catch((e) =>
                                setErrorSnack(
                                    `There was an error: ${e.message}`,
                                    config.notificationDuration
                                )
                            );
                    }

                    const queries = (addressId) => {
                        const params = {
                            contactType: 'WORK',
                            phoneNumber: get(values, 'phoneNumber', ''),
                        };
                        return apiCall('create-core-contact', params)
                            .then((response) => {
                                const contactResponse = JSON.parse(
                                    get(response, 'data.remoteAction.data', '{}')
                                );

                                const contactId = get(contactResponse, 'contactId', '');
                                const params = {
                                    accountName: get(values, 'accountName', ''),
                                    accountNumber: get(values, 'accountNumber', ''),
                                    accountType: get(values, 'accountType', ''),
                                    addressId,
                                    administratorId,
                                    attnLine: get(values, 'attnLine', ''),
                                    billDaysPriorToPayroll: get(
                                        values,
                                        'billDaysPriorToPayroll',
                                        ''
                                    ),
                                    billingCycle: get(values, 'billingCycle', ''),
                                    billingType: get(values, 'billingType', ''),
                                    contactId,
                                    contractNumber: get(values, 'contractNumber', ''),
                                    firstCoverageDate,
                                    firstPayrollDate,
                                    firstBillCreationDate,
                                    firstDueDate,
                                    groupId,
                                    payrollFrequency: get(values, 'payrollFrequency'),
                                    poNumber: get(values, 'poNumber', ''),
                                    routingNumber: get(values, 'routingNumber', ''),
                                };
                                return apiCall('create-billing-info', params)
                                    .then((response) => {
                                        const billingResponse = JSON.parse(
                                            get(response, 'data.remoteAction.data', '{}')
                                        );
                                        const billingInfo = {
                                            billingInfoId: get(billingResponse, 'billingInfoId', ''),
                                            groupId,
                                        };

                                        const { payrollSchedule } = values;
                                        const convertedPayrollSchedule = payrollSchedule.map(
                                            (item) => {
                                                const {
                                                    startDate,
                                                    endDate,
                                                    generatedPaidDate,
                                                    paidDate,
                                                    generatedBillCreationDate,
                                                    billCreationDate,
                                                    generatedDueDate,
                                                    dueDate,
                                                    payrollCalendarStartDate,
                                                    payrollCalendarEndDate,
                                                } = item;
                                                return {
                                                    ...item,
                                                    billCreationDate:
                                                        dateUtils.setAPIDateOnly(billCreationDate),
                                                    dueDate: dateUtils.setAPIDateOnly(dueDate),
                                                    endDate: dateUtils.setAPIDateOnly(endDate),
                                                    generatedBillCreationDate: dateUtils.setAPIDateOnly(
                                                        generatedBillCreationDate
                                                    ),
                                                    generatedDueDate:
                                                        dateUtils.setAPIDateOnly(generatedDueDate),
                                                    generatedPaidDate:
                                                        dateUtils.setAPIDateOnly(generatedPaidDate),
                                                    paidDate: dateUtils.setAPIDateOnly(paidDate),
                                                    payrollCalendarEndDate: dateUtils.setAPIDateOnly(
                                                        payrollCalendarEndDate
                                                    ),
                                                    payrollCalendarStartDate: dateUtils.setAPIDateOnly(
                                                        payrollCalendarStartDate
                                                    ),
                                                    startDate: dateUtils.setAPIDateOnly(startDate),
                                                };
                                            }
                                        );

                                        return apiCall('create-payroll-schedule', {
                                            billingInfo,
                                            payrollSchedule: convertedPayrollSchedule,
                                        }).then(async () => {
                                            if (groupStatus !== 'ACTIVE') {
                                                await apiCall('update-group', {
                                                    groupId,
                                                    status: ['BILLING_INFO_REQUIRED'].includes(
                                                        get(groups, 'status')
                                                    )
                                                        ? 'ACTIVE'
                                                        : 'GROUP_ACTIVATION',
                                                    userId,
                                                }).then(() => {
                                                    setSuccessSnack(
                                                        'Group created successfully.',
                                                        config.notificationDuration
                                                    );
                                                    if (isRenewal) {
                                                        return navigate(
                                                            generateRedirectPath({
                                                                ...location,
                                                                queryParams: { [name]: 6 },
                                                            })
                                                        );
                                                    }
                                                    return navigate('/groups/dashboard');
                                                });
                                            }
                                            setSuccessSnack(
                                                'Group details updated successfully.',
                                                config.notificationDuration
                                            );
                                            if (isRenewal) {
                                                return navigate(
                                                    generateRedirectPath({
                                                        ...location,
                                                        queryParams: { [name]: 6 },
                                                    })
                                                );
                                            }
                                            return navigate('/groups/dashboard');
                                        });
                                    })
                                    .catch((e) =>
                                        setErrorSnack(
                                            `There was an error: ${e.message}`,
                                            config.notificationDuration
                                        )
                                    );
                            })
                            .catch((e) =>
                                setErrorSnack(
                                    `There was an error: ${e.message}`,
                                    config.notificationDuration
                                )
                            );
                    };
                    const isBillingAddressSameAsCompanyAddress = get(
                        values,
                        'billingAddress',
                        false
                    );
                    if (!isBillingAddressSameAsCompanyAddress) {
                        const params = {
                            country: 'US',
                            ...get(values, 'billingInfo', {}),
                        };
                        return apiCall('create-core-address', params)
                            .then((response) => {
                                const addressResponse = JSON.parse(
                                    get(response, 'data.remoteAction.data', '{}')
                                );
                                const addressId = get(addressResponse, 'addressId', '');
                                queries(addressId);
                            })
                            .catch(
                                (e) => setErrorSnack(`There was an error: ${e.message}`),
                                config.notificationDuration
                            );
                    }
                    return queries(get(primaryLocation, 'address.addressId', ''));
                };
                return (
                    <FinalForm
                        initialValues={initialValues}
                        keepDirtyOnReinitialize
                        onSubmit={async (values) => {
                            await onClickAction(values);
                        }}
                        render={({ handleSubmit, form, submitting, values }) => {
                            const billingAddress = get(primaryLocation, 'address', '');
                            const showGeneratedPayrollSchedule = payrollInformationDiff(
                                billingInfoResponse,
                                values
                            );

                            return (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <Box
                                            sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    bg: 'white',
                                                    borderRadius: 4,
                                                    boxShadow: 1,
                                                    maxWidth: 'fit-content',
                                                    mb: '40px',
                                                    padding: 3,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <Box
                                                        as="h3"
                                                        color="black"
                                                        display="flex"
                                                        justifyContent="flex-start"
                                                        sx={{
                                                            color: 'primary',
                                                            margin: '20px 0px 20px 0px;',
                                                            padding: 3,
                                                            width: '25rem',
                                                        }}
                                                    >
                                                        Billing Information
                                                    </Box>
                                                    <BillingFormSection
                                                        accountNumber={accountNumber}
                                                        billingAddress={billingAddress}
                                                        billingInfoResponse={billingInfoResponse}
                                                        change={form.change}
                                                        groupStatus={groupStatus}
                                                        isRenewal={isRenewal}
                                                        values={values}
                                                        {...rest}
                                                    />
                                                </Box>
                                                <Box display="flex" justifyContent="center" mt={6}>
                                                    <ToolbarButton
                                                        bg="accentLight"
                                                        iconSx={{
                                                            height: '20px',
                                                            width: '20px',
                                                        }}
                                                        isDisabled={
                                                            loading ||
                                                            !values.billingCycle ||
                                                            !values.billingType ||
                                                            !values.firstPayrollDate ||
                                                            !values?.firstCoverageDate ||
                                                            (values.billingCycle === '12' && !values.firstBillCreationDate) ||
                                                            (values.billingCycle === '12' && !values.firstDueDate) ||
                                                            (values.billingCycle !== '12' && !values.billDaysPriorToPayroll)
                                                        }
                                                        label={
                                                            showPayrollSchedule
                                                                ? 'Hide Payroll Cycle'
                                                                : t('groups.generatePayrollCycle')
                                                        }
                                                        onClick={() =>
                                                            setShowPayrollSchedule(!showPayrollSchedule)
                                                        }
                                                    />
                                                </Box>
                                                <Box
                                                    sx={
                                                        showPayrollSchedule
                                                            ? { position: 'unset', visibility: 'unset' }
                                                            : {
                                                                height: 0,
                                                                position: 'absolute',
                                                                visibility: 'hidden',
                                                                width: 0,
                                                            }
                                                    }
                                                >
                                                    {(values.billingCycle &&
                                                        values.billingType &&
                                                        values.firstPayrollDate &&
                                                        values.firstCoverageDate &&
                                                        (values.billingCycle.toString() === '12' ? get(values, 'firstBillCreationDate', '') !== '' : true) &&
                                                        (values.billingCycle.toString() === '12' ? get(values, 'firstDueDate', '') !== '' : true) &&
                                                        values.billDaysPriorToPayroll) &&
                                                        (
                                                            <PayrollSchedule
                                                                billingInfoId={billingInformationId}
                                                                change={form.change}
                                                                isRenewal={isRenewal}
                                                                loading={payrollScheduleLoading}
                                                                payrollData={payrollData}
                                                                showGeneratedPayrollSchedule={
                                                                    showGeneratedPayrollSchedule
                                                                }
                                                                values={values}
                                                                {...rest}
                                                            />
                                                        )}
                                                </Box>
                                                <Box
                                                    sx={{
                                                        alignItems: 'center',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'center',
                                                        my: 6,
                                                        p: 3,
                                                    }}
                                                >
                                                    <ToolbarButton
                                                        bg="primaryLight"
                                                        disabled={['BILLING_INFO_REQUIRED'].includes(
                                                            get(groups, 'status')
                                                        )}
                                                        label={t('common.previous')}
                                                        ml={0}
                                                        onClick={() => {
                                                            return navigate(
                                                                generateRedirectPath({
                                                                    ...location,
                                                                    queryParams: {
                                                                        [name]: isRenewal ? 4 : 3,
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                        width="150px"
                                                    />
                                                    <ToolbarButton
                                                        bg="primaryDark"
                                                        disabled={submitting}
                                                        label={isRenewal ? t('common.next') : t('common.submit')}
                                                        ml={5}
                                                        mr={0}
                                                        submitting={submitting}
                                                        type="submit"
                                                        width="150px"
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </form>
                                </>
                            );
                        }}
                    />
                );
            }}
        </ApolloConsumer>
    );
};

BillingInformation.propTypes = {
    isRenewal: PropTypes.bool,
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
    }).isRequired,
};

BillingInformation.defaultProps = {
    isRenewal: false,
};

export default BillingInformation;
