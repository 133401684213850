import React from 'react';
import PropTypes from 'prop-types';
import Box from '@basecomponents/Box';
import Route from "@petcomponents/Route";
import ListGrid from "@basecomponents/ListGrid";

/**
 * @category Commission
 * @param {string} producerId Indicates the unique Producer ID
 * @param {*} rest All the other Props
 * @returns {React.FC}
 */

const CommissionsDashboardPage = ({ producerId, ...rest }) => {
  const filters = [];
  const grid = {
    actions: [],
    fields: [
      {
        name: 'groupCommissionHistoryDetail.groupName',
      },
      {
        name: 'subProducerDetail.name',
      },
      {
        name: 'groupCommissionHistoryDetail.effectiveDate',
      },
      {
        name: 'commissionPercentage',
      },
    ],
  };
  const defaultFilterValues = producerId ? [{ producerId }] : [];
  const uiContent = (
    <ListGrid
      defaultFilterValues={defaultFilterValues}
      filters={filters}
      grid={grid}
      gridQueryName="get-all-commission"
      moduleName="commissions"
    />
  );
  return producerId ? (
    uiContent
  ) : (
    <Route
      header={{
        title: 'Dashboard',
        type: 'commissions',
      }}
      isPrivate
      permission="commission.page.dashboard"
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Commissions Dashboard
      </Box>
      {uiContent}
    </Route>
  );
};

CommissionsDashboardPage.defaultProps = {
  producerId: null,
};

CommissionsDashboardPage.propTypes = {
  producerId: PropTypes.string,
};

export default CommissionsDashboardPage;
