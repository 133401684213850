import React from 'react';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import moment from 'moment';
import ModuleContainer from "@petcomponents/Variant/ModuleInfo/Container";
import { TOOLTIP } from "@basecomponents/ListGrid/utils/constants";

/**
 * @category Components
 * @param {Object} data
 * @returns {React.FC}
 */
const MasterCalendarSlaMarker = ({ data, filterValues }) => {
  const currentDate = moment(new Date()).utc();
  const relativeDate = new Date(get(data, 'relativeDate'));
  const dueDate = new Date(get(data, 'dueDate'));
  const status = get(data, 'status');

  const isPastView = get(filterValues, 'masterBillingCalendarView').includes(
    'PAST_VIEW'
  );

  const checkConditions = (condition) => {
    if (status !== 'PAID' && isPastView && condition) {
      return true;
    }
    return false;
  };

  const getBackgroundColor = () => {
    if (
      (relativeDate !== null && checkConditions(relativeDate > dueDate)) ||
      (relativeDate === null && checkConditions(currentDate > dueDate))
    ) {
      return 'red';
    }

    return 'grey';
  };

  const displayValue = (
    <Box sx={{ alignItems: 'center', display: 'flex' }}>
      <Box data-for={TOOLTIP} data-tip="">
        <Box
          sx={{
            background: getBackgroundColor(),
            borderRadius: '5px',
            height: '10px',
            mx: '5px',
            width: '10px',
          }}
        />
      </Box>
      <Box sx={{ ml: 2 }}>{get(data, 'groupName', 'NA')}</Box>
    </Box>
  );
  return <ModuleContainer displayValue={displayValue} />;
};

MasterCalendarSlaMarker.defaultProps = {
  filterValues: {},
};

MasterCalendarSlaMarker.propTypes = {
  data: PropTypes.shape({}).isRequired,
  filterValues: PropTypes.shape({}),
};

export default MasterCalendarSlaMarker;
