import React from 'react';
import PropTypes from 'prop-types';
import General from "@petcomponents/MasterPolicyGenerator/Variations/default";
import AK from "@petcomponents/MasterPolicyGenerator/Variations/AK";
import AR from "@petcomponents/MasterPolicyGenerator/Variations/AR";
import CT from "@petcomponents/MasterPolicyGenerator/Variations/CT";
import FL from "@petcomponents/MasterPolicyGenerator/Variations/FL";
import IL from "@petcomponents/MasterPolicyGenerator/Variations/IL";
import KS from "@petcomponents/MasterPolicyGenerator/Variations/KS";
import KY from "@petcomponents/MasterPolicyGenerator/Variations/KY";
import MA_A from "@petcomponents/MasterPolicyGenerator/Variations/MA_A";
import MA_AI from "@petcomponents/MasterPolicyGenerator/Variations/MA_AI";
import MO from "@petcomponents/MasterPolicyGenerator/Variations/MO";
import MN from "@petcomponents/MasterPolicyGenerator/Variations/MN";
import MT from "@petcomponents/MasterPolicyGenerator/Variations/MT";
import ND from "@petcomponents/MasterPolicyGenerator/Variations/ND";
import NE from "@petcomponents/MasterPolicyGenerator/Variations/NE";
import NH from "@petcomponents/MasterPolicyGenerator/Variations/NH";
import NV from "@petcomponents/MasterPolicyGenerator/Variations/NV";
import PA from "@petcomponents/MasterPolicyGenerator/Variations/PA";
import OH from "@petcomponents/MasterPolicyGenerator/Variations/OH";
import OK from "@petcomponents/MasterPolicyGenerator/Variations/OK";
import OR from "@petcomponents/MasterPolicyGenerator/Variations/OR";
import SD from "@petcomponents/MasterPolicyGenerator/Variations/SD";
import TX from "@petcomponents/MasterPolicyGenerator/Variations/TX";
import VA from "@petcomponents/MasterPolicyGenerator/Variations/VA";

const Master = ({ type, product, ...rest }) => {
  switch (type) {
    case 'AK':
      return <AK product={product} {...rest} />;
    case 'AR':
      return <AR product={product} {...rest} />;
    case 'CT':
      return <CT product={product} {...rest} />;
    case 'FL':
      return <FL product={product} {...rest} />;
    case 'IL':
      return <IL product={product} {...rest} />;
    case 'KS':
      return <KS product={product} {...rest} />;
    case 'KY':
      return <KY product={product} {...rest} />;
    case 'MA':
      if (product === 'Accident Only') {
        return <MA_A product={product} {...rest} />;
      }
      return <MA_AI product={product} {...rest} />;
    case 'MO':
      return <MO product={product} {...rest} />;
    case 'MN':
      return <MN product={product} {...rest} />;
    case 'MT':
      return <MT product={product} {...rest} />;

    case 'ND':
      return <ND product={product} {...rest} />;

    case 'NE':
      return <NE product={product} {...rest} />;

    case 'NH':
      return <NH product={product} {...rest} />;

    case 'NV':
      return <NV product={product} {...rest} />;
    case 'PA':
      return <PA product={product} {...rest} />;

    case 'OH':
      return <OH product={product} {...rest} />;

    case 'OK':
      return <OK product={product} {...rest} />;

    case 'OR':
      return <OR product={product} {...rest} />;

    case 'SD':
      return <SD product={product} {...rest} />;

    case 'TX':
      return <TX product={product} {...rest} />;

    case 'VA':
      return <VA product={product} {...rest} />;
    default:
      return <General product={product} {...rest} />;
  }
};

Master.propTypes = {
  product: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Master;
