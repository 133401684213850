import { ApolloConsumer } from '@apollo/client';
import { get } from 'lodash';
import { navigate, useParams } from '@reach/router';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import React from 'react';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';
import { FSRAcode } from '@utils/validations';
import { FSRAMask } from '@utils/masks';
import { useTranslation } from 'react-i18next';
import { composeValidations, required } from "@src/utilities/validators";
import InputText from "@basecomponents/Input";
import InputDropdown from "@basecomponents/Dropdown";
import InputMasked from "@basecomponents/InputMasked";
import { labelHandler } from "@src/utilities/label-utils";
import {
  CA_PROVINCES,
  PRODUCER_SUB_PRODUCER_STATUS,
  PRODUCER_TYPE,
  US_STATES,
} from "@src/utilities/constants";
import Icon from "@src/components/Icon";
import ToolbarButton from "@basecomponents/ToolbarButton";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import config from "@src/config.json";

/**
 * @param {Object} location
 * @param {Object} producerInfo
 * @param {*} rest
 * @returns {React.FC}
 */
const InitialSetup = ({ location, producerInfo, ...rest }) => {
  const queryParams = useParams();
  const producerId = get(queryParams, 'producerId', '');
  const isEditing = get(location, 'pathname', '').includes('/groups/edit/');
  const name = 'step';
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const { t } = useTranslation()

  const createProducerInfo = {
    authorizedStates: get(producerInfo, 'authorizedStates', []),
    name: get(producerInfo, 'name', ''),
    producerCode: get(producerInfo, 'producerCode', ''),
    status: get(producerInfo, 'status', ''),
    type: get(producerInfo, 'type', ''),
  };

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  return (
    <ApolloConsumer>
      {(client) => {
        const onClickCreateProducerAction = async (values) => {
          const apiCall = (pathName, params) =>
            client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: pathName,
                  params: JSON.stringify(params),
                },
              })
              .then((response) => {
                const apiProducerId = get(
                  JSON.parse(get(response, 'data.remoteAction.data', '')),
                  'producerId',
                  ''
                );
                if (apiProducerId) {
                  navigate(
                    `/producers/${
                      isEditing ? 'edit' : 'create'
                    }/${apiProducerId}?${name}=1`,
                    { replace: true }
                  );
                } else {
                  setErrorSnack(
                    'Something went wrong. Please try again.',
                    config.notificationDuration
                  );
                }
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );
          if (producerId) {
            const { authorizedStates, ...rest } = createProducerInfo;
            const { authorizedStates: newlyAddedStates, ...restValues } =
              values;
            const stateDiffPayload = newlyAddedStates
              .filter((x) => !authorizedStates.includes(x))
              .concat(
                authorizedStates.filter((x) => !newlyAddedStates.includes(x))
              );
            const diffPayload = diff(rest, restValues);
            if (Object.keys(diffPayload).length || stateDiffPayload.length) {
              return apiCall('update-producer', {
                ...diffPayload,
                authorizedStates: newlyAddedStates,
                id: producerId,
              });
            }
            return navigate(
              `/producers/${
                isEditing ? 'edit' : 'create'
              }/${producerId}?${name}=1`,
              { replace: true }
            );
          }
          return apiCall('create-producer', { ...values, deleted: false });
        };

        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={producerId ? { ...createProducerInfo } : {}}
              onSubmit={onClickCreateProducerAction}
              render={(formContext) => (
                <form onSubmit={formContext.handleSubmit}>
                  <Box
                    sx={{
                      bg: 'white',
                      borderRadius: 4,
                      boxShadow: 1,
                      padding: 3,
                    }}
                  >
                    <Box
                      as="h3"
                      color="black"
                      display="flex"
                      justifyContent="flex-start"
                      sx={{
                        color: 'primary',
                        margin: '20px 0px 20px 0px;',
                        padding: 3,
                        width: '25rem',
                      }}
                    >
                      Create Producer
                    </Box>
                    <Field
                      aria-label={config.canadaEnv ? t('component.canada.fsraAgentNumber') : "Producer Code/NPN"}
                      component={config.canadaEnv ? InputMasked : InputText}
                      label={config.canadaEnv ? labelHandler(t('component.canada.fsraAgentNumber'), true) : labelHandler('Producer Code/NPN', true)}
                      mask={config.canadaEnv ? FSRAMask : ''}
                      name="producerCode"
                      validate={composeValidations(config.canadaEnv ? (required, FSRAcode) : required)}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Field
                      aria-label={t('groupEmployees.dashboardPage.producerType')}
                      component={InputDropdown}
                      label={labelHandler(t('groupEmployees.dashboardPage.producerType'), true)}
                      name="type"
                      options={PRODUCER_TYPE}
                      searchable={false}
                      validate={required}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Field
                      aria-label="Producer Name"
                      component={InputText}
                      label={labelHandler('Producer Name', true)}
                      name="name"
                      validate={required}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Field
                      aria-label={config.canadaEnv ? t('groupEmployees.detailsPage.authorizedProvinces') :'Authorized States'}
                      checkIcon={<Icon svg="checkbox" width="1rem" />}
                      component={InputDropdown}
                      isMulti
                      label={labelHandler(config.canadaEnv ? t('groupEmployees.detailsPage.authorizedProvinces') :'Authorized States', true)}
                      name="authorizedStates"
                      options={config.canadaEnv ? CA_PROVINCES : US_STATES}
                      searchable
                      uncheckIcon={<Icon svg="uncheck" width="1rem" />}
                      validate={required}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Field
                      aria-label={t('metadata.status')}
                      component={InputDropdown}
                      label={labelHandler(t('metadata.status'), true)}
                      name="status"
                      options={PRODUCER_SUB_PRODUCER_STATUS}
                      searchable={false}
                      validate={required}
                      wrapperSx={{ ...commonFieldSxHalf }}
                      {...rest}
                    />
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        my: 6,
                      }}
                    >
                      <ToolbarButton
                        bg="primaryDark"
                        label={t('common.next')}
                        mr={0}
                        submitting={formContext.submitting}
                        type="submit"
                        width="150px"
                      />
                    </Box>
                  </Box>
                </form>
              )}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

InitialSetup.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  producerInfo: PropTypes.shape({}),
};

InitialSetup.defaultProps = {
  producerInfo: {},
};

export default InitialSetup;
