import { navigate, useParams } from '@reach/router';
import { ApolloConsumer } from '@apollo/client';
import { get } from 'lodash';
import Box from '@basecomponents/Box';
import { Form as FinalForm } from 'react-final-form';
import Button from '@basecomponents/Button';
import React from 'react';
import PropTypes from 'prop-types';
import { diff } from 'deep-object-diff';
import { useTranslation } from 'react-i18next';
import AddressFormSection from "@petcomponents/AddressFormSection";
import ToolbarButton from "@basecomponents/ToolbarButton";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import generateRedirectPath from "@src/utilities/generate-redirect-path";
import config from "@src/config.json";

/**
 * @category Producer
 * @param {Object} location
 * @param {Object} address
 * @param {*} rest
 * @returns {React.FC}
 */
const ProducerAddress = ({ location, address, ...rest }) => {
  const { t } = useTranslation();
  const queryParams = useParams();
  const producerId = get(queryParams, 'producerId', '');
  const isEditing = get(location, 'pathname', '').includes('/groups/edit/');
  const name = 'step';
  const [setErrorSnack] = useSnackbar({ color: 'error' });

  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };

  const addressInfo = {
    addressLine1: get(address, 'addressLine1', ''),
    addressLine2: get(address, 'addressLine2', ''),
    city: get(address, 'city', ''),
    state: get(address, 'state', ''),
    zipcode: get(address, 'zipcode', ''),
  };

  const addressId = get(address, 'addressId', '');

  if (!producerId) {
    return navigate(
      generateRedirectPath({
        ...location,
        queryParams: { [name]: 0 },
      })
    );
  }

  return (
    <ApolloConsumer>
      {(client) => {
        const onClickAction = async (values) => {
          const { address } = values;
          const apiCall = (pathName, params) =>
            client.query({
              fetchPolicy: 'no-cache',
              query: remoteActionQuery,
              variables: {
                name: pathName,
                params: JSON.stringify(params),
              },
            });

          if (isEditing || addressId) {
            const diffPayload = diff(addressInfo, address);
            if (Object.keys(diffPayload).length) {
              return apiCall('update-producer-address', {
                ...diffPayload,
                id: addressId,
              })
                .then((response) => {
                  const addressId = get(
                    JSON.parse(get(response, 'data.remoteAction.data', '')),
                    'addressId',
                    ''
                  );
                  if (addressId) {
                    return navigate(
                      `/producers/${
                        isEditing ? 'edit' : 'create'
                      }/${producerId}?${name}=2`,
                      { replace: true }
                    );
                  }
                  setErrorSnack(
                    'Something went wrong. Please try again.',
                    config.notificationDuration
                  );
                })
                .catch((e) =>
                  setErrorSnack(
                    `There was an error: ${e.message}`,
                    config.notificationDuration
                  )
                );
            }
            return navigate(
              `/producers/${
                isEditing ? 'edit' : 'create'
              }/${producerId}?${name}=2`,
              { replace: true }
            );
          }
          return apiCall('create-producer-address', address)
            .then((response) => {
              const addressId = get(
                JSON.parse(get(response, 'data.remoteAction.data', '')),
                'addressId',
                ''
              );
              if (!addressId) {
                return setErrorSnack(
                  'Something went wrong. Please try again.',
                  config.notificationDuration
                );
              }
              return apiCall('update-producer', {
                addressId,
                id: producerId,
              }).then((response) => {
                const savedAddressId = get(
                  JSON.parse(get(response, 'data.remoteAction.data', '')),
                  'addressId',
                  ''
                );
                if (savedAddressId !== addressId) {
                  return setErrorSnack(
                    'Something went wrong. Please try again.',
                    config.notificationDuration
                  );
                }
                navigate(
                  `/producers/${
                    isEditing ? 'edit' : 'create'
                  }/${producerId}?${name}=2`,
                  { replace: true }
                );
              });
            })
            .catch((e) =>
              setErrorSnack(
                `There was an error: ${e.message}`,
                config.notificationDuration
              )
            );
        };
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <FinalForm
              initialValues={addressId ? { address: addressInfo } : {}}
              onSubmit={onClickAction}
              render={(formContext) => {
                return (
                  <form onSubmit={formContext.handleSubmit}>
                    <Box
                      sx={{
                        bg: 'white',
                        borderRadius: 4,
                        boxShadow: 1,
                        padding: 3,
                      }}
                    >
                      <Box
                        as="h3"
                        color="black"
                        display="flex"
                        justifyContent="flex-start"
                        sx={{
                          color: 'primary',
                          margin: '20px 0px 20px 0px;',
                          padding: 3,
                          width: '25rem',
                        }}
                      >
                        Producer Address
                      </Box>
                      <AddressFormSection
                        label="address"
                        wrapperSx={commonFieldSxHalf}
                        {...rest}
                      />
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          my: 6,
                        }}
                      >
                        <Button
                          onClick={() =>
                            navigate(
                              generateRedirectPath({
                                ...location,
                                queryParams: { [name]: 0 },
                              })
                            )
                          }
                          sx={{
                            '&:hover': { bg: 'primaryLight' },
                            bg: 'primary',
                            mx: 'auto',
                            width: '10rem',
                          }}
                        >
                          Previous
                        </Button>
                        <ToolbarButton
                          bg="primaryDark"
                          label={t('common.next')}
                          mr={0}
                          submitting={formContext.submitting}
                          type="submit"
                          width="10rem"
                        />
                      </Box>
                    </Box>
                  </form>
                );
              }}
            />
          </Box>
        );
      }}
    </ApolloConsumer>
  );
};

ProducerAddress.propTypes = {
  address: PropTypes.shape({}),
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

ProducerAddress.defaultProps = {
  address: {},
};

export default ProducerAddress;
