import PropTypes from 'prop-types';
import React from 'react';
import { navigate, useParams } from '@reach/router';
import { get } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import Box from '@basecomponents/Box';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { diff } from 'deep-object-diff';
import { useTranslation } from 'react-i18next';
import Route from "@petcomponents/Route";
import GroupLocationsFormSection from "@petcomponents/GroupLocationsFormSection";
import GetData from "@src/utilities/get-data";
import Spinner from "@basecomponents/Spinner";
import ToolbarButton from "@basecomponents/ToolbarButton";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import config from "@src/config.json";

/**
 * @category Group
 * @param {*} rest
 * @returns {React.FC}
 */
const UpsertGroupLocations = ({ ...rest }) => {
  const { t } = useTranslation()
  const params = useParams();
  const groupId = get(params, 'groupId', '');
  const [setErrorSnack] = useSnackbar({ color: 'error' });

  const {
    apiData: { content = [] },
    loading,
  } = GetData(
    'get-all-group-locations',
    JSON.stringify({
      groupId,
      queryParams: [{ page: 0 }],
    })
  );

  const {apiData: groups = {}} = GetData(
    'get-group-by-id',
    JSON.stringify({id: groupId})
);

  const primaryAddressIndex = content.findIndex((v) => v.isSitus);
  if (content.length) {
    content.splice(0, 0, content.splice(primaryAddressIndex, 1)[0]);
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Route
      header={{
        title: 'Edit Locations',
        type: 'groups',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Edit Locations
      </Box>
      <ApolloConsumer>
        {(client) => {
          const checkIfLocationsExist = (locations) => {
            if (locations.length) {
              return locations.every((item) => {
                return !!Object.keys(item).length;
              });
            }
            return false;
          };

          const locationsPresent = checkIfLocationsExist(content);

          const onClickAction = async (values) => {
            // API Calls
            const locationApiCall = (path, params) =>
              client.query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: path,
                  params: JSON.stringify(params),
                },
              });

            // Checking of it is in edit Mode
            if (locationsPresent) {
              const promises = [];
              // Locations
              // Getting values from locations
              const updatedLocationValues = get(values, 'locations', []);

              // checking if isSitus address is present or not
              if (!updatedLocationValues.some((v) => v.isSitus)) {
                updatedLocationValues[0].isSitus = true;
              }

              // Checking for deleted locations
              const deletedLocations = content
                .filter(
                  (v) =>
                    !updatedLocationValues.some(
                      (a) => a.locationId === v.locationId
                    )
                )
                .map((item) => {
                  return {
                    ...item,
                    address: { ...item.address, deleted: true },
                    contact: { ...item.contact, deleted: true },
                    deleted: true,
                  };
                });

              // Checking for added locations
              const addedLocations = updatedLocationValues.filter(
                (v) => !v.locationId
              );

              // Checking for updated locations
              const updatedLocations = updatedLocationValues.filter((v) =>
                content.some((a) => {
                  if (a.locationId === v.locationId) {
                    const updatedLocationDiff = diff(a, v);
                    return !!Object.keys(updatedLocationDiff).length;
                  }
                  return false;
                })
              );
              // Get All Existing Locations with updated value
              const updatedAllLocations = updatedLocationValues.filter(
                (v) => v.locationId
              );
              // Checking if there are any updates
              if (updatedLocations.length || deletedLocations.length) {
                const params = {
                  groupId,
                  locations: [...updatedAllLocations, ...deletedLocations].map(
                    (item) => {
                      if (item.isSitus) {
                        return item;
                      }
                      return {
                        ...item,
                        isSitus: false,
                      };
                    }
                  ),
                };

                // Update locations API call
                const updateLocationsPromise = new Promise(
                  (resolve, reject) => {
                    locationApiCall('update-group-locations', params)
                      .then((response) => {
                        resolve(response);
                      })
                      .catch((error) => {
                        reject(error);
                        setErrorSnack(
                          `There was an error updating locations: ${error.message}`,
                          config.notificationDuration
                        );
                      });
                  }
                );
                promises.push(updateLocationsPromise);
              }

              // Checking for New locations
              if (addedLocations.length) {
                const params = {
                  groupId,
                  locations: addedLocations.map((item) => {
                    if (item.isSitus) {
                      return item;
                    }
                    return {
                      ...item,
                      isSitus: false,
                    };
                  }),
                };

                // Create locations API call
                const createLocationsPromise = new Promise(
                  (resolve, reject) => {
                    locationApiCall('create-group-locations', params)
                      .then((response) => {
                        resolve(response);
                      })
                      .catch((error) => {
                        reject(error);
                        setErrorSnack(
                          `There was an error creating new locations: ${error.message}`,
                          config.notificationDuration
                        );
                      });
                  }
                );
                promises.push(createLocationsPromise);
              }

              // Making all requests
              return Promise.all(promises)
                .then(() => navigate(`/groups/${groupId}/details`))
                .catch((e) =>
                  setErrorSnack(
                    `There was an error: ${e.message}`,
                    config.notificationDuration
                  )
                );
            }

            // If not editing or not updating,
            const locations = get(values, 'locations', []);

            if (!locations.some((v) => v.isSitus)) {
              locations[0].isSitus = true;
            }

            // Getting all parameters
            const params = {
              groupId,
              locations: locations.map((item) => {
                if (item.isSitus) {
                  return item;
                }
                return {
                  ...item,
                  isSitus: false,
                };
              }),
            };

            // Creating locations
            return locationApiCall('create-group-locations', params)
              .then(() => navigate(`/groups/${groupId}/details`))
              .catch((e) =>
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                )
              );
          };
          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                key="basicInformationForm"
                initialValues={{ locations: content.length ? content : [{}] }}
                mutators={arrayMutators}
                onSubmit={async (formValues) => {
                  await onClickAction(formValues);
                }}
                render={({ handleSubmit, submitting }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          alignItems: 'center',
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                          padding: 3,
                        }}
                      >
                        <GroupLocationsFormSection
                          isStateEditing={
                            // get(content, '0.address.state', null) !== null
                            get(groups, 'status', '') === 'ACTIVE'
                          }
                          {...rest}
                        />
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            maxWidth: '25rem',
                            my: 6,
                            width: '100%',
                          }}
                        >
                          <ToolbarButton
                            bg="primaryDark"
                            isDisabled={submitting}
                            label={t('common.submit')}
                            mr={0}
                            submitting={submitting}
                            type="submit"
                            width="150px"
                          />
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

UpsertGroupLocations.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default UpsertGroupLocations;
