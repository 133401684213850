/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import get from 'lodash/get';
import Box from '@basecomponents/Box';
import PropTypes from 'prop-types';
import {capitalCase} from 'change-case';
import Icon from '@src/components/Icon';
import {useTranslation} from 'react-i18next';
import config from '@src/config.json';
import EmptyVariant from "@petcomponents/Variant/EmptyVariant";
import Picklist from "@petcomponents/Picklist";
import dateUtils from "@src/utilities/date-utils";
import Address from "@petcomponents/Variant/Address";
import Contact from "@petcomponents/Variant/Contact";
import ModuleLink from "@petcomponents/Variant/ModuleLink";
import ModuleInfo from "@petcomponents/Variant/ModuleInfo";
import Condition from "@petcomponents/Variant/Condition";
import Truncate from "@basecomponents/Truncate";
import {TOOLTIP} from "@basecomponents/ListGrid/utils/constants";

/**
 * @category Components
 * @param {Object} data
 * @param {Object} meta
 * @param {string} name
 * @param {Object} gridMeta
 * @returns {React.FC}
 */
const Variant = ({data, meta, name, gridMeta, filterValues}) => {
    const {t, i18n} = useTranslation();
    let result = null;
    const metaType = get(meta, `${name}.type`, 'string');
    const referenceName = get(meta, `${name}.referenceName`);
    const value = get(data, name, get(data, referenceName));
    const defaultValue = get(meta, `${name}.defaultValue`);
    const valueType = typeof value;
    const [copy, setCopy] = useState(false);

    if (gridMeta && gridMeta.type === 'lookup') {
        const {lookupTable, lookupKey} = gridMeta;
        result = get(lookupTable, get(data, lookupKey));
    } else if (!['boolean', 'onTheFlyCal'].includes(metaType) && !['groupNameWithSlaMarker', 'groupNameWithNotification', 'groupNameWithMidYearTransition', 'renewalNotificationDate', 'ratingFrequency', 'renewalStatus', 'renewalType', 'identifier', 'payrollCycleNo', 'enrollmentDOE', 'terminationDOE', 'paidAmount'].includes(name) && valueType !== 'boolean' && !value) {
        let displayData = defaultValue;
        if (name === 'activityTableLabel' && get(data, 'activityTableLabel') === null) {
            displayData = (<Picklist
                    data={data}
                    name="changeType"
                    referenceName={referenceName}
                    type={get(meta, `${'changeType'}.picklistId`)}
                />);
        }
        if (name === 'dependentData.age') {
            if (get(data, 'demographicChangesRequired', false) && value === 0) {
                displayData = 'NA';
            } else {
                displayData = String(value) || String(defaultValue)
            }
        }
        result = <EmptyVariant value={displayData}/>;
    } else {
        switch (metaType) {
            case 'yesno': {
                if (value === true) {
                    result = 'Yes'
                } else {
                    result = 'No'
                }
                break;
            }
            case 'boolean': {
                if (value === 1 || value === true || value === 'included') {
                    result = t('metadata.included');
                } else if (name === 'priorCoverageCredit' && value === null) {
                    result = <EmptyVariant value='NA'/>;
                } else {
                    result = t('metadata.notIncluded');
                }
                break;
            }

            case 'species': {
                if (value === 'Cat') {
                    result = t('common.cat');
                } else {
                    result = t('common.dog');
                }
                break;
            }

            case 'productDetails': {
                if (value === 'Accident Only') {
                    result = t('metadata.accidentOnly');
                } else {
                    result = t('metadata.accidentAndIllness');
                }
                break;
            }

            case 'date': {
                const dValue = (config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getUTCDateOnlyCA(value) : dateUtils.getUTCDateOnlyUS(value));
                if (value !== '') {
                    result = dValue;
                } else if (dValue === 'Invalid Date') {
                    result = <EmptyVariant value="Invalid Date"/>;
                }
                break;
            }
            case 'localDate': {
                const dValue = dateUtils.getFormattedDate(value);
                if (value !== '') {
                    result = dValue;
                } else if (dValue === 'Invalid Date') {
                    result = <EmptyVariant value="Invalid Date"/>;
                }
                break;
            }
            case 'weight': {
                if (Number.isNaN(value) || value.toString() === '0') {
                    result = <EmptyVariant/>;
                } else {
                    result = `${value} lbs`;
                }
                break;
            }

            case 'dateTime': {
                const dValue = (config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getFormattedDateTimeComponentsCA(value) : dateUtils.getFormattedDateTimeComponentsUS(value));
                if (dValue !== '') {
                    result = (<Box>
                            <Box>{dValue.date}</Box>
                            <Box sx={{fontSize: 2}}>{dValue.time} Local Time</Box>
                        </Box>);
                } else if (dValue === 'Invalid Date') {
                    result = <EmptyVariant value="Invalid Date"/>;
                }

                break;
            }

            case 'UTCDateTime': {
                const dValue = dateUtils.getUTCFormattedDateTimeComponents(value);
                if (dValue !== '') {
                    result = (<Box>
                            <Box>{dValue.date}</Box>
                            <Box sx={{fontSize: 2}}>{dValue.time} Local Time</Box>
                        </Box>);
                } else if (dValue === 'Invalid Date') {
                    result = <EmptyVariant value="Invalid Date"/>;
                }

                break;
            }

            case 'dateWithDiff': {
                const dValue = (config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getFormattedDateTimeCA(value) : dateUtils.getFormattedDateTimeUS(value));
                const reopenedDate = get(get(data, 'qleEventHistories', []).find((obj) => get(obj, 'eventData.reopenReasonType', null) !== null), 'created', null);

                const reopenDateEnv = config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.getFormattedDateTimeCA(reopenedDate) : dateUtils.getFormattedDateTimeUS(reopenedDate);

                const reopenDateValue = reopenedDate ? reopenDateEnv : null;

                const fromNowDate = config.canadaEnv && (i18n.language === 'fr' || i18n.language === 'caEn') ? dateUtils.fromNowCA(dValue) : dateUtils.fromNow(dValue);

                if (value !== '') {
                    result = (<Box>
                            <Box>{dValue}</Box>
                            <Box fontSize={2} fontWeight="bold">
                                {reopenDateValue ? `Reopened ${dateUtils.fromNow(reopenDateValue)}` : fromNowDate}
                            </Box>
                        </Box>);
                } else if (dValue === 'Invalid Date') {
                    result = <EmptyVariant value="Invalid Date"/>;
                }
                break;
            }

            case 'percentage': {
                if (Number.isNaN(value)) {
                    result = <EmptyVariant/>;
                } else {
                    result = `${(value * 100).toFixed(2)} %`;
                }
                break;
            }

            case 'directPercentage': {
                if (Number.isNaN(value)) {
                    result = <EmptyVariant/>;
                } else {
                    result = `${Number(value).toFixed(2)} %`;
                }
                break;
            }

            case 'currency': {
                const formatter = new Intl.NumberFormat('en-US', {
                    currency: get(meta, `${name}.formatter.currency`, 'USD'),
                    minimumFractionDigits: get(meta, `${name}.formatter.minimumFractionDigits`, 2),
                    style: get(meta, `${name}.formatter.style`, 'currency'),
                });
                result = value > 0 ? formatter.format(value) : '$0';
                break;
            }

            case 'picklist': {
                result = (<Picklist
                        data={data}
                        name={name}
                        referenceName={referenceName}
                        type={get(meta, `${name}.picklistId`)}
                    />);
                break;
            }

            case 'capitalCase': {
                result = capitalCase(value);
                break;
            }

            case 'allCaps': {
                if (typeof value === 'string') {
                    result = value.toUpperCase();
                }
                break;
            }

            case 'address': {
                result = <Address value={value}/>;
                break;
            }

            case 'contact': {
                result = <Contact value={value}/>;
                break;
            }

            case 'string-array': {
                if (Array.isArray(value) && value.length > 0) {
                    result = value.join(', ');
                } else {
                    result = <EmptyVariant/>;
                }
                break;
            }

            case 'postfix': {
                const postfix = get(meta, `${name}.text`);
                result = `${value} ${postfix}`;
                break;
            }
            case 'copyClipBoard': {
                result = (<Box
                        onClick={() => {
                            setCopy(true);
                            navigator.clipboard.writeText(`${value}`);
                        }}
                        sx={{
                            color: 'accentDark', cursor: 'pointer', display: 'flex', fontSize: 2,
                        }}
                    >
                        <> {value}</>
                        <Icon
                            height="1.5rem"
                            svg={copy ? 'check' : 'file'}
                            width="1.5rem"
                        />
                    </Box>);
                break;
            }

            // case 'boolean': {
            //   result = value ? 'Yes' : 'No';
            //   break;
            // }

            case 'name': {
                result = `${get(value, 'firstName')} ${get(value, 'lastName')}`;
                break;
            }

            case 'moduleLink': {
                const {displayField, moduleName} = get(meta, name);
                result = (<ModuleLink
                        data={data}
                        displayField={displayField}
                        moduleId={value}
                        moduleName={moduleName}
                    />);
                break;
            }

            case 'moduleInfo': {
                const moduleName = get(meta, `${name}.moduleName`);
                result = (<ModuleInfo
                        data={data}
                        filterValues={filterValues}
                        moduleId={get(data, name)}
                        type={moduleName}
                    />);
                break;
            }
            case 'onTheFlyCal': {
                result = <Condition data={data} name={name}/>;
                break;
            }
            default: {
                const type = typeof value;
                if (type === 'object') {
                    result = <Box as="pre">{JSON.stringify(value, null, 2)}</Box>;
                } else {
                    result = value;
                }
            }
        }
    }

    return (<>
            <Box
                data-for={TOOLTIP}
                data-tip={typeof result === 'string' ? result : null}
                sx={{
                    minWidth: '125px',
                }}
            >
                {metaType === 'billingNotes' || metaType === 'ineligibleReason' ? (result) : (
                    <Truncate>{result}</Truncate>)}
            </Box>
        </>);
};

Variant.defaultProps = {
    data: {}, filterValues: {}, gridMeta: {
        lookupKey: '', lookupTable: {}, type: '',
    },
};

Variant.propTypes = {
    data: PropTypes.shape({}),
    filterValues: PropTypes.shape({}),
    gridMeta: PropTypes.shape({
        lookupKey: PropTypes.string, lookupTable: PropTypes.shape({}), type: PropTypes.string,
    }),
    meta: PropTypes.shape({}).isRequired,
    name: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string,]).isRequired,
};

export default Variant;
