import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
import Input from '@basecomponents/Input';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import React, { useContext, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Field, Form as FinalForm, Form } from 'react-final-form';
import styled, { ThemeContext } from 'styled-components';
import { print } from 'graphql';
import Redirect from '@basecomponents/Redirect';
import { get } from 'lodash';
import {
  checkEmail,
  composeValidations,
  email,
  required,
} from '@utils/validators';
import { navigate } from 'gatsby';
import localStorage from '@src/utilities/local-storage';
import EmailSentModal from '@basecomponents/EmailSentModal';
import Modal from '@basecomponents/Modal';
import { EmailButton } from '@basecomponents/EmailButton';
import { PhoneButton } from '@basecomponents/PhoneButton';
import { useTranslation } from 'react-i18next';
import Dropdown from '@basecomponents/Dropdown';
import { OnChange } from 'react-final-form-listeners';
import i18n from '@src/utilities/i18n';
import Logo from "@petcomponents/Logo";
import ToolbarButton from "@basecomponents/ToolbarButton";
import config from "@src/config.json";
import pixelToNumber from "@src/utilities/pixel-to-number";
import useSnackbar from "@src/utilities/use-snackbar";
import mutationRemoteAction from "@mutations/mutation-remote-action.gql";

const HoverableButton = styled(Button)`
  transition: 0.25s ease-in-out;

  &:hover {
    transition: 0.25s ease-in-out;
    background-color: ${(p) => p.theme.colors.accentLight};
  }
`;

const AddEmailID = ({ location }) => {
  const [setSucessSnack] = useSnackbar({ color: 'accent' });
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [wasResetCodeSent, setWasResetCodeSent] = useState(false);
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [redirectEmail, setRedirect] = useState(false);
  const [isSendingPasscode, setIsSendingPasscode] = useState(false);
  const [modal, setModal] = useState(false);
  const [emailModal, setEmailModal] = useState(null);
  const [isSignInHelpOpen, setIsSignInHelpOpen] = useState(false);
  const { breakpoints, shadows } = useContext(ThemeContext);
  const { redirect } = parse(location.search);
  const { t } = useTranslation();
  const selectedLang = localStorage.getItem('lang');
  const height = window.innerHeight;
  const width = window.innerWidth;
  // eslint-disable-next-line camelcase
  const {
    empId,
    groupNumber,
    flow,
    emp_id: empID,
    group_number: grpNumber,
    user_id: userId,
  } = parse(location.search);
  const emailId = get(location, 'state.emailId', null);
  const backgroundSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        backgroundImage: `url(${config.promoImageUrl})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '95%',
      };
    }
    return {};
  };

  const boxShadowSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        boxShadow: shadows[4],
      };
    }
    return {};
  };

  const setSSOEMail = async (email) => {
    const setEmail = await (
      await fetch(config.apollo.url, {
        body: JSON.stringify({
          query: print(mutationRemoteAction),
          variables: {
            name: 'sso-update-email',
            params: JSON.stringify({
              email,
              empId: empID,
              groupNumber: grpNumber,
              userId,
            }),
          },
        }),
        headers: {
          'content-type': 'application/json',
          'x-api-key': config.apollo.apiKey,
        },
        method: 'POST',
      })
    ).json();
    if (setEmail.data) {
      const { relayUrl } = JSON.parse(setEmail.data.mutationRemoteAction.data);
      if (relayUrl) {
        setIsSendingPasscode(false);
        return navigate(`/${relayUrl}`);
      }
      setIsSendingPasscode(false);
    } else {
      setIsSendingPasscode(false);
      setErrorSnack(t('addEmail.notRegistered'), config.notificationDuration);
    }
  };

  const sendCode = async (email) => {
    const usersWithEmailAddress = await (
      await fetch(config.apollo.url, {
        body: JSON.stringify({
          query: print(mutationRemoteAction),
          variables: {
            name: 'user-exists',
            params: JSON.stringify({ email }),
          },
        }),
        headers: {
          'content-type': 'application/json',
          'x-api-key': config.apollo.apiKey,
        },
        method: 'POST',
      })
    ).json();
    if (usersWithEmailAddress?.data) {
      const rawJson = JSON.parse(
        usersWithEmailAddress.data?.mutationRemoteAction?.data
      );
      const emailExists = rawJson?.content?.length > 0;
      if (emailExists) {
        setIsSendingPasscode(false);
        return setErrorSnack(
          t('snack.error.emailInUse'),
          config.notificationDuration
        );
      }
    } else {
      setIsSendingPasscode(false);
      return setErrorSnack(t('snack.error.error'), config.notificationDuration);
    }
    const resendVerification = await (
      await fetch(config.apollo.url, {
        body: JSON.stringify({
          query: print(mutationRemoteAction),
          variables: {
            name: 'send-email-code',
            params: JSON.stringify({ email }),
          },
        }),
        headers: {
          'content-type': 'application/json',
          'x-api-key': config.apollo.apiKey,
        },
        method: 'POST',
      })
    ).json();
    if (resendVerification.data) {
      setModal(t('addEmail.verificationCodeSentToEmail', { email }));
      setWasResetCodeSent(true);
    } else {
      setErrorSnack(t('snack.error.error'), config.notificationDuration);
    }
    setIsSendingPasscode(false);
  };

  const checkCode = async (email, passcode) => {
    const resendVerification = await (
      await fetch(config.apollo.url, {
        body: JSON.stringify({
          query: print(mutationRemoteAction),
          variables: {
            name: 'check-email-code',
            params: JSON.stringify({
              email,
              empId,
              flow,
              groupNumber,
              passcode,
            }),
          },
        }),
        headers: {
          'content-type': 'application/json',
          'x-api-key': config.apollo.apiKey,
        },
        method: 'POST',
      })
    ).json();
    // redirect to '/login' when redirect=alternate in path
    if (resendVerification.data) {
      const { message, success } = JSON.parse(
        resendVerification.data.mutationRemoteAction.data
      );
      if (success) {
        setSucessSnack(
          t('snack.success.redirecting'),
          config.notificationDuration
        );
        if (flow === 'IDP') {
          await setSSOEMail(email.toLowerCase().trim());
          return;
        }
        if (redirect === 'alternate') {
          setRedirectLogin(true);
        } else {
          setRedirect(email);
        }
      } else {
        setErrorSnack(message, config.notificationDuration);
      }
    } else if (redirect === 'alternate') {
      if (resendVerification?.errors.length > 0) {
        setErrorSnack(
          resendVerification?.errors[0]?.message,
          config.notificationDuration
        );
      }
    } else {
      setErrorSnack(t('addEmail.notRegistered'), config.notificationDuration);
    }
  };

  const updateEmployeeEmail = async (groupNumber, empId, email) => {
    try {
      const updateEmailResponse = await (
        await fetch(config.apollo.url, {
          body: JSON.stringify({
            query: print(mutationRemoteAction),
            variables: {
              name: 'alternative-registration-create-cognito-user',
              params: JSON.stringify({
                email,
                empId,
                groupNumber,
              }),
            },
          }),
          headers: {
            'content-type': 'application/json',
            'x-api-key': config.apollo.apiKey,
          },
          method: 'POST',
        })
      ).json();
      if (updateEmailResponse?.data) {
        const { userId = null } = JSON.parse(
          get(updateEmailResponse, 'data.mutationRemoteAction.data', {})
        );
        if (userId) {
          localStorage.setItem('AltUserId', userId);
          return setEmailModal(t('modal.tempPasswordCheckSpam'));
        }
      }
      if (updateEmailResponse?.errors.length > 0) {
        return setModal(updateEmailResponse?.errors[0]?.message);
      }
      setErrorSnack(
        t('snack.error.notRegistered'),
        config.notificationDuration
      );
    } catch (e) {
      return setErrorSnack(
        t('snack.error.contactIAICAdmin'),
        config.notificationDuration
      );
    } finally {
      setIsSendingPasscode(false);
    }
  };

  if (redirectLogin) {
    return <Redirect to="/login" when />;
  }
  if (redirectEmail) {
    return (
      <Redirect
        to={`/build-profile?email=${redirectEmail}&groupNumber=${groupNumber}`}
        when
      />
    );
  }
  return (
    <Box
      bg={{ _: 'primary', lg: 'white' }}
      display="flex"
      flexDirection={{ _: 'column', lg: 'row' }}
      height={height}
      width={width}
    >
      <EmailSentModal modal={modal} setModal={setModal} />
      {emailModal && (
        <Modal
          closeText={t('common.ok')}
          isOpen
          onClose={() => {
            setEmailModal(null);
            navigate(
              `/login?alternateRegistration=true&email=${encodeURIComponent(
                emailId
              )}`
            );
          }}
          shouldCloseOnOverlayClick={false}
        >
          {emailModal}
        </Modal>
      )}
      <Box
        bg={{ _: 'primary', lg: 'white' }}
        display="flex"
        height={{ lg: '100%' }}
        justifyContent={{ _: 'center', lg: 'flex-start' }}
        sx={{ ...backgroundSx() }}
        width={{ lg: '50%' }}
      >
        {width >= pixelToNumber(breakpoints[3]) ? (
          <Logo
            marginLeft={{ lg: 5 }}
            mono={width < pixelToNumber(breakpoints[3])}
            sx={{
              mt: '30px',
              top: '0',
            }}
          />
        ) : (
          <Logo
            mono
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              mt: '50px',
              p: 1,
              svg: {
                height: 'auto',
              },
              width: '20rem',
            }}
          />
        )}
        {width >= pixelToNumber(breakpoints[3]) && (
          <Box
            as="h1"
            color="primary"
            fontSize={7}
            sx={{
              ml: 5,
              mt: 5,
              position: [null, null, null, null, 'absolute'],
              top: '13rem',
              width: '30%',
            }}
          >
            <Box>{t('auth.subtitle1')}</Box>
            <Box>{t('auth.subtitle2')}</Box>
          </Box>
        )}
      </Box>
      <Box
        alignItems={['flex-start', null, 'center']}
        bg="primary"
        display="flex"
        height="auto"
        justifyContent="center"
        p={5}
        sx={{
          ...boxShadowSx(),
        }}
        width={{ lg: '50%' }}
      >
        <FinalForm
          onSubmit={async ({ email, passcode }) =>
            checkCode(email.toLowerCase().trim(), passcode)
          }
          render={(formContext) => {
            return (
              <Box maxWidth="maxWidths.smallForm" mx="auto" width="100%">
                {config.canadaEnv && (
                  <Box
                    sx={{
                      position: 'fixed',
                      right: '1rem',
                      top: '1rem',
                      width: 300,
                    }}
                  >
                    <Form
                      initialValues={{
                        language: selectedLang || i18n.language,
                      }}
                      onSubmit={() => {}}
                      render={() => (
                        <>
                          <Field
                            component={Dropdown}
                            isSearchable={false}
                            name="language"
                            options={
                              config.canadaEnv
                                ? [
                                    {
                                      canEnglishValue: null,
                                      frenchValue: 'Français Canadien',
                                      label: 'Français Canadien',
                                      value: 'fr',
                                    },
                                    {
                                      canEnglishValue: null,
                                      frenchValue: null,
                                      label: 'Canadian English',
                                      value: 'caEn',
                                    },
                                  ]
                                : [
                                    {
                                      canEnglishValue: null,
                                      frenchValue: 'Français Canadien',
                                      label: 'Français Canadien',
                                      value: 'fr',
                                    },
                                    {
                                      canEnglishValue: null,
                                      frenchValue: null,
                                      label: 'Canadian English',
                                      value: 'caEn',
                                    },
                                    {
                                      canEnglishValue: null,
                                      frenchValue: null,
                                      label: 'American English',
                                      value: 'en',
                                    },
                                  ]
                            }
                          />
                          <OnChange name="language">
                            {(lang) => {
                              i18n.changeLanguage(lang);
                              localStorage.setItem('lang', lang);
                            }}
                          </OnChange>
                        </>
                      )}
                    />
                  </Box>
                )}
                <Box
                  color="white"
                  fontSize={7}
                  mb={[4, null, 6]}
                  textAlign={{ _: 'center', lg: 'left' }}
                >
                  {t('addEmail.verify')}
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  width="100%"
                >
                  <Box
                    as="button"
                    color="accentSecondaryDark"
                    fontSize={4}
                    fontWeight={600}
                    onClick={() => setIsSignInHelpOpen(!isSignInHelpOpen)}
                  >
                    {t('addEmail.signInHelp')}
                  </Box>
                </Box>
                {!config.canadaEnv && isSignInHelpOpen && (
                  <Box color="white" my={5}>
                    <Box mb={4}>
                      {t('addEmail.contactIaicTechnicalQuestions')}
                    </Box>
                    <PhoneButton number="844-738-4242" />
                    <EmailButton email="IAIC.pets@independenceamerican.com" />
                  </Box>
                )}
                {config.canadaEnv && isSignInHelpOpen && (
                  <Box color="white" my={5}>
                    <Box mb={4}>
                      {t('addEmail.contactOnepackTechnicalQuestions')}
                    </Box>
                    <PhoneButton number="833-310-7225" />
                    <EmailButton email="mypolicy@onepackplan.ca" />
                  </Box>
                )}
                <form onSubmit={formContext.handleSubmit}>
                  <Field
                    component={Input}
                    initialValue={emailId}
                    label={t('addEmail.email')}
                    labelSx={{
                      color: 'white',
                    }}
                    name="email"
                    readOnly={wasResetCodeSent || (emailId ?? '') !== ''}
                    style={
                      wasResetCodeSent || (emailId ?? '') !== ''
                        ? { pointerEvents: 'none' }
                        : { pointerEvents: 'auto' }
                    }
                    validate={composeValidations(
                      required,
                      email,
                      checkEmail(emailId)
                    )}
                  />
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      mt: 6,
                    }}
                  >
                    <HoverableButton
                      disabled={
                        (!formContext.values.empId &&
                          !formContext.values.email &&
                          !formContext.values.groupNumber) ||
                        !!formContext.errors.empId ||
                        !!formContext.errors.email ||
                        !!formContext.errors.groupNumber ||
                        wasResetCodeSent
                      }
                      onClick={async () => {
                        setIsSendingPasscode(true);
                        if (redirect === 'alternate') {
                          await updateEmployeeEmail(
                            groupNumber,
                            empId,
                            formContext.values.email.toLowerCase().trim()
                          );
                          return;
                        }
                        await sendCode(
                          formContext.values.email.toLowerCase().trim()
                        );
                      }}
                      submitting={isSendingPasscode}
                      sx={{
                        bg: 'accent',
                        mr: 3,
                      }}
                      width="100%"
                    >
                      {flow === 'alternate'
                        ? t('addEmail.verifyEmail')
                        : t('addEmail.sendVerificationCode')}
                    </HoverableButton>
                    {wasResetCodeSent && (
                      <CountdownCircleTimer
                        colors={[
                          ['#7ED321', 0.33],
                          ['#FF7F00', 0.33],
                          ['#FF0000', 0.33],
                        ]}
                        duration={300}
                        initialRemainingTime={300}
                        isPlaying
                        onComplete={() => setWasResetCodeSent(false)}
                        size={30}
                        strokeLinecap="square"
                        strokeWidth={3}
                      />
                    )}
                  </Box>
                  {redirect !== 'alternate' && (
                    <>
                      <Field
                        component={Input}
                        label={t('addEmail.verificationCode')}
                        labelSx={{
                          color: 'white',
                        }}
                        name="passcode"
                        readOnly={!wasResetCodeSent}
                        style={
                          !wasResetCodeSent
                            ? { pointerEvents: 'none' }
                            : { pointerEvents: 'auto' }
                        }
                        validate={composeValidations(required)}
                      />
                      <ToolbarButton
                        label={t('addEmail.verifyEmail')}
                        submitting={formContext.submitting}
                        sx={{
                          ml: 0,
                          mt: 6,
                        }}
                        type="submit"
                        width="100%"
                      />
                    </>
                  )}
                </form>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
};

AddEmailID.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string, state: PropTypes.shape({
            empId: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default AddEmailID;
