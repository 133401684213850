import Box from '@basecomponents/Box';
import arrayMutators from 'final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import React, { useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { ApolloConsumer } from '@apollo/client';
import { composeValidations, required } from '@utils/validators';
import {CA_PROVINCES, notificationTypes, settingLevel, US_STATES} from '@utils/constants';
import GetData from "@src/utilities/get-data";
import ToolbarButton from "@basecomponents/ToolbarButton";
import Route from "@petcomponents/Route";
import Spinner from "@basecomponents/Spinner";
import Card from "@basecomponents/Card";
import remoteActionQuery from "@queries/remote-action.gql";
import useSnackbar from "@src/utilities/use-snackbar";
import NotificationSettingItemWithCheckbox from "@petcomponents/Settings/Notifications/NotificationSettingItemWithCheckbox";
import config from "@src/config.json";

const isGreaterThanZero = (value) => {
  if (value <= 0) {
    return `Must be greater than Zero`;
  }
};

/**
 * @category Settings
 * @param {*} rest
 * @returns {React.FC}
 */
const EmployeeNotificationSettings = ({ ...rest }) => {
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSuccessSnack] = useSnackbar({ color: 'accent' });
  const [isRefetching, setIsRefetching] = useState(null);

  const {
    apiData: globalSettingsData = {},
    loading: globalSettingDataLoading,
    refetch: globalSettingDataRefetch,
  } = GetData(
    'get-all-system-settings',
    JSON.stringify({
      settingLevel: 'GLOBAL',
      settingType: notificationTypes.enrollmentStartNotification,
    })
  );

  const {
    apiData: stateSettingsData = {},
    loading: stateSettingsDataLoading,
    refetch: stateSettingsDataRefetch,
  } = GetData(
    'get-all-system-settings',
    JSON.stringify({
      settingLevel: 'STATE',
      settingType: notificationTypes.enrollmentStartNotification,
      size: config.canadaEnv ? CA_PROVINCES.length :US_STATES.length,
    })
  );

  const { apiData: constantsData = {} } = GetData(
    'get-enum-options',
    JSON.stringify([{ params: ['SettingType', 'SettingLevel'] }])
  );

  const globalSettingsObj = {
    globalSettings:
      globalSettingsData?.content?.[0]?.settingValue === '-1'
        ? '1'
        : globalSettingsData?.content?.[0]?.settingValue,
    'globalSettings-checkbox':
      globalSettingsData?.content?.[0]?.settingValue === '-1',
  };

  const getStateSettingsValue = (value) => {
    return stateSettingsData?.content?.find(
      ({ identifier }) => identifier === value
    )?.settingValue;
  };

  const getRenewalNotifcationDays = (value) => {
    if (value === '-1') {
      return '';
    }

    if (value) {
      return value;
    }
    return 0;
  };

  let stateSettings = [];

  const stateList = config.canadaEnv ? CA_PROVINCES : US_STATES;

  stateSettings = stateList.map((item) => {
    const temp = item;
    temp.renewalNotificationDays = getRenewalNotifcationDays(
      getStateSettingsValue(item.value)
    );
    temp['renewalNotificationDays-checkbox'] =
      getStateSettingsValue(item.value) === '-1';
    return temp;
  });

  const getSettingLevel = (settingLevel) => {
    const item = constantsData?.SettingLevel?.find(
      ({ value }) => value === settingLevel
    );
    return item?.value;
  };

  const getSettingType = (settingType) => {
    const item = constantsData?.SettingType?.find(
      ({ value }) => value === settingType
    );
    return item?.value;
  };

  if (stateSettingsDataLoading || globalSettingDataLoading) {
    return <Spinner />;
  }

  return (
    <Route isPrivate permission="setting.page.notifications" {...rest}>
      <Box as="h2" sx={{ py: 3 }}>
        Employee Notification Settings
      </Box>
      <Box as="h4">
        All Global and State settings for any configurable employee
        notifications.
      </Box>
      <ApolloConsumer>
        {(client) => {
          const onAddGlobalAndStateSettings = async (values) => {
            setIsRefetching(true);

            const removeMaskedgGlobalSettingsValue =
              values?.globalSettingsObj?.globalSettings
                ?.match?.(/\d/g)
                ?.join('');

            const globalSetttingsApiData = {
              identifier: 'GLOBAL',
              settingLevel: getSettingLevel(settingLevel.global),
              settingType: getSettingType(
                notificationTypes.enrollmentStartNotification
              ),
              settingValue: values?.globalSettingsObj?.[
                'globalSettings-checkbox'
              ]
                ? -1
                : removeMaskedgGlobalSettingsValue,
            };

            await client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'create-system-settings',
                  params: JSON.stringify({ ...globalSetttingsApiData }),
                },
              })
              .then(() => {
                Object.values(values?.stateSettings).forEach(
                  async (settings) => {
                    const removeMaskedStateSettingsValue =
                      settings?.renewalNotificationDays
                        ?.match?.(/\d/g)
                        ?.join('');

                    if (
                      removeMaskedStateSettingsValue !==
                      removeMaskedgGlobalSettingsValue
                    ) {
                      const stateSettingsObj = {
                        identifier: settings?.value,
                        settingLevel: getSettingLevel(settingLevel.state),
                        settingType: getSettingType(
                          notificationTypes.enrollmentStartNotification
                        ),
                        settingValue: settings?.[
                          'renewalNotificationDays-checkbox'
                        ]
                          ? -1
                          : removeMaskedStateSettingsValue ?? '0',
                      };

                      await client
                        .query({
                          fetchPolicy: 'no-cache',
                          query: remoteActionQuery,
                          variables: {
                            name: 'create-system-settings',
                            params: JSON.stringify({ ...stateSettingsObj }),
                          },
                        })
                        .then(async () => {
                          setSuccessSnack(
                            'Notifications Updated Successfully',
                            config.notificationDuration
                          );
                          await globalSettingDataRefetch();
                          await stateSettingsDataRefetch();
                          setIsRefetching(false);
                        })
                        .catch((e) => {
                          setErrorSnack(
                            `There was an error: ${e.message}`,
                            config.notificationDuration
                          );
                          setIsRefetching(false);
                        });
                    }
                  }
                );
              })
              .catch((e) => {
                setErrorSnack(
                  `There was an error: ${e.message}`,
                  config.notificationDuration
                );
              });
          };

          return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Card sx={{ width: '30rem' }}>
                <Box as="h2" sx={{ py: 5 }}>
                  Renewal Approaching Notification Settings
                </Box>
                <Box as="h4">
                  Set number of days prior to the enrolment starting period at
                  which a notification email must go out to the employees.
                  Individual group settings can be configured on the Group
                  Details Page.
                </Box>
                {isRefetching ? (
                  <Spinner />
                ) : (
                  <FinalForm
                    initialValues={{
                      globalSettingsObj,
                      stateSettings,
                    }}
                    mutators={{
                      ...arrayMutators,
                    }}
                    onSubmit={onAddGlobalAndStateSettings}
                    render={({ handleSubmit, submitting, form }) => (
                      <form onSubmit={handleSubmit}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            mb: 1,
                            mt: 6,
                            width: '100%',
                          }}
                        >
                          <ToolbarButton
                            bg="primary"
                            isDisabled={submitting}
                            isLoading={submitting}
                            label="Save"
                            mr={0}
                            submitting={submitting}
                            type="submit"
                            width="150px"
                          />
                        </Box>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Card
                            cardSx={{
                              boxShadow: 6,
                              width: '27rem',
                            }}
                          >
                            <NotificationSettingItemWithCheckbox
                              name="globalSettingsObj.globalSettings"
                              title="Global Settings"
                              validators={composeValidations(
                                required,
                                isGreaterThanZero
                              )}
                            />
                            <OnChange name="globalSettingsObj.globalSettings">
                              {(value) => {
                                if (value) {
                                  stateSettings.forEach((item, index) => {
                                    if (item.renewalNotificationDays === '0') {
                                      form.change(
                                        `stateSettings[${index}].renewalNotificationDays`,
                                        value
                                      );
                                    }
                                  });
                                }
                              }}
                            </OnChange>
                          </Card>
                        </Box>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <Card
                            cardSx={{
                              boxShadow: 6,
                              width: '27rem',
                            }}
                          >
                            <FieldArray name="stateSettings">
                              {({ fields }) =>
                                fields.map((name, index) => {
                                  return (
                                    <Box
                                      key={`${index}-notificationSettingItem`}
                                      sx={{ mb: 7 }}
                                    >
                                      <NotificationSettingItemWithCheckbox
                                        name={`${name}.renewalNotificationDays`}
                                        title={stateSettings[index].label}
                                      />
                                    </Box>
                                  );
                                })
                              }
                            </FieldArray>
                          </Card>
                        </Box>
                      </form>
                    )}
                  />
                )}
              </Card>
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

export default EmployeeNotificationSettings;
