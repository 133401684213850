import PropTypes from 'prop-types';
import React from 'react';
import { navigate, useParams } from '@reach/router';
import { get } from 'lodash';
import { ApolloConsumer } from '@apollo/client';
import Box from '@basecomponents/Box';
import { Field, Form as FinalForm } from 'react-final-form';
import { required } from '@src/utilities/validators';
import {
  billingAccountNumberMask,
  billingRoutingNumberMask,
  institutionNumberMask,
  transitNumberMask,
} from '@utils/masks';
import { composeValidations, legalInstitutionNumber, legalRoutingNumber, legalTransitNumber } from '@utils/validations';
import { useTranslation } from 'react-i18next';
import Route from "@petcomponents/Route";
import remoteActionQuery from "@queries/remote-action.gql";
import { labelHandler } from "@src/utilities/label-utils";
import ToolbarButton from "@basecomponents/ToolbarButton";
import useSnackbar from "@src/utilities/use-snackbar";
import GetData from "@src/utilities/get-data";
import KeyValuePairs from "@basecomponents/KeyValuePairs";
import getMetaData from "@src/utilities/get-meta-data";
import Spinner from "@basecomponents/Spinner";
import Card from "@basecomponents/Card";
import InputMasked from "@basecomponents/InputMasked";
import config from "@src/config.json";

/**
 * @category Group
 * @param {*} rest
 * @returns {React.FC}
 */
const UpdateAccountInfo = ({ ...rest }) => {
  const commonFieldSxHalf = {
    padding: 3,
    width: '25rem',
  };
  const { t } = useTranslation()

  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const [setSucessSnack] = useSnackbar({ color: 'accent' });

  const params = useParams();
  const groupId = get(params, 'groupId', '');
  const { apiData: groups = {}, loading } = GetData(
    'get-group-by-id',
    JSON.stringify({ id: groupId }),
    !groupId
  );
  const accountNumber = get(groups, 'billingInfo.accountNumber', null);
  const billingInformationId = get(groups, 'billingInfo.billingInfoId', '');
  const billingInfoResponse = {
    accountName: get(groups, 'billingInfo.accountName', ''),
    accountType: get(groups, 'billingInfo.accountType', ''),
    billingCycle: get(groups, 'billingInfo.billingCycle', ''),
    billingType: get(groups, 'billingInfo.billingType', ''),
  };
  const billingMetaData = getMetaData({ moduleName: 'billing' });
  if (loading) {
    return <Spinner />;
  }
  return (
    <Route
      header={{
        title: 'Update Account Info',
        type: 'billing',
      }}
      isPrivate
      {...rest}
    >
      <Box as="h2" sx={{ py: 3 }}>
        Update Banking Info
      </Box>
      <ApolloConsumer>
        {(client) => {
          const onAddCredit = async (values) => {
            const { accountNumber, routingNumber } = values;
            await client
              .query({
                fetchPolicy: 'no-cache',
                query: remoteActionQuery,
                variables: {
                  name: 'update-billing-info',
                  params: JSON.stringify({
                    accountNumber,
                    id: get(groups, 'billingInfo.billingInfoId', ''),
                    routingNumber,
                  }),
                },
              })
              .then(() => {
                setSucessSnack(
                  'Account Updated successfully!',
                  config.notificationDuration
                );
                navigate(`/groups/${groupId}/details`);
              })
              .catch((e) =>
                setErrorSnack(
                  `There was an error creating the user: ${e.message}`,
                  config.notificationDuration
                )
              );
          };

          return (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <FinalForm
                initialValues={
                  billingInformationId
                    ? {
                      ...billingInfoResponse,
                    }
                    : {}
                }
                keepDirtyOnReinitialize
                onSubmit={onAddCredit}
                render={({ handleSubmit, submitting, values }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        sx={{
                          bg: 'white',
                          borderRadius: 4,
                          boxShadow: 1,
                          padding: 3,
                        }}
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            borderRadius: 3,
                            boxShadow: 7,
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: 5,
                            ...commonFieldSxHalf,
                          }}
                        >
                          <Card>
                            <KeyValuePairs
                              columnCount={1}
                              data={{ ...values, accountNumber }}
                              fields={[
                                { name: 'accountType' },
                                { name: 'accountNumber' },
                                { name: 'billingType' },
                              ]}
                              meta={billingMetaData}
                            />
                          </Card>
                        </Box>
                        <Box flex="1" justifyContent="center">
                          {
                            config.canadaEnv ?
                              <>
                                <Field
                                  aria-label="Institution Number"
                                  component={InputMasked}
                                  label={labelHandler(t('claimsScreen.bankingInfo.institutionNumber'), true)}
                                  mask={institutionNumberMask}
                                  maxLength={16}
                                  name="institutionNumber"
                                  type="InputMasked"
                                  validate={composeValidations(required, legalInstitutionNumber)}
                                  {...rest}
                                />
                                <Field
                                  aria-label="Transit Number"
                                  component={InputMasked}
                                  label={labelHandler(t('claimsScreen.bankingInfo.transitNumber'), true)}
                                  mask={transitNumberMask}
                                  maxLength={16}
                                  name="transitNumber"
                                  type="InputMasked"
                                  validate={composeValidations(required, legalTransitNumber)}
                                  {...rest}
                                />
                              </>
                              :
                              <Field
                                component={InputMasked}
                                label={labelHandler('Billing Routing Number', true)}
                                mask={billingRoutingNumberMask}
                                name="routingNumber"
                                type="tel"
                                validate={composeValidations(
                                  required,
                                  legalRoutingNumber
                                )}
                                wrapperSx={{ ...commonFieldSxHalf }}
                                {...rest}
                              />
                          }
                          <Field
                            component={InputMasked}
                            label={labelHandler('Billing Account Number', true)}
                            mask={billingAccountNumberMask}
                            name="accountNumber"
                            type="tel"
                            validate={required}
                            wrapperSx={{ ...commonFieldSxHalf }}
                            {...rest}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              mt: '30px',
                            }}
                          >
                            <ToolbarButton
                              bg="error"
                              label={t('common.cancel')}
                              my={6}
                              onClick={() => {
                                navigate(`/groups/${groupId}/details`);
                              }}
                              width="150px"
                            />
                            <ToolbarButton
                              bg="accentDark"
                              isDisabled={submitting}
                              label="Save"
                              mr={0}
                              my={6}
                              submitting={submitting}
                              type="submit"
                              width="150px"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  );
                }}
              />
            </Box>
          );
        }}
      </ApolloConsumer>
    </Route>
  );
};

UpdateAccountInfo.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default UpdateAccountInfo;
