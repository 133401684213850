import { AuthContext } from '@basecomponents/Auth';
import Box from '@basecomponents/Box';
import Button from '@basecomponents/Button';
import Redirect from '@basecomponents/Redirect';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import { get } from 'lodash';
import { lighten, transparentize } from 'polished';
import React, { useContext, useEffect, useState } from 'react';
import { ApolloConsumer } from '@apollo/client';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Field, Form as FinalForm, Form } from 'react-final-form';
import { ThemeContext } from 'styled-components';
import updateLastLogin from '@src/utilities/ihc/updateLastLogin';
import EmailSentModal from '@basecomponents/EmailSentModal';
import useWindowSize from '@utils/hooks/windowSize';
import { useTranslation } from 'react-i18next';
import Dropdown from '@basecomponents/Dropdown';
import { OnChange } from 'react-final-form-listeners';
import i18n from '@src/utilities/i18n';
import { required } from '@utils/validators';
import Input from "@basecomponents/Input";
import Logo from "@petcomponents/Logo";
import Spinner from "@basecomponents/Spinner";
import ToolbarButton from "@basecomponents/ToolbarButton";
import mutationRemoteAction from "@mutations/mutation-remote-action.gql";
import localStorage from "@src/utilities/local-storage";
import pixelToNumber from "@src/utilities/pixel-to-number";
import useSnackbar from "@src/utilities/use-snackbar";
import config from "@src/config.json";

const PASSCODE_TIMEOUT_IN_SECONDS = 1800;

const SecondaryAuth = () => {
  const { breakpoints, shadows, colors } = useContext(ThemeContext);
  const { width } = useWindowSize();
  const [setErrorSnack] = useSnackbar({ color: 'error' });
  const selectedLang = localStorage.getItem('lang');

  const { user } = useContext(AuthContext);

  // function getDaysSinceLastLogin() {
  //   const customLastLoginTimestamp = get(
  //     user,
  //     'customLastLoginTimestamp',
  //     null
  //   );
  //   if (customLastLoginTimestamp) {
  //     // make date from customLastLoginTimestamp
  //     const lastLoginDate = new Date(customLastLoginTimestamp * 1000);
  //     // calculate number of days from lastLoginDate to now
  //     const daysSinceLastLogin = Math.floor(
  //       (new Date() - lastLoginDate) / (1000 * 60 * 60 * 24)
  //     );
  //     return daysSinceLastLogin;
  //   }
  //   return Infinity;
  // }

  // const daysSinceLastLogin = getDaysSinceLastLogin();
  // const [createMutation] = useMutation(mutationRemoteAction);
  const [passcodeRequired, setRequired] = useState(true);
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState(0);
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const effect = async () => {
      try {
        // if (daysSinceLastLogin > 10) {
        //   const result = await createMutation({
        //     variables: {
        //       name: 'request-mfa',
        //       params: JSON.stringify({
        //         email: user.email,
        //         mga: get(user, 'customMga', ''),
        //         resend: false,
        //         userId: user.sub,
        //       }),
        //     },
        //   });
        //   const mutationRes = JSON.parse(
        //     get(result, 'data.mutationRemoteAction.data', '{}')
        //   );
        //   setRequired(mutationRes.passcodeRequired);
        //   setLoading(mutationRes.loading);
        // } else {
        localStorage.setItem('secondaryAuthSuccessful', true);
        setRequired(false);
        setLoading(false);
        // }
      } catch (e) {
        if (Object.keys(user).length) {
          setErrorSnack(e.message);
        } else {
          // navigate('/login/?redirect=/secondary-auth/');
          // window.location.reload();
        }
      }
    };
    effect();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  const backgroundSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        backgroundImage: `url(${config.promoImageUrl})`,
        backgroundPosition: 'center bottom',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '95%',
      };
    }
    return {};
  };

  const boxShadowSx = () => {
    if (width >= pixelToNumber(breakpoints[3])) {
      return {
        boxShadow: shadows[4],
      };
    }
    return {};
  };

  return (
    (<ApolloConsumer>
      {(client) => {
        const apiCall = async (name, values, usefn) => {
          return client
            .mutate({
              mutation: mutationRemoteAction,
              variables: {
                name,
                params: JSON.stringify(values),
              },
            })
            .then((response) => {
              if (usefn) {
                usefn(
                  JSON.parse(
                    get(response, 'data.mutationRemoteAction.data', '{}')
                  )
                );
              }
            })
            .catch((e) =>
              setErrorSnack(
                t('snack.error.errorWithMessage', { message: e.message }),
                config.notificationDuration
              )
            );
        };
        return (
          (<div>
            <Redirect to="/home" when={passcodeRequired === false} />
            <EmailSentModal modal={modal} setModal={setModal} />
            <Box
              bg="primary"
              display="flex"
              flexDirection={{ _: 'column', lg: 'row' }}
              height="100vh"
              width="100%"
            >
              <Box
                bg={{ _: 'primary', lg: 'white' }}
                display="flex"
                height={{ lg: '100%' }}
                justifyContent={{ _: 'center', lg: 'flex-start' }}
                sx={{ ...backgroundSx() }}
                width={{ lg: '50%' }}
              >
                {width >= pixelToNumber(breakpoints[3]) ? (
                  <Logo
                    marginLeft={{ lg: 5 }}
                    mono={width < pixelToNumber(breakpoints[3])}
                    sx={{
                      mt: '30px',
                      top: '0',
                    }}
                  />
                ) : (
                  <Logo
                    mono
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      mb: '50px',
                      mt: '50px',
                      p: 1,
                      svg: {
                        height: 'auto',
                      },
                      width: '20rem',
                    }}
                  />
                )}
                {width >= pixelToNumber(breakpoints[3]) && (
                  <Box
                    as="h1"
                    color="primary"
                    fontSize={7}
                    sx={{
                      ml: 5,
                      mt: 5,
                      position: [null, null, null, null, 'absolute'],
                      top: '13rem',
                    }}
                  >
                    <Box>{t('auth.subtitle1')}</Box>
                    <Box>{t('auth.subtitle2')}</Box>
                  </Box>
                )}
              </Box>
              <Box
                alignItems="center"
                bg="primary"
                display="flex"
                flexWrap="wrap"
                // height={{ _: '100%', lg: height }}
                height={{ lg: '100%' }}
                justifyContent="center"
                paddingBottom={6}
                px={5}
                sx={{
                  ...boxShadowSx(),
                }}
                width={{ lg: '50%' }}
              >
                <FinalForm
                  onSubmit={async ({ passcode }, form) => {
                    await apiCall(
                      'check-mfa',
                      {
                        passcode,
                        userId: user.sub,
                      },
                      async (x) => {
                        try {
                          if (x.passcodeRequired === false) {
                            await updateLastLogin(user.email);
                            localStorage.setItem(
                              'secondaryAuthSuccessful',
                              true
                            );
                            navigate('/home');
                          }
                          if (x.message.includes("doesn't match")) {
                            throw new Error(x.message);
                          }
                        } catch (e) {
                          form.restart();
                          setErrorSnack(
                            t('snack.error.errorWithMessage', {
                              message: e.message,
                            }),
                            config.notificationDuration
                          );
                        }
                      }
                    );
                  }}
                  render={(formContext) => (
                    (<Box maxWidth="maxWidths.smallForm" mx="auto" width="100%">
                      {config.canadaEnv && (
                        <Box
                          sx={{
                            position: 'fixed',
                            right: '1rem',
                            top: '1rem',
                            width: 300,
                          }}
                        >
                          <Form
                            initialValues={{
                              language: selectedLang || i18n.language,
                            }}
                            onSubmit={() => {}}
                            render={() => (
                              <>
                                <Field
                                  component={Dropdown}
                                  isSearchable={false}
                                  name="language"
                                  options={
                                    config.canadaEnv
                                      ? [
                                          {
                                            canEnglishValue: null,
                                            frenchValue: 'Français Canadien',
                                            label: 'Français Canadien',
                                            value: 'fr',
                                          },
                                          {
                                            canEnglishValue: null,
                                            frenchValue: null,
                                            label: 'Canadian English',
                                            value: 'caEn',
                                          },
                                        ]
                                      : [
                                          {
                                            canEnglishValue: null,
                                            frenchValue: 'Français Canadien',
                                            label: 'Français Canadien',
                                            value: 'fr',
                                          },
                                          {
                                            canEnglishValue: null,
                                            frenchValue: null,
                                            label: 'Canadian English',
                                            value: 'caEn',
                                          },
                                          {
                                            canEnglishValue: null,
                                            frenchValue: null,
                                            label: 'American English',
                                            value: 'en',
                                          },
                                        ]
                                  }
                                />
                                <OnChange name="language">
                                  {(lang) => {
                                    i18n.changeLanguage(lang);
                                    localStorage.setItem('lang', lang);
                                  }}
                                </OnChange>
                              </>
                            )}
                          />
                        </Box>
                      )}
                      <Box
                        color="white"
                        fontSize={7}
                        mb={6}
                        textAlign={{ _: 'center', lg: 'left' }}
                      >
                        {t('auth.enterPasscode')}
                      </Box>
                      <Box
                        color="white"
                        fontSize={3}
                        mb={6}
                        textAlign={{ _: 'center', lg: 'left' }}
                      >
                        {t('auth.codeSent', {
                          minutes: PASSCODE_TIMEOUT_IN_SECONDS / 60,
                        })}
                      </Box>
                      <form onSubmit={formContext.handleSubmit}>
                        <Field
                          component={Input}
                          data-cy="passcode"
                          label={t('auth.passcode')}
                          labelSx={{
                            color: 'white',
                          }}
                          maxLength={6}
                          name="passcode"
                          validate={required}
                        />
                        <Box
                          alignItems="center"
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          mt={6}
                        >
                          <ToolbarButton
                            bg="accentDark"
                            data-cy="submit"
                            label={t('auth.verify')}
                            submitting={formContext.submitting}
                            sx={{ mr: 4 }}
                            type="submit"
                            width="100%"
                          />
                          <CountdownCircleTimer
                            key={key}
                            colors={[
                              ['#7ED321', 0.33],
                              ['#FF7F00', 0.33],
                              ['#FF0000', 0.33],
                            ]}
                            duration={PASSCODE_TIMEOUT_IN_SECONDS}
                            initialRemainingTime={PASSCODE_TIMEOUT_IN_SECONDS}
                            isPlaying
                            onComplete={() => {
                              setErrorSnack(
                                t('auth.exceededTimeLimit'),
                                config.notificationDuration
                              );
                            }}
                            size={30}
                            strokeLinecap="square"
                            strokeWidth={3}
                          />
                        </Box>

                        <Button
                          onClick={async () => {
                            try {
                              await apiCall('request-mfa', {
                                email: user.email, // resend: true,
                                mga: get(user, 'customMga', ''),
                                userId: user.sub,
                              });
                              setModal(t('auth.verificationCodeSent'));
                              setKey((prevKey) => prevKey + 1);
                            } catch (err) {
                              setErrorSnack(
                                t('snack.error.errorWithMessage', {
                                  message: err.message,
                                }),
                                config.notificationDuration
                              );
                            }
                          }}
                          simple
                          sx={{
                            '&:hover': {
                              color: `${lighten(0.1, `${colors.accent}`)}`,
                              transition: 'color ease-in-out 0.25s',
                            },
                            color: 'white',
                            display: 'flex',
                            fontSize: 2,
                            fontWeight: 'bold',
                            justifyContent: 'center',
                            mb: 5,
                            mt: 5,
                            width: '100%',
                          }}
                        >
                          {t('auth.dintReceiveCode')}
                        </Button>
                        <Button
                          onClick={async () => {
                            try {
                              await Auth.signOut().then(() =>
                                navigate('/login/?redirect=/secondary-auth/')
                              );
                            } catch (err) {
                              setErrorSnack(
                                t('snack.error.errorWithMessage', {
                                  message: err.message,
                                }),
                                config.notificationDuration
                              );
                            }
                          }}
                          simple
                          sx={{
                            color: transparentize(0.25, '#FFFFFF'),
                            display: 'flex',
                            fontSize: 2,
                            fontWeight: 'bold',
                            justifyContent: 'center',
                            mb: 5,
                            mt: 5,
                            textTransform: 'uppercase',
                            width: '100%',
                          }}
                        >
                          {t('auth.backToLogin')}
                        </Button>
                      </form>
                    </Box>)
                    // </form>
                  )}
                />
              </Box>
            </Box>
          </div>)
        );
      }}
    </ApolloConsumer>)
  );
};

export default SecondaryAuth;
